var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "aml-version-panel"
    }
  }, [_c('div', {
    staticClass: "header-row"
  }, [_c('h4', [_vm._v(_vm._s(_vm.$t("VersionHistory")))]), _c('img', {
    attrs: {
      "src": require("../../assets/images/close.svg"),
      "alt": ""
    },
    on: {
      "click": _vm.closePanel
    }
  })]), _c('div', [_c('v-row', {
    staticClass: "version-title-row"
  }, [_c('v-col', [_c('p', {
    staticClass: "label12 gray-text"
  }, [_vm._v(_vm._s(_vm.$t("Date")))])]), _c('v-col', [_c('p', {
    staticClass: "label12 gray-text"
  }, [_vm._v(_vm._s(_vm.$t("User")))])])], 1), _c('div', {
    staticClass: "versions-wrapper"
  }, _vm._l(_vm.aml, function (aml, index) {
    return _c('v-row', {
      key: index,
      staticClass: "version-row",
      class: {
        'version-row': index !== _vm.chosenIndex,
        'version-row gray': index === _vm.chosenIndex
      },
      on: {
        "click": function ($event) {
          return _vm.chooseVersion(index);
        }
      }
    }, [_c('v-col', [index === _vm.lastIndex ? _c('p', {
      staticClass: "label10 green-text"
    }, [_vm._v(" " + _vm._s(_vm.$t("CurrentVersion")) + " ")]) : _vm._e(), _c('p', [_vm._v(_vm._s(_vm.formatAmlEditDate(aml.createdAt)))])]), _c('v-col', {
      staticStyle: {
        "align-self": "flex-end"
      }
    }, [aml.creator ? _c('p', [_vm._v(_vm._s(aml.creator.name))]) : _vm._e()])], 1);
  }), 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }