<template>
  <div class="delete-overlay">
    <div class="main-wrapper">
      <v-row style="display:flex; flex-wrap:nowrap">
        <img
            style="margin-right: 10px; width:20px; height:20px"
            src="../../assets/images/info.svg"
            alt=""
        />
        <p class="content">{{$t('StageChangeWarning')}}</p>
      </v-row>
      <div class="buttons-row">
        <button @click="canceled" style="line-height: 16px" class="cancel content">{{ $t("Cancel") }}</button>
        <button
            @click="approved"
            style="background-color: black; color:white; line-height: 16px"
            class="delete content"
        >
          {{$t('Yes')}}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "StageChangeWarningModal",
  //props: [],
  data: () => ({
    refactoredFiles: [],
    isUploading: false,
    hovered: false,
    /* srcNormal: require("../../assets/images/fileIcon.svg"),
    srcHover: require("../../assets/images/trash-red.svg"), */
  }),
  created() {},
  watch: {},
  computed: {},
  methods: {
    canceled() {
      this.$emit("canceled");
    },
    approved() {
      this.$emit("approved");
      this.$emit("closePanel");
    },
  },
};
</script>
<style lang="scss" scoped>
.delete-overlay {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 8;
}
.main-wrapper {
  z-index: 10;
  width: 400px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 24px;
  background: #ffffff;
  border-radius: 8px;
}
.buttons-row {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-top: 40px;
  gap: 8px;
}
.content {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */
  /* black */
  color: rgba(0, 0, 0, 1);
}
</style>
