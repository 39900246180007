var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "accordion-container",
    style: _vm.accordionStyle
  }, [_c('div', {
    style: _vm.headerStyle
  }, [_c('div', {
    staticClass: "accordion-header",
    on: {
      "click": function ($event) {
        return _vm.changeAccordionState();
      }
    }
  }, [_c('img', {
    class: {
      'accordion-image-closed': !_vm.accordionStateActive(),
      'accordion-image-open': _vm.accordionStateActive()
    },
    attrs: {
      "alt": "",
      "src": require("../../assets/images/chevron_24_24.svg")
    }
  }), _c('div', {
    staticClass: "accordion-title-and-counter"
  }, [!_vm.headingSmall ? _c('h5', [_vm._v(_vm._s(_vm.title))]) : _vm._e(), _vm.headingSmall ? _c('h6', [_vm._v(_vm._s(_vm.title))]) : _vm._e(), _vm.counter !== undefined ? _c('div', {
    staticClass: "accordion-counter",
    class: {
      'accordion-counter-with-items': _vm.accordionCounterBackgroundRed && _vm.counter > 0
    }
  }, [_c('p', {
    staticClass: "content-small"
  }, [_vm._v(_vm._s(_vm.counter))])]) : _vm._e(), _vm.toggleButton ? _c('div', {
    attrs: {
      "id": "toggle-div"
    }
  }, [_c('div', {
    staticClass: "toggle-wrapper"
  }, [_c('img', {
    staticStyle: {
      "cursor": "pointer"
    },
    attrs: {
      "src": _vm.toggleStatus ? require('../../assets/images/toggle-active.svg') : require('../../assets/images/toggle-inactive.svg'),
      "alt": ""
    },
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.clickOnToggle.apply(null, arguments);
      }
    }
  }), _c('p', {
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.clickOnToggle.apply(null, arguments);
      }
    }
  }, [_vm._v(_vm._s(_vm.toggleText))])])]) : _vm._e()])]), _vm.showAccordionButton ? [_vm.buttonName ? _c('BaseButton', {
    attrs: {
      "items": _vm.buttonDropdownItems,
      "display-key": _vm.buttonDisplayKey,
      "display-value": _vm.buttonValueKey,
      "size": 'small',
      "icon-left": _vm.buttonIcon,
      "label": _vm.buttonName
    },
    on: {
      "click": _vm.buttonPressed,
      "itemSelected": _vm.itemSelected
    }
  }) : _vm._e()] : _vm._e()], 2), _vm.accordionStateActive() ? _c('div', [_vm._t("default")], 2) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }