<template>
  <div
      id="main-container"
      class="main-content"
      v-if="listings && listings.length > 0"
  >
    <div
        v-for="(listing, index) in listings"
        :key="listing._id"
        class="listing-card"
    >
      <ProjectListingHeader
          :listing="listing"
          :listing-index="index"
          :open-house="openHouse"
          :additional-activities="additional_activities"
          :listing-refresh-key="projectListingRefreshKey"
          :is-project-inactive="isProjectInactive"
          :deal-pipeline-step="dealPipelineStep"
          @statusChanged="changeListingStatuses"
          @openListingPanel="openListingPanel"
          @openStatusDropdown="openStatusDropdown"
          @selectActivity="selectActivity"
          @changeActiveUntil="changeActiveUntil"
      />
      <div
          class="kanban"
          :id="'kanbanRow' + listing._id"
      >
        <div
            v-if="listing.funnels"
            style="overflow-y: scroll; display: flex"
        >
          <LeadsKanban
              @leadOpened="openKanbanLead"
              @leadsOpened="open = true"
              @leadsClosed="open = false"
              :isProjectInactive="isProjectInactive"
              :kanban-updater="kanbanUpdater"
              :listing="listing"
              :project="true"
              style="cursor: auto; min-width: 1440px"
          />
        </div>
      </div>
    </div>
    <ListingSidepanel
        @overlayClicked="closeListingPanel"
        @updateStatuses="updateFromSidepanel"
        @statusChanged="changeListingStatuses"
        v-if="listingPanelOpen"
        :listing-id="chosenListingId"
    />
    <StatusDropdown
        @closed="statusesOpen = false"
        @statusChanged="changeListingStatuses"
        v-if="statusesOpen"
        :position="position"
        :listing="selectedListing"
    />
    <ClientSidepanel
        @panelClosed="leadPanelKanbanOpen = false"
        @refreshLeads="getObjectListings"
        :customer-data="clickedLead.leadId"
        :customer-data-leads-list="clickedLead.customerId"
        v-if="leadPanelKanbanOpen"
    />
    <!-- <BoardLeads :from-project="true" /> -->
    <DeleteModal
        @canceled="isDeleteModalOpen = false"
        @approved="deleteListing()"
        v-if="isDeleteModalOpen"
    />
    <ConfirmationModal
      v-if="confirmModalOpen"
      @onConfirm="createNewId"
      @onCancel="() => (confirmModalOpen = false)"
    >
      <div class="text-center">
        {{ $t("CreateNewId") }}
      </div>
    </ConfirmationModal>
  </div>
</template>

<script>
import LeadsKanban from "@/components/Listings/LeadsKanban";
import ListingSidepanel from "@/components/Listings/ListingSidepanel";
import StatusDropdown from "@/components/Listings/StatusDropdown";
import ClientSidepanel from "@/components/Kliendiregister/ClientSidepanel";
import axios from "axios";
import {mapActions, mapGetters} from "vuex";
import nanoid from "nanoid";
import DeleteModal from "@/components/common/DeleteModal";
import listingApi from "@/http/listing";
import ProjectListingHeader from "@/components/Deals/Detail/ProjectListingHeader";
import ConfirmationModal from "@/components/common/ConfirmationModal";

export default {
  name: "ListContent",
  props: [
    "objectListings",
    "openHouse",
    "dealIdentificator",
    "listingRefreshKey",
    "isProjectInactive",
    "dealPipelineStep",
  ],
  components: {
    ConfirmationModal,
    ProjectListingHeader,
    LeadsKanban,
    ListingSidepanel,
    StatusDropdown,
    ClientSidepanel,
    DeleteModal,
  },
  data() {
    return {
      statusValues: null,
      kanbanUpdater: 0,
      projectListingRefreshKey: 0,
      clickedLead: null,
      leadPanelKanbanOpen: false,
      position: {},
      statusesOpen: false,
      listings: null,
      usableListings: [],
      dealId: null,
      open: false,
      clickedListing: null,
      isDeleteModalOpen: false,
      confirmModalOpen: false,
      chosenListingId: null,
      listingPanelOpen: false,
      outputsModals: [],
      selectedOutputs: [],
      brokerAccountsCity24: [],
      brokerAccountsKv24: [],
      brokerAccountsKv: [],
      brokerAccountsWww: [],
      archivedListings: null,
      userInSharedCreators: false,
      listing: null,
      listingInfo: null,
      listingId: null,
      colleagues: [
        {
          name: this.$t("TransferOwnership"),
          value: "allBrokers",
          _id: nanoid(16),
        },
      ],
      selectedListing: null,
      account_kv24: "kati@maakler.ee (4/20)",
      account_city24: "mati@maakler.ee (1/30)",
      account_kv_ee: "timo@maakler.ee (8/20)",
      account_www: "karl@maakler.ee (1/20)",
      additional_activities: [
        {name: this.$t("EditListing"), value: "edit_listing", _id: nanoid(8)},
        {
          name: this.$t("DuplicateListing"),
          value: "duplicate_listing",
          _id: nanoid(8),
        },
        {
          name: this.$t("Print/Preview"),
          value: "print_preview",
          _id: nanoid(8),
        },
        {
          name: this.$t("CreateNewId"),
          value: "create_new_id",
          _id: nanoid(8),
        },
        { name: this.$t("Delete"), value: "delete_listing", _id: nanoid(8) },
      ],
    };
  },
  async created() {
    console.log(this.user)
    await Promise.all([this.getObjectListings()]);
    this.brokerAccountsCity24 = this.user.listingAccounts.filter(
        (acc) => acc.platform == "city24"
    );
    this.brokerAccountsKv = this.user.listingAccounts.filter(
        (acc) => acc.platform == "kv"
    );
    this.brokerAccountsKv24 = this.user.listingAccounts.filter(
        (acc) => acc.platform == "kv24"
    );
  },
  mounted() {
    document.addEventListener("click", (event) => {
      this.listings.forEach((listing) => {
        const path = event.path || (event.composedPath && event.composedPath());
        path.forEach((innerPath) => {
          if (innerPath.id === listing._id) {
            this.listing = listing._id;
          }
        });
      });
    });
  },
  beforeDestroy() {
    document.removeEventListener("click", null);
  },
  computed: {
    ...mapGetters(["user", "users"]),
  },
  watch: {
    dealPipelineStep() {
      this.usableListings = [];
      this.getObjectListings();
    },
    listingRefreshKey() {
      this.projectListingRefreshKey++;
    },
    statusValues: function () {
      this.listings.forEach((listing) => {
        if (listing._id === this.statusValues.listingId) {
          listing.marketplaces = this.statusValues.marketplaces;
        }
      });
    },
  },
  methods: {
    ...mapActions(["getListings"]),
    adminRights(listing) {
      if (listing.creator._id === this.user._id) {
        return true;
      } else if (listing.userInSharedCreators) {
        return true
      } else if (this.colleagues.some((colleague) => listing.creator._id === colleague._id)) {
        return true;
      } else if (this.colleagues.some((colleague) => listing.sharedCreators.find((creator) => creator._id === colleague._id))) {
        return true;
      }
      return false;
    },
    async getArchivedListings() {
      let dealId = this.dealIdentificator;
      const response = await axios.get(
          `/api/listings/project/archived/${dealId}`
      );
      this.listings = response.data.listingsByDeal;
      this.usableListings = this.listings;
      if (this.listings.length < 1) {
        this.$emit("noListings");
        this.$emit("noArchivedListings");
      } else {
        this.$emit("listingsExist");
        this.$emit("archivedListingsExist");
      }
    },
    openKanbanLead(lead) {
      if (lead.module && lead.module === "projects") {
        this.$router.push({
          name: "DealDetail",
          params: { id: lead.leadId },
        });
      } else {
        this.leadPanelKanbanOpen = true;
        this.clickedLead = lead;
      }
    },

    async selectActivity(activity, listing) {
      this.listing = listing._id;
      switch (activity.value) {
        case "print_preview": {
          let routeData = this.$router.resolve({
            name: "ListingsFullDetail",
            params: {id: listing._id},
          });
          window.open(routeData.href, "_blank");
        }
          break;
        case "edit_listing":
          if (!this.hasAccess(listing)) {
            this.toastError(this.$t("noListingEditAccess"));
          } else {
            this.$router.push({
              name: "ListingsChange",
              params: {id: listing._id},
            });
          }
          break;
        case "duplicate_listing":
          if (!this.hasAccess(listing)) return;
          await axios.post("/api/listing/duplicate/single", {listingId: listing._id});
          location.reload();
          break;
        case "delete_listing":
          if (!this.hasAccess(listing)) return;
          this.isDeleteModalOpen = true;
          break;
        case "create_new_id":
          if (!this.hasAccess(listing)) return;
          this.confirmModalOpen = true
          break;
      }
    },
    async duplicateListings() {
      let listingIds = [];
      this.listings.forEach((listing) => {
        listingIds.push(listing._id);
      });
      await axios.post("/api/listing/duplicate/bulk", listingIds);
      //location.reload();
    },
    async deleteListings() {
      let listingIds = [];
      this.selectedListings.forEach((listing) => {
        listingIds.push(listing._id);
      });
      await axios.post("/api/listings/delete", {listingIds});
      location.reload();
    },
    deleteListing() {
      let listingId = this.listing;
      axios.post(`/api/listing/delete/${listingId}`);
      this.$router.go();
    },
    async changeActiveUntil(changedListing, newDate) {
      newDate = this.formatDate(newDate);
      this.usableListings.forEach((listing) => {
        if (listing._id === changedListing._id) {
          listing.activeUntil = newDate;
        }
      });
      let body = {
        listing: changedListing,
        newDate: newDate,
      };
      await listingApi.updateListingActiveUntil(body);
    },
    formatDate(date) {
      const allDate = date.split(" ");
      const thisDate = allDate[0].split("-");
      return [thisDate[2], thisDate[1], thisDate[0]].join(".");
    },
    clearListings() {
      this.selectedListings = [];
    },
    openStatusDropdown(listing, event) {
      this.position.right = window.innerWidth - event.clientX - 120;
      this.position.top = window.scrollY + event.clientY - 450;
      this.selectedListing = listing;
      this.statusesOpen = true;
    },
    async changeListingStatuses(event) {
      this.statusesOpen = false;
      this.listings.forEach((listing) => {
        if (listing._id === event.listingId) {
          listing.status = event.status;
          listing.bookedUntil = event.bookedUntil;
          if (event.activeUntil) {
            listing.activeUntil = event.activeUntil;
          }
        }
      });
      this.projectListingRefreshKey++;
    },
    openListingPanel(listingId) {
      this.chosenListingId = listingId;
      this.listingPanelOpen = true;
    },
    closeListingPanel() {
      this.listingPanelOpen = false;
    },
    async getObjectListings() {
      let listingIds = this.objectListings;
      let payload = {listingIds};
      let matchingListings = await axios.post("/api/listings/full", payload);
      this.usableListings = matchingListings.data.listings;
      console.log(matchingListings)
      if (this.dealPipelineStep === "Done") {
        console.log('DONE');
        await this.getArchivedListings();
      }
      if (this.usableListings.length >= 1) {
        this.listings = this.usableListings;
        this.$emit("listingsExist");
      } else if (
        (this.isProjectInactive && this.listings.length >= 1) ||
        (this.isProjectInactive && this.usableListings.length >= 1)
      ) {
        this.listings = this.usableListings;
        this.$emit("listingsExist");
      } else {
        this.listings = [];
        this.$emit("noListings");
      }
      this.dealId = this.dealIdentificator;
      this.listings.forEach(() => {
        this.outputsModals.push({status: false});
      });
      this.listings.forEach((listing) => {
        if (listing.sharedCreators) {
          listing.sharedCreators.forEach((creator) => {
            if (creator._id === this.user._id) {
              listing.userInSharedCreators = true
            }
          })
        }
      });
      // when change status to archived it dissapears backendlogic
      this.kanbanUpdater++;
    },
    updateFromSidepanel(statusValues) {
      this.statusValues = statusValues;
    },
    async createNewId() {
      this.confirmModalOpen = false;
      try {
        await listingApi.updateListingIdentifier(this.listing);
        this.toastSuccess(this.$t("NewIdCreated"));
      } catch (e) {
        this.toastError(this.$t("Error"));
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/global_variables";

.main-content {
  width: 100%;
  border-radius: 8px;
}

.separator {
  border-top: 1px solid #e6e8ec;
  width: 100%;
  height: 1px;
}

.listing-card {
  width: 100%;
  min-height: 88px;
  background-color: white;
  border-radius: 8px;
  margin-bottom: 8px;
}

.kanban {
  display: flex;
  width: 100%;
  padding: 0 24px;
  border-top: 1px solid $grey;
}
</style>
