<template>
  <div
    id="marketing-expense-files-dropdown"
    v-click-outside="closeWidget"
    v-bind:class="{
        'marketing-expense-files-dropdown-with-content': displayedFiles.length > 0,
        'marketing-expense-files-dropdown-without-upload': hideFileUpload,
      }"
    class="marketing-expense-files-dropdown"
  >
    <div
      v-for="(file, index) in displayedFiles"
      :class="{ 'divider-line': index < displayedFiles.length - 1 }"
      :key="'file:' + index"
      class="marketing-expense-files-dropdown-content"
    >
      <div class="marketing-expense-files-dropdown-content-first-column">
        <div
          v-if="!file.image"
          class="marketing-expense-files-dropdown-content-empty-picture"
        ></div>
        <div
          v-if="file.image"
          class="marketing-expense-files-dropdown-content-image-container"
        >
          <img
            class="marketing-expense-files-dropdown-content-image-container"
            :src="file.image.path.name"
            alt=""
          />
        </div>
        <div
          id="lightgallery"
          v-if="file.image"
          @click="openImageGallery(file)"
          style="cursor: pointer"
          class="content-small"
        >
          {{ file.image.name }}
        </div>
        <div
          v-if="!file.image"
          @click="downloadFile(file.key)"
          style="cursor: pointer"
          class="content-small"
        >
          {{ file.fileName }}
        </div>
      </div>
      <div class="marketing-expense-files-dropdown-content-last-column">
        <div class="Labels">{{ formatDate(file.createdAt) }}</div>
        <img
          v-if="!hideFileUpload"
          @click="deleteFile(file, index)"
          style="cursor: pointer"
          src="../../assets/images/red_trash_16_16.svg"
          alt="delete"
        />
      </div>
    </div>
    <div
      v-if="!hideFileUpload"
      class="marketing-expense-files-dropdown-button-container"
    >
      <div
        @click="uploadButton"
        class="marketing-expense-files-dropdown-button"
      >
        <img src="../../assets/images/image_16_16.svg" alt="upload picture" />
        <p class="content-small">{{ $t("UploadFiles") }}</p>
      </div>
    </div>
    <input
      style="display: none"
      type="file"
      id="marketing-expense-file-upload-html"
      multiple
      @change="uploadFiles"
    />
    <a href="" id="download-link" download></a>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import moment from "moment";
import "lightgallery.js";
import "lightgallery.js/dist/css/lightgallery.css";

export default {
  name: "MarketingExpensesFileDropdown",
  props: ["files", "marketingExpense", "hideFileUpload"],
  data() {
    return {
      displayedFiles: [],
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  created() {
    if (this.marketingExpense) {
      this.marketingExpense.images.forEach((image) => {
        this.displayedFiles.push({
          image: image,
        });
      });
      this.marketingExpense.files.forEach((file) => {
        this.displayedFiles.push(file);
      });
    }
  },
  mounted() {
    const position = {};
    position.top = this.marketingExpense.top + window.scrollY - 68;
    position.left = this.marketingExpense.left + window.scrollX - 5;
    let element = document.getElementById("marketing-expense-files-dropdown");
    element.style.setProperty("position", "absolute");
    element.style.setProperty("left", position.left + "px");
    element.style.setProperty("top", position.top + "px");
  },
  methods: {
    closeWidget() {
      this.$emit("closeWidget");
    },
    uploadButton() {
      if (document.getElementById("marketing-expense-file-upload-html")) {
        document.getElementById("marketing-expense-file-upload-html").click();
      }
    },
    formatDate(date) {
      return moment(date).format("DD.MM.YYYY");
    },
    async fileListToBase64(fileList) {
      function getBase64(file) {
        const reader = new FileReader();
        return new Promise((resolve) => {
          reader.onload = (ev) => {
            resolve(ev.target.result);
          };
          reader.readAsDataURL(file);
        });
      }
      const promises = [];
      for (let i = 0; i < fileList.length; i++) {
        promises.push({
          base64: await getBase64(fileList[i]),
          name: fileList[i].name,
        });
      }
      return await Promise.all(promises);
    },
    async uploadFiles(event) {
      const eventFiles = event.target.files;
      const images = [];
      const files = [];
      Object.keys(eventFiles).map(function (key) {
        const file = eventFiles[key];
        if (file && file.type.split("/")[0] === "image") {
          images.push(file);
        } else {
          files.push(file);
        }
      });
      const imageArray = await this.fileListToBase64(images);

      const formData = new FormData();
      files.forEach((file) => {
        formData.append("file", file);
      });
      imageArray.forEach((image) => {
        formData.append("image", JSON.stringify(image));
      });
      formData.append("userName", this.user.name);
      formData.append("expenseId", this.marketingExpense.id);

      const response = await axios.post(
        `/api/project/marketing-expenses/files/${this.$route.params.id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        const file = document.getElementById(
          "marketing-expense-file-upload-html"
        );
        file.value = "";
      }

      this.$emit("fileUploaded");
    },
    async downloadFile(fileName) {
      await axios
        .post(`/api/project/file/${fileName}`, { responseType: "blob" })
        .then((response) => {
          let fileDownloader = document.getElementById("download-link");
          fileDownloader.href = response.data.data;
          fileDownloader.click();
        });
    },
    async deleteFile(file, index) {
      let fileid;
      if (file.image) {
        fileid = file.image.id;
      } else {
        fileid = file._id;
      }
      await axios
        .post(
          `/api/project/marketing-expenses/files/delete/${this.$route.params.id}`,
          {
            fileId: fileid,
            expenseId: this.marketingExpense.id,
          }
        )
        .then((res) => {
          console.log(res);
          this.$emit("fileDeleted");
          console.log(index);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    openImageGallery(file) {
      let dynamicEl = [];
      dynamicEl.push({ src: file.image.path.name });
      const imageWrapper = document.getElementById("lightgallery");
      if (imageWrapper) {
        window.lightGallery(imageWrapper, {
          dynamic: true,
          dynamicEl,
        });
      }
    },
  },
};
</script>

<style scoped>
.Labels {
  font-family: Inter, sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0;
  text-align: left;
  color: #75787a;
}

.marketing-expense-files-dropdown {
  display: flex;
  flex-direction: column;
  width: 264px;
  padding: 11px 11px 11px 11px;
  background: white;
  border-radius: 8px;
  border: 1px solid #e6e8ec;
  z-index: 5;
}

.marketing-expense-files-dropdown-with-content {
  padding: 0 11px 11px 11px;
}
.marketing-expense-files-dropdown-without-upload {
  padding: 0 11px 0px 11px;
}

.marketing-expense-files-dropdown-content {
  display: flex;
  flex-wrap: nowrap;
  padding: 8px 0;
  min-height: 40px;
  justify-content: space-between;
}

.marketing-expense-files-dropdown-content-image-container {
  max-width: 32px;
  max-height: 24px;
  border-radius: 4px;
}

.marketing-expense-files-dropdown-content-first-column {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  word-break: break-all;
  gap: 8px;
}

.marketing-expense-files-dropdown-content-last-column {
  padding-left: 8px;
  display: flex;
  gap: 8px;
  flex-wrap: nowrap;
  align-items: center;
}

.marketing-expense-files-dropdown-button-container {
  height: 32px;
  width: 240px;
  cursor: pointer;
}

.marketing-expense-files-dropdown-button {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  border: 1px dashed black;
  border-radius: 8px;
  gap: 8px;
}

.marketing-expense-files-dropdown-content-empty-picture {
  min-width: 32px;
  min-height: 24px;
  border-radius: 4px;
  background-color: #f4f5f7;
}

.divider-line {
  border-bottom: 1px solid #e6e8ec;
}
</style>
