var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.contracts ? _c('div', {
    staticClass: "contracts-content"
  }, [_vm._l(_vm.contracts, function (contract, index) {
    return _c('div', {
      key: 'marketing-card' + index,
      staticClass: "contracts-card",
      class: {
        'contract-card-first': index === 0,
        'contract-card-middle': index > 0 && index < _vm.contracts.length - 1,
        'contract-card-last': index === _vm.contracts.length - 1,
        'contract-card-only': _vm.contracts.length === 1
      }
    }, [_c('div', {
      staticClass: "contract-card-columns-container",
      staticStyle: {
        "padding": "12px",
        "gap": "8px",
        "cursor": "pointer"
      },
      on: {
        "click": function ($event) {
          return _vm.openContract(contract);
        }
      }
    }, [_c('v-row', {
      staticClass: "content",
      staticStyle: {
        "height": "20px",
        "width": "366px"
      }
    }, [_c('div', [_vm._v(" " + _vm._s(contract.number) + " ")]), _c('span', {
      staticStyle: {
        "margin-left": "8px",
        "margin-right": "8px"
      }
    }, [_vm._v(" • ")]), _c('div', {
      class: {
        'project-class': !_vm.developmentView,
        'development-class': _vm.developmentView
      }
    }, [_vm._v(" " + _vm._s(_vm.formatContractType(contract.type)) + " ")])]), _c('v-row', {
      staticClass: "content",
      staticStyle: {
        "height": "20px",
        "margin-top": "0px",
        "background": "#F4F5F7",
        "padding": "0px 4px",
        "border-radius": "4px",
        "max-width": "fit-content"
      }
    }, [_vm._v(_vm._s(contract.startDate ? _vm.formatDate(contract.startDate) : "...") + " - " + _vm._s(contract.endDate ? _vm.formatDate(contract.endDate) : '...'))])], 1)]);
  }), _vm.contracts.length === 0 ? _c('v-row', {
    staticClass: "content"
  }, [_c('p', {
    staticStyle: {
      "color": "#75787a"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("NoContractsAdded")) + " ")])]) : _vm._e()], 2) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }