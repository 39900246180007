var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: function () {
        return _vm.isOpen = false;
      },
      expression: "() => (isOpen = false)"
    }],
    staticClass: "main",
    on: {
      "focusin": function ($event) {
        return _vm.openItems();
      }
    }
  }, [_c('v-text-field', {
    staticStyle: {
      "background": "white"
    },
    attrs: {
      "prepend-inner-icon": "mdi-magnify",
      "autocomplete": "off",
      "label": _vm.placeholder,
      "error": _vm.errorKey,
      "outlined": "",
      "clearable": "",
      "hide-details": ""
    },
    on: {
      "focusin": function ($event) {
        _vm.chosenObject ? _vm.isOpen = false : _vm.isOpen = true;
      },
      "click:clear": function ($event) {
        return _vm.clearSelection(_vm.chosenObjectApi, _vm.chosenObject);
      }
    },
    model: {
      value: _vm.search,
      callback: function ($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  }), _vm.foundItemsApi.length > -1 && _vm.isOpen && !this.redirected ? _c('div', {
    staticClass: "items"
  }, [_c('div', {
    staticClass: "item-wrapper"
  }, [_c('div', _vm._l(_vm.foundItemsApi, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "item",
      on: {
        "click": function ($event) {
          return _vm.chooseResultApi(item['contactName'], item);
        }
      }
    }, [!item.isPrivatePerson ? _c('p', [_vm._v(" " + _vm._s(item["contactName"]) + " "), _c('span', [_vm._v(" - " + _vm._s(item["company_name"]) + " ")])]) : _c('p', [_vm._v(" " + _vm._s(item["contactName"]) + " "), _c('span', [_vm._v(" - " + _vm._s(_vm.$t("PrivatePerson.Label").toLowerCase()))])])]);
  }), 0)]), _vm.search && _vm.search.length > 1 && !_vm.resultChosen && _vm.foundItemsApi.length < 1 ? _c('div', {
    staticClass: "item-wrapper"
  }, [_c('div', {
    staticClass: "item no-hover"
  }, [_vm._v("No result found for \"" + _vm._s(_vm.search) + "\"")])]) : _vm._e(), _c('div', {
    staticClass: "separator"
  }), _c('div', {
    staticClass: "add-new",
    on: {
      "click": _vm.addNew
    }
  }, [_c('img', {
    attrs: {
      "src": require("../../assets/images/plusSign.svg"),
      "alt": ""
    }
  }), _vm._v(" " + _vm._s(_vm.$t("AddNew")) + " ")])]) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }