<template>
  <div class="project-listing-header">
    <div
      class="listing-main-info"
      @click="$emit('openListingPanel', listing._id)"
    >
      <img
        v-if="listing.images.length > 0"
        class="listing-image"
        :src="listing.images[0].thumbnail || listing.images[0].name"
        alt=""
      />
      <div v-else class="listing-image" />
      <div class="listing-main-info-text">
        <div class="content-semibold listing-name" :key="listingNameUpdater">
          {{ listing.listingName }}
        </div>
        <div
          v-if="listing.description"
          class="content-small listing-description"
        >
          {{ listing.description }}
        </div>
        <OpenHouseContainer v-if="openHouse" :task="openHouse" :invert="true" />
      </div>
    </div>

    <div class="listing-additional-info">
      <div class="listing-estate-type">
        {{ $lfMapper.estateType(listing.estateType) }}
      </div>

      <div
        v-if="listing.generalSurfaceArea"
        class="listing-general-surface-area"
      >
        {{ listing.generalSurfaceArea }} (m²)
      </div>

      <v-row
        @click="openDropdown"
        v-if="listing.price"
        style="cursor: pointer"
        class="listing-price"
      >
        <p>{{ listingPrice }} (€)</p>
        <div
          v-if="
            listing.priceHistoryLog &&
            listing.priceHistoryLog.length &&
            dropdownIsOpen
          "
          class="price-info-container price-info-container-hover"
        >
          <div
            v-click-outside="closeDropdown"
            class="price-history-info-dropdown"
          >
            <div
              class="price-info"
              v-for="(info, index) in listing.priceHistoryLog.slice(-20)"
              :key="'price-info-' + index"
            >
              <div class="content-normal">
                {{ formatDate(info.date) }}
              </div>
              <div
                style="width: 100%; text-align: right"
                class="content-semibold"
              >
                {{ $nMapper.price(info.to, "€") }}
              </div>
            </div>
          </div>
        </div>
      </v-row>

      <div class="listing-status">
        <div class="listing-status-top">
          <StatusLabel
            :disabled="
              listing.status === 'archived' &&
              (dealPipelineStep === 'archived' || dealPipelineStep === 'Done')
            "
            :status="listing.status"
            :listing="listing"
            @statusChanged="(data) => $emit('statusChanged', data)"
            @open-booking-modal="openBookingDetailsModal"
            @onPress="(event) => $emit('openStatusDropdown', listing, event)"
          />

          <v-tooltip bottom right :key="bookingUpdater">
            <template v-slot:activator="{ on, attrs }">
              <p
                v-bind="attrs"
                v-on="on"
                v-if="listing.status === 'booked'"
                class="booked-label booked-info-label-listing"
              >
                <img
                  style="filter: invert(1)"
                  src="@/assets/images/info.svg"
                  alt=""
                  @click="openBookingDetailsModal(listing)"
                />
              </p>
            </template>
            <span>
              {{ $t("BookingDetails") }}
            </span>
          </v-tooltip>

          <p
            v-if="
              listing.status === 'active' &&
              listing.dateAvailable &&
              !listing.activeUntil
            "
            class="content-small"
          >
            {{ $t("available") }} {{ formatDate(listing.dateAvailable) }}
          </p>
          <p
            style="margin-left: 8px"
            v-if="listing.status === 'expired' && listing.expiryDate"
            class="content-small"
          >
            {{ $lfMapper.expiryDate(listing.expiryDate) }}
          </p>
          <p
            v-if="listing.status === 'booked' && listing.bookedUntil"
            @click="openBookingDetailsModal(listing)"
            class="content-small booking-until-date"
          >
            {{ $t("until") }} {{ formatDate(listing.bookedUntil) }}
          </p>
          <date-pick
            v-model="listing.activeUntil"
            :format="'DD.MM.YYYY'"
            @input="(value) => $emit('changeActiveUntil', listing, value)"
            v-if="listing.status === 'active' && listing.activeUntil"
          >
            <template v-slot:default="{ toggle }">
              <p
                @click.stop="toggle"
                v-if="listing.status === 'active' && listing.activeUntil"
                class="content-small"
              >
                {{ $t("until") }} {{ listing.activeUntil }}
              </p>
            </template>
          </date-pick>
        </div>
        <Outputs v-if="!isProjectInactive && (listing.status === 'active' || listing.status === 'booked')"
                 @openOutputSettings="(outputPosition) => openOutputsModal(outputPosition)" :listing="listing" />
      </div>
      <IconDropdown
        v-if="
          !dealPipelineStep ||
          listing.status !== 'archived' ||
          (listing.status === 'archived' &&
            dealPipelineStep &&
            dealPipelineStep !== 'archived' &&
            dealPipelineStep !== 'Done')
        "
        :items="additionalActivities"
        :display-key="'name'"
        :value-key="'value'"
        :icon="'dot_menu.svg'"
        @itemSelected="(item) => $emit('selectActivity', item, listing)"
        :project-view="true"
      />
      <BookingDetailsModal
        v-if="bookingDetailsModalOpen"
        :opened-listing="bookedListing"
        @update-listing="changeListingStatus"
        @close-modal="bookingDetailsModalOpen = false"
      >
      </BookingDetailsModal>
      <OutputsModal
        :position="modalPosition"
        :toggle-modal="toggleOutputsModal"
        :listing="listing"
      />
    </div>
  </div>
</template>

<script>
import OpenHouseContainer from "@/components/common/OpenHouseContainer";
import IconDropdown from "@/components/common/IconDropdown";
import DatePick from "vue-date-pick";
import OutputsModal from "@/components/Listings/OutputsModal2";
import StatusLabel from "@/components/Listings/StatusLabel";
import axios from "axios";
import BookingDetailsModal from "../../Listings/BookingDetailsModal";
import moment from "moment";
import Outputs from "@/components/Listings/Outputs/Outputs";

export default {
  name: "ProjectListingHeader",
  components: {
    Outputs,
    BookingDetailsModal,
    StatusLabel,
    OutputsModal,
    IconDropdown,
    OpenHouseContainer,
    DatePick,
  },
  props: [
    "listing",
    "listingIndex",
    "openHouse",
    "additionalActivities",
    "listingRefreshKey",
    "isProjectInactive",
    "dealPipelineStep",
  ],
  data() {
    return {
      listingNameUpdater: 0,
      bookedListing: 0,
      bookingDetailsModalOpen: false,
      bookingUpdater: 0,
      toggleOutputsModal: false,
      modalPosition: null,
      dropdownIsOpen: false,
    };
  },
  watch: {
    async listingRefreshKey() {
      let listingIds = this.listing._id;
      let response = await axios.post("/api/listings", { listingIds });
      this.listing.listingName = response.data.listings[0].listingName;
      this.listingNameUpdater++;
      this.bookingUpdater++;
    },
  },
  computed: {
    listingPrice: function () {
      return this.$nMapper.price(this.listing.price);
    },
  },
  methods: {
    formatDate(date) {
      if (date) {
        return moment(date).format("DD.MM.YYYY");
      } else {
        return "-";
      }
    },
    isFutureDate(date) {
      const currentDate = new Date();
      return date <= currentDate;
    },
    openBookingDetailsModal(listing) {
      this.bookedListing = listing;
      this.bookingDetailsModalOpen = true;
    },
    changeListingStatus(listing) {
      this.listing.status = listing.status;
      this.bookingUpdater++;
    },
    openDropdown() {
      if (!this.dropdownOpen) {
        this.dropdownIsOpen = true;
      }
    },
    closeDropdown() {
      this.dropdownIsOpen = false;
    },
    openOutputsModal(position) {
      this.modalPosition = position;
      this.$nextTick(
          () => (this.toggleOutputsModal = !this.toggleOutputsModal)
      );
    }
  },
};
</script>

<style lang="scss" scoped>
@import "src/global_variables";

.project-listing-header {
  display: flex;
  padding: 12px 24px;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.listing-main-info {
  display: flex;
  gap: 16px;
  align-items: center;
  cursor: pointer;
}

.listing-additional-info {
  display: flex;
  gap: 20px;
  align-items: center;
  height: 64px;
}

.listing-main-info-text {
  align-items: center;
  display: grid;
}

.listing-estate-type {
  max-width: 120px;
}

.listing-general-surface-area {
  max-width: 80px;
}

.listing-price {
  max-width: max-content;
  white-space: nowrap;
  height: 64px;
  display: flex;
  align-content: center;
}

.listing-image {
  width: 72px;
  height: 64px;
  min-width: 72px;
  border-radius: 8px;
  background: $grey;
  object-fit: cover;
  object-position: center;
}

.listing-status {
  display: grid;
  gap: 4px;
}

.listing-status-top {
  display: flex;
  align-items: center;
  gap: 8px;
}

.listing-description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.listing-main-info-text {
}

//.booked-label {
//  border-radius: 4px 0 0 4px !important;
//}

.booked-info-label-listing {
  border-radius: 0 4px 4px 0;
  border-left: 1px solid #e6e8ec;
  display: flex;
  align-items: center;
  padding: 2px 4px;
  gap: 10px;
  margin-left: -8px;
  height: 24px;

  img {
    width: 16px;
    height: 16px;
  }

  &:hover {
    background: black;
    cursor: pointer;
  }
}

.booking-until-date {
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
}

@media only screen and (max-width: 1292px) {
  .listing-estate-type {
    display: none;
  }
}

@media only screen and (max-width: 1340px) {
  .listing-general-surface-area {
    display: none;
  }
}

@media only screen and (max-width: 1422px) {
  .listing-price {
    display: none;
  }

  .listing-main-info-text {
    max-width: 100px;
  }
}
.price-info-container {
  position: relative;
  display: flex;
  border-radius: 8px;
}
.price-info-dropdown {
  display: flex;
  align-items: center;
}

.price-info-container-hover:hover {
  background-color: black !important;
}

.price-info-container-hover:hover img {
  filter: invert(100%) sepia(0) saturate(0) hue-rotate(317deg) brightness(103%)
    contrast(102%);
}
.price-history-info-dropdown {
  box-shadow: rgba(0, 0, 0, 0.11) 0px 2px 8px, rgba(0, 0, 0, 0.11) 2px 1px 9px, rgba(0, 0, 0, 0.1) 0px 0px 0px;
  position: absolute;
  z-index: 8;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 8px;
  gap: 12px;
  padding: 16px;
}
.price-info {
  border-radius: 8px 8px 0 0;
  display: flex;
  gap: 8px;
  flex-wrap: nowrap;
  white-space: nowrap;
}

</style>
