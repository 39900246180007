<template>
  <div
    @keyup.esc="closePanel"
    class="overlay-detailpanel"
    id="overlay-customer"
    @click="closePanel($event)"
  >
    <div
      v-bind:class="{
        'panel-wrapper': !versionPanelOpen,
        'panel-wrapper panel-wrapper-versions-open': versionPanelOpen,
      }"
      id="panel-wrapper-customer"
    >
      <div
        v-if="!editAddPanel"
        @click="closeAccordion()"
        id="wrapped-header-customer"
      ></div>
      <div id="content-wrapper">
        <v-row class="customer-row">
          <v-col
            style="
              justify-content: space-between;
              gap: 8px;
              padding: 0;
              min-width: 70%;
            "
          >
            <h3 style="align-self: center">
              {{ $t("KnowYourCustomer") }}
            </h3>
            <p
              v-if="chosenAml.creator"
              @click="toggleVersionsPanel"
              class="link-small top8"
            >
              {{ $t("LastEdit") }}&nbsp;{{
                formatAmlEditDate(chosenAml.createdAt)
              }}
              {{ " " }}
              {{ chosenAml.creator.name }}
            </p>
          </v-col>
          <v-col style="width: 35%">
            <div
              v-if="
                deal.pipelineStep == 'qualified' ||
                deal.pipelineStep == 'contactMade'
              "
              style="display: flex; gap: 8px; justify-content: flex-end"
            >
              <div
                v-if="
                  deal.pipelineStep == 'qualified' ||
                  deal.pipelineStep == 'contactMade'
                "
                @click="openAddModal()"
                class="edit-button"
              >
                <img src="../../assets/images/edit.svg" alt="" />
                <p class="edit-button-text">{{ $t("Edit") }}</p>
              </div>
            </div>
          </v-col>
        </v-row>
        <div class="main-wrapper" style="background-color: white">
          <div class="sub-block">
            <v-col class="sub-heading">
              <h6>
                {{ $t("Subblock.GeneralData") }}
              </h6>
            </v-col>
            <div class="main-info">
              <v-row style="margin-top: 0px" class="customer-field-row top16">
                <p class="field-name">
                  {{ $t("ActiveBusinessRelationshipFrom1") }}
                </p>
                <p class="field-value">
                  {{ formatDate(chosenAml.activeBusiness) }}
                </p>
              </v-row>
              <v-row class="customer-field-row top16">
                <p class="field-name">
                  {{ $t("ActiveBusinessRelationshipTo") }}
                </p>
                <p
                  v-if="chosenAml.businessRelationship != null"
                  class="field-value"
                >
                  {{ formatDate(chosenAml.businessRelationship) }}
                </p>
                <p
                  v-if="chosenAml.businessRelationship == null"
                  class="field-value"
                >
                  -
                </p>
              </v-row>
              <v-row class="customer-field-row top16">
                <p class="field-name">
                  {{ $t("RepresentationContract") }}
                </p>
                <p class="field-value">
                  {{ chosenAml.representationContract ? $t("Yes") : $t("No") }}
                </p>
              </v-row>

              <v-row class="customer-field-row top16">
                <p class="field-name">
                  {{ $t("PowerOfAttorney") }}
                </p>
                <p class="field-value">
                  {{ chosenAml.powerOfAttorney ? $t("Yes") : $t("No") }}
                </p>
              </v-row>
              <v-row class="customer-field-row top16">
                <p class="field-name">
                  {{ $t("CustomerConfirmation") }}
                </p>
                <p class="field-value">
                  {{ chosenAml.customerConfirmation ? $t("Yes") : $t("No") }}
                </p>
              </v-row>

              <v-row class="customer-field-row top16">
                <p class="field-name">
                  {{ $t("ServicesRequested") }}
                </p>
                <p class="field-value">
                  {{ chosenAml.servicesRequested ? $t("Yes") : $t("No") }}
                </p>
              </v-row>

              <v-row class="customer-field-row top16">
                <p class="field-name">
                  {{ $t("PrintoutOfRegister") }}
                </p>
                <p class="field-value">
                  {{ chosenAml.printoutOfRegister ? $t("Yes") : $t("No") }}
                </p>
              </v-row>
              <v-row class="customer-field-row top16">
                <p class="field-name">
                  {{ $t("CopyOfIdenification") }}
                </p>
                <p class="field-value">
                  {{ chosenAml.copyOfIdenification ? $t("Yes") : $t("No") }}
                </p>
              </v-row>

              <v-row class="customer-field-row top16">
                <p class="field-name">
                  {{ $t("BackgroundCheck") }}
                </p>
                <p class="field-value">
                  {{ chosenAml.backgroundCheck ? $t("Yes") : $t("No") }}
                </p>
              </v-row>
              <v-row class="customer-field-row top16">
                <p class="field-name">
                  {{ $t("PoliticallyExposedPerson1") }}
                </p>
                <p class="field-value">
                  {{
                    chosenAml.politicallyExposedPerson ? $t("Yes") : $t("No")
                  }}
                </p>
              </v-row>

              <v-row class="customer-field-row top16">
                <p class="field-name">
                  {{ $t("SubjectOfSanctions1") }}
                </p>
                <p class="field-value">
                  {{ chosenAml.subjectOfSanctions ? $t("Yes") : $t("No") }}
                </p>
              </v-row>
            </div>
          </div>
          <div style="margin-top: 32px" class="sub-block">
            <div style="border: 0.1px solid #e6e8ec" class="separator-row-main">
              <td class="separator-row"></td>
            </div>
            <v-col class="sub-heading">
              <div
                style="
                  display: flex;
                  flex-direction: row;
                  flex-wrap: nowrap;
                  gap: 8px;
                "
              >
                <h6>
                  {{ $t("Files") }}
                </h6>
                <div class="counter-icon">
                  <p class="content-small" style="color: white">
                    {{ chosenAml.files.length }}
                  </p>
                </div>
              </div>
            </v-col>
            <div style="padding: 0px 32px 16px 32px">
              <v-row
                class="file-row"
                v-for="(el, index) in chosenAml.files"
                :key="index"
              >
                <span style="display: flex; gap: 8px">
                  <span
                    class="file-name"
                    @click="downloadFile(el.name, el.amlId)"
                  >
                    {{ el.name }}
                  </span>
                </span>

                <span
                  class="content-small"
                  style="font-weight: 400; color: #75787a; gap: 8px"
                >
                  <span>
                    {{ el.creator.name }}
                  </span>
                  <span>
                    {{ formatAmlEditDate(el.createdAt) }}
                  </span>
                </span>
              </v-row>

              <a href="" id="download-link" download></a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <AmlVersionsPanel
      v-if="versionPanelOpen"
      :aml="deal.aml"
      @closed="toggleVersionsPanel"
      @versionChosen="chooseAmlVersion"
    />
    <AmlSidepanelAdd
      @refresher="$emit('refresh')"
      v-if="editAddPanel"
      :isEdit="true"
      :deal="deal"
      @amlDeleted="amlDeleted()"
      @closed="closeAddModal()"
    />
  </div>
</template>
<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import AmlSidepanelAdd from "@/components/Deals/AmlSidepanelAdd.vue";
import AmlVersionsPanel from "@/components/Deals/AmlVersionsPanel";

export default {
  name: "SidepanelDetail",
  props: ["customerData", "customerDataLeadsList", "fromProjectGeneral"],
  components: {
    AmlSidepanelAdd,
    AmlVersionsPanel,
  },
  data: () => ({
    deal: null,
    routeParams: null,
    allDeals: [],
    isDeleteModal: false,
    editAddPanel: false,
    dataLoaded: false,
    customer: null,
    editMode: false,
    chosenAml: null,
    versionPanelOpen: false,
  }),
  created() {
    this.setupAml();
  },
  watch: {
    "$route.params.id": function () {
      this.setRouteParams();

      //this.getCustomer(this.routeParams);
    },
  },
  mounted() {
    //document.documentElement.style.overflow = "hidden";
  },
  beforeDestroy() {
    //document.documentElement.style.overflow = "visible";
  },
  computed: {
    customerType: function () {
      if (this.customer.customerType == "Buyer") {
        return "Ostja";
      }
      if (this.customer.customerType == "Seller") {
        return "Müüja";
      }
      if (this.customer.customerType == "Landlord") {
        return "Maaomanik";
      }
      if (this.customer.customerType == "Tenant") {
        return "Üürnik";
      }
      if (this.customer.customerType == "Looking to buy") {
        return "Soovivad osta";
      }
      if (this.customer.customerType == "Looking to rent") {
        return "Soovivad üürida";
      }
      return 0;
    },
    ...mapGetters(["customers"]),
  },
  methods: {
    async setupAml() {
      await this.getDeal();
      let allAmls = this.deal.aml;
      this.chosenAml = allAmls[allAmls.length - 1];
      this.setRouteParams();
      //this.getDeal(this.routeParams);
      document.onkeydown = (evt) => {
        evt = evt || window.event;
        if (evt.key === "Escape") {
          this.closePanel();
          document.onkeydown = null;
        }
      };
    },
    async downloadFile(fileName, amlId) {
      await axios
        .post(`/api/project/${this.deal._id}/aml/${amlId}/file/${fileName}`, {
          responseType: "blob",
        })
        .then((response) => {
          let fileDownloader = document.getElementById("download-link");
          fileDownloader.href = response.data.data;
          fileDownloader.click();
        });
    },
    async getDeal() {
      const response = await axios.get(`/api/project/${this.$route.params.id}`);
      this.deal = response.data;
    },
    toggleVersionsPanel() {
      this.versionPanelOpen = !this.versionPanelOpen;
    },
    chooseAmlVersion(index) {
      this.chosenAml = this.deal.aml[index];
    },
    amlDeleted() {
      this.$emit("amlDeleted");
      this.closePanel();
    },
    formatCustomerLanguage() {
      if (this.customer.customerLang === "ESTONIAN") {
        return this.$t("Estonian");
      } else if (this.customer.customerLang === "ENGLISH") {
        return this.$t("English");
      } else if (this.customer.customerLang === "RUSSIAN") {
        return this.$t("Russian");
      } else if (this.customer.customerLang === "FINNISH") {
        return this.$t("Finnish");
      } else if (this.customer.customerLang === "GERMAN") {
        return this.$t("German");
      }
    },
    countAmlFiles() {
      if (this.chosenAml.files) {
        return this.chosenAml.files.length;
      } else {
        return 0;
      }
    },
    ...mapActions(["setClientSidepanel", "addNotes", "changeModalStateDeals"]),
    ...mapActions([
      "detailSidePanelRegister",
      "addNotes",
      "changeModalStateDeals",
    ]),
    closeRelatedCustomersModal() {
      this.relatedCustomersModalOpen = false;
    },
    openRelatedCustomerModal() {
      this.relatedCustomersModalOpen = true;
    },
    /*    async getDeal() {
      let chosenObject = this.$route.params.id;
      const response = await axios.get(`/api/project/${chosenObject}`);
      console.log(response);
      //this.deal = response.data[0];
    },*/
    setRouteParams() {
      if (this.customerData) {
        if (this.customerData.customerId) {
          this.routeParams = this.customerData.customerId;
        } else {
          this.routeParams = this.customerData.contactId;
        }
      } else if (this.customerDataLeadsList) {
        this.routeParams = this.customerDataLeadsList;
      } else {
        this.routeParams = this.$route.params.id;
      }
    },
    openAddModal() {
      this.editAddPanel = true;
      document.getElementById("panel-wrapper-customer").style.display = "none";
      //document.documentElement.style.overflow = "hidden";
    },
    async closeAddModal() {
      await this.getDeal();
      this.editAddPanel = false;
      document.getElementById("panel-wrapper-customer").style.display =
        "initial";

      this.chosenAml = this.deal.aml[this.deal.aml.length - 1];
      //this.getCustomer(this.routeParams);
    },
    customerUpdated() {
      //this.getCustomer(this.routeParams);
      this.$emit("updated");
    },
    /* async getAllDeals() {
      const response = await axios.get("/api/project");
      let deals = response.data.deals;
      deals.forEach((randomDeal, index) => {
        this.deal.forEach((customerDeal) => {
          if (randomDeal._id === customerDeal._id) {
            deals.splice(index, 1);
          }
        });
      });
      this.allDeals = deals;
    }, */
    closePanel(e) {
      //this.$emit("closed");
      if (!e) {
        this.$emit("closed", this.deal.aml);
        //document.documentElement.style.overflow = "hidden";
      } else if (e.target.id === "overlay-customer") {
        this.$emit("closed", this.deal.aml);
      }
      if (e.target !== e.currentTarget) return;
    },
    async saveCustomer(customer) {
      const response = await axios.patch("/api/contact", customer);
      console.log(response);
      //await this.changeSidepanelStateDeals(0);
    },
    async deleteCustomer() {
      const response = await axios.post(
        `/api/contact/delete/${this.customer.customerId}`
      );
      console.log(response);
      //await this.changeSidepanelStateDeal(0);
      await this.$router.push({ name: "Kliendiregister" });
      await this.$router.go();
    },
    formatAmlEditDate(e) {
      return moment(e).format("DD.MM.YYYY");
    },
    formatDate(e) {
      var allDate = e.split(" ");
      var thisDate = allDate[0].split("-");
      var newDate = [thisDate[2], thisDate[1], thisDate[0]].join(".");
      return newDate;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "src/global_variables";

.subinfo {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  p {
    color: #75787a !important;
  }
}
.customer-interests {
  margin-bottom: 12px;
}
.interests-wrapper {
  padding-left: 0px;
  padding-right: 24px;
}
.interest-title-wrapper {
  padding: 12px;
  cursor: pointer;
  border: 1px solid #e6e8ec;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.bottom-radius {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.no-bottom-radius {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.interest-title-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.all-funnels-wrapper {
  display: flex;
  flex-direction: column;
}
.suggestions-wrapper {
  background: #f4f5f7;
  width: 100%;
  padding: 12px 12px 0px 12px;
  border-left: 1px solid #e6e8ec;
  border-right: 1px solid #e6e8ec;
  border-bottom: 1px solid #e6e8ec;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.file-row {
  padding: 12px;
  gap: 4px;
  width: 100%;
  border-radius: 8px;
  background: #f4f5f7;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.uploaded-date {
  margin-left: 4px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #75787a;
}

.uploader-name {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #75787a;
}

.file-name {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}
.note-row {
  flex-flow: row;
  margin-top: 16px !important;
  cursor: pointer;
  max-width: 100%;
}

.note-text {
  white-space: pre-line;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  width: 100%;
  padding-left: 4px;
  box-sizing: border-box;
}

.note-text:hover {
  cursor: pointer;
  /*
  box-shadow: inset 0px 0px 0px 1px rgb(230, 232, 236);
*/
  border-radius: 8px;
}

.params {
  display: flex;
}

.relationIcon1 {
  color: rgb(0, 0, 0);
  width: 32px;
  height: 32px;
  font-family: Geomanist;
  font-weight: bold;
  user-select: none;
  border-radius: 50%;
}

.related-deal {
  font-size: 14px !important;
  line-height: 16px;

  &:hover {
    text-decoration: underline;
  }
}

.v-text-field {
  padding-top: 0px !important;
  padding: 0px !important;
  padding-bottom: 0px !important;
}

.relationIcon2 {
  background-color: rgb(186, 13, 173);
  cursor: initial;
}

.text-field-class {
  padding: 0px !important;
  max-width: 40%;

  .v-text-field ::v-deep input {
    padding: 0px !important;
  }
}

.registration-num {
  padding: 0px !important;
}

.birth-class {
  border: 1px solid #939597;
  border-radius: 8px;
  width: 40%;
  height: 36px;
  padding-top: 0px !important;
  padding: 0px !important;
  padding-bottom: 0px !important;

  &:focus {
    outline: none !important;
  }
}

.related-persons {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #000000;
  margin-bottom: 0 !important;
}

/*Add max-width 70/30 */
.register-code {
  display: flex;
  justify-content: space-between;
  max-width: 100%;
  align-items: baseline;
}
.relations {
  padding-left: 0px;
  padding-right: 12px;
  padding-top: 0px;
}
.interests-row {
  /*  border-left: 1px solid #e6e8ec;
  border-right: 1px solid #e6e8ec; */
  /* border-bottom: 1px solid #e6e8ec; */
  padding-left: 12px;
  padding-right: 12px;
  //padding-top: 12px;
}
.notes {
  padding-left: 0px;
  padding-right: 28px;
  padding-bottom: 32px;
  padding-top: 24px;
}

.activities {
  /*  padding-left: 24px;
  padding-right: 56px; */
  padding-bottom: 32px;
  padding-top: 32px;
}

.contact-types {
  width: 84px;
  height: 16px;
  left: 48px;
  top: 18px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #75787a;
  padding-left: 48px;
  margin-top: -18px !important;
}

.alignedRow-relationitem {
  display: flex;
  align-items: center;
  justify-content: center;
}

.add-person-inside {
  height: 16px;
  min-width: 52px;
  margin-left: 8px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  white-space: nowrap;
}

.createdAt {
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  display: flex;
  align-items: center;
  margin-bottom: 0 !important;

  /* grey/grey900 */

  color: #939597;
}

.add-person {
  padding-left: 24px;
  padding-right: 56px;
  padding-bottom: 24px;
  padding-top: 12px;
}

.add-persons {
  flex-direction: row;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-family: Inter;
  font-size: 12px;
  line-height: 16px;
  border-radius: 8px;
  height: 28px;
  padding: 0;
  justify-content: space-between;
  border: 1px solid #e6e8ec;
  padding: 8px;
}

.add-field {
  margin-bottom: 0 !important;
  width: 100% !important;
}

.add-notes {
  height: 50px;
  flex-direction: row;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-family: Inter;
  border-radius: 8px;
  height: 40px;
  justify-content: space-between;
}

.add-note-inside {
  width: 73px;
  height: 16px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
}

.text-area {
  height: fit-content !important;
}

.delete {
  background-color: #ff1e24;
  color: white;
}

.theme--light.v-input--is-disabled,
.theme--light.v-input--is-disabled input,
.theme--light.v-input--is-disabled textarea {
  color: black !important;
}

.v-text-field--outlined ::v-deep fieldset {
  border: 1px solid #e6e8ec;
}

.buttons-row {
  justify-content: flex-end;
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: 12px 12px 12px;
  background-color: white;
  border-radius: 8px;

  .cancel {
    background-color: transparent !important;
    box-shadow: 0px 0 2px rgba(28, 14, 3, 0.3);
    text-transform: none;
    padding: 12px;
    display: flex;
    align-items: center;
    height: 40px;
  }
}

.pipeline-header {
  margin-top: 48px;
  width: 30%;

  p {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
  }
}

.inputFields {
  font-size: 14px;
  font-family: Inter;
  line-height: 1.2;
  gap: 8px 24px;
  display: flex;
  flex-flow: row wrap;
}

.stageSelector {
  font-size: 14px;
  font-family: Inter;
  line-height: 1.2;
  width: 100%;
  display: flex;
  width: 100%;
  display: flex;
}
.hover-open-delete {
  background-image: url("../../assets/images/trash-red.svg");
}
.stageSelector > input {
  margin: 0;
  padding: 0;
  -moz-appearance: none;
  -webkit-appearance: none;
}

.stageSelector > * {
  margin-right: 16px;
}

.arrowLabel {
  width: 173px;
  cursor: pointer;
  height: 40px;
  display: flex;
  position: relative;
  font-size: 12px;
  background: #e6e8ec;
  align-items: center;
  justify-content: center;
}

.stageSelector > input:checked + label:before {
  border-left-color: #75787a;
}

.arrowLabel:before {
  right: -10px;
  z-index: 2;
}

.arrowLabel:before {
  width: 0;
  bottom: 0;
  height: 0;
  content: "";
  position: absolute;
  border-top: 20px solid transparent;
  border-left: 10px solid #e6e8ec;
  border-bottom: 20px solid transparent;
}

.stageSelector > input:checked + label {
  color: #ffffff;
  background: #75787a;
}

.panel-wrapper {
  position: absolute;
  right: 0;
  top: 0%;
  bottom: 0%;
  background-color: #ffffff;
  width: 560px;
  z-index: 102;
  min-width: 560px;
  max-width: 560px;
  border: 1px solid #e6e8ec;
  overflow-y: scroll;
  -webkit-transition: width 0.5s ease-in-out;
  -moz-transition: width 0.5s ease-in-out;
  -o-transition: width 0.5s ease-in-out;
  transition: width 0.5s ease-in-out;
}
.panel-wrapper-versions-open {
  right: 340px !important;
}
#panel-wrapper-customer {
  -webkit-transition: width 0.5s ease-in-out, right 0.5s ease-in-out,
    min-width 0.5s ease-in-out;
  -moz-transition: width 0.5s ease-in-out, right 0.5s ease-in-out,
    min-width 0.5s ease-in-out;
  -o-transition: width 0.5s ease-in-out, right 0.5s ease-in-out,
    min-width 0.5s ease-in-out;
  transition: width 0.5s ease-in-out, right 0.5s ease-in-out,
    min-width 0.5s ease-in-out;
}
#wrapped-header-customer {
  display: none;
  position: relative;
  width: 100%;
  height: 100%;
  opacity: 0;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
  flex-direction: column;
  justify-content: flex-start;
  padding: 48px 24px 24px;
  border-right: 1px solid $grey500;

  h4 {
    white-space: nowrap;

    overflow: hidden;
    text-overflow: ellipsis;
    transform: rotate(180deg);
    writing-mode: vertical-lr;
    text-align: center;
    position: relative;
    justify-self: center;
    align-self: center;
  }
}
.row {
  gap: 16px;
}

.overlay-detailpanel {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 101;
}

.top-row {
  width: 100%;
  justify-content: flex-end;
  padding: 12px;
}

.close-button {
  background: #f4f5f7;
  border-radius: 8px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.divider {
  width: 100%;

  border: 1px solid #e6e8ec;
  margin-bottom: 24px;
  margin-top: 16px;
  left: 0;
  right: 0;
}

.main-wrapper {
  //margin: 12px;
  border-top: 1px solid #e6e8ec;
  //border-radius: 8px;
}

.sub-block {
  position: relative;
  padding: 0px;
  //border-bottom: 1px solid #e6e8ec;
}

.sub-block-wrapper {
  padding: 12px;
}

.content-block {
  padding-left: 32px;
  padding-top: 0px;
  padding-bottom: 32px;
  padding-right: 56px;
}

.content-item-item {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;

  text-align: right;
  color: #000000;
}

.content-items {
  justify-content: space-between;
}

.no-active {
  height: 40px;
  max-width: 65%;
}

.textarea {
  width: 100%;
  height: 110px;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #e6e8ec;
}

.textarea:focus {
  border: 1px solid #e6e8ec;
  outline: none;
  border-radius: 8px;
}

.no-active.v-input.v-input__slot {
  border-radius: 20px;
  border: 1px solid #e6e8ec !important;
}

.v-text-field--outlined > fieldset {
  border: 1px solid #e6e8ec;
}

.probability {
  padding-right: 20%;
}

.customer-row {
  border-radius: 8px;
  margin-bottom: 12px;
  padding-left: 32px;
  padding-right: 24px;
  padding-top: 48px;
  background-color: #ffffff;
}

.sub-heading {
  padding: 32px;
  padding-right: 56px;
  padding-bottom: 24px;
}

.content-item-row {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #75787a;
}

.edit-button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  height: 32px;
  gap: 8px;
  /* grey/grey500 */

  border: 1px solid #e6e8ec;
  box-sizing: border-box;
  border-radius: 8px;
  img {
    width: 16px;
    height: 16px;
  }

  &:hover {
    background-color: #e7e7e7;
    cursor: pointer;
  }
}

.edit-button-text {
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 0 !important;
}

.vertical-line {
  background-color: #e6e8ec;
  width: 1px;
  height: 16px;
}

.description {
  border: none !important;
  outline: none !important;
  width: 100%;
  resize: none;
}

.customer-field-row {
  gap: 24px;
}
.top16 {
  margin-top: 16px;
}
.top24 {
  margin-top: 24px;
}
.object-link:hover {
  color: black;
  text-decoration: underline;
  cursor: pointer;
}

.save {
  background-color: #0aaf60;
  color: white;
  border-color: #e6e8ec;
}

.related-deals {
  font-size: 14px !important;
  line-height: 16px;
  padding-left: 0px;
  padding-right: 56px;
  padding-bottom: 32px;
  max-width: fit-content;
  &:hover {
    text-decoration: underline;
  }
  cursor: pointer;
}

.contact-type {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #75787a;
}

.related-person-info {
  text-align: right;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  /* or 133% */

  text-align: right;

  /* black */

  color: #000000;
}
.name-wrapper {
  min-width: 45%;
  max-width: 45%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.right-wrapper {
  display: flex;
  min-width: 70%;
  flex-flow: row;
  justify-content: flex-end;
  align-items: center;
  gap: 24px;
}
.step-wrapper {
  text-align: right;
  width: 35%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 0px;
  img {
    cursor: pointer;
  }
}
.steps-menu-row {
  padding: 8px;
  cursor: pointer;
  &:hover {
    background-color: #e6e8ec;
  }
}
.date-wrapper {
  min-width: fit-content;
  margin-left: 24px;
  text-align: right;
  flex-direction: row;
  align-items: center;
  display: flex;
  flex: 1;
  gap: 12px;
  img {
    height: 100%;
    width: auto;
    cursor: pointer;
  }
}
.fit {
  min-width: 100%;
  overflow: hidden;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.pointer {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
/* .deal-button {
  //margin: 12px 56px 24px 24px;
} */
.field-name {
  color: #75787a;
}
.field-name,
.field-value {
  width: 240px;
  line-height: 20px;
  font-family: "Inter", sans-serif;
  font-size: 14px;
}
.main-info {
  padding-left: 32px;
  //border-bottom: 1px solid #e6e8ec;
}

.point-separator {
  margin-left: 8px;
  margin-right: 8px;
}
.counter-icon {
  background: #939597;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  width: 16px;
  height: 16px;
}
@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
.top8 {
  margin-top: 8px;
}
</style>
