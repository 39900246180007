var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.sellerIsLoaded ? _c('div', {
    staticClass: "overlay-notarymodal",
    attrs: {
      "id": "overlay-notary-document"
    },
    on: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) return null;
        return _vm.closeModal.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "modal-wrapper",
    attrs: {
      "id": "panel-wrapper-notary-document"
    }
  }, [_c('div', {
    staticStyle: {
      "padding-left": "24px"
    },
    attrs: {
      "id": "wrapped-header-notary-document"
    },
    on: {
      "click": _vm.closeContactAdd
    }
  }, [_c('h4', {
    staticStyle: {
      "align-self": "center"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("NotaryDocument")) + " ")])]), _c('div', {
    staticClass: "notary-content",
    attrs: {
      "id": "content-wrapper"
    }
  }, [_c('v-row', [_vm.notaryDocument.dealId ? _c('span', {
    staticClass: "flex justify-end",
    staticStyle: {
      "cursor": "pointer",
      "text-decoration-line": "underline"
    },
    on: {
      "click": function ($event) {
        return _vm.clearDocument();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Clear") + " " + _vm.$t("Document")) + " ")]) : _vm._e()]), _c('h5', {
    staticClass: "modal-title"
  }, [_vm._v(" " + _vm._s(_vm.$t("CustomerField.CustomerType.Options.SELLER")) + " ")]), _c('span', {
    key: _vm.sellersUpdater
  }, _vm._l(_vm.notaryDocument.sellers, function (seller, index) {
    return _c('div', {
      key: 'seller' + index,
      staticStyle: {
        "padding-top": "12px"
      }
    }, [_c('div', {
      staticClass: "form-wrapper gray-wrapper"
    }, [index !== 0 ? _c('span', {
      staticClass: "content-small clear-button",
      on: {
        "click": function ($event) {
          return _vm.notaryDocument.sellers.splice(index, 1);
        }
      }
    }, [_c('img', {
      attrs: {
        "src": require("../../assets/images/close.svg"),
        "alt": ""
      }
    }), _vm._v(" " + _vm._s(_vm.$t("Clear").toLowerCase()) + " ")]) : _vm._e(), _c('v-row', {
      staticStyle: {
        "z-index": "111"
      }
    }, [!_vm.fromAnotherPanel ? _c('ContactSearch', {
      class: 'no-active required ' + (_vm.showErrors && !_vm.isFieldValid('name', 'sellers', index) ? 'not-valid' : ''),
      attrs: {
        "placeholder": _vm.$t('FullName'),
        "items": _vm.customers,
        "chosen-object": seller.contactPerson,
        "already-chosen": _vm.sellerIsLoaded
      },
      on: {
        "resultChosen": function (item) {
          return _vm.setContactPerson(item, 'sellers', index);
        },
        "removeContact": function ($event) {
          return _vm.removeSeller(index);
        },
        "addNew": function ($event) {
          return _vm.openContactAdd('sellers', index);
        }
      },
      model: {
        value: seller.contactPerson,
        callback: function ($$v) {
          _vm.$set(seller, "contactPerson", $$v);
        },
        expression: "seller.contactPerson"
      }
    }) : _vm._e(), _vm.fromAnotherPanel ? _c('v-autocomplete', {
      attrs: {
        "items": _vm.customers,
        "item-text": "customerName",
        "return-object": "",
        "label": _vm.$t('FullName')
      },
      scopedSlots: _vm._u([{
        key: "prepend-inner",
        fn: function () {
          return [_c('img', {
            attrs: {
              "width": "20",
              "height": "20",
              "src": require("../../assets/images/user.svg")
            }
          })];
        },
        proxy: true
      }], null, true),
      model: {
        value: seller.contactPerson,
        callback: function ($$v) {
          _vm.$set(seller, "contactPerson", $$v);
        },
        expression: "seller.contactPerson"
      }
    }) : _vm._e()], 1), _c('v-text-field', {
      class: 'no-active required ' + (_vm.showErrors && !_vm.isFieldValid('personalCode', 'sellers', index) ? 'not-valid' : ''),
      attrs: {
        "outlined": "",
        "label": _vm.$t('CustomerField.personalCode')
      },
      model: {
        value: seller.personalCode,
        callback: function ($$v) {
          _vm.$set(seller, "personalCode", $$v);
        },
        expression: "seller.personalCode"
      }
    }), _c('v-text-field', {
      class: 'no-active required ' + (_vm.showErrors && !_vm.isFieldValid('address', 'sellers', index) ? 'not-valid' : ''),
      attrs: {
        "outlined": "",
        "label": _vm.$t('CustomerField.address')
      },
      model: {
        value: seller.address,
        callback: function ($$v) {
          _vm.$set(seller, "address", $$v);
        },
        expression: "seller.address"
      }
    }), _c('v-text-field', {
      staticClass: "no-active",
      attrs: {
        "outlined": "",
        "label": _vm.$t('CustomerField.email')
      },
      model: {
        value: seller.email,
        callback: function ($$v) {
          _vm.$set(seller, "email", $$v);
        },
        expression: "seller.email"
      }
    }), _c('v-text-field', {
      staticClass: "no-active",
      attrs: {
        "outlined": "",
        "label": _vm.$t('CustomerField.phoneNumber')
      },
      model: {
        value: seller.phoneNumber,
        callback: function ($$v) {
          _vm.$set(seller, "phoneNumber", $$v);
        },
        expression: "seller.phoneNumber"
      }
    }), _c('v-text-field', {
      class: 'no-active required ' + (_vm.showErrors && !_vm.isFieldValid('maritalStatus', 'sellers', index) ? 'not-valid' : ''),
      attrs: {
        "outlined": "",
        "label": _vm.$t('CustomerField.MaritalStatus')
      },
      model: {
        value: seller.maritalStatus,
        callback: function ($$v) {
          _vm.$set(seller, "maritalStatus", $$v);
        },
        expression: "seller.maritalStatus"
      }
    })], 1)]);
  }), 0), _c('div', {
    staticStyle: {
      "text-decoration-line": "underline",
      "margin": "24px 0",
      "cursor": "pointer"
    },
    on: {
      "click": function ($event) {
        _vm.errors.sellers.push(_vm.getContactEmptyErrors());
        _vm.notaryDocument.sellers.push(_vm.getEmptyPerson());
      }
    }
  }, [_vm._v(" " + _vm._s("+ " + _vm.$t("AddAnother")) + " ")]), _c('h5', {
    staticClass: "modal-title"
  }, [_vm._v(" " + _vm._s(_vm.$t("CustomerField.CustomerType.Options.BUYER")) + " ")]), _c('span', {
    key: _vm.buyersUpdater
  }, _vm._l(_vm.notaryDocument.buyers, function (buyer, index) {
    return _c('div', {
      key: _vm.buyersUpdater[index],
      staticStyle: {
        "padding-top": "12px"
      }
    }, [_c('div', {
      staticClass: "form-wrapper gray-wrapper"
    }, [index !== 0 ? _c('span', {
      staticClass: "content-small clear-button",
      on: {
        "click": function ($event) {
          return _vm.notaryDocument.buyers.splice(index, 1);
        }
      }
    }, [_c('img', {
      attrs: {
        "src": require("../../assets/images/close.svg"),
        "alt": ""
      }
    }), _vm._v(" " + _vm._s(_vm.$t("Clear").toLowerCase()) + " ")]) : _vm._e(), _c('v-row', {
      staticStyle: {
        "z-index": "111"
      }
    }, [!_vm.fromAnotherPanel ? _c('ContactSearch', {
      class: 'no-active required ' + (_vm.showErrors && !_vm.isFieldValid('name', 'buyers', index) ? 'not-valid' : ''),
      attrs: {
        "placeholder": _vm.$t('FullName'),
        "items": _vm.customers,
        "chosen-object": buyer.contactPerson
      },
      on: {
        "resultChosen": function (item) {
          return _vm.setContactPerson(item, 'buyers', index);
        },
        "removeContact": function ($event) {
          return _vm.removeBuyer(index);
        },
        "addNew": function ($event) {
          return _vm.openContactAdd('buyers', index);
        }
      },
      model: {
        value: buyer.contactPerson,
        callback: function ($$v) {
          _vm.$set(buyer, "contactPerson", $$v);
        },
        expression: "buyer.contactPerson"
      }
    }) : _vm._e(), _vm.fromAnotherPanel ? _c('v-autocomplete', {
      attrs: {
        "items": _vm.customers,
        "item-text": "customerName",
        "return-object": "",
        "label": _vm.$t('FullName')
      },
      scopedSlots: _vm._u([{
        key: "prepend-inner",
        fn: function () {
          return [_c('img', {
            attrs: {
              "width": "20",
              "height": "20",
              "src": require("../../assets/images/user.svg")
            }
          })];
        },
        proxy: true
      }], null, true),
      model: {
        value: buyer.contactPerson,
        callback: function ($$v) {
          _vm.$set(buyer, "contactPerson", $$v);
        },
        expression: "buyer.contactPerson"
      }
    }) : _vm._e()], 1), _c('v-text-field', {
      class: 'no-active required ' + (_vm.showErrors && !_vm.isFieldValid('personalCode', 'buyers', index) ? 'not-valid' : ''),
      attrs: {
        "outlined": "",
        "label": _vm.$t('CustomerField.personalCode')
      },
      model: {
        value: buyer.personalCode,
        callback: function ($$v) {
          _vm.$set(buyer, "personalCode", $$v);
        },
        expression: "buyer.personalCode"
      }
    }), _c('v-text-field', {
      class: 'no-active required ' + (_vm.showErrors && !_vm.isFieldValid('address', 'buyers', index) ? 'not-valid' : ''),
      attrs: {
        "outlined": "",
        "label": _vm.$t('CustomerField.address')
      },
      model: {
        value: buyer.address,
        callback: function ($$v) {
          _vm.$set(buyer, "address", $$v);
        },
        expression: "buyer.address"
      }
    }), _c('v-text-field', {
      staticClass: "no-active",
      attrs: {
        "outlined": "",
        "label": _vm.$t('CustomerField.email')
      },
      model: {
        value: buyer.email,
        callback: function ($$v) {
          _vm.$set(buyer, "email", $$v);
        },
        expression: "buyer.email"
      }
    }), _c('v-text-field', {
      staticClass: "no-active",
      attrs: {
        "outlined": "",
        "label": _vm.$t('CustomerField.phoneNumber')
      },
      model: {
        value: buyer.phoneNumber,
        callback: function ($$v) {
          _vm.$set(buyer, "phoneNumber", $$v);
        },
        expression: "buyer.phoneNumber"
      }
    }), _c('v-text-field', {
      class: 'no-active required ' + (_vm.showErrors && !_vm.isFieldValid('maritalStatus', 'buyers', index) ? 'not-valid' : ''),
      attrs: {
        "outlined": "",
        "label": _vm.$t('CustomerField.MaritalStatus')
      },
      model: {
        value: buyer.maritalStatus,
        callback: function ($$v) {
          _vm.$set(buyer, "maritalStatus", $$v);
        },
        expression: "buyer.maritalStatus"
      }
    })], 1)]);
  }), 0), _c('div', {
    staticStyle: {
      "text-decoration-line": "underline",
      "margin": "24px 0",
      "cursor": "pointer"
    },
    on: {
      "click": function ($event) {
        _vm.errors.buyers.push(_vm.getContactEmptyErrors());
        _vm.notaryDocument.buyers.push(_vm.getEmptyPerson());
      }
    }
  }, [_vm._v(" " + _vm._s("+ " + _vm.$t("AddAnother")) + " ")]), _c('h5', {
    staticClass: "modal-title"
  }, [_vm._v(" " + _vm._s(_vm.$t("Object")) + " ")]), _c('div', {
    staticClass: "form-wrapper"
  }, [_c('v-text-field', {
    class: 'no-active required ' + (_vm.showErrors && !_vm.isFieldValid('address', 'object') ? 'not-valid' : ''),
    attrs: {
      "outlined": "",
      "label": _vm.$t('Address')
    },
    model: {
      value: _vm.notaryDocument.object.address,
      callback: function ($$v) {
        _vm.$set(_vm.notaryDocument.object, "address", $$v);
      },
      expression: "notaryDocument.object.address"
    }
  }), _c('v-text-field', {
    class: 'no-active required ' + (_vm.showErrors && !_vm.isFieldValid('cadastralNumber', 'object') ? 'not-valid' : ''),
    attrs: {
      "outlined": "",
      "label": _vm.$t('CadastralNumber')
    },
    model: {
      value: _vm.notaryDocument.object.cadastralNumber,
      callback: function ($$v) {
        _vm.$set(_vm.notaryDocument.object, "cadastralNumber", $$v);
      },
      expression: "notaryDocument.object.cadastralNumber"
    }
  }), _c('v-text-field', {
    class: 'no-active required ' + (_vm.showErrors && !_vm.isFieldValid('estateNumber', 'object') ? 'not-valid' : ''),
    attrs: {
      "outlined": "",
      "label": _vm.$t('EstateNumber')
    },
    model: {
      value: _vm.notaryDocument.object.estateNumber,
      callback: function ($$v) {
        _vm.$set(_vm.notaryDocument.object, "estateNumber", $$v);
      },
      expression: "notaryDocument.object.estateNumber"
    }
  }), _c('v-text-field', {
    class: 'no-active required ' + (_vm.showErrors && !_vm.isFieldValid('price', 'object') ? 'not-valid' : ''),
    attrs: {
      "outlined": "",
      "label": _vm.$t('SellingPrice')
    },
    model: {
      value: _vm.notaryDocument.object.price,
      callback: function ($$v) {
        _vm.$set(_vm.notaryDocument.object, "price", $$v);
      },
      expression: "notaryDocument.object.price"
    }
  }), _c('h6', {
    staticStyle: {
      "padding-top": "24px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("PaymentOptions")) + " ")]), _c('v-radio-group', {
    attrs: {
      "column": ""
    },
    model: {
      value: _vm.notaryDocument.object.paymentMethod,
      callback: function ($$v) {
        _vm.$set(_vm.notaryDocument.object, "paymentMethod", $$v);
      },
      expression: "notaryDocument.object.paymentMethod"
    }
  }, [_c('v-radio', {
    attrs: {
      "label": _vm.$t('ThroughNotary'),
      "color": "orange darken-3",
      "value": 'throughNotary',
      "row": ""
    }
  }), _c('v-radio', {
    attrs: {
      "label": _vm.$t('BeforeTransaction'),
      "color": "orange darken-3",
      "value": 'beforeTransaction',
      "row": ""
    }
  }), _c('v-radio', {
    attrs: {
      "label": _vm.$t('ThroughLoan'),
      "color": "orange darken-3",
      "value": 'throughLoan'
    }
  })], 1)], 1), _c('h5', {
    staticClass: "modal-title"
  }, [_vm._v(" " + _vm._s(_vm.$t("EstateInfo.About")) + " ")]), _c('div', {
    staticClass: "form-wrapper"
  }, [_c('v-text-field', {
    staticClass: "no-active",
    attrs: {
      "outlined": "",
      "label": _vm.$t('EstateInfo.BuiltUp')
    },
    model: {
      value: _vm.notaryDocument.estateInfo.builtUp,
      callback: function ($$v) {
        _vm.$set(_vm.notaryDocument.estateInfo, "builtUp", $$v);
      },
      expression: "notaryDocument.estateInfo.builtUp"
    }
  }), _c('v-text-field', {
    staticClass: "no-active",
    attrs: {
      "outlined": "",
      "label": _vm.$t('EstateInfo.WhatKind')
    },
    model: {
      value: _vm.notaryDocument.estateInfo.buildings,
      callback: function ($$v) {
        _vm.$set(_vm.notaryDocument.estateInfo, "buildings", $$v);
      },
      expression: "notaryDocument.estateInfo.buildings"
    }
  }), _c('v-text-field', {
    staticClass: "no-active",
    attrs: {
      "outlined": "",
      "label": _vm.$t('EstateInfo.Condition')
    },
    model: {
      value: _vm.notaryDocument.estateInfo.condition,
      callback: function ($$v) {
        _vm.$set(_vm.notaryDocument.estateInfo, "condition", $$v);
      },
      expression: "notaryDocument.estateInfo.condition"
    }
  }), _c('v-text-field', {
    staticClass: "no-active",
    attrs: {
      "outlined": "",
      "label": _vm.$t('EstateInfo.Insured')
    },
    model: {
      value: _vm.notaryDocument.estateInfo.insured,
      callback: function ($$v) {
        _vm.$set(_vm.notaryDocument.estateInfo, "insured", $$v);
      },
      expression: "notaryDocument.estateInfo.insured"
    }
  }), _c('v-text-field', {
    staticClass: "no-active",
    attrs: {
      "outlined": "",
      "label": _vm.$t('EstateInfo.SignedIn')
    },
    model: {
      value: _vm.notaryDocument.estateInfo.signedIn,
      callback: function ($$v) {
        _vm.$set(_vm.notaryDocument.estateInfo, "signedIn", $$v);
      },
      expression: "notaryDocument.estateInfo.signedIn"
    }
  }), _c('v-text-field', {
    staticClass: "no-active",
    attrs: {
      "outlined": "",
      "label": _vm.$t('EstateInfo.Furniture')
    },
    model: {
      value: _vm.notaryDocument.estateInfo.furniture,
      callback: function ($$v) {
        _vm.$set(_vm.notaryDocument.estateInfo, "furniture", $$v);
      },
      expression: "notaryDocument.estateInfo.furniture"
    }
  })], 1), _c('h5', {
    staticClass: "modal-title"
  }, [_vm._v(" " + _vm._s(_vm.$t("SpecialConditions")) + " ")]), _c('v-textarea', {
    staticClass: "form-wrapper",
    attrs: {
      "no-resize": "",
      "outlined": "",
      "placeholder": "Note"
    },
    model: {
      value: _vm.notaryDocument.specialConditions,
      callback: function ($$v) {
        _vm.$set(_vm.notaryDocument, "specialConditions", $$v);
      },
      expression: "notaryDocument.specialConditions"
    }
  }), _c('h5', {
    staticClass: "modal-title"
  }, [_vm._v(" " + _vm._s(_vm.$t("HandOver")) + " ")]), _c('p', {
    staticStyle: {
      "color": "grey"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("HandOverExtra")) + " ")]), _c('v-textarea', {
    staticClass: "form-wrapper",
    attrs: {
      "no-resize": "",
      "outlined": "",
      "placeholder": "Note"
    },
    model: {
      value: _vm.notaryDocument.handedOver,
      callback: function ($$v) {
        _vm.$set(_vm.notaryDocument, "handedOver", $$v);
      },
      expression: "notaryDocument.handedOver"
    }
  }), _c('h5', {
    staticClass: "modal-title"
  }, [_vm._v(" " + _vm._s(_vm.$t("Leases")) + " ")]), _c('div', {
    staticClass: "form-wrapper"
  }, [_c('v-radio-group', {
    attrs: {
      "row": ""
    },
    model: {
      value: _vm.notaryDocument.activeLeases,
      callback: function ($$v) {
        _vm.$set(_vm.notaryDocument, "activeLeases", $$v);
      },
      expression: "notaryDocument.activeLeases"
    }
  }, [_c('v-radio', {
    attrs: {
      "label": _vm.$t('Yes'),
      "color": "orange darken-3",
      "value": true
    }
  }), _c('v-radio', {
    attrs: {
      "label": _vm.$t('No'),
      "color": "orange darken-3",
      "value": false
    }
  })], 1)], 1), _c('h5', {
    staticClass: "modal-title"
  }, [_vm._v(" " + _vm._s(_vm.$t("ResponsibleForPayment")) + " ")]), _c('div', {
    staticClass: "form-wrapper"
  }, [_c('v-radio-group', {
    attrs: {
      "row": ""
    },
    model: {
      value: _vm.notaryDocument.payment,
      callback: function ($$v) {
        _vm.$set(_vm.notaryDocument, "payment", $$v);
      },
      expression: "notaryDocument.payment"
    }
  }, [_c('v-radio', {
    attrs: {
      "label": _vm.$t('CustomerField.CustomerType.Options.SELLER'),
      "color": "orange darken-3",
      "value": 'seller'
    }
  }), _c('v-radio', {
    attrs: {
      "label": _vm.$t('CustomerField.CustomerType.Options.BUYER'),
      "color": "orange darken-3",
      "value": 'buyer'
    }
  })], 1)], 1)], 1)]), _c('div', {
    staticClass: "buttons-container"
  }, [_c('button', {
    staticClass: "transparent-button",
    on: {
      "click": _vm.save
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("SaveAndExit")) + " ")]), _c('span', {
    staticStyle: {
      "display": "flex",
      "gap": "8px"
    }
  }, [_c('button', {
    staticClass: "transparent-button",
    on: {
      "click": function ($event) {
        return _vm.closeModal();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")]), _c('button', {
    staticClass: "transparent-button",
    staticStyle: {
      "background": "black !important",
      "color": "white"
    },
    attrs: {
      "name": "generate-doc-button"
    },
    on: {
      "click": function ($event) {
        return _vm.generate();
      }
    }
  }, [_c('img', {
    staticStyle: {
      "height": "16px",
      "width": "16px"
    },
    attrs: {
      "src": require("../../assets/images/checkmarkCircled.svg"),
      "alt": ""
    }
  }), _c('span', [_vm._v(" " + _vm._s(_vm.$t("GenerateDocument")) + " ")])])])]), _c('VueHtml2pdf', {
    ref: "html2Pdf",
    attrs: {
      "show-layout": false,
      "float-layout": true,
      "enable-download": true,
      "preview-modal": true,
      "paginate-elements-by-height": 1400,
      "filename": "notary-doc",
      "pdf-quality": 2,
      "manual-pagination": false,
      "pdf-format": "a4",
      "pdf-orientation": "portrait",
      "pdf-content-width": "800px"
    },
    on: {
      "hasDownloaded": function ($event) {
        return _vm.hasDownloaded($event);
      }
    }
  }, [_c('section', {
    staticClass: "pdf-content",
    attrs: {
      "slot": "pdf-content"
    },
    slot: "pdf-content"
  }, [_c('section', {
    staticStyle: {
      "padding-bottom": "12px"
    }
  }, [_c('h2', [_vm._v(_vm._s(_vm.$t("NotaryDocument")))]), _c('p', [_vm._v(_vm._s(_vm.dateToday()))])]), _c('section', {
    staticClass: "sub-block"
  }, [_c('h4', [_vm._v(_vm._s(_vm.$t("Sellers")))]), _vm._l(_vm.notaryDocument.sellers, function (seller, index) {
    return _c('div', {
      key: 'pdfseller' + index,
      staticStyle: {
        "margin-top": "8px"
      }
    }, [_vm._v(" " + _vm._s(seller.name) + ", "), seller.registerCode ? _c('span', [_vm._v(_vm._s(seller.registerCode) + ",")]) : _vm._e(), _vm._v(" " + _vm._s(seller.personalCode) + ", " + _vm._s(seller.address) + ", "), seller.email ? _c('span', [_vm._v(" " + _vm._s(seller.email) + ", ")]) : _vm._e(), seller.phoneNumber ? _c('span', [_vm._v(" " + _vm._s(seller.phoneNumber) + ", ")]) : _vm._e(), _vm._v(" " + _vm._s(seller.maritalStatus) + " ")]);
  })], 2), _c('section', {
    staticClass: "sub-block"
  }, [_c('h4', [_vm._v(_vm._s(_vm.$t("Buyers")))]), _vm._l(_vm.notaryDocument.buyers, function (buyer, index) {
    return _c('div', {
      key: 'pdfBuyer' + index,
      staticStyle: {
        "margin-top": "8px"
      }
    }, [_vm._v(" " + _vm._s(buyer.customerName) + ", "), buyer.registerCode ? _c('span', [_vm._v(_vm._s(buyer.registerCode) + ",")]) : _vm._e(), _vm._v(" " + _vm._s(buyer.personalCode) + ", " + _vm._s(buyer.address) + ", "), buyer.email ? _c('span', [_vm._v(" " + _vm._s(buyer.email) + ", ")]) : _vm._e(), buyer.phoneNumber ? _c('span', [_vm._v(" " + _vm._s(buyer.phoneNumber) + ", ")]) : _vm._e(), _vm._v(" " + _vm._s(buyer.maritalStatus) + " ")]);
  })], 2), _c('section', {
    staticClass: "sub-block"
  }, [_c('h4', [_vm._v(_vm._s(_vm.$t("ImmovableProperty")))]), _c('p', [_vm._v(_vm._s(_vm.notaryDocument.object.address))]), _vm.notaryDocument.object.cadastralNumber ? _c('p', [_vm._v(" " + _vm._s(_vm.$t("CadastralEntityNumber")) + ": " + _vm._s(_vm.notaryDocument.object.cadastralNumber) + " ")]) : _vm._e(), _c('p', [_vm._v(" " + _vm._s(_vm.$t("EstateNumber")) + ": " + _vm._s(_vm.notaryDocument.object.estateNumber) + " ")]), _vm.notaryDocument.object.price ? _c('p', [_vm._v(" " + _vm._s(_vm.$t("Price")) + ": " + _vm._s(_vm.notaryDocument.object.price) + " ")]) : _vm._e(), _vm.notaryDocument.object.paymentMethod === 'throughNotary' ? _c('p', [_vm._v(" " + _vm._s(_vm.$t("ThroughNotary")) + " ")]) : _vm._e(), _vm.notaryDocument.object.paymentMethod === 'beforeTransaction' ? _c('p', [_vm._v(" " + _vm._s(_vm.$t("BeforeTransaction")) + " ")]) : _vm._e(), _vm.notaryDocument.object.paymentMethod === 'throughLoan' ? _c('p', [_vm._v(" " + _vm._s(_vm.$t("ThroughLoan")) + " ")]) : _vm._e()]), _vm.notaryDocument.estateInfo.builtUp ? _c('section', {
    staticClass: "sub-block"
  }, [_c('h4', [_vm._v(_vm._s(_vm.$t("EstateInfo.BuiltUp")))]), _c('p', [_vm._v(_vm._s(_vm.notaryDocument.estateInfo.builtUp))])]) : _vm._e(), _vm.notaryDocument.estateInfo.buildings ? _c('section', {
    staticClass: "sub-block"
  }, [_c('h4', [_vm._v(_vm._s(_vm.$t("EstateInfo.WhatKind")))]), _c('p', [_vm._v(_vm._s(_vm.notaryDocument.estateInfo.buildings))])]) : _vm._e(), _vm.notaryDocument.estateInfo.condition ? _c('section', {
    staticClass: "sub-block"
  }, [_c('h4', [_vm._v(_vm._s(_vm.$t("EstateInfo.Condition")))]), _c('p', [_vm._v(_vm._s(_vm.notaryDocument.estateInfo.condition))])]) : _vm._e(), _vm.notaryDocument.estateInfo.insured ? _c('section', {
    staticClass: "sub-block"
  }, [_c('h4', [_vm._v(_vm._s(_vm.$t("EstateInfo.Insured")))]), _c('p', [_vm._v(_vm._s(_vm.notaryDocument.estateInfo.insured))])]) : _vm._e(), _vm.notaryDocument.estateInfo.signedIn ? _c('section', {
    staticClass: "sub-block"
  }, [_c('h4', [_vm._v(_vm._s(_vm.$t("EstateInfo.SignedIn")))]), _c('p', [_vm._v(_vm._s(_vm.notaryDocument.estateInfo.signedIn))])]) : _vm._e(), _vm.notaryDocument.estateInfo.furniture ? _c('section', {
    staticClass: "sub-block"
  }, [_c('h4', [_vm._v(_vm._s(_vm.$t("EstateInfo.Furniture")))]), _c('p', [_vm._v(_vm._s(_vm.notaryDocument.estateInfo.furniture))])]) : _vm._e(), _vm.notaryDocument.specialConditions ? _c('section', {
    staticClass: "sub-block"
  }, [_c('h4', [_vm._v(_vm._s(_vm.$t("SpecialConditions")))]), _c('p', [_vm._v(_vm._s(_vm.notaryDocument.specialConditions))])]) : _vm._e(), _vm.notaryDocument.handedOver ? _c('section', {
    staticClass: "sub-block"
  }, [_c('h4', [_vm._v(_vm._s(_vm.$t("HandOverExtra")))]), _c('p', [_vm._v(_vm._s(_vm.notaryDocument.handedOver))])]) : _vm._e(), _vm.notaryDocument.activeLeases ? _c('section', {
    staticClass: "sub-block"
  }, [_c('h4', [_vm._v(_vm._s(_vm.$t("Leases")))]), _c('p', [_vm._v(_vm._s(_vm.notaryDocument.activeLeases ? _vm.$t("Yes") : _vm.$t("No")))])]) : _vm._e(), _vm.notaryDocument.payment ? _c('section', {
    staticClass: "sub-block"
  }, [_c('h4', [_vm._v(_vm._s(_vm.$t("ResponsibleForPayment")))]), _vm.notaryDocument.payment === 'seller' ? _c('p', [_vm._v(" " + _vm._s(_vm.$t("CustomerField.CustomerType.Options.SELLER")) + " ")]) : _vm._e(), _vm.notaryDocument.payment === 'buyer' ? _c('p', [_vm._v(" " + _vm._s(_vm.$t("CustomerField.CustomerType.Options.BUYER")) + " ")]) : _vm._e()]) : _vm._e()])]), _vm.clientAddOpen ? _c('AddModal', {
    staticStyle: {
      "z-index": "1200"
    },
    attrs: {
      "accordion": true
    },
    on: {
      "newAdded": _vm.chooseNewClient,
      "closed": _vm.closeContactAdd
    }
  }) : _vm._e()], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }