<template>
  <span v-if="!smallFont" :class="'default-deal-type-con'">
    {{ $t(projectEndReason) }}
  </span>
  <span
    v-else-if="smallFont"
    :class="'content-small'"
    style="
      font-weight: bold;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      border-radius: 4px;
      border-width: 0;
      padding: 0 4px;
      flex-direction: row;
      display: inline-flex;
      color: #000000 !important;
      background: #fff2f7;
    "
  >
    {{ $t(projectEndReason) }}
  </span>
</template>

<script>
export default {
  name: "DealTypeContainer",
  props: ["projectEndReason", "customStyle", "smallFont"],
  data: () => ({}),
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.default-deal-type-con {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  border-radius: 4px;
  border-width: 0;
  padding: 0 4px;
  flex-direction: row;
  display: inline-flex;

  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
}
</style>
