var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.closeWidget,
      expression: "closeWidget"
    }],
    staticClass: "details-dropdown",
    attrs: {
      "id": "details-dropdown"
    }
  }, [_vm._l(_vm.data, function (item, index) {
    return _c('div', {
      key: 'details-dropdown-item-' + index,
      staticClass: "details-dropdown-content-row"
    }, [_c('div', {
      staticClass: "details-dropdown-icon"
    }, [item.icon ? _c('img', {
      attrs: {
        "src": require(`@/assets/images/${item.icon}`),
        "alt": ""
      }
    }) : _vm._e()]), item.editType === 'text' ? _c('div', {
      staticClass: "details-dropdown-content"
    }, [_c('div', {
      staticClass: "label12",
      staticStyle: {
        "color": "#75787A"
      }
    }, [_vm._v(" " + _vm._s(item.title) + " ")]), !_vm.inlineEditOpen || _vm.inlineEditIndex !== index ? _c('div', {
      class: {
        'content': item.font === 'normal',
        'content-small': item.font === 'small' || !item.font
      },
      staticStyle: {
        "cursor": "pointer",
        "word-break": "break-all"
      },
      on: {
        "click": function ($event) {
          return _vm.inlineEdit(item, index);
        }
      }
    }, [_c('span', [_vm._v(" " + _vm._s(item.value) + " ")]), item.value && item.value !== '-' ? _c('span', [_vm._v(" " + _vm._s(item.valueSuffix) + " ")]) : _vm._e()]) : _vm._e(), _vm.inlineEditOpen && _vm.inlineEditIndex === index ? _c('v-text-field', {
      ref: "inlineEdit",
      refInFor: true,
      staticStyle: {
        "height": "24px"
      },
      attrs: {
        "type": "number",
        "dense": ""
      },
      on: {
        "focusout": function ($event) {
          return _vm.saveEditedItem(item, index);
        },
        "keydown": function ($event) {
          if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
          return $event.target.blur();
        }
      },
      model: {
        value: item.value,
        callback: function ($$v) {
          _vm.$set(item, "value", $$v);
        },
        expression: "item.value"
      }
    }) : _vm._e()], 1) : _vm._e(), item.editType === 'options' ? _c('div', {
      staticClass: "details-dropdown-content"
    }, [_c('div', {
      staticClass: "label12",
      staticStyle: {
        "color": "#75787A"
      }
    }, [_vm._v(_vm._s(item.title))]), _c('div', {
      class: {
        'content': item.font === 'normal',
        'content-small': item.font === 'small' || !item.font
      },
      staticStyle: {
        "word-break": "break-word",
        "cursor": "pointer"
      },
      on: {
        "click": _vm.openOptions
      }
    }, [_c('span', {
      staticStyle: {
        "word-break": "keep-all",
        "white-space": "nowrap"
      }
    }, [_vm._v(_vm._s(_vm.formatOptionValue(item)))]), item.value && item.value !== '-' ? _c('span', [_vm._v(_vm._s(item.valueSuffix))]) : _vm._e()]), _vm.optionsOpen ? _c('div', {
      ref: "dropdownOptions",
      refInFor: true,
      staticStyle: {
        "position": "absolute",
        "outline": "none",
        "display": "flex",
        "flex-direction": "column",
        "gap": "16px",
        "z-index": "9",
        "padding": "12px",
        "border": "1px solid #E6E8EC",
        "border-radius": "8px",
        "background-color": "white"
      },
      attrs: {
        "tabindex": "0"
      },
      on: {
        "focusout": function ($event) {
          _vm.optionsOpen = false;
        }
      }
    }, _vm._l(item.options, function (option) {
      return _c('div', {
        key: option.value
      }, [option.value === item.value ? _c('span', {
        staticClass: "content-small",
        staticStyle: {
          "font-weight": "600",
          "cursor": "pointer"
        }
      }, [_vm._v(_vm._s(option.name))]) : _c('span', {
        staticClass: "content-small",
        staticStyle: {
          "cursor": "pointer"
        },
        on: {
          "click": function ($event) {
            return _vm.chooseOption(option, item);
          }
        }
      }, [_vm._v(_vm._s(option.name))])]);
    }), 0) : _vm._e()]) : _vm._e(), item.editType === 'calendar' ? _c('div', {
      staticClass: "details-dropdown-content"
    }, [_c('div', {
      staticClass: "label12",
      staticStyle: {
        "color": "#75787A"
      }
    }, [_vm._v(_vm._s(item.title))]), _c('date-pick', {
      staticStyle: {
        "width": "100%"
      },
      on: {
        "input": function (date) {
          _vm.chooseDate(date, item);
        }
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function (_ref) {
          var toggle = _ref.toggle;
          return [_c('div', {
            class: {
              'content': item.font === 'normal',
              'content-small': item.font === 'small' || !item.font
            },
            staticStyle: {
              "word-break": "break-all",
              "cursor": "pointer",
              "width": "100%"
            },
            on: {
              "click": toggle
            }
          }, [_c('span', {
            staticStyle: {
              "width": "100%",
              "white-space": "nowrap"
            }
          }, [_vm._v(_vm._s(_vm.formatDate(item.value)))])])];
        }
      }], null, true)
    })], 1) : _vm._e(), !item.editType ? _c('div', {
      staticClass: "details-dropdown-content"
    }, [_c('div', {
      staticClass: "label12",
      staticStyle: {
        "color": "#75787A"
      }
    }, [_vm._v(_vm._s(item.title))]), _c('div', {
      class: {
        'content': item.font === 'normal',
        'content-small': item.font === 'small' || !item.font
      },
      staticStyle: {
        "word-break": "break-all"
      }
    }, [_c('span', [_vm._v(_vm._s(item.value))]), item.value && item.value !== '-' ? _c('span', [_vm._v(_vm._s(item.valueSuffix))]) : _vm._e()])]) : _vm._e(), item.clearable && item.value !== '-' ? _c('div', {
      staticClass: "details-dropdown-row-clear-button"
    }, [_c('img', {
      staticStyle: {
        "cursor": "pointer"
      },
      attrs: {
        "src": require(`@/assets/images/close_16_16.svg`),
        "alt": ""
      },
      on: {
        "click": function ($event) {
          return _vm.clearField(item, index);
        }
      }
    })]) : _vm._e()]);
  }), _vm.showDelete ? _c('div', {
    staticClass: "details-dropdown-content-row",
    staticStyle: {
      "height": "20px",
      "cursor": "pointer"
    },
    on: {
      "click": function ($event) {
        _vm.isDeleteModalOpen = true;
      }
    }
  }, [_c('div', {
    staticClass: "details-dropdown-icon"
  }, [_c('img', {
    attrs: {
      "src": require(`@/assets/images/red_trash_20_20.svg`),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "details-dropdown-content",
    staticStyle: {
      "display": "flex",
      "align-items": "center",
      "max-height": "20px"
    }
  }, [_c('div', {
    staticClass: "content-small",
    staticStyle: {
      "color": "#FF1E24"
    }
  }, [_vm._v(_vm._s(_vm.$t("Delete")))])])]) : _vm._e(), _vm.isDeleteModalOpen ? _c('DeleteModal', {
    on: {
      "canceled": function ($event) {
        _vm.isDeleteModalOpen = false;
      },
      "approved": function ($event) {
        _vm.$emit('delete', _vm.item);
        _vm.isDeleteModalOpen = false;
      }
    }
  }) : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }