<template>
  <div class="content">
    <v-row class="customer-row">
      <div class="toolbar-row">
        <button
          @click="unitsAddOpen = true"
          class="outlined-small-button"
          style="background-color: black !important; color: white !important"
        >
          <img
            style="
              filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(317deg)
                brightness(103%) contrast(102%);
            "
            src="../../../assets/images/plusSign.svg"
            alt=""
          />
          {{ $t("AddUnit") }}
        </button>

        <button class="outlined-small-button search-button">
          <img src="../../../assets/images/search.svg" alt="" />
          {{ $t("Search") }}...
        </button>
      </div>
      <div
        style="margin-top: 32px; position: absolute; top: 30%; left: 50%"
        v-if="loading"
        class="loader"
      ></div>
      <div
        v-if="
          (!loading && !object.units) || (!loading && object.units.length < 1)
        "
        class="no-results"
      >
        <h4>{{ $t("NoUnits") }}</h4>
      </div>
      <v-row v-if="object" class="customer-row">
        <v-row class="collection-row">
          <div style="display: flex" class="listings-wrapper">
            <Table
              :key="objectsRefresher"
              v-if="object.units.length > 0"
              :fields="tableFields"
              :dataset="object.units"
              :project-type="projectType"
              :object-id="object._id"
              :deal-id="$route.params.id"
              :customers="customers"
              :listing-view="true"
            />
          </div>
        </v-row>
      </v-row>
    </v-row>
    <UnitsAdd
      @saved="unitsAdded"
      @closed="unitsAddOpen = false"
      v-if="unitsAddOpen"
      :object="object"
      :clients="customers"
    />
    <UndoButton
      @timerDone="handleTimerDone"
      @undone="
        undoButton = false;
        $router.go(0);
      "
      :units-view="true"
      :object-to-undo="recentlyAddedBuildingId"
      :property-to-undo="$route.params.id"
      :items-to-undo="recentlyAddedItems"
      v-if="undoButton"
    />
  </div>
</template>

<script>
import axios from "axios";
import Table from "@/components/common/Table";
import UnitsAdd from "@/components/Deals/UnitsAdd";
import { mapActions, mapGetters } from "vuex";
import UndoButton from "@/components/common/UndoButton";
export default {
  name: "ListContent",
  props: ["object", "refreshKey", "projectType"],
  components: {
    UndoButton,
    Table,
    UnitsAdd,
  },
  data() {
    return {
      loading: false,
      unitsAddOpen: false,
      objectsRefresher: 1,
      objects: [],
      timeout: null,
      recentlyAddedItems: null,
      recentlyAddedObjectId: null,

      undoButton: false,
      tableFields: [
        {
          fieldValue: this.$t("Floor"),
          jsonValue: "floor",
          style: "min-width: 96px; max-width: 96px;",
        },
        {
          fieldValue: this.$t("Size") + " (m2)",
          jsonValue: "unitSize",
          style: "min-width: 96px; max-width: 96px; text-transform: none !important;",
        },
        {
          fieldValue: this.$t("Price") + " €",
          jsonValue: "price",
          style: "min-width: 120px; max-width: 120px;",
        },
        {
          fieldValue: this.$t("Client"),
          jsonValue: "client",
          style: "min-width: 160px; max-width: 160px;",
        },
        {
          fieldValue: this.$t("Status"),
          jsonValue: "status",
          style: "min-width: 144px; max-width: 144px;",
        },
        {
          fieldValue: this.$t("Description"),
          jsonValue: "description",
          style: " width: 100%;",
        },
      ],
    };
  },
  created() {
    this.loading = true;
    this.getDealObjects(this.$route.params.id);
    this.getCustomers();
  },
  mounted() {
    console.log(this.objects);
  },
  computed: {
    ...mapGetters(["customers"]),
  },
  watch: {
    refreshKey() {
      this.objectsRefresher++;
    },
  },
  methods: {
    ...mapActions(["getCustomers"]),
    async handleTimerDone() {
      this.undoButton = false;
    },
    async unitsAdded(timeout, body) {
      this.unitsAddOpen = false;

      if (timeout) {
        this.timeout = timeout;
        let objectId = body.objectId;
        await this.getDealObjects(this.$route.params.id);
        this.recentlyAddedObjectId = objectId;
        this.recentlyAddedItems = body.savedUnitsIds;
        this.undoButton = true;
        this.$emit("refresh");
      } else {
        this.$emit("refresh");

        /* this.$router.go(0);*/
      }
    },
    async getDealObjects(id) {
      const resp = await axios.get(`/api/project/objects/${id}`);
      console.log("objects: ", resp.data.data.objects);
      this.objects = resp.data.data.objects;
      this.loading = false;
      //get different addresses across all listings
    },
    collapseBoard(el) {
      let collapsedElement = document.getElementById(el + "column");
      let arrowUp = document.getElementById(el + "up");
      let arrowDown = document.getElementById(el + "down");
      console.log("to collapse: ", el);
      if (collapsedElement.style.display !== "flex") {
        collapsedElement.style.display = "flex";
        arrowUp.style.display = "block";
        arrowDown.style.display = "none";
      } else {
        collapsedElement.style.display = "none";
        arrowUp.style.display = "none";
        arrowDown.style.display = "block";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.toolbar-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 24px;
}
.col {
  padding: 0;
}
.content {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  display: block;
  width: 100%;
  height: 100%;
  background-color: #fff;
}
.customer-row {
  margin-bottom: 8px;
  background-color: transparent;
  flex-direction: column;
}
.tag {
  padding: 4px 8px;
  margin-right: 8px;
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
}
.tag:before {
  top: 8px;
  left: 8px;
  width: 8px;
  height: 8px;
  content: "";
  position: absolute;
  border-radius: 50%;
  background-color: #0aaf60;
}
.title {
  width: 100%;
}
img,
p {
  display: inline-block;
}
img {
  float: left;
}
.gray {
  background: #f4f5f7;
}
.green {
  background: #0aaf60;
  color: white;
}
.orange {
  background: #ff5c01;
  color: white;
}
.collection-row {
  border-radius: 8px;
  width: 100%;
}
.object-row {
  border-radius: 8px;
  width: 100%;
  margin-top: 8px;
  max-height: 104px;
  .img-col {
    cursor: pointer;
    overflow: hidden;
    padding: 16px;
    width: 136px;
    max-width: 136px;
    max-height: 100%;

    .img-wrap {
      width: 100%;
      height: 100%;
      max-width: 100%;
      max-height: 100%;
      overflow: hidden;
    }
    img {
      width: 100%;
      height: auto;
      border-radius: 8px;
      margin-left: 50%;
      transform: translateX(-50%);
    }
  }
  .info-col {
    padding-left: 16px;
    .listing-title {
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
    .info-icon {
      width: 24px;
      height: 24px;
    }
    .info-text {
      padding-left: 8px;
    }
  }
  img {
    height: auto;
    width: 100%;
  }
}
.relation:hover {
  cursor: pointer;
  color: dodgerblue;
}
.tags-row {
  margin-top: 16px;
  .booking {
    margin-bottom: 0 !important;
    padding-top: 4px;
  }
}
.tags {
  p {
    margin-bottom: 0 !important;
  }
  .active {
    width: 8px;
    height: 8px;
    margin-right: 8px;
    background-image: url("../../../assets/images/green-circle.svg");
  }
  .tag-wrapper {
    align-items: center;
  }
}

.button-col {
  display: flex;
  justify-content: flex-end;
  .v-btn {
    background-color: transparent !important;
    box-shadow: 0px 0 2px rgba(28, 14, 3, 0.3);
    text-transform: none;
    min-width: 10px !important;
  }
}
.customer-row {
  margin-bottom: 8px;
  background-color: transparent;
}
.listing-title-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  background-color: white;
  border-radius: 8px;
  padding: 16px;
}
#img {
  width: 136px;
  max-width: 136px;
}
#desc {
  width: 328px;
  max-width: 328px;
}
#size,
#price {
  width: 144px;
  max-width: 144px;
}
#kv,
#kv24,
#city24 {
  width: 104px;
  max-width: 104px;
}
.listings-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  border-radius: 8px;
  width: 100%;
}
.listings-wrapper-header {
  display: flex;
  width: 100%;
  border-radius: 8px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  div {
    padding: 12px 16px;
  }
}
.description-col {
  width: 328px;
  max-width: 328px;
  max-height: 104px;
  padding: 16px;
  overflow: hidden;

  p {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}
.size-col {
  width: 144px;
  max-width: 144px;
  padding: 16px;
}
.price-col {
  width: 144px;
  max-width: 144px;
  padding: 16px;
}
.publish-col {
  width: 104px;
  max-width: 104px;
  padding: 16px;
}
.content-small {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
}
.publish-status {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 6px;
  height: 24px;
  border-radius: 4px;

  p {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff !important;
  }
}
.ok {
  background-color: #0aaf60 !important;
}
.pending {
  background: #ff5c01;
}
.no-results {
  padding-left: 24px;
}
</style>
