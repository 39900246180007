var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.marketingExpenses ? _c('div', {
    staticClass: "marketing-expenses-content"
  }, [_vm._l(_vm.marketingExpenses, function (marketingExpense, index) {
    return _c('div', {
      key: 'marketing-card' + index,
      staticClass: "marketing-expenses-card",
      class: {
        'marketing-expenses-card-first': index === 0,
        'marketing-expenses-card-middle': index > 0 && index < _vm.marketingExpenses.length - 1,
        'marketing-expenses-card-last': index === _vm.marketingExpenses.length - 1,
        'marketing-expenses-card-only': _vm.marketingExpenses.length === 1
      }
    }, [_vm.addNewMarketingExpenseOpen && index === 0 && _vm.addNewMarketingExpenseType === 'levelService' ? _c('div', {
      staticStyle: {
        "width": "100%",
        "padding": "12px",
        "min-height": "52px"
      }
    }, [_c('div', [_c('div', {
      staticStyle: {
        "display": "flex",
        "flex-direction": "column",
        "gap": "16px",
        "height": "199px",
        "padding": "16px",
        "border-top": "1px solid #e6e8ec",
        "border-right": "1px solid #e6e8ec",
        "border-left": "1px solid #e6e8ec",
        "border-radius": "8px 8px 0 0"
      }
    }, [_c('div', {
      staticClass: "required",
      staticStyle: {
        "display": "flex",
        "gap": "8px",
        "height": "32px",
        "flex-wrap": "nowrap"
      }
    }, [_c('label', {
      staticClass: "label12",
      staticStyle: {
        "width": "96px",
        "height": "16px",
        "color": "#75787a"
      }
    }, [_vm._v(_vm._s(_vm.$t("Portal")))]), _c('div', {
      staticStyle: {
        "display": "flex",
        "flex-wrap": "nowrap",
        "flex": "1 1 0"
      }
    }, [_c('div', {
      staticClass: "content-small portal-option",
      class: {
        'selected-portal': _vm.newMarketingService.portal === 'KV',
        'selected-portal-error-1': _vm.servicePortalError
      },
      staticStyle: {
        "border": "1px solid #e6e8ec",
        "border-radius": "8px 0 0 8px"
      },
      on: {
        "click": function ($event) {
          return _vm.setPortal('KV');
        }
      }
    }, [_vm._v(" KV ")]), _c('div', {
      staticClass: "content-small portal-option",
      class: {
        'selected-portal': _vm.newMarketingService.portal === 'KV24',
        'selected-portal-error-2': _vm.servicePortalError
      },
      staticStyle: {
        "border-right": "1px solid #e6e8ec",
        "border-top": "1px solid #e6e8ec",
        "border-bottom": "1px solid #e6e8ec"
      },
      on: {
        "click": function ($event) {
          return _vm.setPortal('KV24');
        }
      }
    }, [_vm._v(" KV24 ")]), _c('div', {
      staticClass: "content-small portal-option",
      class: {
        'selected-portal': _vm.newMarketingService.portal === 'CITY24',
        'selected-portal-error-3': _vm.servicePortalError
      },
      staticStyle: {
        "border-right": "1px solid #e6e8ec",
        "border-top": "1px solid #e6e8ec",
        "border-bottom": "1px solid #e6e8ec",
        "border-radius": "0 8px 8px 0"
      },
      on: {
        "click": function ($event) {
          return _vm.setPortal('CITY24');
        }
      }
    }, [_vm._v(" City24 ")])])]), _c('div', {
      staticStyle: {
        "display": "flex",
        "gap": "8px",
        "height": "32px",
        "flex-wrap": "nowrap"
      }
    }, [_c('label', {
      staticClass: "label12",
      staticStyle: {
        "width": "96px",
        "height": "16px",
        "color": "#75787a"
      }
    }, [_vm._v(_vm._s(_vm.$t("DailyCost")))]), _c('div', {
      staticStyle: {
        "display": "flex"
      }
    }, [_c('Input', {
      attrs: {
        "prefilled-value": _vm.newMarketingService.dailyCost,
        "id": 'input-container',
        "type": "number",
        "size": "small",
        "suffix-icon": "euro_16_16.svg"
      },
      on: {
        "value": _vm.setDailyCost
      }
    })], 1)]), _c('div', {
      staticStyle: {
        "display": "flex",
        "gap": "8px",
        "height": "32px",
        "flex-wrap": "nowrap"
      }
    }, [_c('label', {
      staticClass: "label12",
      staticStyle: {
        "width": "96px",
        "color": "#75787a",
        "height": "16px"
      }
    }, [_vm._v(_vm._s(_vm.$t("Duration")))]), _c('div', {
      staticStyle: {
        "display": "flex",
        "flex-wrap": "nowrap",
        "gap": "4px"
      }
    }, [_c('date-pick', {
      on: {
        "input": function (date) {
          _vm.setStartDate(date);
        }
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function (_ref) {
          var toggle = _ref.toggle;
          return [_c('div', {
            staticStyle: {
              "display": "flex",
              "flex-wrap": "nowrap",
              "gap": "8px",
              "width": "99px",
              "height": "32px",
              "padding": "7px 11px",
              "border-radius": "8px",
              "border": "1px solid #e6e8ec"
            },
            on: {
              "click": toggle
            }
          }, [_c('img', {
            attrs: {
              "src": require(`@/assets/images/calendar.svg`),
              "alt": ""
            }
          }), !_vm.newMarketingService.dates || !_vm.newMarketingService.dates.startDate ? _c('div', {
            staticStyle: {
              "display": "flex",
              "align-items": "center",
              "color": "#939597"
            }
          }, [_vm._v(" " + _vm._s(_vm.$t("Start")) + " ")]) : _vm._e(), _vm.newMarketingService.dates || _vm.newMarketingService.dates.startDate ? _c('div', {
            staticStyle: {
              "display": "flex",
              "font-size": "8px",
              "align-items": "center",
              "color": "#939597"
            }
          }, [_vm._v(" " + _vm._s(_vm.formatDate(_vm.newMarketingService.dates.startDate)) + " ")]) : _vm._e()])];
        }
      }], null, true)
    }), _c('div', {
      staticStyle: {
        "display": "flex",
        "align-items": "center"
      }
    }, [_vm._v("-")]), _c('date-pick', {
      on: {
        "input": function (date) {
          _vm.setEndDate(date);
        }
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function (_ref2) {
          var toggle = _ref2.toggle;
          return [_c('div', {
            staticStyle: {
              "display": "flex",
              "flex-wrap": "nowrap",
              "gap": "8px",
              "width": "99px",
              "height": "32px",
              "padding": "7px 11px",
              "border-radius": "8px",
              "border": "1px solid #e6e8ec"
            },
            on: {
              "click": toggle
            }
          }, [_c('img', {
            attrs: {
              "src": require(`@/assets/images/calendar.svg`),
              "alt": ""
            }
          }), !_vm.newMarketingService.dates || !_vm.newMarketingService.dates.endDate ? _c('div', {
            staticStyle: {
              "display": "flex",
              "align-items": "center",
              "color": "#939597"
            }
          }, [_vm._v(" " + _vm._s(_vm.$t("End")) + " ")]) : _vm._e(), _vm.newMarketingService.dates || _vm.newMarketingService.dates.endDate ? _c('div', {
            staticStyle: {
              "display": "flex",
              "font-size": "8px",
              "align-items": "center",
              "color": "#939597"
            }
          }, [_vm._v(" " + _vm._s(_vm.formatDate(_vm.newMarketingService.dates.endDate)) + " ")]) : _vm._e()])];
        }
      }], null, true)
    })], 1)]), _c('div', {
      staticStyle: {
        "display": "flex",
        "gap": "8px",
        "height": "24px",
        "flex-wrap": "nowrap"
      }
    }, [_c('label', {
      staticClass: "label12",
      staticStyle: {
        "width": "96px",
        "height": "16px"
      }
    }, [_vm._v(_vm._s(_vm.$t("TotalCost")))]), _c('div', {
      staticClass: "font-20-bold",
      staticStyle: {
        "width": "96px"
      }
    }, [_vm._v(" " + _vm._s(_vm.newMarketingService.totalCost) + " € ")])])]), _c('div', {
      staticStyle: {
        "height": "58px",
        "display": "flex",
        "justify-content": "flex-end",
        "align-items": "center",
        "border": "1px solid #e6e8ec",
        "border-radius": "0 0 8px 8px",
        "padding": "12px"
      }
    }, [_c('div', {
      staticStyle: {
        "display": "flex",
        "gap": "8px",
        "flex-wrap": "nowrap"
      }
    }, [_c('div', {
      staticClass: "outlined-small-button",
      staticStyle: {
        "cursor": "pointer"
      },
      on: {
        "click": _vm.cancelNewExpenseOptionAdd
      }
    }, [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")]), _c('div', {
      staticClass: "button-small",
      staticStyle: {
        "padding": "8px 12px",
        "background-color": "black",
        "border-radius": "8px",
        "color": "white",
        "cursor": "pointer"
      },
      on: {
        "click": _vm.saveMarketingService
      }
    }, [_vm._v(" " + _vm._s(_vm.$t("Save")) + " ")])])])])]) : _vm.addNewMarketingExpenseOpen && index === 0 && _vm.addNewMarketingExpenseType === 'advertisement' ? _c('div', {
      staticStyle: {
        "width": "100%",
        "padding": "8px",
        "min-height": "52px"
      }
    }, [_vm.addNewMarketingExpenseStep === 1 ? _c('div', {
      ref: "dropdownOptions",
      refInFor: true,
      staticStyle: {
        "height": "100%",
        "width": "100%",
        "border": "1px solid #e6e8ec",
        "border-radius": "8px",
        "display": "flex",
        "flex-direction": "column",
        "outline": "none"
      },
      attrs: {
        "tabindex": "0"
      },
      on: {
        "focusout": _vm.cancelNewExpenseOptionAdd
      }
    }, _vm._l(_vm.options, function (option, index) {
      return _c('div', {
        key: option.name,
        class: {
          'divider-line': index < _vm.options.length - 1
        },
        staticStyle: {
          "width": "100%"
        }
      }, [_c('div', {
        staticClass: "content dropdown-option-name",
        class: {
          'first-option-name': index === 0,
          'last-option-name': index === _vm.options.length - 1
        },
        on: {
          "click": function ($event) {
            return _vm.chooseNewExpenseOption(option);
          }
        }
      }, [_vm._v(" " + _vm._s(option.name) + " ")])]);
    }), 0) : _vm._e(), _vm.addNewMarketingExpenseStep === 2 ? _c('v-text-field', {
      ref: "marketingAddAdvertisement",
      refInFor: true,
      attrs: {
        "placeholder": _vm.$t('AddDescription'),
        "hide-details": "",
        "dense": "",
        "outlined": ""
      },
      on: {
        "focusout": _vm.addMarketingExpense,
        "keydown": function ($event) {
          if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
          return $event.target.blur();
        }
      },
      model: {
        value: _vm.addNewMarketingExpenseName,
        callback: function ($$v) {
          _vm.addNewMarketingExpenseName = $$v;
        },
        expression: "addNewMarketingExpenseName"
      }
    }) : _vm._e()], 1) : _vm._e(), _vm.addNewMarketingExpenseOpen && index > 0 || !_vm.addNewMarketingExpenseOpen ? _c('div', {
      staticClass: "marketing-expenses-card-columns-container"
    }, [_c('div', {
      staticClass: "marketing-expenses-card-first-column"
    }, [_c('div', {
      staticClass: "marketing-expenses-card-photo"
    }, [marketingExpense.type === 'KV' ? _c('img', {
      attrs: {
        "src": require("../../assets/images/KV_48_48.svg")
      }
    }) : _vm._e(), marketingExpense.type === 'KV24' ? _c('img', {
      attrs: {
        "src": require("../../assets/images/KV24_48_48.svg")
      }
    }) : _vm._e(), marketingExpense.type === 'CITY24' ? _c('img', {
      attrs: {
        "src": require("../../assets/images/CITY24_48_48.svg")
      }
    }) : _vm._e(), marketingExpense.images && marketingExpense.images.length === 0 ? _c('div', {
      staticClass: "marketing-expenses-photo-card"
    }) : _vm._e(), marketingExpense.images && marketingExpense.images.length > 0 ? _c('img', {
      staticClass: "marketing-expenses-photo-card",
      attrs: {
        "src": marketingExpense.images[0].path.name,
        "alt": ""
      }
    }) : _vm._e()]), _c('div', {
      staticClass: "marketing-expenses-card-content"
    }, [_c('div', {
      staticClass: "Labels"
    }, [marketingExpense.type ? _c('p', [_vm._v(" " + _vm._s(_vm.formatExpenseType(marketingExpense.type)) + " ")]) : _vm._e(), marketingExpense.type && marketingExpense.dates && (marketingExpense.dates.startDate || marketingExpense.dates.endDate) ? _c('p', {
      staticStyle: {
        "padding-left": "4px",
        "padding-right": "4px"
      }
    }, [_vm._v(" • ")]) : _vm._e(), marketingExpense.dates && marketingExpense.dates.startDate && marketingExpense.dates.endDate ? _c('p', [_vm._v(" " + _vm._s(_vm.formatDate(marketingExpense.dates.startDate)) + " - " + _vm._s(_vm.formatDate(marketingExpense.dates.endDate)) + " ")]) : _vm._e(), marketingExpense.dates && marketingExpense.dates.startDate && !marketingExpense.dates.endDate ? _c('p', [_vm._v(" " + _vm._s(_vm.formatDate(marketingExpense.dates.startDate)) + " - ... ")]) : _vm._e(), marketingExpense.dates && !marketingExpense.dates.startDate && marketingExpense.dates.endDate ? _c('p', [_vm._v(" ... - " + _vm._s(_vm.formatDate(marketingExpense.dates.endDate)) + " ")]) : _vm._e()]), marketingExpense.type === 'KV' || marketingExpense.type === 'KV24' || marketingExpense.type === 'CITY24' ? _c('div', {
      staticClass: "marketing-expenses-card-content-description",
      staticStyle: {
        "display": "flex",
        "flex-wrap": "wrap"
      }
    }, [_c('p', {
      staticClass: "content-semibold",
      staticStyle: {
        "display": "inline",
        "padding-right": "4px"
      }
    }, [_vm._v(" " + _vm._s(_vm.$t("LevelService")) + " ")]), marketingExpense.dailyCost || marketingExpense.cost ? [marketingExpense.cost ? _c('p', {
      staticClass: "content",
      staticStyle: {
        "display": "inline",
        "white-space": "nowrap",
        "height": "20px"
      }
    }, [_vm._v(" - " + _vm._s(marketingExpense.cost) + " € ")]) : _vm._e(), marketingExpense.dailyCost ? _c('p', {
      staticClass: "content",
      staticStyle: {
        "display": "inline",
        "padding-left": "4px",
        "color": "#75787a",
        "white-space": "nowrap"
      }
    }, [_vm._v(" (" + _vm._s(marketingExpense.dailyCost) + " €/" + _vm._s(_vm.$t("day")) + ") ")]) : _vm._e()] : _vm._e()], 2) : _vm._e(), marketingExpense.type !== 'KV' && marketingExpense.type !== 'KV24' && marketingExpense.type !== 'CITY24' ? _c('div', {
      staticClass: "marketing-expenses-card-content-description",
      class: {
        '': !_vm.dailyCostHidden,
        'marketing-expenses-wrapper': _vm.dailyCostHidden
      }
    }, [_c('div', {
      staticClass: "content-semibold",
      staticStyle: {
        "padding-right": "4px",
        "display": "block",
        "word-break": "keep-all"
      }
    }, [_c('p', {
      staticStyle: {
        "display": "inline"
      }
    }, [_vm._v(_vm._s(marketingExpense.name))]), marketingExpense.sellingPrice || marketingExpense.cost ? _c('span', [marketingExpense.sellingPrice ? _c('p', {
      staticClass: "content",
      staticStyle: {
        "display": "inline",
        "white-space": "nowrap",
        "height": "20px"
      }
    }, [_vm._v(" - " + _vm._s(marketingExpense.sellingPrice) + " € ")]) : _vm._e(), marketingExpense.cost && _vm.selfServicePriceFormat && !_vm.dailyCostHidden ? _c('p', {
      staticClass: "content",
      staticStyle: {
        "display": "inline",
        "white-space": "nowrap",
        "height": "20px"
      }
    }, [_vm._v(" - " + _vm._s(marketingExpense.cost) + " € ")]) : _vm._e(), marketingExpense.cost && !_vm.dailyCostHidden ? _c('p', {
      staticClass: "content",
      staticStyle: {
        "display": "inline",
        "padding-left": "4px",
        "color": "#75787a",
        "white-space": "nowrap"
      }
    }, [_vm._v(" (" + _vm._s(marketingExpense.cost) + " €) ")]) : _vm._e()]) : _vm._e()])]) : _vm._e(), marketingExpense.type !== 'KV' && marketingExpense.type !== 'KV24' && marketingExpense.type !== 'CITY24' ? _c('div', {
      staticStyle: {
        "display": "flex"
      }
    }, [_c('div', {
      staticStyle: {
        "display": "flex"
      },
      attrs: {
        "id": 'marketing-expense-file-upload-button-' + index
      },
      on: {
        "click": function ($event) {
          return _vm.openUploadWidget(marketingExpense, index);
        }
      }
    }, [!marketingExpense.files && !marketingExpense.images && !_vm.hideFileAdding || marketingExpense.files && marketingExpense.files.length === 0 && marketingExpense.images && marketingExpense.images.length === 0 && !_vm.hideFileAdding ? _c('p', {
      staticClass: "link-small",
      staticStyle: {
        "cursor": "pointer"
      }
    }, [_vm._v(" " + _vm._s(_vm.$t("AddFiles")) + " ")]) : _vm._e(), marketingExpense.files && marketingExpense.files.length > 0 || marketingExpense.images && marketingExpense.images.length > 0 ? _c('p', {
      staticClass: "link-small",
      staticStyle: {
        "cursor": "pointer"
      }
    }, [_vm._v(" " + _vm._s(marketingExpense.files.length + marketingExpense.images.length) + " "), marketingExpense.files.length + marketingExpense.images.length === 1 ? _c('span', [_vm._v(_vm._s(_vm.$t("file")))]) : _c('span', [_vm._v(_vm._s(_vm.$t("files")))])]) : _vm._e()])]) : _vm._e()])]), !_vm.showDetailButton ? _c('div', {
      staticClass: "marketing-expenses-card-last-column"
    }, [_c('div', {
      staticClass: "marketing-expenses-card-menu-button-container"
    }, [_c('img', {
      staticStyle: {
        "cursor": "pointer"
      },
      attrs: {
        "id": 'marketing-expense-details-button-' + index,
        "src": require("../../assets/images/dot_menu_grey.svg"),
        "alt": ""
      },
      on: {
        "click": function ($event) {
          return _vm.openDetailsWidget(marketingExpense, index);
        }
      }
    })])]) : _vm._e()]) : _vm._e()]);
  }), _vm.fileUploadDropdownOpen ? _c('MarketingExpensesFileDropdown', {
    attrs: {
      "marketing-expense": _vm.clickedMarketingExpense,
      "hideFileUpload": _vm.hideUploadAndDelete
    },
    on: {
      "closeWidget": _vm.closeUploadWidget,
      "fileUploaded": _vm.refreshExpenses,
      "fileDeleted": _vm.refreshExpenses
    }
  }) : _vm._e(), _vm.marketingDetailsDropdownOpen ? _c('DetailsDropdown', {
    attrs: {
      "position": _vm.marketingDetailsPosition,
      "data": _vm.clickedMarketingExpenseDropdownData,
      "show-delete": true,
      "item": _vm.clickedMarketingExpense
    },
    on: {
      "delete": _vm.deleteExpense,
      "action": _vm.expenseAction,
      "closeWidget": _vm.closeDetailsWidget
    }
  }) : _vm._e()], 2) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }