var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "overlay",
    attrs: {
      "id": "overlay-customer-related"
    },
    on: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) return null;
        return _vm.closeModalPanel.apply(null, arguments);
      },
      "click": function ($event) {
        return _vm.closeModalPanel($event);
      }
    }
  }, [_c('div', {
    staticClass: "panel-wrapper",
    attrs: {
      "id": "modal"
    }
  }, [_c('v-row', {
    staticClass: "top-row"
  }, [_c('h4', {
    staticClass: "main-subtitle"
  }, [_vm._v("Lisa seotud isik")]), _c('button', {
    staticClass: "close-button",
    on: {
      "click": function ($event) {
        return _vm.closeModalPanel();
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("../../../assets/images/cross.svg"),
      "alt": ""
    }
  })])]), _c('v-banner', {
    attrs: {
      "single-line": ""
    }
  }), _c('div', {
    staticClass: "relationModal-0-2-185"
  }, [_c('form', {
    attrs: {
      "novalidate": ""
    }
  }, [_c('div', {
    staticClass: "modalContent-0-2-188"
  }, [_c('h5', {
    staticClass: "title-0-2-189"
  }, [_vm._v("Lisa isik")]), _c('div', {
    staticClass: "relationSearch-0-2-190"
  }, [_c('div', {
    staticClass: "search-0-2-193"
  }, [_c('div', {
    staticClass: "find-person"
  }, [_c('v-autocomplete', {
    attrs: {
      "return-object": "",
      "value": "",
      "items": _vm.customersCopy,
      "autocomplete": "nope",
      "prepend-inner-icon": "mdi-magnify",
      "outlined": "",
      "item-text": "customerName",
      "placeholder": _vm.$t('CustomerRelation.FieldPlaceholder.COMP')
    },
    model: {
      value: _vm.selectedRelation,
      callback: function ($$v) {
        _vm.selectedRelation = $$v;
      },
      expression: "selectedRelation"
    }
  })], 1)]), _c('div', [_c('button', {
    staticClass: "addPersonButton-0-2-204",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        _vm.showDiv();
        _vm.name = null;
        _vm.phone = null;
        _vm.email = null;
      }
    }
  }, [_c('span', {
    staticClass: "buttonLabel-0-2-206"
  }, [_vm._v("Lisa uus seotud isik")]), _c('svg', {
    staticClass: "plusSign-0-2-205",
    attrs: {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M4.46447 11.5353C4.85499 11.9258 5.48816 11.9258 5.87868 11.5353L8 9.41399L10.1213 11.5353C10.5118 11.9258 11.145 11.9258 11.5355 11.5353C11.9261 11.1448 11.9261 10.5116 11.5355 10.1211L9.41421 7.99978L11.5355 5.87846C11.9261 5.48793 11.9261 4.85477 11.5355 4.46424C11.145 4.07372 10.5118 4.07372 10.1213 4.46424L8 6.58557L5.87868 4.46424C5.48816 4.07372 4.85499 4.07372 4.46447 4.46424C4.07394 4.85477 4.07394 5.48793 4.46447 5.87846L6.58579 7.99978L4.46447 10.1211C4.07394 10.5116 4.07394 11.1448 4.46447 11.5353Z",
      "fill": "black"
    }
  })])])])]), _c('div', {
    staticClass: "relationRow-0-2-232 addedRelation-0-2-207",
    staticStyle: {
      "display": "none"
    },
    attrs: {
      "id": "added-person"
    }
  }, [_vm._m(0), _c('div', {
    staticClass: "relationInfo-0-2-235 alignedRow-0-2-233"
  }, [_c('div', {
    staticClass: "roleInfo-0-2-239 relationBlock-0-2-236"
  }, [_c('span', [_vm._v(_vm._s(_vm.name))]), _c('span', {
    staticClass: "roleLabel-0-2-238 smallText-0-2-237"
  })]), _c('div', {
    staticClass: "buttonContainer-0-2-240 relationBlock-0-2-236 smallText-0-2-237"
  }, [_c('button', {
    staticClass: "removeRelation-0-2-242",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        _vm.email = null;
        _vm.phone = null;
        _vm.name = null;
        _vm.showPerson();
      }
    }
  }, [_c('svg', {
    attrs: {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M11.3334 4.00004H14.6667V5.33337H13.3334V14C13.3334 14.1769 13.2631 14.3464 13.1381 14.4714C13.0131 14.5965 12.8435 14.6667 12.6667 14.6667H3.33337C3.15656 14.6667 2.98699 14.5965 2.86197 14.4714C2.73695 14.3464 2.66671 14.1769 2.66671 14V5.33337H1.33337V4.00004H4.66671V2.00004C4.66671 1.82323 4.73695 1.65366 4.86197 1.52864C4.98699 1.40361 5.15656 1.33337 5.33337 1.33337H10.6667C10.8435 1.33337 11.0131 1.40361 11.1381 1.52864C11.2631 1.65366 11.3334 1.82323 11.3334 2.00004V4.00004ZM12 5.33337H4.00004V13.3334H12V5.33337ZM6.00004 7.33337H7.33338V11.3334H6.00004V7.33337ZM8.66671 7.33337H10V11.3334H8.66671V7.33337ZM6.00004 2.66671V4.00004H10V2.66671H6.00004Z",
      "fill": "#08051B"
    }
  })])])])])]), _c('div', {
    staticClass: "rows-0-2-191 rows-d2-0-2-222"
  })]), _c('div', {
    staticClass: "modalFooter-0-2-214"
  }, [_c('div', {
    staticClass: "add-new-person",
    staticStyle: {
      "display": "none"
    },
    attrs: {
      "id": "new-person"
    }
  }, [_c('h5', {
    staticClass: "title-0-2-213"
  }, [_vm._v("Uus isik")]), _c('div', {
    staticClass: "newRelationInputs-0-2-210"
  }, [_c('div', {
    staticClass: "newRelationField-0-2-211"
  }, [_c('div', {
    staticClass: "inputFieldWrapper-0-2-199 inputFieldWrapper-d55-0-2-387 textInputField-0-2-219 textInputField-d108-0-2-385"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.name,
      expression: "name"
    }],
    staticClass: "inputField-0-2-220",
    attrs: {
      "type": "text",
      "name": "customerName",
      "required": "",
      "placeholder": "Kliendi nimi (kohustuslik)"
    },
    domProps: {
      "value": _vm.name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.name = $event.target.value;
      }
    }
  }), _c('label', {
    staticClass: "label-0-2-222 label-d109-0-2-386",
    attrs: {
      "for": "customerName"
    }
  })])]), _c('div', {
    staticClass: "newRelationField-0-2-211"
  }, [_c('div', {
    staticClass: "inputFieldWrapper-0-2-199 inputFieldWrapper-d56-0-2-390 textInputField-0-2-219 textInputField-d110-0-2-388"
  }, [_c('span', {
    staticClass: "prefix-0-2-202 icon-0-2-200"
  }, [_c('svg', {
    attrs: {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M13.3333 3.33333H2.66663C2.29844 3.33333 1.99996 3.63181 1.99996 4V12C1.99996 12.3682 2.29844 12.6667 2.66663 12.6667H13.3333C13.7015 12.6667 14 12.3682 14 12V4C14 3.63181 13.7015 3.33333 13.3333 3.33333ZM2.66663 2C1.56206 2 0.666626 2.89543 0.666626 4V12C0.666626 13.1046 1.56206 14 2.66663 14H13.3333C14.4379 14 15.3333 13.1046 15.3333 12V4C15.3333 2.89543 14.4379 2 13.3333 2H2.66663Z",
      "fill": "black"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M3.48779 4.9064C3.7235 4.62355 4.14387 4.58533 4.42672 4.82104L7.57315 7.44306C7.82038 7.64908 8.17949 7.64908 8.42672 7.44306L11.5731 4.82104C11.856 4.58533 12.2764 4.62355 12.5121 4.9064C12.7478 5.18925 12.7096 5.60963 12.4267 5.84533L9.2803 8.46735C8.53861 9.08543 7.46126 9.08543 6.71957 8.46735L3.57315 5.84533C3.29029 5.60963 3.25208 5.18925 3.48779 4.9064Z",
      "fill": "black"
    }
  })])]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.email,
      expression: "email"
    }],
    staticClass: "inputField-0-2-220",
    attrs: {
      "type": "text",
      "name": "email",
      "placeholder": "E-post"
    },
    domProps: {
      "value": _vm.email
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.email = $event.target.value;
      }
    }
  }), _c('label', {
    staticClass: "label-0-2-222 label-d111-0-2-389",
    attrs: {
      "for": "email"
    }
  })])]), _c('div', {
    staticClass: "newRelationField-0-2-211"
  }, [_c('div', {
    staticClass: "inputFieldWrapper-0-2-199 inputFieldWrapper-d57-0-2-393 textInputField-0-2-219 textInputField-d112-0-2-391"
  }, [_c('span', {
    staticClass: "prefix-0-2-202 icon-0-2-200"
  }, [_c('svg', {
    attrs: {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('g', {
    attrs: {
      "clip-path": "url(#clip0)"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M3.09838 1.56812C3.959 0.707503 5.38234 0.808659 6.1126 1.78234L7.32478 3.39858C7.92196 4.19482 7.84277 5.30901 7.13899 6.01279L6.24528 6.9065C6.33189 7.13194 6.61701 7.62666 7.48528 8.49493C8.35355 9.3632 8.84827 9.64832 9.07371 9.73493L9.96742 8.84122C10.6712 8.13744 11.7854 8.05825 12.5816 8.65543L14.1979 9.86762C15.1716 10.5979 15.2727 12.0212 14.4121 12.8818C14.1323 13.1616 14.0849 13.209 13.618 13.6759C13.1421 14.1518 12.1317 14.5966 11.1087 14.641C9.50782 14.7107 7.3333 13.9998 4.65685 11.3234C1.9804 8.64691 1.26956 6.47239 1.33917 4.87151C1.37789 3.98098 1.66101 3.00289 2.30804 2.36592C2.77124 1.89527 2.83147 1.83503 3.09838 1.56812ZM2.67124 4.92943C2.62299 6.03913 3.09066 7.87155 5.59966 10.3805C8.10866 12.8895 9.94109 13.3572 11.0508 13.309C12.0867 13.2639 12.6424 12.7634 12.6752 12.7331L13.4693 11.939C13.7562 11.6521 13.7224 11.1777 13.3979 10.9343L11.7816 9.7221C11.5162 9.52304 11.1448 9.54943 10.9102 9.78403C10.5568 10.1375 10.3155 10.3823 9.8432 10.8528C8.86211 11.8302 7.18273 10.078 6.54247 9.43774C5.95408 8.84935 4.16098 7.11665 5.12641 6.13976C5.12832 6.13785 5.38835 5.87781 6.19618 5.06998C6.43078 4.83539 6.45717 4.46399 6.25811 4.19858L5.04593 2.58234C4.80251 2.25778 4.32806 2.22406 4.04119 2.51093C3.77716 2.77497 3.47834 3.07378 3.24792 3.3058C2.77367 3.78332 2.69662 4.3457 2.67124 4.92943Z",
      "fill": "black"
    }
  })]), _c('defs', [_c('clipPath', {
    attrs: {
      "id": "clip0"
    }
  }, [_c('rect', {
    attrs: {
      "width": "16",
      "height": "16",
      "fill": "white"
    }
  })])])])]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.phone,
      expression: "phone"
    }],
    staticClass: "inputField-0-2-220",
    attrs: {
      "type": "tel",
      "name": "phoneNumber",
      "placeholder": "Telefoni number"
    },
    domProps: {
      "value": _vm.phone
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.phone = $event.target.value;
      }
    }
  }), _c('label', {
    staticClass: "label-0-2-222 label-d113-0-2-392",
    attrs: {
      "for": "phoneNumber"
    }
  })])]), _c('div', {
    staticClass: "newRelationField-0-2-211"
  }, [_c('button', {
    staticClass: "addRelationButton-0-2-212",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        _vm.showPerson();
        _vm.showDiv();
      }
    }
  }, [_vm._v(" Lisa seotud isik ")])])]), _c('v-banner', {
    attrs: {
      "single-line": ""
    }
  })], 1)])]), _c('div', {
    staticClass: "buttonContainer-0-2-216 button-0-2-215"
  }, [_c('div', [_c('button', {
    staticClass: "cancelButton-0-2-218 button-0-2-215",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.closeModalPanel();
      }
    }
  }, [_vm._v(" Katkesta ")])]), _c('div', [_c('button', {
    staticClass: "submitButton-0-2-217",
    on: {
      "click": function ($event) {
        return _vm.createRelation();
      }
    }
  }, [_vm._v(" Lisa seotud isik ")])])])])], 1)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "relationIcon-0-2-234 alignedRow-0-2-233 relationIcon-d4-0-2-247"
  }, [_c('span', {
    staticClass: "initialsText-0-2-241"
  }, [_vm._v("+")])]);

}]

export { render, staticRenderFns }