var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.sellerIsLoaded ? _c('div', {
    staticClass: "overlay-notarymodal",
    on: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) return null;
        return _vm.closeModal.apply(null, arguments);
      }
    }
  }, [_c('div', [_c('v-card', {
    staticClass: "modal-wrapper"
  }, [_c('v-card', {
    staticClass: "form-wrapper",
    attrs: {
      "flat": ""
    }
  }, [_c('v-row', {
    staticClass: "input-wrapper",
    staticStyle: {
      "max-height": "fit-content"
    }
  }, [_c('v-text-field', {
    staticClass: "no-active",
    attrs: {
      "outlined": "",
      "label": _vm.$t('NotaryName')
    },
    model: {
      value: _vm.notaryName,
      callback: function ($$v) {
        _vm.notaryName = $$v;
      },
      expression: "notaryName"
    }
  }), _c('v-row', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.transactionDate,
      expression: "transactionDate"
    }],
    staticClass: "date-class",
    attrs: {
      "id": "input-112",
      "type": "date",
      "name": "session-date"
    },
    domProps: {
      "value": _vm.transactionDate
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.transactionDate = $event.target.value;
      }
    }
  }), _c('label', {
    staticClass: "v-label v-label--active theme--light date-input",
    attrs: {
      "for": "input-112"
    }
  }, [_vm._v(_vm._s(_vm.$t("TransactionDate")))])]), _c('v-text-field', {
    staticClass: "no-active",
    attrs: {
      "outlined": "",
      "label": _vm.$t('Time')
    },
    model: {
      value: _vm.time,
      callback: function ($$v) {
        _vm.time = $$v;
      },
      expression: "time"
    }
  })], 1)], 1), _c('h5', {
    staticClass: "modal-title"
  }, [_vm._v(" " + _vm._s(_vm.$t("CustomerField.CustomerType.Options.SELLER")) + " ")]), _c('div', {
    key: _vm.sellersUpdater,
    staticStyle: {
      "width": "100%"
    }
  }, _vm._l(_vm.notaryDocument.sellers, function (seller, index) {
    return _c('div', {
      key: 'seller' + index,
      staticStyle: {
        "padding-top": "12px"
      }
    }, [_c('div', {
      staticClass: "gray-wrapper"
    }, [index !== 0 ? _c('span', {
      staticClass: "content-small clear-button",
      staticStyle: {
        "margin-left": "380px"
      },
      on: {
        "click": function ($event) {
          return _vm.notaryDocument.sellers.splice(index, 1);
        }
      }
    }, [_c('img', {
      attrs: {
        "src": require("../../assets/images/close.svg"),
        "alt": ""
      }
    }), _vm._v(" " + _vm._s(_vm.$t("Clear").toLowerCase()) + " ")]) : _vm._e(), _c('v-row', {
      staticStyle: {
        "z-index": "111"
      }
    }, [!_vm.fromAnotherPanel ? _c('ContactSearch', {
      class: 'no-active required ' + (_vm.showErrors && !_vm.isFieldValid('name', 'sellers', index) ? 'not-valid' : ''),
      attrs: {
        "placeholder": _vm.$t('FullName'),
        "items": _vm.customers,
        "chosen-object": seller.contactPerson
      },
      on: {
        "resultChosen": function (item) {
          return _vm.setContactPerson(item, 'sellers', index);
        },
        "removeContact": function ($event) {
          return _vm.removeContact(index);
        },
        "addNew": function ($event) {
          return _vm.openContactAdd('sellers', index);
        }
      },
      model: {
        value: seller.contactPerson,
        callback: function ($$v) {
          _vm.$set(seller, "contactPerson", $$v);
        },
        expression: "seller.contactPerson"
      }
    }) : _vm._e(), _vm.fromAnotherPanel ? _c('v-autocomplete', {
      attrs: {
        "items": _vm.customers,
        "item-text": "customerName",
        "return-object": "",
        "label": _vm.$t('FullName')
      },
      scopedSlots: _vm._u([{
        key: "prepend-inner",
        fn: function () {
          return [_c('img', {
            attrs: {
              "width": "20",
              "height": "20",
              "alt": "icon",
              "src": require("../../assets/images/user.svg")
            }
          })];
        },
        proxy: true
      }], null, true),
      model: {
        value: seller.contactPerson,
        callback: function ($$v) {
          _vm.$set(seller, "contactPerson", $$v);
        },
        expression: "seller.contactPerson"
      }
    }) : _vm._e()], 1), _c('v-text-field', {
      class: 'no-active required ' + (_vm.showErrors && !_vm.isFieldValid('personalCode', 'sellers', index) ? 'not-valid' : ''),
      attrs: {
        "outlined": "",
        "label": _vm.$t('CustomerField.personalCode')
      },
      model: {
        value: seller.personalCode,
        callback: function ($$v) {
          _vm.$set(seller, "personalCode", $$v);
        },
        expression: "seller.personalCode"
      }
    }), _c('v-text-field', {
      class: 'no-active required ' + (_vm.showErrors && !_vm.isFieldValid('address', 'sellers', index) ? 'not-valid' : ''),
      attrs: {
        "outlined": "",
        "label": _vm.$t('CustomerField.address')
      },
      model: {
        value: seller.address,
        callback: function ($$v) {
          _vm.$set(seller, "address", $$v);
        },
        expression: "seller.address"
      }
    }), _c('v-text-field', {
      staticClass: "no-active",
      attrs: {
        "outlined": "",
        "label": _vm.$t('CustomerField.email')
      },
      model: {
        value: seller.email,
        callback: function ($$v) {
          _vm.$set(seller, "email", $$v);
        },
        expression: "seller.email"
      }
    }), _c('v-text-field', {
      staticClass: "no-active",
      attrs: {
        "outlined": "",
        "label": _vm.$t('CustomerField.phoneNumber')
      },
      model: {
        value: seller.phoneNumber,
        callback: function ($$v) {
          _vm.$set(seller, "phoneNumber", $$v);
        },
        expression: "seller.phoneNumber"
      }
    }), _c('v-text-field', {
      class: 'no-active required ' + (_vm.showErrors && !_vm.isFieldValid('maritalStatus', 'sellers', index) ? 'not-valid' : ''),
      attrs: {
        "outlined": "",
        "label": _vm.$t('CustomerField.MaritalStatus')
      },
      model: {
        value: seller.maritalStatus,
        callback: function ($$v) {
          _vm.$set(seller, "maritalStatus", $$v);
        },
        expression: "seller.maritalStatus"
      }
    })], 1)]);
  }), 0), _c('div', {
    staticClass: "add-another-button",
    on: {
      "click": function ($event) {
        return _vm.pushToList();
      }
    }
  }, [_vm._v(" " + _vm._s("+ " + _vm.$t("AddAnother")) + " ")]), _c('h5', {
    staticClass: "modal-title"
  }, [_vm._v(" " + _vm._s(_vm.$t("CustomerField.CustomerType.Options.BUYER")) + " ")]), _c('div', {
    key: _vm.buyersUpdater
  }, _vm._l(_vm.notaryDocument.buyers, function (buyer, index) {
    return _c('div', {
      key: _vm.buyersUpdater[index],
      staticStyle: {
        "padding-top": "12px"
      }
    }, [_c('div', {
      staticClass: "gray-wrapper"
    }, [index !== 0 ? _c('span', {
      staticClass: "content-small clear-button",
      on: {
        "click": function ($event) {
          return _vm.notaryDocument.buyers.splice(index, 1);
        }
      }
    }, [_c('img', {
      attrs: {
        "src": require("../../assets/images/close.svg"),
        "alt": ""
      }
    }), _vm._v(" " + _vm._s(_vm.$t("Clear").toLowerCase()) + " ")]) : _vm._e(), _c('v-row', {
      staticStyle: {
        "z-index": "111"
      }
    }, [!_vm.fromAnotherPanel ? _c('ContactSearch', {
      class: 'no-active required ' + (_vm.showErrors && !_vm.isFieldValid('name', 'buyers', index) ? 'not-valid' : ''),
      attrs: {
        "placeholder": _vm.$t('FullName'),
        "items": _vm.customers,
        "chosen-object": buyer.contactPerson,
        "already-chosen": _vm.sellerIsLoaded
      },
      on: {
        "resultChosen": function (item) {
          return _vm.setContactPerson(item, 'buyers', index);
        },
        "removeContact": function ($event) {
          return _vm.removeContact(index);
        },
        "addNew": function ($event) {
          return _vm.openContactAdd('buyers', index);
        }
      },
      model: {
        value: buyer.contactPerson,
        callback: function ($$v) {
          _vm.$set(buyer, "contactPerson", $$v);
        },
        expression: "buyer.contactPerson"
      }
    }) : _vm._e(), _vm.fromAnotherPanel ? _c('v-autocomplete', {
      attrs: {
        "items": _vm.customers,
        "item-text": "customerName",
        "return-object": "",
        "label": _vm.$t('FullName')
      },
      scopedSlots: _vm._u([{
        key: "prepend-inner",
        fn: function () {
          return [_c('img', {
            attrs: {
              "width": "20",
              "height": "20",
              "alt": "icon",
              "src": require("../../assets/images/user.svg")
            }
          })];
        },
        proxy: true
      }], null, true),
      model: {
        value: buyer.contactPerson,
        callback: function ($$v) {
          _vm.$set(buyer, "contactPerson", $$v);
        },
        expression: "buyer.contactPerson"
      }
    }) : _vm._e()], 1), _c('v-text-field', {
      class: 'no-active required ' + (_vm.showErrors && !_vm.isFieldValid('personalCode', 'buyers', index) ? 'not-valid' : ''),
      attrs: {
        "outlined": "",
        "label": _vm.$t('CustomerField.personalCode')
      },
      model: {
        value: buyer.personalCode,
        callback: function ($$v) {
          _vm.$set(buyer, "personalCode", $$v);
        },
        expression: "buyer.personalCode"
      }
    }), _c('v-text-field', {
      class: 'no-active required ' + (_vm.showErrors && !_vm.isFieldValid('address', 'buyers', index) ? 'not-valid' : ''),
      attrs: {
        "outlined": "",
        "label": _vm.$t('CustomerField.address')
      },
      model: {
        value: buyer.address,
        callback: function ($$v) {
          _vm.$set(buyer, "address", $$v);
        },
        expression: "buyer.address"
      }
    }), _c('v-text-field', {
      staticClass: "no-active",
      attrs: {
        "outlined": "",
        "label": _vm.$t('CustomerField.email')
      },
      model: {
        value: buyer.email,
        callback: function ($$v) {
          _vm.$set(buyer, "email", $$v);
        },
        expression: "buyer.email"
      }
    }), _c('v-text-field', {
      staticClass: "no-active",
      attrs: {
        "outlined": "",
        "label": _vm.$t('CustomerField.phoneNumber')
      },
      model: {
        value: buyer.phoneNumber,
        callback: function ($$v) {
          _vm.$set(buyer, "phoneNumber", $$v);
        },
        expression: "buyer.phoneNumber"
      }
    }), _c('v-text-field', {
      class: 'no-active required ' + (_vm.showErrors && !_vm.isFieldValid('maritalStatus', 'buyers', index) ? 'not-valid' : ''),
      attrs: {
        "outlined": "",
        "label": _vm.$t('CustomerField.MaritalStatus')
      },
      model: {
        value: buyer.maritalStatus,
        callback: function ($$v) {
          _vm.$set(buyer, "maritalStatus", $$v);
        },
        expression: "buyer.maritalStatus"
      }
    })], 1)]);
  }), 0), _c('div', {
    staticClass: "add-another-button",
    on: {
      "click": function ($event) {
        _vm.errors.buyers.push(_vm.getContactEmptyErrors());
        _vm.notaryDocument.buyers.push(_vm.getEmptyPerson());
      }
    }
  }, [_vm._v(" " + _vm._s("+ " + _vm.$t("AddAnother")) + " ")]), _c('h5', {
    staticClass: "modal-title"
  }, [_vm._v(_vm._s(_vm.$t("Object")))]), _c('v-card', {
    staticClass: "form-wrapper",
    attrs: {
      "flat": ""
    }
  }, [_c('v-row', {
    staticClass: "input-wrapper",
    staticStyle: {
      "max-height": "fit-content"
    }
  }, [_c('v-text-field', {
    class: 'no-active required ' + (_vm.showErrors && !_vm.isFieldValid('address', 'object') ? 'not-valid' : ''),
    attrs: {
      "outlined": "",
      "label": _vm.$t('Address')
    },
    model: {
      value: _vm.object.address,
      callback: function ($$v) {
        _vm.$set(_vm.object, "address", $$v);
      },
      expression: "object.address"
    }
  }), _c('v-text-field', {
    class: 'no-active required ' + (_vm.showErrors && !_vm.isFieldValid('acquiredFrom', 'object') ? 'not-valid' : ''),
    attrs: {
      "outlined": "",
      "label": _vm.$t('AcquiredFrom')
    },
    model: {
      value: _vm.object.acquiredFrom,
      callback: function ($$v) {
        _vm.$set(_vm.object, "acquiredFrom", $$v);
      },
      expression: "object.acquiredFrom"
    }
  }), _c('v-text-field', {
    class: 'no-active required ' + (_vm.showErrors && !_vm.isFieldValid('estateNumber', 'object') ? 'not-valid' : ''),
    attrs: {
      "outlined": "",
      "label": _vm.$t('EstateNumber')
    },
    model: {
      value: _vm.object.estateNumber,
      callback: function ($$v) {
        _vm.$set(_vm.object, "estateNumber", $$v);
      },
      expression: "object.estateNumber"
    }
  })], 1), _c('v-row', {
    staticClass: "input-wrapper apartment-input"
  }, [_c('h6', [_vm._v(_vm._s(_vm.$t("ApartmentAssociation")))]), _c('v-row', {
    staticClass: "input-wrapper",
    staticStyle: {
      "max-height": "fit-content"
    }
  }, [_c('v-radio-group', {
    attrs: {
      "row": ""
    },
    model: {
      value: _vm.object.apartmentAssociation,
      callback: function ($$v) {
        _vm.$set(_vm.object, "apartmentAssociation", $$v);
      },
      expression: "object.apartmentAssociation"
    }
  }, [_c('v-radio', {
    attrs: {
      "label": _vm.$t('Yes'),
      "color": "orange darken-3",
      "value": 'Jah'
    }
  }), _c('v-radio', {
    attrs: {
      "label": _vm.$t('No'),
      "color": "orange darken-3",
      "value": 'Ei'
    }
  })], 1)], 1), _c('h6', [_vm._v(_vm._s(_vm.$t("UtilityBillDebts")))]), _c('v-row', {
    staticClass: "input-wrapper",
    staticStyle: {
      "max-height": "fit-content"
    }
  }, [_c('v-radio-group', {
    attrs: {
      "row": ""
    },
    model: {
      value: _vm.object.communalPayments,
      callback: function ($$v) {
        _vm.$set(_vm.object, "communalPayments", $$v);
      },
      expression: "object.communalPayments"
    }
  }, [_c('v-radio', {
    attrs: {
      "label": _vm.$t('Yes'),
      "color": "orange darken-3",
      "value": 'on'
    }
  }), _c('v-radio', {
    attrs: {
      "label": _vm.$t('No'),
      "color": "orange darken-3",
      "value": 'ei ole'
    }
  })], 1), _vm.object.communalPayments === 'on' ? _c('v-text-field', {
    staticClass: "no-active",
    staticStyle: {
      "padding-bottom": "80px"
    },
    attrs: {
      "outlined": "",
      "label": _vm.$t('Amount')
    },
    model: {
      value: _vm.object.communalPaymentsAmount,
      callback: function ($$v) {
        _vm.$set(_vm.object, "communalPaymentsAmount", $$v);
      },
      expression: "object.communalPaymentsAmount"
    }
  }) : _vm._e()], 1), _c('h6', [_vm._v(_vm._s(_vm.$t("LeaseContracts")))]), _c('v-row', {
    staticClass: "input-wrapper",
    staticStyle: {
      "max-height": "fit-content"
    }
  }, [_c('v-radio-group', {
    attrs: {
      "row": ""
    },
    model: {
      value: _vm.object.contracts,
      callback: function ($$v) {
        _vm.$set(_vm.object, "contracts", $$v);
      },
      expression: "object.contracts"
    }
  }, [_c('v-radio', {
    attrs: {
      "label": _vm.$t('Yes'),
      "color": "orange darken-3",
      "value": 'Jah'
    }
  }), _c('v-radio', {
    attrs: {
      "label": _vm.$t('No'),
      "color": "orange darken-3",
      "value": 'Ei'
    }
  })], 1)], 1), _vm.object.contracts === 'Jah' ? _c('div', [_c('v-row', {
    staticClass: "input-wrapper",
    staticStyle: {
      "max-height": "fit-content",
      "padding-bottom": "40px"
    }
  }, [_c('v-row', {
    staticStyle: {
      "margin-bottom": "-10px",
      "width": "100%"
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.object.rentedStartDate,
      expression: "object.rentedStartDate"
    }],
    staticClass: "date-class",
    attrs: {
      "id": "rentedStartDateInput",
      "type": "date",
      "name": "session-date"
    },
    domProps: {
      "value": _vm.object.rentedStartDate
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.object, "rentedStartDate", $event.target.value);
      }
    }
  }), _c('label', {
    staticClass: "v-label v-label--active theme--light date-input",
    attrs: {
      "for": "rentedStartDateInput"
    }
  }, [_vm._v(_vm._s(_vm.$t("ContractStartDate")))])]), _c('v-row', {
    staticStyle: {
      "margin-bottom": "-10px",
      "width": "100%"
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.object.rentedEndDate,
      expression: "object.rentedEndDate"
    }],
    staticClass: "date-class",
    attrs: {
      "id": "rentedEndDateInput",
      "type": "date",
      "name": "session-date"
    },
    domProps: {
      "value": _vm.object.rentedEndDate
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.object, "rentedEndDate", $event.target.value);
      }
    }
  }), _c('label', {
    staticClass: "v-label v-label--active theme--light date-input",
    attrs: {
      "for": "rentedEndDateInput"
    }
  }, [_vm._v(_vm._s(_vm.$t("ContractEndDate")))])]), _c('v-row', {
    staticStyle: {
      "width": "100%"
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.object.rightsToBuyer,
      expression: "object.rightsToBuyer"
    }],
    staticClass: "date-class",
    attrs: {
      "id": "rightsToBuyerInput",
      "type": "date",
      "name": "session-date"
    },
    domProps: {
      "value": _vm.object.rightsToBuyer
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.object, "rightsToBuyer", $event.target.value);
      }
    }
  }), _c('label', {
    staticClass: "v-label v-label--active theme--light date-input",
    attrs: {
      "for": "rightsToBuyerInput"
    }
  }, [_vm._v(_vm._s(_vm.$t("RightsToBuyer")))])]), _c('v-text-field', {
    staticClass: "no-active",
    attrs: {
      "outlined": "",
      "label": _vm.$t('Deposit')
    },
    model: {
      value: _vm.object.receivedDeposit,
      callback: function ($$v) {
        _vm.$set(_vm.object, "receivedDeposit", $$v);
      },
      expression: "object.receivedDeposit"
    }
  })], 1)], 1) : _vm._e()], 1), _c('h6', [_vm._v(_vm._s(_vm.$t("IsMortgaged")))]), _c('v-radio-group', {
    attrs: {
      "row": ""
    },
    model: {
      value: _vm.object.currentMortgage,
      callback: function ($$v) {
        _vm.$set(_vm.object, "currentMortgage", $$v);
      },
      expression: "object.currentMortgage"
    }
  }, [_c('v-radio', {
    attrs: {
      "label": _vm.$t('Yes'),
      "color": "orange darken-3",
      "value": 'Jah'
    }
  }), _c('v-radio', {
    attrs: {
      "label": _vm.$t('No'),
      "color": "orange darken-3",
      "value": 'Ei'
    }
  })], 1), _vm.object.currentMortgage === 'Jah' ? _c('v-text-field', {
    staticClass: "no-active",
    staticStyle: {
      "padding-bottom": "90px"
    },
    attrs: {
      "outlined": "",
      "label": _vm.$t('Bank')
    },
    model: {
      value: _vm.object.currentMortgageBank,
      callback: function ($$v) {
        _vm.$set(_vm.object, "currentMortgageBank", $$v);
      },
      expression: "object.currentMortgageBank"
    }
  }) : _vm._e(), _c('h6', [_vm._v(_vm._s(_vm.$t("Freed")))]), _c('v-row', {
    staticClass: "input-wrapper",
    staticStyle: {
      "max-height": "fit-content"
    }
  }, [_c('v-radio-group', {
    attrs: {
      "row": ""
    },
    model: {
      value: _vm.object.beReleased,
      callback: function ($$v) {
        _vm.$set(_vm.object, "beReleased", $$v);
      },
      expression: "object.beReleased"
    }
  }, [_c('v-radio', {
    attrs: {
      "label": _vm.$t('Yes'),
      "color": "orange darken-3",
      "value": 'Jah'
    }
  }), _c('v-radio', {
    attrs: {
      "label": _vm.$t('No'),
      "color": "orange darken-3",
      "value": 'Ei'
    }
  })], 1), _vm.object.beReleased === 'Jah' ? _c('v-row', {
    staticClass: "input-wrapper",
    staticStyle: {
      "padding-bottom": "30px"
    }
  }, [_c('v-menu', {
    staticClass: "no-active",
    attrs: {
      "return-value": _vm.object.releaseDate,
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "auto"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('input', _vm._g(_vm._b({
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.object.releaseDate,
            expression: "object.releaseDate"
          }],
          staticClass: "date-class",
          staticStyle: {
            "width": "100%"
          },
          attrs: {
            "type": "date"
          },
          domProps: {
            "value": _vm.object.releaseDate
          },
          on: {
            "input": function ($event) {
              if ($event.target.composing) return;
              _vm.$set(_vm.object, "releaseDate", $event.target.value);
            }
          }
        }, 'input', attrs, false), on))];
      }
    }], null, false, 1952517414),
    model: {
      value: _vm.object.releaseDateChanged,
      callback: function ($$v) {
        _vm.$set(_vm.object, "releaseDateChanged", $$v);
      },
      expression: "object.releaseDateChanged"
    }
  })], 1) : _vm._e()], 1), _c('h6', [_vm._v(_vm._s(_vm.$t("WillBeMortgaged")))]), _c('v-radio-group', {
    attrs: {
      "row": ""
    },
    model: {
      value: _vm.object.willBeMortgaged,
      callback: function ($$v) {
        _vm.$set(_vm.object, "willBeMortgaged", $$v);
      },
      expression: "object.willBeMortgaged"
    }
  }, [_c('v-radio', {
    attrs: {
      "label": _vm.$t('Yes'),
      "color": "orange darken-3",
      "value": 'Jah'
    }
  }), _c('v-radio', {
    attrs: {
      "label": _vm.$t('No'),
      "color": "orange darken-3",
      "value": 'Ei'
    }
  })], 1), _vm.object.willBeMortgaged === 'Jah' ? _c('div', [_c('v-row', {
    staticClass: "input-wrapper",
    staticStyle: {
      "max-height": "fit-content"
    }
  }, [_c('v-text-field', {
    staticClass: "no-active",
    attrs: {
      "outlined": "",
      "label": _vm.$t('Bank')
    },
    model: {
      value: _vm.object.willBeMortgagedBank,
      callback: function ($$v) {
        _vm.$set(_vm.object, "willBeMortgagedBank", $$v);
      },
      expression: "object.willBeMortgagedBank"
    }
  }), _c('v-text-field', {
    staticClass: "no-active",
    staticStyle: {
      "padding-bottom": "80px"
    },
    attrs: {
      "outlined": "",
      "label": _vm.$t('BankContact')
    },
    model: {
      value: _vm.object.bankContact,
      callback: function ($$v) {
        _vm.$set(_vm.object, "bankContact", $$v);
      },
      expression: "object.bankContact"
    }
  })], 1)], 1) : _vm._e(), _c('h6', [_vm._v(_vm._s(_vm.$t("ComesWith")))]), _c('v-textarea', {
    attrs: {
      "no-resize": "",
      "outlined": ""
    },
    model: {
      value: _vm.object.comesWith,
      callback: function ($$v) {
        _vm.$set(_vm.object, "comesWith", $$v);
      },
      expression: "object.comesWith"
    }
  }), _c('h6', [_vm._v(_vm._s(_vm.$t("AnyShortcomings")))]), _c('v-radio-group', {
    attrs: {
      "row": ""
    },
    model: {
      value: _vm.object.anyShortcomings,
      callback: function ($$v) {
        _vm.$set(_vm.object, "anyShortcomings", $$v);
      },
      expression: "object.anyShortcomings"
    }
  }, [_c('v-radio', {
    attrs: {
      "label": _vm.$t('Yes'),
      "color": "orange darken-3",
      "value": 'Jah'
    }
  }), _c('v-radio', {
    attrs: {
      "label": _vm.$t('No'),
      "color": "orange darken-3",
      "value": 'Ei'
    }
  })], 1), _vm.object.anyShortcomings === 'Jah' ? _c('div', [_c('v-textarea', {
    attrs: {
      "no-resize": "",
      "outlined": ""
    },
    model: {
      value: _vm.object.shortcomings,
      callback: function ($$v) {
        _vm.$set(_vm.object, "shortcomings", $$v);
      },
      expression: "object.shortcomings"
    }
  })], 1) : _vm._e(), _c('h6', [_vm._v(_vm._s(_vm.$t("RegisteringAfterNotary")))]), _c('v-row', {
    staticClass: "input-wrapper registering-input"
  }, [_c('v-text-field', {
    staticClass: "no-active",
    attrs: {
      "outlined": ""
    },
    model: {
      value: _vm.object.registering,
      callback: function ($$v) {
        _vm.$set(_vm.object, "registering", $$v);
      },
      expression: "object.registering"
    }
  })], 1), _c('h6', [_vm._v(_vm._s(_vm.$t("ObjectInsured")))]), _c('v-row', {
    staticClass: "input-wrapper",
    staticStyle: {
      "max-height": "fit-content"
    }
  }, [_c('v-radio-group', {
    attrs: {
      "row": ""
    },
    model: {
      value: _vm.object.insured,
      callback: function ($$v) {
        _vm.$set(_vm.object, "insured", $$v);
      },
      expression: "object.insured"
    }
  }, [_c('v-radio', {
    attrs: {
      "label": _vm.$t('Yes'),
      "color": "orange darken-3",
      "value": 'Jah'
    }
  }), _c('v-radio', {
    attrs: {
      "label": _vm.$t('No'),
      "color": "orange darken-3",
      "value": 'Ei'
    }
  })], 1)], 1), _c('h6', [_vm._v(_vm._s(_vm.$t("EnergyLabel")))]), _c('v-row', {
    staticClass: "input-wrapper",
    staticStyle: {
      "margin-top": "-16px"
    }
  }, [_c('v-select', {
    staticClass: "no-active select-input",
    attrs: {
      "clearable": "",
      "items": _vm.energyLabels,
      "item-text": "name",
      "item-value": "value",
      "outlined": "",
      "label": _vm.$t('EnergyLabel')
    },
    model: {
      value: _vm.object.energyLabel,
      callback: function ($$v) {
        _vm.$set(_vm.object, "energyLabel", $$v);
      },
      expression: "object.energyLabel"
    }
  })], 1), _c('h6', [_vm._v(_vm._s(_vm.$t("Documents")))]), _c('v-row', {
    staticClass: "input-wrapper",
    staticStyle: {
      "max-height": "fit-content"
    }
  }, [_c('v-checkbox', {
    attrs: {
      "label": _vm.$t('BuildingPermit'),
      "color": "#FF5C01"
    },
    model: {
      value: _vm.object.documents.buildingPermit,
      callback: function ($$v) {
        _vm.$set(_vm.object.documents, "buildingPermit", $$v);
      },
      expression: "object.documents.buildingPermit"
    }
  }), _c('v-checkbox', {
    attrs: {
      "label": _vm.$t('UsePermit'),
      "color": "#FF5C01"
    },
    model: {
      value: _vm.object.documents.usePermit,
      callback: function ($$v) {
        _vm.$set(_vm.object.documents, "usePermit", $$v);
      },
      expression: "object.documents.usePermit"
    }
  }), _c('v-checkbox', {
    attrs: {
      "label": _vm.$t('Plans'),
      "color": "#FF5C01"
    },
    model: {
      value: _vm.object.documents.plans,
      callback: function ($$v) {
        _vm.$set(_vm.object.documents, "plans", $$v);
      },
      expression: "object.documents.plans"
    }
  })], 1), _c('h6', [_vm._v(_vm._s(_vm.$t("AccessThroughPublicRoad")))]), _c('v-row', [_c('v-radio-group', {
    attrs: {
      "row": ""
    },
    model: {
      value: _vm.object.publicAccess,
      callback: function ($$v) {
        _vm.$set(_vm.object, "publicAccess", $$v);
      },
      expression: "object.publicAccess"
    }
  }, [_c('v-radio', {
    attrs: {
      "label": _vm.$t('Yes'),
      "color": "orange darken-3",
      "value": 'Jah'
    }
  }), _c('v-radio', {
    attrs: {
      "label": _vm.$t('No'),
      "color": "orange darken-3",
      "value": 'Ei'
    }
  })], 1)], 1), _vm.object.publicAccess === 'Ei' ? _c('v-radio-group', {
    attrs: {
      "column": ""
    },
    model: {
      value: _vm.object.noPublicAccess,
      callback: function ($$v) {
        _vm.$set(_vm.object, "noPublicAccess", $$v);
      },
      expression: "object.noPublicAccess"
    }
  }, [_c('v-radio', {
    attrs: {
      "label": _vm.$t('ThroughNeighbor'),
      "color": "orange darken-3",
      "value": 'Läbi naaberkinnistu'
    }
  }), _c('v-radio', {
    attrs: {
      "label": _vm.$t('Servitute'),
      "color": "orange darken-3",
      "value": 'Servituuti on'
    }
  }), _c('v-radio', {
    attrs: {
      "label": _vm.$t('NotAssigned'),
      "color": "orange darken-3",
      "value": 'Ei ole seatud'
    }
  })], 1) : _vm._e(), _c('h6', [_vm._v(_vm._s(_vm.$t("Utilities")))]), _c('v-textarea', {
    attrs: {
      "no-resize": "",
      "outlined": ""
    },
    model: {
      value: _vm.object.communications,
      callback: function ($$v) {
        _vm.$set(_vm.object, "communications", $$v);
      },
      expression: "object.communications"
    }
  }), _c('h6', [_vm._v(_vm._s(_vm.$t("DetailedPlanning")))]), _c('v-row', {
    staticStyle: {
      "margin-top": "-4px"
    }
  }, [_c('v-radio-group', {
    attrs: {
      "row": ""
    },
    model: {
      value: _vm.object.detailPlan,
      callback: function ($$v) {
        _vm.$set(_vm.object, "detailPlan", $$v);
      },
      expression: "object.detailPlan"
    }
  }, [_c('v-radio', {
    attrs: {
      "label": _vm.$t('Yes'),
      "color": "orange darken-3",
      "value": 'Jah'
    }
  }), _c('v-radio', {
    attrs: {
      "label": _vm.$t('No'),
      "color": "orange darken-3",
      "value": 'Ei'
    }
  })], 1)], 1)], 1), _c('v-card', {
    staticClass: "form-wrapper",
    attrs: {
      "flat": ""
    }
  }, [_c('h5', [_vm._v(_vm._s(_vm.$t("Price")))]), _c('v-row', {
    staticClass: "input-wrapper",
    staticStyle: {
      "max-height": "fit-content",
      "margin-top": "-16px"
    }
  }, [_c('v-text-field', {
    class: 'no-active select-input required ' + (_vm.showErrors && !_vm.isFieldValid('price', 'object') ? 'not-valid' : ''),
    attrs: {
      "label": _vm.$t('Price'),
      "outlined": ""
    },
    model: {
      value: _vm.object.payment.price,
      callback: function ($$v) {
        _vm.$set(_vm.object.payment, "price", $$v);
      },
      expression: "object.payment.price"
    }
  })], 1), _c('h6', [_vm._v(_vm._s(_vm.$t("PaidFor")))]), _c('v-row', {
    staticClass: "input-wrapper paid-to-input"
  }, [_c('v-text-field', {
    staticClass: "no-active",
    attrs: {
      "outlined": "",
      "label": _vm.$t('ToWho')
    },
    model: {
      value: _vm.object.payment.paidTo,
      callback: function ($$v) {
        _vm.$set(_vm.object.payment, "paidTo", $$v);
      },
      expression: "object.payment.paidTo"
    }
  }), _c('v-text-field', {
    staticClass: "no-active",
    attrs: {
      "outlined": "",
      "label": _vm.$t('Sum')
    },
    model: {
      value: _vm.object.payment.reservationPayment,
      callback: function ($$v) {
        _vm.$set(_vm.object.payment, "reservationPayment", $$v);
      },
      expression: "object.payment.reservationPayment"
    }
  })], 1), _c('h6', [_vm._v(_vm._s(_vm.$t("PayForRest")))]), _c('v-row', {
    staticClass: "input-wrapper",
    staticStyle: {
      "max-height": "fit-content"
    }
  }, [_c('v-checkbox', {
    attrs: {
      "label": _vm.$t('Cash'),
      "color": "#FF5C01"
    },
    model: {
      value: _vm.object.payment.cash,
      callback: function ($$v) {
        _vm.$set(_vm.object.payment, "cash", $$v);
      },
      expression: "object.payment.cash"
    }
  }), _c('v-checkbox', {
    attrs: {
      "label": _vm.$t('NotaryDeposit'),
      "color": "#FF5C01"
    },
    model: {
      value: _vm.object.payment.notaryDeposit,
      callback: function ($$v) {
        _vm.$set(_vm.object.payment, "notaryDeposit", $$v);
      },
      expression: "object.payment.notaryDeposit"
    }
  }), _c('v-checkbox', {
    attrs: {
      "label": _vm.$t('FromBankLoan'),
      "color": "#FF5C01"
    },
    model: {
      value: _vm.object.payment.bankLoan,
      callback: function ($$v) {
        _vm.$set(_vm.object.payment, "bankLoan", $$v);
      },
      expression: "object.payment.bankLoan"
    }
  }), _vm.object.payment.bankLoan === true ? _c('v-row', {
    staticClass: "input-wrapper",
    staticStyle: {
      "max-height": "fit-content",
      "padding-bottom": "20px"
    }
  }, [_c('v-text-field', {
    staticClass: "no-active",
    attrs: {
      "outlined": "",
      "label": _vm.$t('Bank')
    },
    model: {
      value: _vm.object.payment.bankLoanBank,
      callback: function ($$v) {
        _vm.$set(_vm.object.payment, "bankLoanBank", $$v);
      },
      expression: "object.payment.bankLoanBank"
    }
  })], 1) : _vm._e()], 1), _c('h6', [_vm._v(_vm._s(_vm.$t("SelfFinanced")))]), _c('v-row', {
    staticClass: "input-wrapper extra-input"
  }, [_c('v-text-field', {
    staticClass: "no-active",
    attrs: {
      "outlined": "",
      "label": _vm.$t('NotaryBank')
    },
    model: {
      value: _vm.object.payment.notaryBank,
      callback: function ($$v) {
        _vm.$set(_vm.object.payment, "notaryBank", $$v);
      },
      expression: "object.payment.notaryBank"
    }
  }), _c('v-text-field', {
    staticClass: "no-active",
    attrs: {
      "outlined": "",
      "label": _vm.$t('NotaryBankAccount')
    },
    model: {
      value: _vm.object.payment.notaryBankAccount,
      callback: function ($$v) {
        _vm.$set(_vm.object.payment, "notaryBankAccount", $$v);
      },
      expression: "object.payment.notaryBankAccount"
    }
  })], 1), _c('h6', [_vm._v(_vm._s(_vm.$t("NotaryDepositUse")))]), _c('v-radio-group', {
    attrs: {
      "row": ""
    },
    model: {
      value: _vm.object.payment.usesNotaryDeposit,
      callback: function ($$v) {
        _vm.$set(_vm.object.payment, "usesNotaryDeposit", $$v);
      },
      expression: "object.payment.usesNotaryDeposit"
    }
  }, [_c('v-radio', {
    attrs: {
      "label": _vm.$t('Yes'),
      "color": "orange darken-3",
      "value": 'Jah'
    }
  }), _c('v-radio', {
    attrs: {
      "label": _vm.$t('No'),
      "color": "orange darken-3",
      "value": 'Ei'
    }
  })], 1), _c('h6', [_vm._v(_vm._s(_vm.$t("MoneyMovesTo")))]), _c('v-row', {
    staticClass: "input-wrapper extra-input"
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("ForLoanPayment")))]), _c('v-text-field', {
    staticClass: "no-active",
    attrs: {
      "outlined": "",
      "label": _vm.$t('Bank')
    },
    model: {
      value: _vm.object.payment.returnLoanBank,
      callback: function ($$v) {
        _vm.$set(_vm.object.payment, "returnLoanBank", $$v);
      },
      expression: "object.payment.returnLoanBank"
    }
  }), _c('v-text-field', {
    staticClass: "no-active",
    attrs: {
      "outlined": "",
      "label": _vm.$t('AccountNumber')
    },
    model: {
      value: _vm.object.payment.assignedAccountByBank,
      callback: function ($$v) {
        _vm.$set(_vm.object.payment, "assignedAccountByBank", $$v);
      },
      expression: "object.payment.assignedAccountByBank"
    }
  }), _c('span', {
    staticStyle: {
      "margin-top": "10px"
    }
  }, [_vm._v(_vm._s(_vm.$t("RestPaidForward")))]), _c('v-text-field', {
    staticClass: "no-active",
    attrs: {
      "outlined": "",
      "label": _vm.$t('AccountName')
    },
    model: {
      value: _vm.object.payment.paymentForwardName,
      callback: function ($$v) {
        _vm.$set(_vm.object.payment, "paymentForwardName", $$v);
      },
      expression: "object.payment.paymentForwardName"
    }
  }), _c('v-text-field', {
    staticClass: "no-active",
    attrs: {
      "outlined": "",
      "label": _vm.$t('AccountNumber')
    },
    model: {
      value: _vm.object.payment.paymentForwardAccount,
      callback: function ($$v) {
        _vm.$set(_vm.object.payment, "paymentForwardAccount", $$v);
      },
      expression: "object.payment.paymentForwardAccount"
    }
  })], 1), _c('h6', [_vm._v(_vm._s(_vm.$t("NotaryFeePaidBy")))]), _c('v-row', [_c('v-radio-group', {
    attrs: {
      "row": ""
    },
    model: {
      value: _vm.object.payment.notaryPayment,
      callback: function ($$v) {
        _vm.$set(_vm.object.payment, "notaryPayment", $$v);
      },
      expression: "object.payment.notaryPayment"
    }
  }, [_c('v-radio', {
    attrs: {
      "label": _vm.$t('HalfHalf'),
      "color": "orange darken-3",
      "value": '50/50'
    }
  }), _c('v-radio', {
    attrs: {
      "label": _vm.$t('BuyerPays'),
      "color": "orange darken-3",
      "value": '100% ostja'
    }
  }), _c('v-radio', {
    attrs: {
      "label": _vm.$t('SellBuyHalved'),
      "color": "orange darken-3",
      "value": 'Ost-müük pooleks'
    }
  })], 1)], 1), _c('h6', [_vm._v(_vm._s(_vm.$t("StateFeePaidBy")))]), _c('v-row', [_c('v-radio-group', {
    attrs: {
      "row": ""
    },
    model: {
      value: _vm.object.payment.paysStateFee,
      callback: function ($$v) {
        _vm.$set(_vm.object.payment, "paysStateFee", $$v);
      },
      expression: "object.payment.paysStateFee"
    }
  }, [_c('v-radio', {
    attrs: {
      "label": _vm.$t('PartyOfInterest'),
      "color": "orange darken-3",
      "value": 'Osapool kelle huvides kanne tehakse'
    }
  }), _c('v-radio', {
    attrs: {
      "label": _vm.$t('BuyerPays'),
      "color": "orange darken-3",
      "value": '100% ostja'
    }
  }), _c('v-radio', {
    attrs: {
      "label": _vm.$t('SellerPays'),
      "color": "orange darken-3",
      "value": '100% müüja'
    }
  })], 1)], 1)], 1), _c('v-card', {
    staticClass: "form-wrapper",
    attrs: {
      "flat": ""
    }
  }, [_c('h5', [_vm._v(_vm._s(_vm.$t("ContactPerson2")) + "/" + _vm._s(_vm.$t("Broker")))]), _c('v-row', {
    staticClass: "input-wrapper extra-input"
  }, [_c('v-text-field', {
    class: 'no-active required ' + (_vm.showErrors && !_vm.isFieldValid('name', 'broker') ? 'not-valid' : ''),
    attrs: {
      "outlined": "",
      "label": _vm.$t('FullName')
    },
    model: {
      value: _vm.broker.name,
      callback: function ($$v) {
        _vm.$set(_vm.broker, "name", $$v);
      },
      expression: "broker.name"
    }
  }), _c('v-text-field', {
    class: 'no-active required ' + (_vm.showErrors && !_vm.isFieldValid('phoneNumber', 'broker') ? 'not-valid' : ''),
    attrs: {
      "outlined": "",
      "label": _vm.$t('Phone')
    },
    model: {
      value: _vm.broker.phoneNumber,
      callback: function ($$v) {
        _vm.$set(_vm.broker, "phoneNumber", $$v);
      },
      expression: "broker.phoneNumber"
    }
  }), _c('v-text-field', {
    class: 'no-active required ' + (_vm.showErrors && !_vm.isFieldValid('email', 'broker') ? 'not-valid' : ''),
    attrs: {
      "outlined": "",
      "label": _vm.$t('CustomerField.email')
    },
    model: {
      value: _vm.broker.email,
      callback: function ($$v) {
        _vm.$set(_vm.broker, "email", $$v);
      },
      expression: "broker.email"
    }
  })], 1)], 1), _c('div', {
    staticClass: "buttons-container"
  }, [_c('v-divider', {
    staticClass: "buttons-divider"
  }), _c('v-row', {
    staticClass: "btns-container-buttons"
  }, [_c('button', {
    staticClass: "transparent-button",
    on: {
      "click": _vm.save
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("SaveAndExit")) + " ")]), _c('button', {
    staticClass: "cancel-button",
    staticStyle: {
      "margin-right": "-40px"
    },
    on: {
      "click": function ($event) {
        return _vm.closeModal();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")]), _c('button', {
    staticClass: "generate-doc-button",
    attrs: {
      "name": "generate-doc-button"
    },
    on: {
      "click": _vm.validateAndGenerate
    }
  }, [_c('img', {
    staticStyle: {
      "padding-right": "8px"
    },
    attrs: {
      "src": require("../../assets/images/generate-file-icon.svg"),
      "alt": ""
    }
  }), _vm._v(" " + _vm._s(_vm.$t("GenerateDocument")) + " ")])])], 1)], 1)], 1), _c('VueHtml2pdf', {
    ref: "html2Pdf",
    attrs: {
      "show-layout": false,
      "float-layout": true,
      "enable-download": true,
      "preview-modal": _vm.showPreview,
      "paginate-elements-by-height": 1400,
      "filename": "notary-base-doc",
      "pdf-quality": 2,
      "manual-pagination": false,
      "pdf-format": "a4",
      "pdf-orientation": "portrait",
      "pdf-content-width": "800px"
    },
    on: {
      "hasDownloaded": function ($event) {
        return _vm.hasDownloaded($event);
      }
    }
  }, [_c('section', {
    staticClass: "pdf-content",
    attrs: {
      "slot": "pdf-content",
      "id": "pdf-content"
    },
    slot: "pdf-content"
  }, [_c('h3', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm._v(_vm._s(_vm.$t("NotaryBaseDocTitle")))]), _vm.notaryName || _vm.transactionDate || _vm.time ? _c('section', {
    staticClass: "pdf-sections"
  }, [_vm.notaryName ? _c('p', [_vm._v("Notar: " + _vm._s(_vm.notaryName))]) : _vm._e(), _vm.transactionDate ? _c('p', [_vm._v("Tehingu kuupäev: " + _vm._s(_vm.transactionDate))]) : _vm._e(), _vm.time ? _c('p', [_vm._v("Kellaaeg: " + _vm._s(_vm.time))]) : _vm._e()]) : _vm._e(), _c('section', {
    staticClass: "pdf-sections"
  }, [_c('h3', [_vm._v("MÜÜJA:")]), _vm._l(_vm.notaryDocument.sellers, function (seller, index) {
    return _c('div', {
      key: 'pdfseller' + index,
      staticStyle: {
        "margin-top": "8px"
      }
    }, [_c('p', [_vm._v(_vm._s(seller.name) + ", isikukood " + _vm._s(seller.personalCode))]), _c('p', [_vm._v("Perekonnaseis: " + _vm._s(seller.maritalStatus))]), _c('p', [_vm._v("Elukoht: " + _vm._s(seller.address))]), seller.email ? _c('p', [_vm._v("Müüja e-post: " + _vm._s(seller.email))]) : _vm._e(), seller.phoneNumber ? _c('p', [_vm._v("Mob: " + _vm._s(seller.phoneNumber))]) : _vm._e()]);
  })], 2), _c('section', {
    staticClass: "pdf-sections"
  }, [_c('h3', [_vm._v("OSTJA:")]), _vm._l(_vm.notaryDocument.buyers, function (buyer, index) {
    return _c('div', {
      key: 'pdfBuyer' + index,
      staticStyle: {
        "margin-top": "8px"
      }
    }, [_c('p', [_vm._v(_vm._s(buyer.name) + ", isikukood " + _vm._s(buyer.personalCode))]), _c('p', [_vm._v("Perekonnaseis: " + _vm._s(buyer.maritalStatus))]), _c('p', [_vm._v("Elukoht: " + _vm._s(buyer.address))]), buyer.email ? _c('p', [_vm._v("Müüja e-post: " + _vm._s(buyer.email))]) : _vm._e(), buyer.phoneNumber ? _c('p', [_vm._v("Mob: " + _vm._s(buyer.phoneNumber))]) : _vm._e()]);
  })], 2), _c('section', {
    staticClass: "pdf-sections"
  }, [_c('h3', [_vm._v("OBJEKT:")]), _c('p', [_vm._v("Aadress " + _vm._s(_vm.object.address))]), _c('p', [_vm._v("Kuidas omandatud: " + _vm._s(_vm.object.acquiredFrom))]), _c('p', [_vm._v("Kinnistu reg. nr: " + _vm._s(_vm.object.estateNumber))])]), _vm.object.apartmentAssociation || _vm.object.communalPayments ? _c('section', {
    staticClass: "pdf-sections"
  }, [_vm.object.apartmentAssociation ? _c('p', [_vm._v(" Korteriühistu: " + _vm._s(_vm.object.apartmentAssociation) + " ")]) : _vm._e(), _vm.object.communalPayments ? _c('p', [_vm._v(" Kommunaalmaksete võlgnevused? " + _vm._s(_vm.object.communalPayments) + " "), _vm.object.communalPayments === 'on' ? _c('span', [_vm._v(" summas " + _vm._s(_vm.object.communalPaymentsAmount) + " ")]) : _vm._e()]) : _vm._e()]) : _vm._e(), _vm.object.contracts || _vm.object.communalPayments || _vm.object.currentMortgage || _vm.object.beReleased || _vm.object.willBeMortgaged ? _c('section', {
    staticClass: "pdf-sections"
  }, [_vm.object.contracts === 'Ei' ? _c('span', [_vm._v(" Kas on üüri/rendilepinguid? " + _vm._s(_vm.object.contracts) + " ")]) : _vm._e(), _vm.object.contracts === 'Jah' ? _c('div', [_c('span', [_vm._v(" Kas on üüri/rendilepinguid? " + _vm._s(_vm.object.contracts) + " ")]), _vm.object.rentedStartDate ? _c('span', [_vm._v(" sõlmitud " + _vm._s(_vm.object.rentedStartDate) + " ")]) : _vm._e(), _vm.object.rentedEndDate ? _c('span', [_vm._v(" , tähtajaga " + _vm._s(_vm.object.rentedEndDate) + " ")]) : _vm._e(), _vm.object.receivedDeposit ? _c('span', [_vm._v(" , üürilepingu alusel saadud tagatisraha " + _vm._s(_vm.object.receivedDeposit) + " ")]) : _vm._e(), _vm.object.communalPayments ? _c('span', [_vm._v(" , üürimaksete, kommunaalmaksete võlgnevusi " + _vm._s(_vm.object.communalPayments) + " ")]) : _vm._e(), _vm.object.communalPayments === 'on' ? _c('span', [_vm._v(" summas " + _vm._s(_vm.object.communalPaymentsAmount) + " ")]) : _vm._e(), _vm.object.rightsToBuyer ? _c('span', [_vm._v(" , ostjale läheb üüri saamise õigus üle " + _vm._s(_vm.object.rightsToBuyer) + " ")]) : _vm._e(), _vm.object.currentMortgage ? _c('p', [_vm._v(" Kas on koormatud hüpoteegiga? " + _vm._s(_vm.object.currentMortgage) + " "), _vm.object.currentMortgage === 'Jah' ? _c('span', [_vm._v(" , " + _vm._s(_vm.object.currentMortgageBank) + " pank ")]) : _vm._e()]) : _vm._e(), _vm.object.beReleased ? _c('p', [_vm._v(" Kas ja millal vabastatakse - " + _vm._s(_vm.object.beReleased) + ", hiljemalt " + _vm._s(_vm.object.releaseDate) + " ")]) : _vm._e(), _vm.object.willBeMortgaged ? _c('p', [_vm._v(" Kas koormatakse hüpoteegiga - " + _vm._s(_vm.object.willBeMortgaged) + " "), _vm.object.willBeMortgaged === 'Jah' ? _c('span', [_vm._v(" " + _vm._s(_vm.object.willBeMortgagedBank) + ", kontaktisik pangas " + _vm._s(_vm.object.bankContact) + " ")]) : _vm._e()]) : _vm._e()]) : _vm._e()]) : _vm._e(), _vm.object.comesWith ? _c('section', {
    staticClass: "pdf-sections"
  }, [_c('p', [_vm._v("Täpne asjade loetelu mis ostja objektiga kaasa saab:")]), _c('p', [_vm._v(_vm._s(_vm.object.comesWith))])]) : _vm._e(), _c('div', {
    staticClass: "html2pdf__page-break"
  }), _c('section', {
    staticStyle: {
      "padding-top": "6.4%"
    }
  }, [_vm.object.anyShortcomings || _vm.object.registering || _vm.object.insured || _vm.object.energyLabel || _vm.object.documents || _vm.object.publicAccess || _vm.object.communications || _vm.object.detailPlan ? _c('section', {
    staticClass: "pdf-sections"
  }, [_vm.object.anyShortcomings ? _c('p', [_vm._v(" Kas on puuduseid, milliest soovib Müüja ostjat teavitada - " + _vm._s(_vm.object.anyShortcomings) + " "), _vm.object.anyShortcomings === 'Jah' ? _c('span', [_vm._v(" " + _vm._s(_vm.object.shortcomings) + " ")]) : _vm._e()]) : _vm._e(), _vm.object.registering ? _c('p', [_vm._v(" Välja registreerimine - " + _vm._s(_vm.object.registering) + " jooksul peale notari lepingut. ")]) : _vm._e(), _vm.object.insured ? _c('p', [_vm._v(" Kas objekt on kindlustatud, " + _vm._s(_vm.object.insured) + " ")]) : _vm._e(), _vm.object.energyLabel ? _c('p', [_vm._v(" Energiamärgis: " + _vm._s(_vm.object.energyLabel) + " ")]) : _vm._e(), _vm.object.documents.buildingPermit || _vm.object.documents.usePermit || _vm.object.documents.plans ? _c('p', [_vm._v(" Dokumendid: "), _vm.object.documents.buildingPermit === true ? _c('span', [_vm._v(" " + _vm._s(_vm.$t("BuildingPermit")) + " ")]) : _vm._e(), _vm.object.documents.usePermit === true ? _c('span', [_vm._v(" " + _vm._s(_vm.$t("UsePermit")) + " ")]) : _vm._e(), _vm.object.documents.plans === true ? _c('span', [_vm._v(" " + _vm._s(_vm.$t("Plans")) + " ")]) : _vm._e()]) : _vm._e(), _vm.object.publicAccess ? _c('p', [_vm._v(" Ligipääs avalikult kasutatavalt teelt: " + _vm._s(_vm.object.publicAccess) + " "), _vm.object.publicAccess === 'Ei' ? _c('span', [_vm._v(" , " + _vm._s(_vm.object.noPublicAccess) + " ")]) : _vm._e()]) : _vm._e(), _vm.object.communications ? _c('p', [_vm._v(" Kommunikatsioonid: " + _vm._s(_vm.object.communications) + " ")]) : _vm._e(), _vm.object.detailPlan ? _c('p', [_vm._v(" Detailplaneering kehtestatud: " + _vm._s(_vm.object.detailPlan) + " ")]) : _vm._e()]) : _vm._e(), _c('section', {
    staticClass: "pdf-sections"
  }, [_c('h3', [_vm._v("HIND: " + _vm._s(_vm.object.payment.price))]), _vm.object.payment.reservationPayment ? _c('p', [_vm._v(" Sellest tasutud: " + _vm._s(_vm.object.payment.reservationPayment) + " eurot broneerimistasuna " + _vm._s(_vm.object.payment.paidTo) + " ")]) : _vm._e(), _vm.object.payment.cash || _vm.object.payment.notaryDeposit || _vm.object.payment.bankLoan ? _c('p', [_vm._v(" Ülejäänud tasutakse: "), _vm.object.payment.cash === true ? _c('span', [_vm._v(" sularahas ")]) : _vm._e(), _vm.object.payment.notaryDeposit === true ? _c('span', [_vm._v(" notari deposiiti ")]) : _vm._e(), _vm.object.payment.bankLoan === true ? _c('span', [_vm._v(" " + _vm._s(_vm.object.payment.bankLoanBank) + " panga laenust ")]) : _vm._e()]) : _vm._e(), _vm.object.payment.notaryBank ? _c('p', [_vm._v(" Omafinantseeringu summa: palun edastada notari " + _vm._s(_vm.object.payment.notaryBank) + " panga deposiitkonto nr. " + _vm._s(_vm.object.payment.notaryBankAccount) + " ")]) : _vm._e(), _c('p', [_vm._v(" Kas kasutatakse notari deposiitkontot - " + _vm._s(_vm.object.payment.usesNotaryDeposit) + " ")])]), _vm.object.payment.returnLoanBank || _vm.object.payment.paymentForwardName ? _c('section', {
    staticClass: "pdf-sections"
  }, [_vm.object.payment.returnLoanBank ? _c('span', [_vm._v("Raha liigub edasi:")]) : _vm._e(), _c('span', [_vm._v("+ Müüja laenu tagastamiseks " + _vm._s(_vm.object.payment.returnLoanBank) + " panga poolt näidatud kontole " + _vm._s(_vm.object.payment.assignedAccountByBank) + " ")]), _vm.object.payment.paymentForwardName ? _c('p', [_vm._v(" + Müüja arveldusarvele järele jäänud summa kantakse: " + _vm._s(_vm.object.payment.paymentForwardName) + " kontole nr " + _vm._s(_vm.object.payment.paymentForwardAccount) + " ")]) : _vm._e()]) : _vm._e(), _vm.object.payment.notaryPayment || _vm.object.payment.paysStateFee ? _c('section', {
    staticClass: "pdf-sections"
  }, [_vm.object.payment.notaryPayment ? _c('p', [_vm._v(" Notaritasu maksab: " + _vm._s(_vm.object.payment.notaryPayment) + " ")]) : _vm._e(), _vm.object.payment.paysStateFee ? _c('p', [_vm._v(" Riigilõivu maksab: " + _vm._s(_vm.object.payment.paysStateFee) + " ")]) : _vm._e()]) : _vm._e(), _c('section', [_c('h3', [_vm._v("KONTAKTISIK/MAAKLER:")]), _c('p', [_vm._v("Nimi: " + _vm._s(_vm.broker.name))]), _c('p', [_vm._v("Telefon: " + _vm._s(_vm.broker.phoneNumber))]), _c('p', [_vm._v("E-post: " + _vm._s(_vm.broker.email))])])])])]), _vm.clientAddOpen ? _c('AddModal', {
    staticStyle: {
      "z-index": "1200"
    },
    attrs: {
      "accordion": true
    },
    on: {
      "newAdded": _vm.chooseNewClient,
      "closed": _vm.closeContactAdd
    }
  }) : _vm._e()], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }