<template>
  <div
    v-if="sellerIsLoaded"
    class="overlay-notarymodal"
    @keyup.esc="closeModal"
  >
    <div>
      <v-card class="modal-wrapper">
        <v-card class="form-wrapper" flat>
          <v-row class="input-wrapper" style="max-height: fit-content">
            <v-text-field
              v-model="notaryName"
              outlined
              :label="$t('NotaryName')"
              class="no-active"
            ></v-text-field>
            <v-row>
              <input
                v-model="transactionDate"
                id="input-112"
                type="date"
                class="date-class"
                name="session-date"
              />
              <label
                for="input-112"
                class="v-label v-label--active theme--light date-input"
                >{{ $t("TransactionDate") }}</label
              >
            </v-row>
            <v-text-field
              v-model="time"
              outlined
              :label="$t('Time')"
              class="no-active"
            ></v-text-field>
          </v-row>
        </v-card>
        <h5 class="modal-title">
          {{ $t("CustomerField.CustomerType.Options.SELLER") }}
        </h5>
        <div :key="sellersUpdater" style="width: 100%">
          <div
            v-for="(seller, index) in notaryDocument.sellers"
            :key="'seller' + index"
            style="padding-top: 12px"
          >
            <div class="gray-wrapper">
              <span
                style="margin-left: 380px"
                v-if="index !== 0"
                class="content-small clear-button"
                @click="notaryDocument.sellers.splice(index, 1)"
              >
                <img src="../../assets/images/close.svg" alt="" />
                {{ $t("Clear").toLowerCase() }}
              </span>

              <v-row style="z-index: 111">
                <ContactSearch
                  v-model="seller.contactPerson"
                  v-if="!fromAnotherPanel"
                  :placeholder="$t('FullName')"
                  :items="customers"
                  :chosen-object="seller.contactPerson"
                  @resultChosen="
                    (item) => setContactPerson(item, 'sellers', index)
                  "
                  @removeContact="removeContact(index)"
                  @addNew="openContactAdd('sellers', index)"
                  :class="
                    'no-active required ' +
                    (showErrors && !isFieldValid('name', 'sellers', index)
                      ? 'not-valid'
                      : '')
                  "
                />

                <v-autocomplete
                  v-if="fromAnotherPanel"
                  v-model="seller.contactPerson"
                  :items="customers"
                  item-text="customerName"
                  return-object
                  :label="$t('FullName')"
                >
                  <template v-slot:prepend-inner>
                    <img
                      width="20"
                      height="20"
                      alt="icon"
                      src="../../assets/images/user.svg"
                    />
                  </template>
                </v-autocomplete>
              </v-row>

              <v-text-field
                v-model="seller.personalCode"
                outlined
                :label="$t('CustomerField.personalCode')"
                :class="
                  'no-active required ' +
                  (showErrors && !isFieldValid('personalCode', 'sellers', index)
                    ? 'not-valid'
                    : '')
                "
              ></v-text-field>
              <v-text-field
                v-model="seller.address"
                outlined
                :label="$t('CustomerField.address')"
                :class="
                  'no-active required ' +
                  (showErrors && !isFieldValid('address', 'sellers', index)
                    ? 'not-valid'
                    : '')
                "
              ></v-text-field>
              <v-text-field
                v-model="seller.email"
                outlined
                :label="$t('CustomerField.email')"
                class="no-active"
              ></v-text-field>
              <v-text-field
                v-model="seller.phoneNumber"
                outlined
                :label="$t('CustomerField.phoneNumber')"
                class="no-active"
              ></v-text-field>
              <v-text-field
                v-model="seller.maritalStatus"
                outlined
                :label="$t('CustomerField.MaritalStatus')"
                :class="
                  'no-active required ' +
                  (showErrors &&
                  !isFieldValid('maritalStatus', 'sellers', index)
                    ? 'not-valid'
                    : '')
                "
              ></v-text-field>
            </div>
          </div>
        </div>
        <div class="add-another-button" @click="pushToList()">
          {{ "+ " + $t("AddAnother") }}
        </div>
        <h5 class="modal-title">
          {{ $t("CustomerField.CustomerType.Options.BUYER") }}
        </h5>
        <div :key="buyersUpdater">
          <div
            v-for="(buyer, index) in notaryDocument.buyers"
            :key="buyersUpdater[index]"
            style="padding-top: 12px"
          >
            <div class="gray-wrapper">
              <span
                v-if="index !== 0"
                class="content-small clear-button"
                @click="notaryDocument.buyers.splice(index, 1)"
              >
                <img src="../../assets/images/close.svg" alt="" />
                {{ $t("Clear").toLowerCase() }}
              </span>
              <v-row style="z-index: 111">
                <ContactSearch
                  v-model="buyer.contactPerson"
                  v-if="!fromAnotherPanel"
                  :placeholder="$t('FullName')"
                  :items="customers"
                  :chosen-object="buyer.contactPerson"
                  :already-chosen="sellerIsLoaded"
                  @resultChosen="
                    (item) => setContactPerson(item, 'buyers', index)
                  "
                  @removeContact="removeContact(index)"
                  @addNew="openContactAdd('buyers', index)"
                  :class="
                    'no-active required ' +
                    (showErrors && !isFieldValid('name', 'buyers', index)
                      ? 'not-valid'
                      : '')
                  "
                />

                <v-autocomplete
                  v-if="fromAnotherPanel"
                  v-model="buyer.contactPerson"
                  :items="customers"
                  item-text="customerName"
                  return-object
                  :label="$t('FullName')"
                >
                  <template v-slot:prepend-inner>
                    <img
                      width="20"
                      height="20"
                      alt="icon"
                      src="../../assets/images/user.svg"
                    />
                  </template>
                </v-autocomplete>
              </v-row>
              <v-text-field
                v-model="buyer.personalCode"
                outlined
                :label="$t('CustomerField.personalCode')"
                :class="
                  'no-active required ' +
                  (showErrors && !isFieldValid('personalCode', 'buyers', index)
                    ? 'not-valid'
                    : '')
                "
              ></v-text-field>
              <v-text-field
                v-model="buyer.address"
                outlined
                :label="$t('CustomerField.address')"
                :class="
                  'no-active required ' +
                  (showErrors && !isFieldValid('address', 'buyers', index)
                    ? 'not-valid'
                    : '')
                "
              ></v-text-field>
              <v-text-field
                v-model="buyer.email"
                outlined
                :label="$t('CustomerField.email')"
                class="no-active"
              ></v-text-field>
              <v-text-field
                v-model="buyer.phoneNumber"
                outlined
                :label="$t('CustomerField.phoneNumber')"
                class="no-active"
              ></v-text-field>
              <v-text-field
                v-model="buyer.maritalStatus"
                outlined
                :label="$t('CustomerField.MaritalStatus')"
                :class="
                  'no-active required ' +
                  (showErrors && !isFieldValid('maritalStatus', 'buyers', index)
                    ? 'not-valid'
                    : '')
                "
              ></v-text-field>
            </div>
          </div>
        </div>

        <div
          class="add-another-button"
          @click="
            errors.buyers.push(getContactEmptyErrors());
            notaryDocument.buyers.push(getEmptyPerson());
          "
        >
          {{ "+ " + $t("AddAnother") }}
        </div>
        <h5 class="modal-title">{{ $t("Object") }}</h5>
        <v-card class="form-wrapper" flat>
          <v-row class="input-wrapper" style="max-height: fit-content">
            <v-text-field
              v-model="object.address"
              outlined
              :label="$t('Address')"
              :class="
                'no-active required ' +
                (showErrors && !isFieldValid('address', 'object')
                  ? 'not-valid'
                  : '')
              "
            ></v-text-field>
            <v-text-field
              v-model="object.acquiredFrom"
              outlined
              :label="$t('AcquiredFrom')"
              :class="
                'no-active required ' +
                (showErrors && !isFieldValid('acquiredFrom', 'object')
                  ? 'not-valid'
                  : '')
              "
            ></v-text-field>
            <v-text-field
              v-model="object.estateNumber"
              outlined
              :label="$t('EstateNumber')"
              :class="
                'no-active required ' +
                (showErrors && !isFieldValid('estateNumber', 'object')
                  ? 'not-valid'
                  : '')
              "
            ></v-text-field>
          </v-row>
          <v-row class="input-wrapper apartment-input">
            <h6>{{ $t("ApartmentAssociation") }}</h6>
            <v-row class="input-wrapper" style="max-height: fit-content">
              <v-radio-group row v-model="object.apartmentAssociation">
                <v-radio
                  :label="$t('Yes')"
                  color="orange darken-3"
                  :value="'Jah'"
                ></v-radio>
                <v-radio
                  :label="$t('No')"
                  color="orange darken-3"
                  :value="'Ei'"
                ></v-radio>
              </v-radio-group>
            </v-row>
            <h6>{{ $t("UtilityBillDebts") }}</h6>
            <v-row class="input-wrapper" style="max-height: fit-content">
              <v-radio-group row v-model="object.communalPayments">
                <v-radio
                  :label="$t('Yes')"
                  color="orange darken-3"
                  :value="'on'"
                ></v-radio>
                <v-radio
                  :label="$t('No')"
                  color="orange darken-3"
                  :value="'ei ole'"
                ></v-radio>
              </v-radio-group>
              <v-text-field
                style="padding-bottom: 80px"
                v-if="object.communalPayments === 'on'"
                v-model="object.communalPaymentsAmount"
                outlined
                :label="$t('Amount')"
                class="no-active"
              ></v-text-field>
            </v-row>
            <h6>{{ $t("LeaseContracts") }}</h6>
            <v-row class="input-wrapper" style="max-height: fit-content">
              <v-radio-group row v-model="object.contracts">
                <v-radio
                  :label="$t('Yes')"
                  color="orange darken-3"
                  :value="'Jah'"
                ></v-radio>
                <v-radio
                  :label="$t('No')"
                  color="orange darken-3"
                  :value="'Ei'"
                ></v-radio>
              </v-radio-group>
            </v-row>
            <div v-if="object.contracts === 'Jah'">
              <v-row
                class="input-wrapper"
                style="max-height: fit-content; padding-bottom: 40px"
              >
                <v-row style="margin-bottom: -10px; width: 100%">
                  <input
                    v-model="object.rentedStartDate"
                    id="rentedStartDateInput"
                    type="date"
                    class="date-class"
                    name="session-date"
                  />
                  <label
                    for="rentedStartDateInput"
                    class="v-label v-label--active theme--light date-input"
                    >{{ $t("ContractStartDate") }}</label
                  >
                </v-row>
                <v-row style="margin-bottom: -10px; width: 100%">
                  <input
                    v-model="object.rentedEndDate"
                    id="rentedEndDateInput"
                    type="date"
                    class="date-class"
                    name="session-date"
                  />
                  <label
                    for="rentedEndDateInput"
                    class="v-label v-label--active theme--light date-input"
                    >{{ $t("ContractEndDate") }}</label
                  >
                </v-row>
                <v-row style="width: 100%">
                  <input
                    v-model="object.rightsToBuyer"
                    id="rightsToBuyerInput"
                    type="date"
                    class="date-class"
                    name="session-date"
                  />
                  <label
                    for="rightsToBuyerInput"
                    class="v-label v-label--active theme--light date-input"
                    >{{ $t("RightsToBuyer") }}</label
                  >
                </v-row>
                <v-text-field
                  v-model="object.receivedDeposit"
                  outlined
                  :label="$t('Deposit')"
                  class="no-active"
                ></v-text-field>
              </v-row>
            </div>
          </v-row>
          <h6>{{ $t("IsMortgaged") }}</h6>
          <v-radio-group row v-model="object.currentMortgage">
            <v-radio
              :label="$t('Yes')"
              color="orange darken-3"
              :value="'Jah'"
            ></v-radio>
            <v-radio
              :label="$t('No')"
              color="orange darken-3"
              :value="'Ei'"
            ></v-radio>
          </v-radio-group>
          <v-text-field
            style="padding-bottom: 90px"
            v-if="object.currentMortgage === 'Jah'"
            v-model="object.currentMortgageBank"
            outlined
            :label="$t('Bank')"
            class="no-active"
          ></v-text-field>
          <h6>{{ $t("Freed") }}</h6>
          <v-row class="input-wrapper" style="max-height: fit-content">
            <v-radio-group row v-model="object.beReleased">
              <v-radio
                :label="$t('Yes')"
                color="orange darken-3"
                :value="'Jah'"
              ></v-radio>
              <v-radio
                :label="$t('No')"
                color="orange darken-3"
                :value="'Ei'"
              ></v-radio>
            </v-radio-group>
            <v-row
              v-if="object.beReleased === 'Jah'"
              class="input-wrapper"
              style="padding-bottom: 30px"
            >
              <v-menu
                v-model="object.releaseDateChanged"
                class="no-active"
                :return-value="object.releaseDate"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <input
                    v-model="object.releaseDate"
                    type="date"
                    class="date-class"
                    v-bind="attrs"
                    style="width: 100%"
                    v-on="on"
                  />
                </template>
              </v-menu>
            </v-row>
          </v-row>
          <h6>{{ $t("WillBeMortgaged") }}</h6>
          <v-radio-group row v-model="object.willBeMortgaged">
            <v-radio
              :label="$t('Yes')"
              color="orange darken-3"
              :value="'Jah'"
            ></v-radio>
            <v-radio
              :label="$t('No')"
              color="orange darken-3"
              :value="'Ei'"
            ></v-radio>
          </v-radio-group>
          <div v-if="object.willBeMortgaged === 'Jah'">
            <v-row class="input-wrapper" style="max-height: fit-content">
              <v-text-field
                v-model="object.willBeMortgagedBank"
                outlined
                :label="$t('Bank')"
                class="no-active"
              ></v-text-field>
              <v-text-field
                style="padding-bottom: 80px"
                v-model="object.bankContact"
                outlined
                :label="$t('BankContact')"
                class="no-active"
              ></v-text-field>
            </v-row>
          </div>
          <h6>{{ $t("ComesWith") }}</h6>
          <v-textarea
            v-model="object.comesWith"
            no-resize
            outlined
          ></v-textarea>
          <h6>{{ $t("AnyShortcomings") }}</h6>
          <v-radio-group row v-model="object.anyShortcomings">
            <v-radio
              :label="$t('Yes')"
              color="orange darken-3"
              :value="'Jah'"
            ></v-radio>
            <v-radio
              :label="$t('No')"
              color="orange darken-3"
              :value="'Ei'"
            ></v-radio>
          </v-radio-group>
          <div v-if="object.anyShortcomings === 'Jah'">
            <v-textarea
              v-model="object.shortcomings"
              no-resize
              outlined
            ></v-textarea>
          </div>
          <h6>{{ $t("RegisteringAfterNotary") }}</h6>
          <v-row class="input-wrapper registering-input">
            <v-text-field
              v-model="object.registering"
              outlined
              class="no-active"
            ></v-text-field>
          </v-row>
          <h6>{{ $t("ObjectInsured") }}</h6>
          <v-row class="input-wrapper" style="max-height: fit-content">
            <v-radio-group row v-model="object.insured">
              <v-radio
                :label="$t('Yes')"
                color="orange darken-3"
                :value="'Jah'"
              ></v-radio>
              <v-radio
                :label="$t('No')"
                color="orange darken-3"
                :value="'Ei'"
              ></v-radio>
            </v-radio-group>
          </v-row>
          <h6>{{ $t("EnergyLabel") }}</h6>
          <v-row class="input-wrapper" style="margin-top: -16px">
            <v-select
              clearable
              v-model="object.energyLabel"
              :items="energyLabels"
              item-text="name"
              item-value="value"
              outlined
              :label="$t('EnergyLabel')"
              class="no-active select-input"
            ></v-select>
          </v-row>
          <h6>{{ $t("Documents") }}</h6>
          <v-row class="input-wrapper" style="max-height: fit-content">
            <v-checkbox
              v-model="object.documents.buildingPermit"
              :label="$t('BuildingPermit')"
              color="#FF5C01"
            ></v-checkbox>
            <v-checkbox
              v-model="object.documents.usePermit"
              :label="$t('UsePermit')"
              color="#FF5C01"
            ></v-checkbox>
            <v-checkbox
              v-model="object.documents.plans"
              :label="$t('Plans')"
              color="#FF5C01"
            ></v-checkbox>
          </v-row>
          <h6>{{ $t("AccessThroughPublicRoad") }}</h6>
          <v-row>
            <v-radio-group row v-model="object.publicAccess">
              <v-radio
                :label="$t('Yes')"
                color="orange darken-3"
                :value="'Jah'"
              ></v-radio>
              <v-radio
                :label="$t('No')"
                color="orange darken-3"
                :value="'Ei'"
              ></v-radio>
            </v-radio-group>
          </v-row>
          <v-radio-group
            v-if="object.publicAccess === 'Ei'"
            column
            v-model="object.noPublicAccess"
          >
            <v-radio
              :label="$t('ThroughNeighbor')"
              color="orange darken-3"
              :value="'Läbi naaberkinnistu'"
            ></v-radio>
            <v-radio
              :label="$t('Servitute')"
              color="orange darken-3"
              :value="'Servituuti on'"
            ></v-radio>
            <v-radio
              :label="$t('NotAssigned')"
              color="orange darken-3"
              :value="'Ei ole seatud'"
            ></v-radio>
          </v-radio-group>
          <h6>{{ $t("Utilities") }}</h6>
          <v-textarea
            v-model="object.communications"
            no-resize
            outlined
          ></v-textarea>
          <h6>{{ $t("DetailedPlanning") }}</h6>
          <v-row style="margin-top: -4px">
            <v-radio-group row v-model="object.detailPlan">
              <v-radio
                :label="$t('Yes')"
                color="orange darken-3"
                :value="'Jah'"
              ></v-radio>
              <v-radio
                :label="$t('No')"
                color="orange darken-3"
                :value="'Ei'"
              ></v-radio>
            </v-radio-group>
          </v-row>
        </v-card>
        <v-card class="form-wrapper" flat>
          <h5>{{ $t("Price") }}</h5>
          <v-row
            class="input-wrapper"
            style="max-height: fit-content; margin-top: -16px"
          >
            <v-text-field
              v-model="object.payment.price"
              :label="$t('Price')"
              :class="
                'no-active select-input required ' +
                (showErrors && !isFieldValid('price', 'object')
                  ? 'not-valid'
                  : '')
              "
              outlined
            ></v-text-field>
          </v-row>
          <h6>{{ $t("PaidFor") }}</h6>
          <v-row class="input-wrapper paid-to-input">
            <v-text-field
              v-model="object.payment.paidTo"
              outlined
              :label="$t('ToWho')"
              class="no-active"
            ></v-text-field>
            <v-text-field
              v-model="object.payment.reservationPayment"
              outlined
              :label="$t('Sum')"
              class="no-active"
            ></v-text-field>
          </v-row>
          <h6>{{ $t("PayForRest") }}</h6>
          <v-row class="input-wrapper" style="max-height: fit-content">
            <v-checkbox
              v-model="object.payment.cash"
              :label="$t('Cash')"
              color="#FF5C01"
            ></v-checkbox>
            <v-checkbox
              v-model="object.payment.notaryDeposit"
              :label="$t('NotaryDeposit')"
              color="#FF5C01"
            ></v-checkbox>
            <v-checkbox
              v-model="object.payment.bankLoan"
              :label="$t('FromBankLoan')"
              color="#FF5C01"
            ></v-checkbox>
            <v-row
              class="input-wrapper"
              style="max-height: fit-content; padding-bottom: 20px"
              v-if="object.payment.bankLoan === true"
            >
              <v-text-field
                v-model="object.payment.bankLoanBank"
                outlined
                :label="$t('Bank')"
                class="no-active"
              ></v-text-field>
            </v-row>
          </v-row>
          <h6>{{ $t("SelfFinanced") }}</h6>
          <v-row class="input-wrapper extra-input">
            <v-text-field
              v-model="object.payment.notaryBank"
              outlined
              :label="$t('NotaryBank')"
              class="no-active"
            ></v-text-field>
            <v-text-field
              v-model="object.payment.notaryBankAccount"
              outlined
              :label="$t('NotaryBankAccount')"
              class="no-active"
            ></v-text-field>
          </v-row>
          <h6>{{ $t("NotaryDepositUse") }}</h6>
          <v-radio-group row v-model="object.payment.usesNotaryDeposit">
            <v-radio
              :label="$t('Yes')"
              color="orange darken-3"
              :value="'Jah'"
            ></v-radio>
            <v-radio
              :label="$t('No')"
              color="orange darken-3"
              :value="'Ei'"
            ></v-radio>
          </v-radio-group>
          <h6>{{ $t("MoneyMovesTo") }}</h6>
          <v-row class="input-wrapper extra-input">
            <span>{{ $t("ForLoanPayment") }}</span>
            <v-text-field
              v-model="object.payment.returnLoanBank"
              outlined
              :label="$t('Bank')"
              class="no-active"
            ></v-text-field>
            <v-text-field
              v-model="object.payment.assignedAccountByBank"
              outlined
              :label="$t('AccountNumber')"
              class="no-active"
            ></v-text-field>
            <span style="margin-top: 10px">{{ $t("RestPaidForward") }}</span>
            <v-text-field
              v-model="object.payment.paymentForwardName"
              outlined
              :label="$t('AccountName')"
              class="no-active"
            ></v-text-field>
            <v-text-field
              v-model="object.payment.paymentForwardAccount"
              outlined
              :label="$t('AccountNumber')"
              class="no-active"
            ></v-text-field>
          </v-row>
          <h6>{{ $t("NotaryFeePaidBy") }}</h6>
          <v-row>
            <v-radio-group row v-model="object.payment.notaryPayment">
              <v-radio
                :label="$t('HalfHalf')"
                color="orange darken-3"
                :value="'50/50'"
              ></v-radio>
              <v-radio
                :label="$t('BuyerPays')"
                color="orange darken-3"
                :value="'100% ostja'"
              ></v-radio>
              <v-radio
                :label="$t('SellBuyHalved')"
                color="orange darken-3"
                :value="'Ost-müük pooleks'"
              ></v-radio>
            </v-radio-group>
          </v-row>
          <h6>{{ $t("StateFeePaidBy") }}</h6>
          <v-row>
            <v-radio-group row v-model="object.payment.paysStateFee">
              <v-radio
                :label="$t('PartyOfInterest')"
                color="orange darken-3"
                :value="'Osapool kelle huvides kanne tehakse'"
              ></v-radio>
              <v-radio
                :label="$t('BuyerPays')"
                color="orange darken-3"
                :value="'100% ostja'"
              ></v-radio>
              <v-radio
                :label="$t('SellerPays')"
                color="orange darken-3"
                :value="'100% müüja'"
              ></v-radio>
            </v-radio-group>
          </v-row>
        </v-card>
        <v-card class="form-wrapper" flat>
          <h5>{{ $t("ContactPerson2") }}/{{ $t("Broker") }}</h5>
          <v-row class="input-wrapper extra-input">
            <v-text-field
              v-model="broker.name"
              outlined
              :label="$t('FullName')"
              :class="
                'no-active required ' +
                (showErrors && !isFieldValid('name', 'broker')
                  ? 'not-valid'
                  : '')
              "
            ></v-text-field>
            <v-text-field
              v-model="broker.phoneNumber"
              outlined
              :label="$t('Phone')"
              :class="
                'no-active required ' +
                (showErrors && !isFieldValid('phoneNumber', 'broker')
                  ? 'not-valid'
                  : '')
              "
            ></v-text-field>
            <v-text-field
              v-model="broker.email"
              outlined
              :label="$t('CustomerField.email')"
              :class="
                'no-active required ' +
                (showErrors && !isFieldValid('email', 'broker')
                  ? 'not-valid'
                  : '')
              "
            ></v-text-field>
          </v-row>
        </v-card>
        <div class="buttons-container">
          <v-divider class="buttons-divider"></v-divider>
          <v-row class="btns-container-buttons">
            <button class="transparent-button" @click="save">
              {{ $t("SaveAndExit") }}
            </button>
            <button
              class="cancel-button"
              style="margin-right: -40px"
              @click="closeModal()"
            >
              {{ $t("Cancel") }}
            </button>
            <button
              name="generate-doc-button"
              class="generate-doc-button"
              @click="validateAndGenerate"
            >
              <img
                style="padding-right: 8px"
                src="../../assets/images/generate-file-icon.svg"
                alt=""
              />
              {{ $t("GenerateDocument") }}
            </button>
          </v-row>
        </div>
      </v-card>
    </div>
    <VueHtml2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="showPreview"
      :paginate-elements-by-height="1400"
      filename="notary-base-doc"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="800px"
      ref="html2Pdf"
      @hasDownloaded="hasDownloaded($event)"
    >
      <section id="pdf-content" slot="pdf-content" class="pdf-content">
        <h3 style="text-align: center">{{ $t("NotaryBaseDocTitle") }}</h3>
        <section
          class="pdf-sections"
          v-if="notaryName || transactionDate || time"
        >
          <p v-if="notaryName">Notar: {{ notaryName }}</p>
          <p v-if="transactionDate">Tehingu kuupäev: {{ transactionDate }}</p>
          <p v-if="time">Kellaaeg: {{ time }}</p>
        </section>
        <section class="pdf-sections">
          <h3>MÜÜJA:</h3>
          <div
            style="margin-top: 8px"
            v-for="(seller, index) in notaryDocument.sellers"
            :key="'pdfseller' + index"
          >
            <p>{{ seller.name }}, isikukood {{ seller.personalCode }}</p>
            <p>Perekonnaseis: {{ seller.maritalStatus }}</p>
            <p>Elukoht: {{ seller.address }}</p>
            <p v-if="seller.email">Müüja e-post: {{ seller.email }}</p>
            <p v-if="seller.phoneNumber">Mob: {{ seller.phoneNumber }}</p>
          </div>
        </section>
        <section class="pdf-sections">
          <h3>OSTJA:</h3>
          <div
            style="margin-top: 8px"
            v-for="(buyer, index) in notaryDocument.buyers"
            :key="'pdfBuyer' + index"
          >
            <p>{{ buyer.name }}, isikukood {{ buyer.personalCode }}</p>
            <p>Perekonnaseis: {{ buyer.maritalStatus }}</p>
            <p>Elukoht: {{ buyer.address }}</p>
            <p v-if="buyer.email">Müüja e-post: {{ buyer.email }}</p>
            <p v-if="buyer.phoneNumber">Mob: {{ buyer.phoneNumber }}</p>
          </div>
        </section>
        <section class="pdf-sections">
          <h3>OBJEKT:</h3>
          <p>Aadress {{ object.address }}</p>
          <p>Kuidas omandatud: {{ object.acquiredFrom }}</p>
          <p>Kinnistu reg. nr: {{ object.estateNumber }}</p>
        </section>
        <section
          class="pdf-sections"
          v-if="object.apartmentAssociation || object.communalPayments"
        >
          <p v-if="object.apartmentAssociation">
            Korteriühistu: {{ object.apartmentAssociation }}
          </p>
          <p v-if="object.communalPayments">
            Kommunaalmaksete võlgnevused? {{ object.communalPayments }}
            <span v-if="object.communalPayments === 'on'">
              summas {{ object.communalPaymentsAmount }}
            </span>
          </p>
        </section>
        <section
          class="pdf-sections"
          v-if="
            object.contracts ||
            object.communalPayments ||
            object.currentMortgage ||
            object.beReleased ||
            object.willBeMortgaged
          "
        >
          <span v-if="object.contracts === 'Ei'">
            Kas on üüri/rendilepinguid? {{ object.contracts }}
          </span>
          <div v-if="object.contracts === 'Jah'">
            <span> Kas on üüri/rendilepinguid? {{ object.contracts }} </span>
            <span v-if="object.rentedStartDate">
              sõlmitud {{ object.rentedStartDate }}
            </span>
            <span v-if="object.rentedEndDate">
              , tähtajaga {{ object.rentedEndDate }}
            </span>
            <span v-if="object.receivedDeposit">
              , üürilepingu alusel saadud tagatisraha
              {{ object.receivedDeposit }}
            </span>
            <span v-if="object.communalPayments">
              , üürimaksete, kommunaalmaksete võlgnevusi
              {{ object.communalPayments }}
            </span>
            <span v-if="object.communalPayments === 'on'">
              summas {{ object.communalPaymentsAmount }}
            </span>
            <span v-if="object.rightsToBuyer">
              , ostjale läheb üüri saamise õigus üle {{ object.rightsToBuyer }}
            </span>
            <p v-if="object.currentMortgage">
              Kas on koormatud hüpoteegiga? {{ object.currentMortgage }}
              <span v-if="object.currentMortgage === 'Jah'">
                , {{ object.currentMortgageBank }} pank
              </span>
            </p>
            <p v-if="object.beReleased">
              Kas ja millal vabastatakse - {{ object.beReleased }}, hiljemalt
              {{ object.releaseDate }}
            </p>
            <p v-if="object.willBeMortgaged">
              Kas koormatakse hüpoteegiga - {{ object.willBeMortgaged }}
              <span v-if="object.willBeMortgaged === 'Jah'">
                {{ object.willBeMortgagedBank }}, kontaktisik pangas
                {{ object.bankContact }}
              </span>
            </p>
          </div>
        </section>
        <section class="pdf-sections" v-if="object.comesWith">
          <p>Täpne asjade loetelu mis ostja objektiga kaasa saab:</p>
          <p>{{ object.comesWith }}</p>
        </section>
        <div class="html2pdf__page-break" />
        <section style="padding-top: 6.4%">
          <section
            class="pdf-sections"
            v-if="
              object.anyShortcomings ||
              object.registering ||
              object.insured ||
              object.energyLabel ||
              object.documents ||
              object.publicAccess ||
              object.communications ||
              object.detailPlan
            "
          >
            <p v-if="object.anyShortcomings">
              Kas on puuduseid, milliest soovib Müüja ostjat teavitada -
              {{ object.anyShortcomings }}
              <span v-if="object.anyShortcomings === 'Jah'">
                {{ object.shortcomings }}
              </span>
            </p>
            <p v-if="object.registering">
              Välja registreerimine - {{ object.registering }} jooksul peale
              notari lepingut.
            </p>
            <p v-if="object.insured">
              Kas objekt on kindlustatud, {{ object.insured }}
            </p>
            <p v-if="object.energyLabel">
              Energiamärgis: {{ object.energyLabel }}
            </p>
            <p
              v-if="
                object.documents.buildingPermit ||
                object.documents.usePermit ||
                object.documents.plans
              "
            >
              Dokumendid:
              <span v-if="object.documents.buildingPermit === true">
                {{ $t("BuildingPermit") }}
              </span>
              <span v-if="object.documents.usePermit === true">
                {{ $t("UsePermit") }}
              </span>
              <span v-if="object.documents.plans === true">
                {{ $t("Plans") }}
              </span>
            </p>
            <p v-if="object.publicAccess">
              Ligipääs avalikult kasutatavalt teelt: {{ object.publicAccess }}
              <span v-if="object.publicAccess === 'Ei'">
                , {{ object.noPublicAccess }}
              </span>
            </p>
            <p v-if="object.communications">
              Kommunikatsioonid: {{ object.communications }}
            </p>
            <p v-if="object.detailPlan">
              Detailplaneering kehtestatud: {{ object.detailPlan }}
            </p>
          </section>
          <section class="pdf-sections">
            <h3>HIND: {{ object.payment.price }}</h3>
            <p v-if="object.payment.reservationPayment">
              Sellest tasutud: {{ object.payment.reservationPayment }} eurot
              broneerimistasuna {{ object.payment.paidTo }}
            </p>
            <p
              v-if="
                object.payment.cash ||
                object.payment.notaryDeposit ||
                object.payment.bankLoan
              "
            >
              Ülejäänud tasutakse:
              <span v-if="object.payment.cash === true"> sularahas </span>
              <span v-if="object.payment.notaryDeposit === true">
                notari deposiiti
              </span>
              <span v-if="object.payment.bankLoan === true">
                {{ object.payment.bankLoanBank }} panga laenust
              </span>
            </p>
            <p v-if="object.payment.notaryBank">
              Omafinantseeringu summa: palun edastada notari
              {{ object.payment.notaryBank }} panga deposiitkonto nr.
              {{ object.payment.notaryBankAccount }}
            </p>
            <p>
              Kas kasutatakse notari deposiitkontot -
              {{ object.payment.usesNotaryDeposit }}
            </p>
          </section>
          <section
            class="pdf-sections"
            v-if="
              object.payment.returnLoanBank || object.payment.paymentForwardName
            "
          >
            <span v-if="object.payment.returnLoanBank">Raha liigub edasi:</span>
            <span
              >+ Müüja laenu tagastamiseks
              {{ object.payment.returnLoanBank }} panga poolt näidatud kontole
              {{ object.payment.assignedAccountByBank }}
            </span>
            <p v-if="object.payment.paymentForwardName">
              + Müüja arveldusarvele järele jäänud summa kantakse:
              {{ object.payment.paymentForwardName }} kontole nr
              {{ object.payment.paymentForwardAccount }}
            </p>
          </section>
          <section
            class="pdf-sections"
            v-if="object.payment.notaryPayment || object.payment.paysStateFee"
          >
            <p v-if="object.payment.notaryPayment">
              Notaritasu maksab: {{ object.payment.notaryPayment }}
            </p>
            <p v-if="object.payment.paysStateFee">
              Riigilõivu maksab: {{ object.payment.paysStateFee }}
            </p>
          </section>
          <section>
            <h3>KONTAKTISIK/MAAKLER:</h3>
            <p>Nimi: {{ broker.name }}</p>
            <p>Telefon: {{ broker.phoneNumber }}</p>
            <p>E-post: {{ broker.email }}</p>
          </section>
        </section>
      </section>
    </VueHtml2pdf>
    <AddModal
      style="z-index: 1200"
      @newAdded="chooseNewClient"
      @closed="closeContactAdd"
      :accordion="true"
      v-if="clientAddOpen"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import ContactSearch from "@/components/common/ContactSearch";
import VueHtml2pdf from "vue-html2pdf";
import AddModal from "@/components/Kliendiregister/AddModal";

export default {
  name: "NotaryBaseDocSidepanel",
  components: {
    ContactSearch,
    VueHtml2pdf,
    AddModal,
  },
  props: ["deal", "fromAnotherPanel"],
  data() {
    return {
      sellerIsLoaded: false,
      formData: new FormData(),
      notaryName: null,
      transactionDate: null,
      time: null,
      estateNumberFromListing: null,
      sellersUpdater: 0,
      buyersUpdater: 100,
      clientAddOpen: false,
      notaryDocument: this.getEmptySellersAndBuyers(),
      showErrors: false,
      validate: {
        sellers: ["name", "personalCode", "address", "maritalStatus"],
        buyers: ["name", "personalCode", "address", "maritalStatus"],
        object: ["address", "acquiredFrom", "estateNumber", "price"],
        broker: ["name", "phoneNumber", "email"],
      },
      errors: null,
      object: {
        address: null,
        acquiredFrom: null,
        estateNumber: null,
        apartmentAssociation: null,
        activeLeases: null,
        communalPayments: null,
        communalPaymentsAmount: null,
        contracts: null,
        currentMortgage: null,
        currentMortgageBank: null,
        beReleased: null,
        releaseDateChanged: false,
        releaseDate: null,
        willBeMortgaged: null,
        willBeMortgagedBank: null,
        bankContact: null,
        comesWith: null,
        anyShortcomings: null,
        shortcomings: null,
        registering: null,
        insured: null,
        documents: {
          buildingPermit: null,
          usePermit: null,
          plans: null,
        },
        publicAccess: null,
        noPublicAccess: null,
        communications: null,
        detailPlan: null,
        payment: {
          price: null,
          reservationPayment: null,
          paidTo: null,
          cash: null,
          notaryDeposit: null,
          bankLoan: null,
          bankLoanBank: null,
          usesNotaryDeposit: null,
          notaryBank: null,
          notaryBankAccount: null,
          returnLoanBank: null,
          assignedAccountByBank: null,
          paymentForwardName: null,
          paymentForwardAccount: null,
          notaryPayment: null,
          paysStateFee: null,
        },
        rentedStartDate: null,
        startDateChanged: false,
        rentedEndDate: null,
        endDateChanged: false,
        receivedDeposit: null,
        rightsToBuyer: null,
        rightsDateChanged: false,
        energyLabel: null,
      },
      broker: {
        name: null,
        phoneNumber: null,
        email: null,
      },
      energyLabels: [
        { name: "A", value: "Energia erikasutuse klass A" },
        { name: "B", value: "Energia erikasutuse klass B" },
        { name: "C", value: "Energia erikasutuse klass C" },
        { name: "D", value: "Energia erikasutuse klass D" },
        { name: "E", value: "Energia erikasutuse klass E" },
        { name: "F", value: "Energia erikasutuse klass F" },
        { name: "G", value: "Energia erikasutuse klass G" },
        { name: "H", value: "Energia erikasutuse klass H" },
        { name: this.$t("NoCertificate"), value: "Energiamärgis puudub" },
      ],
    };
  },
  async created() {
    this.getBrokerInfo();
    this.getObjectInfo();
    this.getCustomers();

    if (this.deal.notaryDocuments && this.deal.notaryDocuments.length > 0) {
      this.notaryDocument = this.deal.notaryDocuments[0];
      this.sellerIsLoaded = true;
    } else {
      await this.initData();
    }
    this.errors = this.getEmptyErrors();

    document.onkeydown = (evt) => {
      evt = evt || window.event;
      if (evt.key === "Escape") {
        this.closeModal();
        document.onkeydown = null;
      }
    };
  },
  mounted() {
    document.documentElement.style.overflow = "hidden";
    window.onclick = (e) => {
      if (e.target.classList[0] === "overlay-notarymodal") {
        this.closeModal();
        this.$emit("panelClosed");
        e.target.style.display = "none";
      }
    };
  },
  beforeDestroy() {
    document.documentElement.style.overflow = "visible";
  },
  computed: {
    ...mapGetters(["customers", "user"]),
    showPreview() {
      return !this.$browserDetect.isSafari;
    },
  },
  watch: {},
  methods: {
    ...mapActions(["changeModalStateDeals", "getCustomers"]),
    hasDownloaded($event) {
      const file = new File([$event], "notary-base-doc.pdf", {
        type: "application/pdf",
      });
      this.formData.append("file", file);
      this.uploadFiles();
      this.closeModal();
      this.$emit("panelClosed");
    },
    pushToList() {
      this.errors.sellers.push(this.getContactEmptyErrors());
      this.notaryDocument.sellers.push(this.getEmptyPerson());
    },
    async initData() {
      await Promise.all([this.initSeller(), this.initObject()]);
    },
    async updateObjectInfo() {
      await axios.post("/api/project/update-from-base-document", {
        dealId: this.deal._id,
        object: this.object,
        notaryName: this.notaryName,
        transactionDate: this.transactionDate,
        time: this.time,
      });
      if (!this.estateNumberFromListing) {
        await axios.post(
          `/api/listing/edit/estatenumber/${this.deal.objects[0].listings[0]}`,
          {
            listingId: this.deal.objects[0].listings[0],
            estateNumber: this.object.estateNumber,
          }
        );
      }
    },
    openContactAdd(field, index) {
      this.obsContactField = field;
      this.obsContactIndex = index;

      let panel = document.getElementById("panel-wrapper-notary-document");
      let panelContent = document.getElementById("content-wrapper");
      let overlay = document.getElementById("overlay-notary-document");
      panelContent.style.display = "none";
      panel.style.overflow = "hidden";
      panel.style.minWidth = "94px";
      panel.style.width = "94px";
      panel.style.right = "558px";
      panel.style.zIndex = "121";
      //panel.style.boxShadow = "0px 0 1px rgba(28, 14, 3, 0.2)";
      overlay.style.zIndex = "5";

      let wrappedHeader = document.getElementById(
        "wrapped-header-notary-document"
      );
      wrappedHeader.style.display = "flex";
      wrappedHeader.style.opacity = "1";
      this.clientAddOpen = true;
    },
    getEmptySellersAndBuyers() {
      return {
        dealId: this.deal._id,
        sellers: [this.getEmptyPerson()],
        buyers: [this.getEmptyPerson()],
        object: {
          address: null,
          acquiredFrom: null,
          estateNumber: null,
          apartmentAssociation: null,
          activeLeases: null,
          communalPayments: null,
          communalPaymentsAmount: null,
          contracts: null,
          currentMortgage: null,
          currentMortgageBank: null,
          beReleased: null,
          releaseDateChanged: false,
          releaseDate: null,
          willBeMortgaged: null,
          willBeMortgagedBank: null,
          bankContact: null,
          comesWith: null,
          anyShortcomings: null,
          shortcomings: null,
          registering: null,
          insured: null,
          documents: {
            buildingPermit: null,
            usePermit: null,
            plans: null,
          },
          publicAccess: null,
          noPublicAccess: null,
          communications: null,
          detailPlan: null,
          payment: {
            price: null,
            reservationPayment: null,
            paidTo: null,
            cash: null,
            notaryDeposit: null,
            bankLoan: null,
            bankLoanBank: null,
            usesNotaryDeposit: null,
            notaryBank: null,
            notaryBankAccount: null,
            returnLoanBank: null,
            assignedAccountByBank: null,
            paymentForwardName: null,
            paymentForwardAccount: null,
            notaryPayment: null,
            paysStateFee: null,
          },
          rentedStartDate: null,
          startDateChanged: false,
          rentedEndDate: null,
          endDateChanged: false,
          receivedDeposit: null,
          rightsToBuyer: null,
          rightsDateChanged: false,
          energyLabel: null,
        },
        broker: {
          name: null,
          phoneNumber: null,
          email: null,
        },
      };
    },
    getEmptyPerson() {
      return {
        _id: null,
        name: null,
        customerName: null,
        personalCode: null,
        address: null,
        email: null,
        phoneNumber: null,
        maritalStatus: null,
        registerCode: null,
        contactPerson: null,
      };
    },
    getEmptyErrors() {
      let errors = {
        buyers: [this.getContactEmptyErrors()],
        sellers: [this.getContactEmptyErrors()],
        object: this.getObjectEmptyErrors(),
        broker: this.getBrokerEmptyErrors(),
      };

      for (let i = 1; i < this.notaryDocument.sellers.length; i++) {
        errors.sellers.push(this.getContactEmptyErrors());
      }
      for (let i = 1; i < this.notaryDocument.buyers.length; i++) {
        errors.buyers.push(this.getContactEmptyErrors());
      }
      return errors;
    },
    getContactEmptyErrors() {
      return {
        name: "",
        personalCode: "",
        address: "",
        maritalStatus: "",
      };
    },
    getObjectEmptyErrors() {
      return {
        address: "",
        acquiredFrom: "",
        estateNumber: "",
        price: "",
      };
    },
    getBrokerEmptyErrors() {
      return {
        name: "",
        phoneNumber: "",
        email: "",
      };
    },
    async initSeller() {
      const response = await axios.get(
        `/api/contact/${this.deal.contactPerson.contactId}`
      );

      const seller = response.data;

      this.notaryDocument.sellers[0] = {
        _id: seller._id,
        name: seller.customerName,
        customerName: seller.customerName,
        personalCode: seller.personalCode,
        address: seller.address,
        email: seller.email,
        phoneNumber: seller.phoneNumber,
        maritalStatus: seller.maritalStatus,
        contactPerson: {
          customerId: seller.customerId,
          customerName: seller.customerName,
        },
        registerCode: null,
      };
      this.initContactPerson(
        this.notaryDocument["sellers"][0]["contactPerson"],
        "sellers",
        0
      );
    },
    async initContactPerson(contactPerson, parentField, arrayIndex) {
      contactPerson.contactId = contactPerson.customerId;
      contactPerson.contactName = contactPerson.customerName;
      const response = await axios.get(
        `/api/contact/${contactPerson.customerId}`
      );
      let person = response.data;

      this.notaryDocument[parentField][arrayIndex] = {
        _id: person._id,
        name: person.customerName,
        customerName: person.customerName,
        personalCode: person.personalCode,
        address: person.address,
        email: person.email,
        phoneNumber: person.phoneNumber,
        maritalStatus: person.maritalStatus,
        contactPerson: contactPerson,
        registerCode: contactPerson.reg_code,
      };

      if (parentField === "sellers") {
        this.sellerIsLoaded = true;
        this.sellersUpdater++;
      } else if (parentField === "buyers") {
        this.buyersUpdater++;
      }
    },
    closeModal() {
      this.$emit("panelClosed");
    },
    removeContact() {
      this.buyer.name = null;
    },
    generatePdf() {
      this.$refs.html2Pdf.generatePdf();
    },
    async save() {
      await axios.post("/api/project/notary-document", this.notaryDocument);
      this.toastSuccess(this.$t("DocumentSaves"));
      this.updateObjectInfo();
      this.closeModal();
    },
    isFormValid() {
      this.errors = this.getEmptyErrors();

      let _errors = [];

      this.validate.sellers.map((field) => {
        for (let i = 0; i < this.notaryDocument.sellers.length; i++) {
          _errors.push(this.isFieldValid(field, "sellers", i));
        }
      });

      this.validate.buyers.map((field) => {
        for (let i = 0; i < this.notaryDocument.buyers.length; i++) {
          _errors.push(this.isFieldValid(field, "buyers", i));
        }
      });

      this.validate.object.map((field) => {
        _errors.push(this.isFieldValid(field, "object"));
      });

      this.validate.broker.map((field) => {
        _errors.push(this.isFieldValid(field, "broker"));
      });

      return _errors.every((item) => item === true);
    },
    isFieldValid(fieldName, parentField, arrayIndex) {
      let fieldValue = null;
      if (arrayIndex !== undefined) {
        fieldValue = this.notaryDocument[parentField][arrayIndex][fieldName];
      } else {
        fieldValue = this.notaryDocument[parentField]
          ? this.notaryDocument[parentField][fieldName]
          : "";
        if (parentField === "object") {
          if (fieldName === "price") {
            fieldValue = this.object.payment[fieldName];
          } else {
            fieldValue = this.object[fieldName];
          }
        }
        if (parentField === "broker") {
          fieldValue = this.broker[fieldName];
        }
      }

      if (!fieldValue) {
        if (arrayIndex) {
          this.errors[parentField][arrayIndex][fieldName] = "FieldRequired";
        } else if (parentField) {
          if (this.errors[parentField]) {
            this.errors[parentField][fieldName] = "FieldRequired";
          }
        } else {
          this.errors[fieldName] = "FieldRequired";
        }
        return false;
      }
      return true;
    },
    async validateAndGenerate() {
      if (!this.isFormValid()) {
        this.showErrors = true;
        this.toastError(this.$t("FormErrors.AllFieldsMustBeFilled"));
        return;
      }

      await axios.post("/api/project/notary-document", this.notaryDocument);
      this.toastSuccess(this.$t("DocumentSaves"));
      this.updateObjectInfo();
      this.generatePdf();
    },
    async getObjectInfo() {
      const extrasResponse = await axios.get(
        `/api/project/extras/${this.deal._id}`
      );
      const listingResponse = await axios.get(
        `/api/listing/${this.deal.objects[0].listings[0]}`
      );

      const extras = extrasResponse.data.extras[0].extras;
      const listingPrice = listingResponse.data[0].price;

      this.object.address = this.deal.objects[0].address;
      this.estateNumberFromListing = listingResponse.data[0].estateNumber;
      this.object.estateNumber = listingResponse.data[0].estateNumber;
      if (extras) {
        if (extras.object.price !== listingPrice) {
          this.object.payment.price = extras.object.price;
        } else {
          this.object.payment.price = listingPrice;
        }
        this.object.acquiredFrom = extras.object.acquiredFrom;
        if (this.estateNumberFromListing) {
          this.object.estateNumber = this.estateNumberFromListing;
        } else {
          this.object.estateNumber = extras.object.estateNumber;
        }
        this.object.apartmentAssociation = extras.object.apartmentAssociation;
        this.object.activeLeases = extras.object.activeLeases;
        this.object.communalPayments = extras.object.communalPayments;
        this.object.communalPaymentsAmount =
          extras.object.communalPaymentsAmount;
        this.object.contracts = extras.object.contracts;
        this.object.currentMortgage = extras.object.currentMortgage;
        this.object.currentMortgageBank = extras.object.currentMortgageBank;
        this.object.beReleased = extras.object.beReleased;
        this.object.releaseDateChanged = extras.object.releaseDateChanged;
        this.object.releaseDate = extras.object.releaseDate;
        this.object.willBeMortgaged = extras.object.willBeMortgaged;
        this.object.willBeMortgagedBank = extras.object.willBeMortgagedBank;
        this.object.bankContact = extras.object.bankContact;
        this.object.comesWith = extras.object.comesWith;
        this.object.anyShortcomings = extras.object.anyShortcomings;
        this.object.shortcomings = extras.object.shortcomings;
        this.object.registering = extras.object.registering;
        this.object.insured = extras.object.insured;
        this.object.documents.buildingPermit = extras.object.buildingPermit;
        this.object.documents.usePermit = extras.object.usePermit;
        this.object.documents.plans = extras.object.plans;
        this.object.publicAccess = extras.object.publicAccess;
        this.object.noPublicAccess = extras.object.noPublicAccess;
        this.object.communications = extras.object.communications;
        this.object.detailPlan = extras.object.detailPlan;
        this.object.payment.reservationPayment =
          extras.object.reservationPayment;
        this.object.payment.paidTo = extras.object.paidTo;
        this.object.payment.cash = extras.object.cash;
        this.object.payment.notaryDeposit = extras.object.notaryDeposit;
        this.object.payment.bankLoan = extras.object.bankLoan;
        this.object.payment.bankLoanBank = extras.object.bankLoanBank;
        this.object.payment.usesNotaryDeposit = extras.object.usesNotaryDeposit;
        this.object.payment.notaryBank = extras.object.notaryBank;
        this.object.payment.notaryBankAccount = extras.object.notaryBankAccount;
        this.object.payment.returnLoanBank = extras.object.returnLoanBank;
        this.object.payment.assignedAccountByBank =
          extras.object.assignedAccountByBank;
        this.object.payment.paymentForwardName =
          extras.object.paymentForwardName;
        this.object.payment.paymentForwardAccount =
          extras.object.paymentForwardAccount;
        this.object.payment.notaryPayment = extras.object.notaryPayment;
        this.object.payment.paysStateFee = extras.object.paysStateFee;
        this.object.rentedStartDate = extras.object.rentedStartDate;
        this.object.rentedEndDate = extras.object.rentedEndDate;
        this.object.receivedDeposit = extras.object.receivedDeposit;
        this.object.rightsToBuyer = extras.object.rightsToBuyer;
        this.object.energyLabel = extras.object.energyLabel;
        this.notaryName = extras.otherInfo.notaryName;
        this.transactionDate = extras.otherInfo.transactionDate;
        this.time = extras.otherInfo.time;
      }
    },
    async setContactPerson(contactPerson, parentField, arrayIndex) {
      const response = await axios.get(
        `/api/contact/${contactPerson.contactId}`
      );
      let person = response.data;

      this.notaryDocument[parentField][arrayIndex] = {
        _id: person._id,
        name: person.customerName,
        customerName: person.customerName,
        personalCode: person.personalCode,
        address: person.address,
        email: person.email,
        phoneNumber: person.phoneNumber,
        maritalStatus: person.maritalStatus,
        contactPerson: contactPerson,
        registerCode: contactPerson.reg_code,
      };

      if (parentField === "sellers") {
        this.sellersUpdater++;
      } else if (parentField === "buyers") {
        this.buyersUpdater++;
      }
    },
    async chooseNewClient(client) {
      await this.getCustomers();
      const customer = this.customers.filter(
        (customer) => customer.customerId === client
      )[0];

      this.notaryDocument[this.obsContactField][this.obsContactIndex] = {
        _id: customer._id,
        name: customer.customerName,
        customerName: customer.customerName,
        personalCode: customer.personalCode,
        address: customer.address,
        email: customer.email,
        phoneNumber: customer.phoneNumber,
        maritalStatus: customer.maritalStatus,
        contactPerson: {
          customerId: customer._id,
          contactName: customer.customerName,
          company_name: customer.organization,
        },
        registerCode: customer.registrationNumber,
      };

      if (this.obsContactField === "sellers") {
        this.sellersUpdater++;
      } else if (this.obsContactField === "buyers") {
        this.buyersUpdater++;
      }
    },
    async initObject() {
      if (
        !this.deal.objects ||
        this.deal.objects.length === 0 ||
        !this.deal.objects[0].listings ||
        this.deal.objects[0].listings.length === 0
      )
        return;

      const listing = (
        await axios.get(`/api/listing/${this.deal.objects[0].listings[0]}`)
      ).data[0];

      let object = this.deal.objects[0];

      this.notaryDocument.object = {
        id: object._id,
        address: object.address,
        estateNumber: listing.estateNumber,
        price: listing.price,
        paymentMethod: null,
      };
    },
    async getBrokerInfo() {
      const response = await axios.get(`/api/user/${this.user._id}`);
      this.broker.name = this.user.name;
      this.broker.phoneNumber = response.data.user.phoneNumber;
      this.broker.email = this.user.email;
    },
    async uploadFiles() {
      const response = await axios.post(
        `/api/project/files/upload/${this.deal._id}`,
        this.formData
      );
      if (response.status === 200) {
        console.log("File uploaded successfully");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.date-input {
  margin-top: -5px;
  position: absolute;
  font-size: 12px;
  background-color: white;
  margin-left: 12px;
}
.add-another-button {
  text-decoration-line: underline;
  cursor: pointer;
  margin: 15px 0 0 30px;
}
.apartment-input {
  max-height: fit-content;
  padding-top: 20px;
}
.registering-input {
  max-height: fit-content;
  padding-bottom: 30px;
}
.select-input {
  padding-top: 20px;
  padding-bottom: 80px;
}
.paid-to-input {
  max-height: fit-content;
  padding-bottom: 30px;
}
.extra-input {
  max-height: fit-content;
  padding-bottom: 20px;
}
.buttons-divider {
  z-index: 111;
  position: absolute;
  width: 560px;
}
.btns-container-buttons {
  justify-content: space-between;
  padding-right: 56px;
  padding-top: 24px;
  padding-left: 28px;
  display: flex;
}
.gray-wrapper {
  padding: 24px 24px 16px 20px;
  width: 514px;
  margin: 0 60px 0 24px;
  background: #f4f5f7;
  border: 1px solid #e6e8ec;
  box-sizing: border-box;
  border-radius: 8px;
  display: grid;
  gap: 12px;

  .v-input {
    background: white;
  }
}
.form-wrapper {
  display: grid;
  gap: 12px;
  padding-top: 12px;
}
.modal-wrapper {
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  bottom: 0;
  background-color: #ffffff;
  padding-top: 0 !important;
  z-index: 7;
  width: 560px;
  overflow: scroll;
  border: 1px solid #e6e8ec;
  border-radius: 0;
  padding-bottom: 110px;
}
.modal-title {
  padding-left: 24px;
  margin-top: 32px;
}
.form-wrapper {
  padding-top: 16px;
  padding-left: 20px;
  padding-right: 16px;
}
.input-wrapper {
  gap: 12px;
  justify-content: space-between;
  height: auto;
  width: 100%;
}
.no-active {
  width: 100% !important;
  max-height: 52px;
  //padding-right: 56px;
  z-index: 110;
}
.overlay-notarymodal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 8;
}
.buttons-container {
  justify-content: flex-end;
  padding-bottom: 64px;
  z-index: 130;
  position: fixed;
  background: white;
  bottom: 0;
  height: 104px;
  width: 560px;
}
.pdf-content {
  padding: 6.4% 4.44% 6.4% 12.36%;
}
.pdf-sections {
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: left;
}
.cancel-button {
  margin-right: 8px;
  background-color: transparent !important;
  box-shadow: 0 0 2px rgba(28, 14, 3, 0.3);
  text-transform: none;
  cursor: pointer;
  padding: 12px 16px;
  font-size: 14px;
  font-family: Inter, serif;
  height: 40px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
}
.generate-doc-button {
  color: #ffffff;
  background: #000000;
  border: none;
  cursor: pointer;
  //padding: 12px 12px;
  font-size: 14px;
  width: 200px;
  height: 40px;
  font-family: Inter, serif;
  text-transform: none;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: center;
}
::v-deep .v-textarea textarea {
  color: black !important;
}
.date-class {
  border: 1px solid #e6e8ec;
  border-radius: 8px;
  width: 100%;
  font-family: Inter, serif;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  height: 56px;
  padding: 0 !important;

  &:focus {
    outline: none !important;
  }
}
input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  z-index: 55;
  left: 8px;
  cursor: pointer;
}
input::-webkit-datetime-edit {
  position: relative;
  left: 24px;
}
input::-webkit-datetime-edit-fields-wrapper {
  position: relative;
  left: 14px;
}
.calender-label {
  margin-top: -5px;
  left: auto;
  right: auto;
  position: absolute;
  font-size: 12px;
  font-family: Inter, serif;
  background-color: white;
  margin-left: 12px;
}

::v-deep .v-text-field--outlined > .v-input__control > .v-input__slot {
  background: white;
}

::v-deep .not-valid fieldset {
  border: 2px solid #ff1e24 !important;
}
</style>
