<template>
  <div
    class="overlay-notarymodal"
    id="overlay-notary-document"
    v-if="sellerIsLoaded"
    @keyup.esc="closeModal"
  >
    <div class="modal-wrapper" id="panel-wrapper-notary-document">
      <div
        @click="closeContactAdd"
        style="padding-left: 24px"
        id="wrapped-header-notary-document"
      >
        <h4 style="align-self: center">
          {{ $t("NotaryDocument") }}
        </h4>
      </div>

      <div class="notary-content" id="content-wrapper">
        <v-row>
          <span
            v-if="notaryDocument.dealId"
            class="flex justify-end"
            style="cursor: pointer; text-decoration-line: underline"
            @click="clearDocument()"
          >
            {{ $t("Clear") + " " + $t("Document") }}
          </span>
        </v-row>

        <h5 class="modal-title">
          {{ $t("CustomerField.CustomerType.Options.SELLER") }}
        </h5>
        <span :key="sellersUpdater">
          <div
            v-for="(seller, index) in notaryDocument.sellers"
            :key="'seller' + index"
            style="padding-top: 12px"
          >
            <div class="form-wrapper gray-wrapper">
              <span
                v-if="index !== 0"
                class="content-small clear-button"
                @click="notaryDocument.sellers.splice(index, 1)"
              >
                <img src="../../assets/images/close.svg" alt="" />
                {{ $t("Clear").toLowerCase() }}
              </span>

              <v-row style="z-index: 111">
                <ContactSearch
                  v-model="seller.contactPerson"
                  v-if="!fromAnotherPanel"
                  :placeholder="$t('FullName')"
                  :items="customers"
                  :chosen-object="seller.contactPerson"
                  :already-chosen="sellerIsLoaded"
                  @resultChosen="
                    (item) => setContactPerson(item, 'sellers', index)
                  "
                  @removeContact="removeSeller(index)"
                  @addNew="openContactAdd('sellers', index)"
                  :class="
                    'no-active required ' +
                    (showErrors && !isFieldValid('name', 'sellers', index)
                      ? 'not-valid'
                      : '')
                  "
                />

                <v-autocomplete
                  v-if="fromAnotherPanel"
                  v-model="seller.contactPerson"
                  :items="customers"
                  item-text="customerName"
                  return-object
                  :label="$t('FullName')"
                >
                  <template v-slot:prepend-inner>
                    <img
                      width="20"
                      height="20"
                      src="../../assets/images/user.svg"
                    />
                  </template>
                </v-autocomplete>
              </v-row>

              <v-text-field
                v-model="seller.personalCode"
                outlined
                :label="$t('CustomerField.personalCode')"
                :class="
                  'no-active required ' +
                  (showErrors && !isFieldValid('personalCode', 'sellers', index)
                    ? 'not-valid'
                    : '')
                "
              ></v-text-field>
              <v-text-field
                v-model="seller.address"
                outlined
                :label="$t('CustomerField.address')"
                :class="
                  'no-active required ' +
                  (showErrors && !isFieldValid('address', 'sellers', index)
                    ? 'not-valid'
                    : '')
                "
              ></v-text-field>
              <v-text-field
                v-model="seller.email"
                outlined
                :label="$t('CustomerField.email')"
                class="no-active"
              ></v-text-field>
              <v-text-field
                v-model="seller.phoneNumber"
                outlined
                :label="$t('CustomerField.phoneNumber')"
                class="no-active"
              ></v-text-field>
              <v-text-field
                v-model="seller.maritalStatus"
                outlined
                :label="$t('CustomerField.MaritalStatus')"
                :class="
                  'no-active required ' +
                  (showErrors &&
                  !isFieldValid('maritalStatus', 'sellers', index)
                    ? 'not-valid'
                    : '')
                "
              ></v-text-field>
            </div>
          </div>
        </span>

        <div
          style="
            text-decoration-line: underline;
            margin: 24px 0;
            cursor: pointer;
          "
          @click="
            errors.sellers.push(getContactEmptyErrors());
            notaryDocument.sellers.push(getEmptyPerson());
          "
        >
          {{ "+ " + $t("AddAnother") }}
        </div>

        <h5 class="modal-title">
          {{ $t("CustomerField.CustomerType.Options.BUYER") }}
        </h5>
        <span :key="buyersUpdater">
          <div
            v-for="(buyer, index) in notaryDocument.buyers"
            :key="buyersUpdater[index]"
            style="padding-top: 12px"
          >
            <div class="form-wrapper gray-wrapper">
              <span
                v-if="index !== 0"
                class="content-small clear-button"
                @click="notaryDocument.buyers.splice(index, 1)"
              >
                <img src="../../assets/images/close.svg" alt="" />
                {{ $t("Clear").toLowerCase() }}
              </span>
              <v-row style="z-index: 111">
                <ContactSearch
                  v-model="buyer.contactPerson"
                  v-if="!fromAnotherPanel"
                  :placeholder="$t('FullName')"
                  :items="customers"
                  :chosen-object="buyer.contactPerson"
                  @resultChosen="
                    (item) => setContactPerson(item, 'buyers', index)
                  "
                  @removeContact="removeBuyer(index)"
                  @addNew="openContactAdd('buyers', index)"
                  :class="
                    'no-active required ' +
                    (showErrors && !isFieldValid('name', 'buyers', index)
                      ? 'not-valid'
                      : '')
                  "
                />

                <v-autocomplete
                  v-if="fromAnotherPanel"
                  v-model="buyer.contactPerson"
                  :items="customers"
                  item-text="customerName"
                  return-object
                  :label="$t('FullName')"
                >
                  <template v-slot:prepend-inner>
                    <img
                      width="20"
                      height="20"
                      src="../../assets/images/user.svg"
                    />
                  </template>
                </v-autocomplete>
              </v-row>
              <v-text-field
                v-model="buyer.personalCode"
                outlined
                :label="$t('CustomerField.personalCode')"
                :class="
                  'no-active required ' +
                  (showErrors && !isFieldValid('personalCode', 'buyers', index)
                    ? 'not-valid'
                    : '')
                "
              ></v-text-field>
              <v-text-field
                v-model="buyer.address"
                outlined
                :label="$t('CustomerField.address')"
                :class="
                  'no-active required ' +
                  (showErrors && !isFieldValid('address', 'buyers', index)
                    ? 'not-valid'
                    : '')
                "
              ></v-text-field>
              <v-text-field
                v-model="buyer.email"
                outlined
                :label="$t('CustomerField.email')"
                class="no-active"
              ></v-text-field>
              <v-text-field
                v-model="buyer.phoneNumber"
                outlined
                :label="$t('CustomerField.phoneNumber')"
                class="no-active"
              ></v-text-field>
              <v-text-field
                v-model="buyer.maritalStatus"
                outlined
                :label="$t('CustomerField.MaritalStatus')"
                :class="
                  'no-active required ' +
                  (showErrors && !isFieldValid('maritalStatus', 'buyers', index)
                    ? 'not-valid'
                    : '')
                "
              ></v-text-field>
            </div>
          </div>
        </span>

        <div
          style="
            text-decoration-line: underline;
            margin: 24px 0;
            cursor: pointer;
          "
          @click="
            errors.buyers.push(getContactEmptyErrors());
            notaryDocument.buyers.push(getEmptyPerson());
          "
        >
          {{ "+ " + $t("AddAnother") }}
        </div>

        <h5 class="modal-title">
          {{ $t("Object") }}
        </h5>
        <div class="form-wrapper">
          <v-text-field
            v-model="notaryDocument.object.address"
            outlined
            :label="$t('Address')"
            :class="
              'no-active required ' +
              (showErrors && !isFieldValid('address', 'object')
                ? 'not-valid'
                : '')
            "
          ></v-text-field>
          <v-text-field
            v-model="notaryDocument.object.cadastralNumber"
            outlined
            :label="$t('CadastralNumber')"
            :class="
              'no-active required ' +
              (showErrors && !isFieldValid('cadastralNumber', 'object')
                ? 'not-valid'
                : '')
            "
          ></v-text-field>
          <v-text-field
            v-model="notaryDocument.object.estateNumber"
            outlined
            :label="$t('EstateNumber')"
            :class="
              'no-active required ' +
              (showErrors && !isFieldValid('estateNumber', 'object')
                ? 'not-valid'
                : '')
            "
          ></v-text-field>
          <v-text-field
            v-model="notaryDocument.object.price"
            outlined
            :label="$t('SellingPrice')"
            :class="
              'no-active required ' +
              (showErrors && !isFieldValid('price', 'object')
                ? 'not-valid'
                : '')
            "
          ></v-text-field>
          <h6 style="padding-top: 24px">
            {{ $t("PaymentOptions") }}
          </h6>
          <v-radio-group column v-model="notaryDocument.object.paymentMethod">
            <v-radio
              :label="$t('ThroughNotary')"
              color="orange darken-3"
              :value="'throughNotary'"
              row
            ></v-radio>
            <v-radio
              :label="$t('BeforeTransaction')"
              color="orange darken-3"
              :value="'beforeTransaction'"
              row
            ></v-radio>
            <v-radio
              :label="$t('ThroughLoan')"
              color="orange darken-3"
              :value="'throughLoan'"
            ></v-radio>
          </v-radio-group>
        </div>

        <h5 class="modal-title">
          {{ $t("EstateInfo.About") }}
        </h5>
        <div class="form-wrapper">
          <v-text-field
            v-model="notaryDocument.estateInfo.builtUp"
            outlined
            :label="$t('EstateInfo.BuiltUp')"
            class="no-active"
          ></v-text-field>
          <v-text-field
            v-model="notaryDocument.estateInfo.buildings"
            outlined
            :label="$t('EstateInfo.WhatKind')"
            class="no-active"
          ></v-text-field>
          <v-text-field
            v-model="notaryDocument.estateInfo.condition"
            outlined
            :label="$t('EstateInfo.Condition')"
            class="no-active"
          ></v-text-field>
          <v-text-field
            v-model="notaryDocument.estateInfo.insured"
            outlined
            :label="$t('EstateInfo.Insured')"
            class="no-active"
          ></v-text-field>
          <v-text-field
            v-model="notaryDocument.estateInfo.signedIn"
            outlined
            :label="$t('EstateInfo.SignedIn')"
            class="no-active"
          ></v-text-field>
          <v-text-field
            v-model="notaryDocument.estateInfo.furniture"
            outlined
            :label="$t('EstateInfo.Furniture')"
            class="no-active"
          ></v-text-field>
        </div>

        <h5 class="modal-title">
          {{ $t("SpecialConditions") }}
        </h5>
        <v-textarea
          v-model="notaryDocument.specialConditions"
          no-resize
          class="form-wrapper"
          outlined
          placeholder="Note"
        />

        <h5 class="modal-title">
          {{ $t("HandOver") }}
        </h5>
        <p style="color: grey">
          {{ $t("HandOverExtra") }}
        </p>
        <v-textarea
          v-model="notaryDocument.handedOver"
          no-resize
          class="form-wrapper"
          outlined
          placeholder="Note"
        />

        <h5 class="modal-title">
          {{ $t("Leases") }}
        </h5>
        <div class="form-wrapper">
          <v-radio-group row v-model="notaryDocument.activeLeases">
            <v-radio :label="$t('Yes')" color="orange darken-3" :value="true" />
            <v-radio :label="$t('No')" color="orange darken-3" :value="false" />
          </v-radio-group>
        </div>

        <h5 class="modal-title">
          {{ $t("ResponsibleForPayment") }}
        </h5>
        <div class="form-wrapper">
          <v-radio-group row v-model="notaryDocument.payment">
            <v-radio
              :label="$t('CustomerField.CustomerType.Options.SELLER')"
              color="orange darken-3"
              :value="'seller'"
            />
            <v-radio
              :label="$t('CustomerField.CustomerType.Options.BUYER')"
              color="orange darken-3"
              :value="'buyer'"
            />
          </v-radio-group>
        </div>
      </div>
    </div>

    <div class="buttons-container">
      <button class="transparent-button" @click="save">
        {{ $t("SaveAndExit") }}
      </button>
      <span style="display: flex; gap: 8px">
        <button class="transparent-button" @click="closeModal()">
          {{ $t("Cancel") }}
        </button>
        <button
          name="generate-doc-button"
          class="transparent-button"
          style="background: black !important; color: white"
          @click="generate()"
        >
          <img
            src="../../assets/images/checkmarkCircled.svg"
            style="height: 16px; width: 16px"
            alt=""
          />
          <span>
            {{ $t("GenerateDocument") }}
          </span>
        </button>
      </span>
    </div>

    <VueHtml2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      filename="notary-doc"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="800px"
      ref="html2Pdf"
      @hasDownloaded="hasDownloaded($event)"
    >
      <section slot="pdf-content" class="pdf-content">
        <section style="padding-bottom: 12px">
          <h2>{{ $t("NotaryDocument") }}</h2>
          <p>{{ dateToday() }}</p>
        </section>
        <section class="sub-block">
          <h4>{{ $t("Sellers") }}</h4>
          <div
            style="margin-top: 8px"
            v-for="(seller, index) in notaryDocument.sellers"
            :key="'pdfseller' + index"
          >
            {{ seller.name }},
            <span v-if="seller.registerCode">{{ seller.registerCode }},</span>
            {{ seller.personalCode }}, {{ seller.address }},
            <span v-if="seller.email"> {{ seller.email }}, </span>
            <span v-if="seller.phoneNumber"> {{ seller.phoneNumber }}, </span>
            {{ seller.maritalStatus }}
          </div>
        </section>
        <section class="sub-block">
          <h4>{{ $t("Buyers") }}</h4>
          <div
            style="margin-top: 8px"
            v-for="(buyer, index) in notaryDocument.buyers"
            :key="'pdfBuyer' + index"
          >
            {{ buyer.customerName }},
            <span v-if="buyer.registerCode">{{ buyer.registerCode }},</span>
            {{ buyer.personalCode }}, {{ buyer.address }},
            <span v-if="buyer.email"> {{ buyer.email }}, </span>
            <span v-if="buyer.phoneNumber"> {{ buyer.phoneNumber }}, </span>
            {{ buyer.maritalStatus }}
          </div>
        </section>
        <section class="sub-block">
          <h4>{{ $t("ImmovableProperty") }}</h4>
          <p>{{ notaryDocument.object.address }}</p>
          <p v-if="notaryDocument.object.cadastralNumber">
            {{ $t("CadastralEntityNumber") }}:
            {{ notaryDocument.object.cadastralNumber }}
          </p>
          <p>
            {{ $t("EstateNumber") }}: {{ notaryDocument.object.estateNumber }}
          </p>
          <p v-if="notaryDocument.object.price">
            {{ $t("Price") }}: {{ notaryDocument.object.price }}
          </p>
          <p v-if="notaryDocument.object.paymentMethod === 'throughNotary'">
            {{ $t("ThroughNotary") }}
          </p>
          <p v-if="notaryDocument.object.paymentMethod === 'beforeTransaction'">
            {{ $t("BeforeTransaction") }}
          </p>
          <p v-if="notaryDocument.object.paymentMethod === 'throughLoan'">
            {{ $t("ThroughLoan") }}
          </p>
        </section>
        <section v-if="notaryDocument.estateInfo.builtUp" class="sub-block">
          <h4>{{ $t("EstateInfo.BuiltUp") }}</h4>
          <p>{{ notaryDocument.estateInfo.builtUp }}</p>
        </section>
        <section v-if="notaryDocument.estateInfo.buildings" class="sub-block">
          <h4>{{ $t("EstateInfo.WhatKind") }}</h4>
          <p>{{ notaryDocument.estateInfo.buildings }}</p>
        </section>
        <section v-if="notaryDocument.estateInfo.condition" class="sub-block">
          <h4>{{ $t("EstateInfo.Condition") }}</h4>
          <p>{{ notaryDocument.estateInfo.condition }}</p>
        </section>
        <section v-if="notaryDocument.estateInfo.insured" class="sub-block">
          <h4>{{ $t("EstateInfo.Insured") }}</h4>
          <p>{{ notaryDocument.estateInfo.insured }}</p>
        </section>
        <section v-if="notaryDocument.estateInfo.signedIn" class="sub-block">
          <h4>{{ $t("EstateInfo.SignedIn") }}</h4>
          <p>{{ notaryDocument.estateInfo.signedIn }}</p>
        </section>
        <section v-if="notaryDocument.estateInfo.furniture" class="sub-block">
          <h4>{{ $t("EstateInfo.Furniture") }}</h4>
          <p>{{ notaryDocument.estateInfo.furniture }}</p>
        </section>
        <section v-if="notaryDocument.specialConditions" class="sub-block">
          <h4>{{ $t("SpecialConditions") }}</h4>
          <p>{{ notaryDocument.specialConditions }}</p>
        </section>
        <section v-if="notaryDocument.handedOver" class="sub-block">
          <h4>{{ $t("HandOverExtra") }}</h4>
          <p>{{ notaryDocument.handedOver }}</p>
        </section>
        <section v-if="notaryDocument.activeLeases" class="sub-block">
          <h4>{{ $t("Leases") }}</h4>
          <p>{{ notaryDocument.activeLeases ? $t("Yes") : $t("No") }}</p>
        </section>
        <section v-if="notaryDocument.payment" class="sub-block">
          <h4>{{ $t("ResponsibleForPayment") }}</h4>
          <p v-if="notaryDocument.payment === 'seller'">
            {{ $t("CustomerField.CustomerType.Options.SELLER") }}
          </p>
          <p v-if="notaryDocument.payment === 'buyer'">
            {{ $t("CustomerField.CustomerType.Options.BUYER") }}
          </p>
        </section>
      </section>
    </VueHtml2pdf>

    <AddModal
      style="z-index: 1200"
      @newAdded="chooseNewClient"
      @closed="closeContactAdd"
      :accordion="true"
      v-if="clientAddOpen"
    />
  </div>
</template>
<script>
import VueHtml2pdf from "vue-html2pdf";
import axios from "axios";
import ContactSearch from "@/components/common/ContactSearch";
import AddModal from "@/components/Kliendiregister/AddModal";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";

export default {
  name: "NotarySidepanel",
  components: {
    VueHtml2pdf,
    ContactSearch,
    AddModal,
  },
  props: ["deal", "fromAnotherPanel"],
  data() {
    return {
      obsContactField: "",
      obsContactIndex: 0,
      clientAddOpen: false,
      sellerIsLoaded: false,
      sellersUpdater: 0,
      buyersUpdater: 100,
      formData: new FormData(),
      documentInfo: {},
      notaryDocument: this.getEmptyNotaryDocument(),
      estateNumberFromListing: null,
      showErrors: false,
      validate: {
        sellers: ["name", "personalCode", "address", "maritalStatus"],
        buyers: ["name", "personalCode", "address", "maritalStatus"],
        object: ["address", "cadastralNumber", "estateNumber", "price"],
      },
      errors: null,
    };
  },
  async created() {
    //this.getCustomers();
    //this.getBrokerInfo();
    //this.initSeller();
    if (this.deal.notaryDocuments && this.deal.notaryDocuments.length > 0) {
      this.notaryDocument = this.deal.notaryDocuments[0];
      this.sellerIsLoaded = true;
    } else {
      await this.initData();
    }
    this.errors = this.getEmptyErrors();

    document.onkeydown = (evt) => {
      evt = evt || window.event;
      if (evt.key === "Escape") {
        this.closeModal();
        document.onkeydown = null;
      }
    };
  },
  mounted() {
    document.documentElement.style.overflow = "hidden";
    window.onclick = (e) => {
      if (e.target.classList[0] === "overlay-notarymodal") {
        this.closeModal();
        this.$emit("panelClosed");
        e.target.style.display = "none";
      }
    };
  },
  beforeDestroy() {
    document.documentElement.style.overflow = "visible";
  },
  computed: {
    ...mapGetters(["customers"]),
    showPreview() {
      //return !this.$browserDetect.isSafari;
      return true;
    },
  },
  watch: {},
  methods: {
    ...mapActions(["getCustomers"]),

    async chooseNewClient(client) {
      await this.getCustomers();
      const customer = this.customers.filter(
        (customer) => customer.customerId === client
      )[0];

      this.notaryDocument[this.obsContactField][this.obsContactIndex] = {
        _id: customer._id,
        name: customer.customerName,
        customerName: customer.customerName,
        personalCode: customer.personalCode,
        address: customer.address,
        email: customer.email,
        phoneNumber: customer.phoneNumber,
        maritalStatus: customer.maritalStatus,
        contactPerson: {
          customerId: customer._id,
          contactName: customer.customerName,
          company_name: customer.organization,
        },
        registerCode: customer.registrationNumber,
      };

      if (this.obsContactField === "sellers") {
        this.sellersUpdater++;
      } else if (this.obsContactField === "buyers") {
        this.buyersUpdater++;
      }
    },
    removeBuyer() {
      this.buyer.contactPerson = null;
    },
    removeSeller() {
      this.seller.contactPerson = null;
    },
    closeContactAdd() {
      let panel = document.getElementById("panel-wrapper-notary-document");
      let panelContent = document.getElementById("content-wrapper");
      let overlay = document.getElementById("overlay-notary-document");
      let wrappedHeader = document.getElementById(
        "wrapped-header-notary-document"
      );

      wrappedHeader.style.display = "none";
      wrappedHeader.style.opacity = "0";
      panelContent.style.display = "block";
      panelContent.style.padding = "32px";
      panel.style.width = "560px";
      panel.style.right = "0";
      overlay.style.zIndex = "8";
      window.onclick = (e) => {
        if (e.target.classList[0] === "overlay-notarymodal") {
          this.closeModal();
          e.target.style.display = "none";
        }
      };

      //waiting for animations
      setTimeout(async () => {
        this.clientAddOpen = false;
      }, 500);
    },
    openContactAdd(field, index) {
      this.obsContactField = field;
      this.obsContactIndex = index;

      let panel = document.getElementById("panel-wrapper-notary-document");
      let panelContent = document.getElementById("content-wrapper");
      let overlay = document.getElementById("overlay-notary-document");
      panelContent.style.display = "none";
      panel.style.overflow = "hidden";
      panel.style.minWidth = "94px";
      panel.style.width = "94px";
      panel.style.right = "558px";
      panel.style.zIndex = "121";
      //panel.style.boxShadow = "0px 0 1px rgba(28, 14, 3, 0.2)";
      overlay.style.zIndex = "5";

      let wrappedHeader = document.getElementById(
        "wrapped-header-notary-document"
      );
      wrappedHeader.style.display = "flex";
      wrappedHeader.style.opacity = "1";
      this.clientAddOpen = true;
    },
    isFormValid() {
      this.errors = this.getEmptyErrors();

      let _errors = [];

      this.validate.sellers.map((field) => {
        for (let i = 0; i < this.notaryDocument.sellers.length; i++) {
          _errors.push(this.isFieldValid(field, "sellers", i));
        }
      });

      this.validate.buyers.map((field) => {
        for (let i = 0; i < this.notaryDocument.buyers.length; i++) {
          _errors.push(this.isFieldValid(field, "buyers", i));
        }
      });

      this.validate.object.map((field) => {
        _errors.push(this.isFieldValid(field, "object"));
      });

      return _errors.every((item) => item === true);
    },
    isFieldValid(fieldName, parentField, arrayIndex) {
      const fieldValue =
        arrayIndex !== undefined
          ? this.notaryDocument[parentField][arrayIndex][fieldName]
          : this.notaryDocument[parentField][fieldName];

      if (!fieldValue) {
        if (arrayIndex) {
          this.errors[parentField][arrayIndex][fieldName] = "FieldRequired";
        } else if (parentField) {
          this.errors[parentField][fieldName] = "FieldRequired";
        } else {
          this.errors[fieldName] = "FieldRequired";
        }
        return false;
      }
      return true;
    },
    getEmptyErrors() {
      let errors = {
        buyers: [this.getContactEmptyErrors()],
        sellers: [this.getContactEmptyErrors()],
        object: this.getObjectEmptyErrors(),
      };

      for (let i = 1; i < this.notaryDocument.sellers.length; i++) {
        errors.sellers.push(this.getContactEmptyErrors());
      }
      for (let i = 1; i < this.notaryDocument.buyers.length; i++) {
        errors.buyers.push(this.getContactEmptyErrors());
      }
      return errors;
    },
    getContactEmptyErrors() {
      return {
        name: "",
        personalCode: "",
        address: "",
        maritalStatus: "",
      };
    },
    getObjectEmptyErrors() {
      return {
        address: "",
        cadastralNumber: "",
        estateNumber: "",
        price: "",
      };
    },
    getEmptyPerson() {
      return {
        _id: null,
        name: null,
        customerName: null,
        personalCode: null,
        address: null,
        email: null,
        phoneNumber: null,
        maritalStatus: null,
        registerCode: null,
        contactPerson: null,
      };
    },
    getEmptyNotaryDocument() {
      return {
        dealId: this.deal._id,
        sellers: [this.getEmptyPerson()],
        buyers: [this.getEmptyPerson()],
        object: {
          id: null,
          address: null,
          cadastralNumber: null,
          estateNumber: null,
          price: null,
          paymentMethod: null,
        },
        estateInfo: {
          builtUp: null,
          buildings: null,
          condition: null,
          insured: null,
          signedIn: null,
          furniture: null,
        },
        specialConditions: null,
        handedOver: null,
        activeLeases: null,
        payment: null,
        estateNumberFromListing: null,
      };
    },
    dateToday() {
      return moment().format("DD.MM.YYYY");
    },
    clearDocument() {
      this.notaryDocument = this.getEmptyNotaryDocument();
      this.sellersUpdater++;
      this.buyersUpdater++;
    },
    closeModal() {
      this.$emit("panelClosed");
    },
    hasDownloaded($event) {
      const file = new File([$event], "notary-doc.pdf", {
        type: "application/pdf",
      });
      this.formData.append("file", file);
      this.uploadFiles();
      this.closeModal();
      this.$emit("panelClosed");
    },
    async initData() {
      await Promise.all([this.initSeller(), this.initObject()]);
    },
    async save() {
      await axios.post("/api/project/notary-document", this.notaryDocument);
      this.toastSuccess(this.$t("DocumentSaves"));
      this.closeModal();
    },
    async generate() {
      if (!this.isFormValid()) {
        this.showErrors = true;
        this.toastError(this.$t("FormErrors.AllFieldsMustBeFilled"));
        return;
      }

      await axios.post("/api/project/notary-document", this.notaryDocument);
      this.$refs.html2Pdf.generatePdf();
      this.toastSuccess(this.$t("GenerateSuccess"));
      //this.closeModal();
    },
    async initContactPerson(contactPerson, parentField, arrayIndex) {
      contactPerson.contactId = contactPerson.customerId;
      contactPerson.contactName = contactPerson.customerName;
      const response = await axios.get(
        `/api/contact/${contactPerson.customerId}`
      );
      let person = response.data;

      this.notaryDocument[parentField][arrayIndex] = {
        _id: person._id,
        name: person.customerName,
        customerName: person.customerName,
        personalCode: person.personalCode,
        address: person.address,
        email: person.email,
        phoneNumber: person.phoneNumber,
        maritalStatus: person.maritalStatus,
        contactPerson: contactPerson,
        registerCode: contactPerson.reg_code,
      };

      if (parentField === "sellers") {
        this.sellerIsLoaded = true;
        this.sellersUpdater++;
      } else if (parentField === "buyers") {
        this.buyersUpdater++;
      }
    },
    async setContactPerson(contactPerson, parentField, arrayIndex) {
      if (!contactPerson) {
        this.notaryDocument[parentField][arrayIndex] = null;
        return;
      }
      const response = await axios.get(
        `/api/contact/${contactPerson.contactId}`
      );
      let person = response.data;

      this.notaryDocument[parentField][arrayIndex] = {
        _id: person._id,
        name: person.customerName,
        customerName: person.customerName,
        personalCode: person.personalCode,
        address: person.address,
        email: person.email,
        phoneNumber: person.phoneNumber,
        maritalStatus: person.maritalStatus,
        contactPerson: contactPerson,
        registerCode: contactPerson.reg_code,
      };

      if (parentField === "sellers") {
        this.sellersUpdater++;
      } else if (parentField === "buyers") {
        this.buyersUpdater++;
      }
    },
    async initSeller() {
      const response = await axios.get(
        `/api/contact/${this.deal.contactPerson.contactId}`
      );

      const seller = response.data;

      this.notaryDocument.sellers[0] = {
        _id: seller._id,
        name: seller.customerName,
        customerName: seller.customerName,
        personalCode: seller.personalCode,
        address: seller.address,
        email: seller.email,
        phoneNumber: seller.phoneNumber,
        maritalStatus: seller.maritalStatus,
        contactPerson: {
          customerId: seller.customerId,
          customerName: seller.customerName,
        },
        registerCode: null,
      };
      this.initContactPerson(
        this.notaryDocument["sellers"][0]["contactPerson"],
        "sellers",
        0
      );

      /* const seller = response.data;

      this.notaryDocument.sellers[0] = {
        _id: seller._id,
        name: seller.customerName,
        customerName: seller.customerName,
        personalCode: seller.personalCode,
        address: seller.address,
        email: seller.email,
        phoneNumber: seller.phoneNumber,
        maritalStatus: seller.maritalStatus,
        contactPerson: {
          customerId: seller.customerId,
          customerName: seller.customerName,
        },
        registerCode: null,
      };
      this.initContactPerson(
        this.notaryDocument["sellers"][0]["contactPerson"],
        "sellers",
        0
      ); */
      //this.sellersUpdater++;
    },
    async initBuyer() {
      // const response = await axios.get(`/api/contact/${this.deal._id}`);
      // const seller = response.data[0];
      //
      // this.notaryDocument.sellers[0] = {
      //   _id: seller._id,
      //   name: seller.customerName,
      //   customerName: seller.customerName,
      //   personalCode: seller.personalCode,
      //   address: seller.address,
      //   email: seller.email,
      //   phoneNumber: seller.phoneNumber,
      //   maritalStatus: seller.maritalStatus,
      //   contactPerson: seller.customerName,
      //   registerCode: null,
      // }
    },
    async initObject() {
      if (
        !this.deal.objects ||
        this.deal.objects.length === 0 ||
        !this.deal.objects[0].listings ||
        this.deal.objects[0].listings.length === 0
      )
        return;

      const listing = (
        await axios.get(`/api/listing/${this.deal.objects[0].listings[0]}`)
      ).data[0];

      let object = this.deal.objects[0];

      this.notaryDocument.object = {
        id: object._id,
        address: object.address,
        cadastralNumber: listing.cadastralNumber,
        estateNumber: listing.estateNumber,
        price: listing.price,
        paymentMethod: null,
      };
    },
    async uploadFiles() {
      const response = await axios.post(
        `/api/project/files/upload/${this.deal._id}`,
        this.formData
      );
      if (response.status === 200) {
        console.log("File uploaded successfully");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "src/global_variables";

#panel-wrapper-notary-document {
  -webkit-transition: width 0.5s ease-in-out, right 0.5s ease-in-out,
    min-width 0.5s ease-in-out;
  -moz-transition: width 0.5s ease-in-out, right 0.5s ease-in-out,
    min-width 0.5s ease-in-out;
  -o-transition: width 0.5s ease-in-out, right 0.5s ease-in-out,
    min-width 0.5s ease-in-out;
  transition: width 0.5s ease-in-out, right 0.5s ease-in-out,
    min-width 0.5s ease-in-out;
}

#wrapped-header-notary-document {
  display: none;
  position: relative;
  width: 100%;
  height: 100%;
  opacity: 0;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
  flex-direction: column;
  justify-content: flex-start;
  padding: 48px 24px 24px;
  border-right: 1px solid $grey500;

  h4 {
    white-space: nowrap;

    overflow: hidden;
    text-overflow: ellipsis;
    transform: rotate(180deg);
    writing-mode: vertical-lr;
    text-align: center;
    position: relative;
    justify-self: center;
    align-self: center;
  }
}

.clear-button {
  display: flex;
  justify-content: flex-end;
  gap: 2px;
  cursor: pointer;

  &:hover {
    text-decoration-line: underline;
  }
}

.gray-wrapper {
  padding: 16px;
  background: #f4f5f7;
  border: 1px solid #e6e8ec;
  box-sizing: border-box;
  border-radius: 8px;

  .v-input {
    background: white;
  }
}

.notary-content {
  width: 100%;
  overflow-y: scroll;
  padding: 32px;
  height: calc(100% - 112px);
}

.modal-wrapper {
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #ffffff;
  z-index: 7;
  position: fixed;
  width: 560px;

  border: 1px solid #e6e8ec;
  -webkit-transition: width 0.5s ease-in-out;
  -moz-transition: width 0.5s ease-in-out;
  -o-transition: width 0.5s ease-in-out;
  transition: width 0.5s ease-in-out;
}
.modal-title {
  padding: 24px 0 12px 0;
}
.form-wrapper {
  display: grid;
  gap: 12px;
  padding-top: 12px;
}

.no-active {
  width: 100% !important;
  max-height: 52px;
  z-index: 110;
}
.overlay-notarymodal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 8;
}
.buttons-container {
  border-top: 1px solid #e6e8ec;
  display: inline-flex;
  justify-content: space-between;
  padding: 32px 32px 40px 32px;
  position: fixed;
  bottom: 0;
  right: 0;
  height: 112px;
  width: 560px;
  z-index: 130;
}
.pdf-content {
  padding: 6.4% 4.44% 6.4% 12.36%;
}
.sub-block {
  padding-top: 2%;
  padding-bottom: 2%;
}
::v-deep .v-textarea textarea {
  color: black !important;
}

::v-deep .not-valid fieldset {
  border: 2px solid #ff1e24 !important;
}

::v-deep .v-text-field--outlined > .v-input__control > .v-input__slot {
  background: white;
}
</style>
