<template>
  <div :id="id + '-container'" :key="id + 'updater'" :style="sidepanelStyle">
    <slot/>
  </div>
</template>

<script>
export default {
  name: "BaseDetailviewContainer",
  props: ["id", "width", "padding"],
  data() {
    return {
      sidepanelStyle: {
        minWidth: "400px",
        maxWidth: "400px",
        background: "#fff",
        padding: "24px",
        borderRadius: "8px",
        height: "100%"
      },
    };
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      if (this.width) {
        this.sidepanelStyle.minWidth = this.width;
        this.sidepanelStyle.maxWidth = this.width;
      }
      if (this.padding) {
        this.sidepanelStyle.padding = this.padding;
      }
    },
  }
}
</script>

<style scoped>

</style>