var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "overlay-detailpanel",
    attrs: {
      "id": "overlay-customer"
    },
    on: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) return null;
        return _vm.closePanel.apply(null, arguments);
      },
      "click": function ($event) {
        return _vm.closePanel($event);
      }
    }
  }, [_c('div', {
    class: {
      'panel-wrapper': !_vm.versionPanelOpen,
      'panel-wrapper panel-wrapper-versions-open': _vm.versionPanelOpen
    },
    attrs: {
      "id": "panel-wrapper-customer"
    }
  }, [!_vm.editAddPanel ? _c('div', {
    attrs: {
      "id": "wrapped-header-customer"
    },
    on: {
      "click": function ($event) {
        return _vm.closeAccordion();
      }
    }
  }) : _vm._e(), _c('div', {
    attrs: {
      "id": "content-wrapper"
    }
  }, [_c('v-row', {
    staticClass: "customer-row"
  }, [_c('v-col', {
    staticStyle: {
      "justify-content": "space-between",
      "gap": "8px",
      "padding": "0",
      "min-width": "70%"
    }
  }, [_c('h3', {
    staticStyle: {
      "align-self": "center"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("KnowYourCustomer")) + " ")]), _vm.chosenAml.creator ? _c('p', {
    staticClass: "link-small top8",
    on: {
      "click": _vm.toggleVersionsPanel
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("LastEdit")) + " " + _vm._s(_vm.formatAmlEditDate(_vm.chosenAml.createdAt)) + " " + _vm._s(" ") + " " + _vm._s(_vm.chosenAml.creator.name) + " ")]) : _vm._e()]), _c('v-col', {
    staticStyle: {
      "width": "35%"
    }
  }, [_vm.deal.pipelineStep == 'qualified' || _vm.deal.pipelineStep == 'contactMade' ? _c('div', {
    staticStyle: {
      "display": "flex",
      "gap": "8px",
      "justify-content": "flex-end"
    }
  }, [_vm.deal.pipelineStep == 'qualified' || _vm.deal.pipelineStep == 'contactMade' ? _c('div', {
    staticClass: "edit-button",
    on: {
      "click": function ($event) {
        return _vm.openAddModal();
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("../../assets/images/edit.svg"),
      "alt": ""
    }
  }), _c('p', {
    staticClass: "edit-button-text"
  }, [_vm._v(_vm._s(_vm.$t("Edit")))])]) : _vm._e()]) : _vm._e()])], 1), _c('div', {
    staticClass: "main-wrapper",
    staticStyle: {
      "background-color": "white"
    }
  }, [_c('div', {
    staticClass: "sub-block"
  }, [_c('v-col', {
    staticClass: "sub-heading"
  }, [_c('h6', [_vm._v(" " + _vm._s(_vm.$t("Subblock.GeneralData")) + " ")])]), _c('div', {
    staticClass: "main-info"
  }, [_c('v-row', {
    staticClass: "customer-field-row top16",
    staticStyle: {
      "margin-top": "0px"
    }
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(" " + _vm._s(_vm.$t("ActiveBusinessRelationshipFrom1")) + " ")]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(" " + _vm._s(_vm.formatDate(_vm.chosenAml.activeBusiness)) + " ")])]), _c('v-row', {
    staticClass: "customer-field-row top16"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(" " + _vm._s(_vm.$t("ActiveBusinessRelationshipTo")) + " ")]), _vm.chosenAml.businessRelationship != null ? _c('p', {
    staticClass: "field-value"
  }, [_vm._v(" " + _vm._s(_vm.formatDate(_vm.chosenAml.businessRelationship)) + " ")]) : _vm._e(), _vm.chosenAml.businessRelationship == null ? _c('p', {
    staticClass: "field-value"
  }, [_vm._v(" - ")]) : _vm._e()]), _c('v-row', {
    staticClass: "customer-field-row top16"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(" " + _vm._s(_vm.$t("RepresentationContract")) + " ")]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(" " + _vm._s(_vm.chosenAml.representationContract ? _vm.$t("Yes") : _vm.$t("No")) + " ")])]), _c('v-row', {
    staticClass: "customer-field-row top16"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(" " + _vm._s(_vm.$t("PowerOfAttorney")) + " ")]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(" " + _vm._s(_vm.chosenAml.powerOfAttorney ? _vm.$t("Yes") : _vm.$t("No")) + " ")])]), _c('v-row', {
    staticClass: "customer-field-row top16"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(" " + _vm._s(_vm.$t("CustomerConfirmation")) + " ")]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(" " + _vm._s(_vm.chosenAml.customerConfirmation ? _vm.$t("Yes") : _vm.$t("No")) + " ")])]), _c('v-row', {
    staticClass: "customer-field-row top16"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(" " + _vm._s(_vm.$t("ServicesRequested")) + " ")]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(" " + _vm._s(_vm.chosenAml.servicesRequested ? _vm.$t("Yes") : _vm.$t("No")) + " ")])]), _c('v-row', {
    staticClass: "customer-field-row top16"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(" " + _vm._s(_vm.$t("PrintoutOfRegister")) + " ")]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(" " + _vm._s(_vm.chosenAml.printoutOfRegister ? _vm.$t("Yes") : _vm.$t("No")) + " ")])]), _c('v-row', {
    staticClass: "customer-field-row top16"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(" " + _vm._s(_vm.$t("CopyOfIdenification")) + " ")]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(" " + _vm._s(_vm.chosenAml.copyOfIdenification ? _vm.$t("Yes") : _vm.$t("No")) + " ")])]), _c('v-row', {
    staticClass: "customer-field-row top16"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(" " + _vm._s(_vm.$t("BackgroundCheck")) + " ")]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(" " + _vm._s(_vm.chosenAml.backgroundCheck ? _vm.$t("Yes") : _vm.$t("No")) + " ")])]), _c('v-row', {
    staticClass: "customer-field-row top16"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(" " + _vm._s(_vm.$t("PoliticallyExposedPerson1")) + " ")]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(" " + _vm._s(_vm.chosenAml.politicallyExposedPerson ? _vm.$t("Yes") : _vm.$t("No")) + " ")])]), _c('v-row', {
    staticClass: "customer-field-row top16"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(" " + _vm._s(_vm.$t("SubjectOfSanctions1")) + " ")]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(" " + _vm._s(_vm.chosenAml.subjectOfSanctions ? _vm.$t("Yes") : _vm.$t("No")) + " ")])])], 1)], 1), _c('div', {
    staticClass: "sub-block",
    staticStyle: {
      "margin-top": "32px"
    }
  }, [_vm._m(0), _c('v-col', {
    staticClass: "sub-heading"
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "row",
      "flex-wrap": "nowrap",
      "gap": "8px"
    }
  }, [_c('h6', [_vm._v(" " + _vm._s(_vm.$t("Files")) + " ")]), _c('div', {
    staticClass: "counter-icon"
  }, [_c('p', {
    staticClass: "content-small",
    staticStyle: {
      "color": "white"
    }
  }, [_vm._v(" " + _vm._s(_vm.chosenAml.files.length) + " ")])])])]), _c('div', {
    staticStyle: {
      "padding": "0px 32px 16px 32px"
    }
  }, [_vm._l(_vm.chosenAml.files, function (el, index) {
    return _c('v-row', {
      key: index,
      staticClass: "file-row"
    }, [_c('span', {
      staticStyle: {
        "display": "flex",
        "gap": "8px"
      }
    }, [_c('span', {
      staticClass: "file-name",
      on: {
        "click": function ($event) {
          return _vm.downloadFile(el.name, el.amlId);
        }
      }
    }, [_vm._v(" " + _vm._s(el.name) + " ")])]), _c('span', {
      staticClass: "content-small",
      staticStyle: {
        "font-weight": "400",
        "color": "#75787a",
        "gap": "8px"
      }
    }, [_c('span', [_vm._v(" " + _vm._s(el.creator.name) + " ")]), _c('span', [_vm._v(" " + _vm._s(_vm.formatAmlEditDate(el.createdAt)) + " ")])])]);
  }), _c('a', {
    attrs: {
      "href": "",
      "id": "download-link",
      "download": ""
    }
  })], 2)], 1)])], 1)]), _vm.versionPanelOpen ? _c('AmlVersionsPanel', {
    attrs: {
      "aml": _vm.deal.aml
    },
    on: {
      "closed": _vm.toggleVersionsPanel,
      "versionChosen": _vm.chooseAmlVersion
    }
  }) : _vm._e(), _vm.editAddPanel ? _c('AmlSidepanelAdd', {
    attrs: {
      "isEdit": true,
      "deal": _vm.deal
    },
    on: {
      "refresher": function ($event) {
        return _vm.$emit('refresh');
      },
      "amlDeleted": function ($event) {
        return _vm.amlDeleted();
      },
      "closed": function ($event) {
        return _vm.closeAddModal();
      }
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "separator-row-main",
    staticStyle: {
      "border": "0.1px solid #e6e8ec"
    }
  }, [_c('td', {
    staticClass: "separator-row"
  })]);

}]

export { render, staticRenderFns }