var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.closeWidget,
      expression: "closeWidget"
    }],
    staticClass: "marketing-expense-files-dropdown",
    class: {
      'marketing-expense-files-dropdown-with-content': _vm.displayedFiles.length > 0,
      'marketing-expense-files-dropdown-without-upload': _vm.hideFileUpload
    },
    attrs: {
      "id": "marketing-expense-files-dropdown"
    }
  }, [_vm._l(_vm.displayedFiles, function (file, index) {
    return _c('div', {
      key: 'file:' + index,
      staticClass: "marketing-expense-files-dropdown-content",
      class: {
        'divider-line': index < _vm.displayedFiles.length - 1
      }
    }, [_c('div', {
      staticClass: "marketing-expense-files-dropdown-content-first-column"
    }, [!file.image ? _c('div', {
      staticClass: "marketing-expense-files-dropdown-content-empty-picture"
    }) : _vm._e(), file.image ? _c('div', {
      staticClass: "marketing-expense-files-dropdown-content-image-container"
    }, [_c('img', {
      staticClass: "marketing-expense-files-dropdown-content-image-container",
      attrs: {
        "src": file.image.path.name,
        "alt": ""
      }
    })]) : _vm._e(), file.image ? _c('div', {
      staticClass: "content-small",
      staticStyle: {
        "cursor": "pointer"
      },
      attrs: {
        "id": "lightgallery"
      },
      on: {
        "click": function ($event) {
          return _vm.openImageGallery(file);
        }
      }
    }, [_vm._v(" " + _vm._s(file.image.name) + " ")]) : _vm._e(), !file.image ? _c('div', {
      staticClass: "content-small",
      staticStyle: {
        "cursor": "pointer"
      },
      on: {
        "click": function ($event) {
          return _vm.downloadFile(file.key);
        }
      }
    }, [_vm._v(" " + _vm._s(file.fileName) + " ")]) : _vm._e()]), _c('div', {
      staticClass: "marketing-expense-files-dropdown-content-last-column"
    }, [_c('div', {
      staticClass: "Labels"
    }, [_vm._v(_vm._s(_vm.formatDate(file.createdAt)))]), !_vm.hideFileUpload ? _c('img', {
      staticStyle: {
        "cursor": "pointer"
      },
      attrs: {
        "src": require("../../assets/images/red_trash_16_16.svg"),
        "alt": "delete"
      },
      on: {
        "click": function ($event) {
          return _vm.deleteFile(file, index);
        }
      }
    }) : _vm._e()])]);
  }), !_vm.hideFileUpload ? _c('div', {
    staticClass: "marketing-expense-files-dropdown-button-container"
  }, [_c('div', {
    staticClass: "marketing-expense-files-dropdown-button",
    on: {
      "click": _vm.uploadButton
    }
  }, [_c('img', {
    attrs: {
      "src": require("../../assets/images/image_16_16.svg"),
      "alt": "upload picture"
    }
  }), _c('p', {
    staticClass: "content-small"
  }, [_vm._v(_vm._s(_vm.$t("UploadFiles")))])])]) : _vm._e(), _c('input', {
    staticStyle: {
      "display": "none"
    },
    attrs: {
      "type": "file",
      "id": "marketing-expense-file-upload-html",
      "multiple": ""
    },
    on: {
      "change": _vm.uploadFiles
    }
  }), _c('a', {
    attrs: {
      "href": "",
      "id": "download-link",
      "download": ""
    }
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }