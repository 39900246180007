<template>
  <div class="overlay" id="overlay-project">
    <div id="panel-wrapper-project" class="panel-wrapper">
      <!-- <div
        style="padding-left: 24px"
        v-if="!isEdit"
        id="wrapped-header-project"
      >
        <h3 style="align-self: center">
          {{ $t("AmlProcedureRules") }}
        </h3>
      </div>
      <div
        v-if="isEdit"
        @click="closeDealAdd()"
        id="wrapped-header-project-with-name"
      >
        <h3 style="align-self: center">
          {{ deal._id }}
        </h3>
      </div> -->
      <div class="content-overlay" id="content-wrapper">
        <div class="sticky-header">
          <v-row class="top-row" style="height: 24px; padding-left: 32px"
            ><h4 v-if="!isEdit" style="height: 24px">
              {{ $t("AddAmlProcedureRules") }}
            </h4>
            <h4 v-if="isEdit" style="height: 24px">
              {{ $t("ChangeAmlProcedureRules") }}
            </h4>
          </v-row>
        </div>
        <v-divider
          style="top: 97px; z-index: 111; position: fixed; width: 560px"
        ></v-divider>

        <v-row
          style="
            gap: 12px;
            gap: 8px;
            padding-left: 32px;
            padding-right: 32px;
            padding-top: 129px;
          "
        >
          <BaseDropdown
            icon-left="calendar_20_20.svg"
            :id="'aml-active-business'"
            :placeholder="$t('ActiveBusinessRelationshipFrom')"
            v-model="form.activeBusiness"
            :show-dropdown-arrow="true"
            :error-message="
              errors && errors.activeBusiness
                ? $t('FormErrors.' + errors.activeBusiness)
                : ''
            "
            required="true"
            :error="isErrorActiveBusiness && !isFieldValid('activeBusinessNew')"
            type="date"
          />
          <!--  <v-row
            :class="'required ' + (isErrorActiveBusiness ? 'not-valid' : '')"
          >
            <input
              v-model="form.activeBusiness"
              :error="isErrorActiveBusiness"
              type="date"
              class="birth-class"
              name="session-date"
            />
            <label
              for="input-112"
              class="v-label v-label--active theme--light"
              style="
                margin-top: -5px;
                position: absolute;
                font-size: 12px;
                background-color: white;
                margin-left: 12px;
              "
              >{{ $t("ActiveBusinessRelationshipFrom") }}</label
            >
          </v-row> -->
        </v-row>
        <!--   <v-row
          class="error-text"
          v-if="isErrorActiveBusiness"
          style="margin: 8px 0 12px 32px"
        >
          {{ $t("FormErrors.FieldRequired") }}
        </v-row> -->
        <v-row
          style="
            gap: 12px;
            margin-top: 10px !important;
            gap: 8px;
            padding-left: 32px;
            padding-right: 32px;
          "
        >
          <BaseDropdown
            icon-left="calendar_20_20.svg"
            :id="'aml-business-relationship-to'"
            :placeholder="$t('ActiveBusinessRelationshipTo')"
            v-model="form.businessRelationship"
            :show-dropdown-arrow="true"
            type="date"
          />
          <!--    <v-row>
            <input
              v-model="form.businessRelationship"
              type="date"
              class="birth-class"
              name="session-date"
            />
            <label
              for="input-112"
              class="v-label v-label--active theme--light"
              style="
                margin-top: -5px;
                position: absolute;
                font-size: 12px;
                background-color: white;
                margin-left: 12px;
              "
              >{{ $t("ActiveBusinessRelationshipTo") }}</label
            >
          </v-row> -->
        </v-row>
        <v-row
          class="deal-type"
          style="padding-left: 32px; padding-right: 32px; margin-top: 14px"
        >
          <v-checkbox
            v-model="form.representationContract"
            :label="$t('RepresentationContract')"
            color="black"
            hide-details
          ></v-checkbox
        ></v-row>
        <v-row
          class="deal-type"
          style="padding-left: 32px; padding-right: 32px; margin-top: 14px"
        >
          <v-checkbox
            v-model="form.powerOfAttorney"
            :label="$t('PowerOfAttorney')"
            color="black"
            hide-details
          ></v-checkbox
        ></v-row>
        <v-row
          class="deal-type"
          style="padding-left: 32px; padding-right: 32px; margin-top: 14px"
        >
          <v-checkbox
            v-model="form.customerConfirmation"
            :label="$t('CustomerConfirmation')"
            color="black"
            hide-details
          ></v-checkbox
        ></v-row>
        <v-row
          class="deal-type"
          style="padding-left: 32px; padding-right: 32px; margin-top: 14px"
        >
          <v-checkbox
            v-model="form.servicesRequested"
            :label="$t('ServicesRequested')"
            color="black"
            hide-details
          ></v-checkbox
        ></v-row>
        <v-row
          class="deal-type"
          style="padding-left: 32px; padding-right: 32px; margin-top: 14px"
        >
          <v-checkbox
            v-model="form.printoutOfRegister"
            :label="$t('PrintoutOfRegister')"
            color="black"
            hide-details
          ></v-checkbox
        ></v-row>
        <v-row
          class="deal-type"
          style="padding-left: 32px; padding-right: 32px; margin-top: 14px"
        >
          <v-checkbox
            v-model="form.copyOfIdenification"
            :label="$t('CopyOfIdenification')"
            color="black"
            hide-details
          ></v-checkbox
        ></v-row>
        <v-row
          class="deal-type"
          style="padding-left: 32px; padding-right: 32px; margin-top: 14px"
        >
          <v-checkbox
            v-model="form.backgroundCheck"
            :label="$t('BackgroundCheck')"
            color="black"
            hide-details
          ></v-checkbox
        ></v-row>
        <v-row style="padding-left: 32px; margin-top: 32px" class="required"
          ><label>{{ $t("PoliticallyExposedPerson") }}</label></v-row
        >
        <v-row
          class="deal-type"
          style="padding-left: 32px; padding-right: 32px; margin-top: 14px"
        >
          <v-radio-group
            row
            v-model="form.politicallyExposedPerson"
            :error="isErrorPoliticallyExposedPerson"
          >
            <v-radio :label="$t('Yes')" :value="1"></v-radio>
            <v-radio :label="$t('No')" :value="0"></v-radio> </v-radio-group
        ></v-row>
        <v-row
          class="error-text"
          v-if="isErrorPoliticallyExposedPerson"
          style="margin: 8px 0 0 32px"
        >
          {{ $t("FormErrors.FieldRequired") }}
        </v-row>
        <v-row style="padding-left: 32px" class="required">
          <label>{{ $t("SubjectOfSanctions") }} </label></v-row
        >
        <v-row
          class="deal-type"
          style="padding-left: 32px; padding-right: 32px; margin-top: 14px"
        >
          <v-radio-group
            row
            v-model="form.subjectOfSanctions"
            :error="isErrorSubjectOfSanctions"
            hide-details
          >
            <v-radio :label="$t('Yes')" :value="1"></v-radio>
            <v-radio :label="$t('No')" :value="0"></v-radio> </v-radio-group
        ></v-row>
        <v-row
          class="error-text"
          v-if="isErrorSubjectOfSanctions"
          style="margin: 8px 0 0 32px"
        >
          {{ $t("FormErrors.FieldRequired") }}
        </v-row>

        <div
          style="
            padding-top: 16px;
            padding-bottom: 200px;
            padding-left: 32px;
            padding-right: 32px;
          "
        >
          <button
            v-bind:class="{'required': user.access.company._id === 'd4awXn68uHcnmKO8cJax'}"
            class="add-files-button"
            style="border: 1px dashed #000000; width: 112px; height: 32px"
            text
            @click="uploadFile()"
          >
            <img
              style="padding-right: 8px"
              src="../../assets/images/attach.svg"
              alt=""
            />
            <p
              class="content-small"
              style="
                font-weight: 400;
                min-width: 64px !important;
                justify-content: center;
              "
            >
              {{ $t("AttachFiles") }}
            </p>
          </button>
        <v-row
          class="error-text"
          v-if="isErrorFiles"
          style="margin: 16px 0 0 0px"
        >
          {{ $t("KYCMustIncludeFile") }}
        </v-row>
          <div style="width: 100%">
            <div class="sub-block">
              <div v-if="isUploading" class="overlay">
                <div class="overlay__inner">
                  <div class="overlay__content">
                    <span class="spinner"></span>
                  </div>
                </div>
              </div>
              <v-row
                class="file-row"
                style="margin-top: 16px"
                v-for="(el, index) in form.files"
                :key="index"
              >
                <span style="display: flex; gap: 8px">
                  <img
                    src="../../assets/images/action-delete.svg"
                    alt=""
                    style="cursor: pointer"
                    @click="deleteFile(el, true)"
                  />

                  <span class="file-name" style="text-decoration: none">
                    {{ el.name }}
                  </span>
                </span>
              </v-row>
              <input
                style="display: none"
                type="file"
                :id="'aml-file-uploader'"
                multiple
                @change="(event) => uploadFiles(event)"
              />
              <!--   <DeleteModal
                  @canceled="isDeleteModalOpen = false"
                  @approved="deleteFile(fileWaitingDeletion, false, interior)"
                  v-if="isDeleteModalOpen"
                /> -->
              <a href="" id="download-link" download></a>
            </div>
          </div>
        </div>
        <div class="buttons-container">
          <v-divider
            style="z-index: 111; position: fixed !important; width: 560px"
          ></v-divider>
          <div class="buttons-row" style="padding-left: 24px">
            <button @click="closeModal()" class="cancel">
              {{ $t("Cancel") }}
            </button>

            <button class="save-button" @click="saveAml(form)">
              <img
                style="padding-right: 8px"
                src="../../assets/images/Submit.svg"
                alt=""
              />
              {{ $t("Submit") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <DeleteModal
      :removing="false"
      @canceled="isDeleteModalOpen = false"
      @approved="deleteAml()"
      v-if="isDeleteModalOpen"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import DeleteModal from "@/components/common/DeleteModal";
import BaseDropdown from "@/components/common/BaseDropdown";
import nanoid from "nanoid";

export default {
  components: {
    DeleteModal,
    BaseDropdown,
  },
  name: "SidepanelAdd",
  props: ["isEdit", "noRefresh", "fromAnotherPanel", "contactPerson", "deal"],
  data() {
    return {
      formData: null,
      isUploading: false,
      validate: ["activeBusinessNew", "activeBusinessEdit"],
      clientAddOpen: false,
      chosenNewClient: null,
      isDeleteModalOpen: false,
      menu: false,
      passedStatus: false,
      showErrors: false,
      errors: null,
      isErrorActiveBusiness: false,
      isErrorPoliticallyExposedPerson: false,
      isErrorSubjectOfSanctions: false,
      isErrorFiles: false,
      form: {
        _id: nanoid(16),
        activeBusiness: "",
        businessRelationship: null,
        representationContract: false,
        powerOfAttorney: false,
        customerConfirmation: false,
        servicesRequested: false,
        printoutOfRegister: false,
        copyOfIdenification: false,
        backgroundCheck: false,
        politicallyExposedPerson: null,
        subjectOfSanctions: null,
        createdAt: Date.now(),
        updatedAt: null,
        files: [],
      },
    };
  },
  created() {
    this.formData = new FormData();
    if (this.isEdit) {
      this.form = JSON.parse(
        JSON.stringify(this.deal.aml[this.deal.aml.length - 1])
      );
      this.form._id = nanoid(16);
    }
  },
  mounted() {
    //document.documentElement.style.overflow = "hidden";
    window.onclick = (e) => {
      if (e.target.classList[0] === "overlay") {
        this.closeModal();
        e.target.style.display = "none";
      }
    };
  },
  beforeDestroy() {
    //document.documentElement.style.overflow = "visible";
  },
  computed: {
    ...mapGetters(["deals", "user"]),
  },
  watch: {
    deal: function () {
      this.form.files = this.deal.aml[this.deal.aml.length - 1].files;
    },
    form: {
      handler() {
        if (this.form.activeBusiness != null) {
          this.isErrorActiveBusiness = false;
        }
        if (this.form.politicallyExposedPerson != null) {
          this.isErrorPoliticallyExposedPerson = false;
        }
        if (this.form.subjectOfSanctions !== null) {
          this.isErrorSubjectOfSanctions = false;
        }
        if (this.form.files.length) {
          this.isErrorFiles = false
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions(["changeSidepanelStateDeals"]),
    uploadFile() {
      const fileContentLoaded = setInterval(() => {
        if (document.getElementById("aml-file-uploader")) {
          document.getElementById("aml-file-uploader").click();
          clearInterval(fileContentLoaded);
        }
      }, 100);
    },
    async uploadFiles(event) {
      this.isUploading = true;
      let selectedFiles = event.target.files;
      for (const value of Object.values(selectedFiles)) {
        this.form.files.push(value);
        const fileName = this.deal._id + "." + this.form._id + "." + value.name;
        this.formData.append("file", value, fileName);
      }
      this.isUploading = false;
    },
    async deleteFile(el) {
      this.form.files.forEach((file, index) => {
        if (file.name === el.name) {
          this.form.files.splice(index, 1);
        }
      });
    },
    closeModal() {
      this.$emit("closed");
    },
    isFormValid() {
      this.errors = this.getEmptyErrors();

      return this.validate
        .map((field) => {
          if (this.isEdit && field === "activeBusinessNew") return true;
          return this.isFieldValid(field);
        })
        .every((item) => item === true);
    },
    getEmptyErrors() {
      return {
        activeBusiness: "",
      };
    },
    isFieldValid(fieldName) {
      switch (fieldName) {
        case "activeBusinessNew":
          if (this.form && !this.form.activeBusiness) {
            this.errors.activeBusiness = "FieldRequired";
            return false;
          }

          break;
        /*  case "activeBusinessEdit":
          if (this.customerData && !this.customerData.firstName) {
            this.errors.firstName = "FieldRequired";
            return false;
          }

          break; */
      }

      return true;
    },
    async deleteAml() {
      const response = await axios.post(
        `/api/project/delete/aml/${this.deal._id}`
      );
      if (response.data === 0) {
        this.toastError(this.$t("ErrorDeleting"));
      } else {
        this.toastSuccess("AML " + this.$t("Deleted").toLowerCase());
        this.$emit("saveResponse", response.data);
        this.$emit("amlDeleted");
        this.closeModal();
        this.passedStatus = true;
      }
    },
    async saveAml(aml) {
      let activeBusinessFilled = false;
      let politicallyExposedPersonFilled = false;
      let subjectOfSanctionsFilled = false;
      let filesFilled = false
      if (aml.activeBusiness) {
        activeBusinessFilled = true;
      } else {
        this.isErrorActiveBusiness = true;
      }

      //FILE CHECK FOR DOMUS ONLY
      if (aml.files.length) {
        filesFilled = true
      } else if (this.user.access.company._id === 'd4awXn68uHcnmKO8cJax' && !aml.files.length) {
        this.isErrorFiles = true
      } else {
        filesFilled = true
      }

      if (
        aml.politicallyExposedPerson == 1 ||
        aml.politicallyExposedPerson == 0
      ) {
        politicallyExposedPersonFilled = true;
      } else {
        this.isErrorPoliticallyExposedPerson = true;
      }

      if (aml.subjectOfSanctions == 0 || aml.subjectOfSanctions == 1) {
        subjectOfSanctionsFilled = true;
      } else {
        this.isErrorSubjectOfSanctions = true;
      }

      if (
        activeBusinessFilled &&
        politicallyExposedPersonFilled &&
        subjectOfSanctionsFilled && filesFilled
      ) {
        //this.updatedAt = Date.now();
        let deal = this.deal;
        let newAmlVersion = this.form;
        newAmlVersion.creator = {
          userId: this.user._id,
          name: this.user.name,
          companyId: this.user.companyId,
        };

        newAmlVersion = JSON.stringify(newAmlVersion);
        await this.formData.append("newAmlVersion", newAmlVersion);
        if (this.deal.aml && this.deal.aml.length > 0) {
          let previousAmlId = this.deal.aml[this.deal.aml.length - 1]._id;
          this.formData.append("previousAmlId", previousAmlId);
        }

        let response = await axios.post(
          `/api/project/aml/${deal._id}`,
          this.formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (response.data === 0) {
          this.toastError(this.$t("ErrorCreating"));
        } else {
          this.toastSuccess("AML " + this.$t("Created").toLowerCase());

          this.$emit("saveResponse", response.data);
          this.closeModal();
          this.passedStatus = true;
          if (!this.isEdit) location.reload();
        }
      } else {
        if (!this.isFormValid()) {
          this.showErrors = true;
        }
        return;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "src/global_variables";

#wrapped-header-project,
#wrapped-header-project-with-name {
  display: none;
  position: relative;
  width: 100%;
  height: 100%;
  opacity: 0;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
  flex-direction: column;
  justify-content: flex-start;
  padding: 48px 24px 24px;
  border-right: 1px solid $grey500;

  h4 {
    white-space: nowrap;

    overflow: hidden;
    text-overflow: ellipsis;
    transform: rotate(180deg);
    writing-mode: vertical-lr;
    text-align: center;
    position: relative;
    justify-self: center;
    align-self: center;
  }
}
.buttons-row {
  justify-content: flex-end;
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: 24px 50px 64px;
  height: 112px;
  font-family: Inter !important;
  color: #000000;
  width: 560px;
  background-color: white;
  .cancel {
    background-color: transparent !important;
    box-shadow: 0px 0 2px rgba(28, 14, 3, 0.3);
    text-transform: none;
    padding: 12px;
    display: flex;
    align-items: center;
    height: 40px;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
  }
}
::v-deep .theme--light.v-select .v-select__selections {
  color: #000000;
  line-height: 20px;
}
.cancel-button {
  background-color: transparent !important;
  box-shadow: 0px 0 2px rgba(28, 14, 3, 0.3);
  text-transform: none;
  cursor: pointer;
  padding: 12px 16px;
  font-size: 14px;
  font-family: Inter;
  font-weight: 400 !important;
  line-height: 16px;
  height: 40px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
}
.pipeline-header {
  margin-top: 48px;
  width: 30%;
  p {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
  }
}
.pipelineFields {
  font-size: 14px;
  font-family: Inter;
  line-height: 1.2;
  gap: 8px 24px;
  display: flex;
  flex-flow: row wrap;
  padding-top: 24px;
  padding-bottom: 48px;
}
.pipelineSelector {
  font-size: 14px;
  font-family: Inter;
  line-height: 1.2;
  width: 100%;
  display: flex;
}
.pipelineSelector > input {
  margin: 0;
  padding: 0;
  -moz-appearance: none;
  -webkit-appearance: none;
}
.arrowLabel {
  width: 173px;
  cursor: pointer;
  height: 40px;
  display: flex;
  position: relative;
  font-size: 12px;
  background: #e6e8ec;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.pipelineSelector > input:checked + label:before {
  border-left-color: #75787a;
}
.arrowLabel:before {
  right: -10px;
  z-index: 2;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  z-index: 55;
  left: 16px;
  cursor: pointer;
}
input::-webkit-datetime-edit {
  position: relative;
  left: 18px;
}
input::-webkit-datetime-edit-fields-wrapper {
  position: relative;
  left: 18px;
}

.birth-class {
  border: 1px solid #e6e8ec;
  border-radius: 8px;
  width: 100%;
  font-family: Inter;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  height: 56px;
  padding-top: 0px !important;
  padding: 0px !important;
  padding-bottom: 0px !important;
  &:focus {
    outline: none !important;
  }
}

.arrowLabel:before {
  width: 0;
  bottom: 0;
  height: 0;
  content: "";
  position: absolute;
  border-top: 20px solid transparent;
  border-left: 10px solid #e6e8ec;
  border-bottom: 20px solid transparent;
}
.pipelineSelector > input:checked + label {
  color: #ffffff;
  background: #75787a;
}

.panel-wrapper {
  position: absolute;
  right: 0;
  top: 0%;
  bottom: 0%;
  background-color: #ffffff;
  padding-left: 0px;
  //padding-top: 48px;
  //padding-right: 56px;
  z-index: 7;
  width: 560px;
  border: 1px solid #e6e8ec;
  overflow-y: scroll;
  -webkit-transition: width 0.5s ease-in-out;
  -moz-transition: width 0.5s ease-in-out;
  -o-transition: width 0.5s ease-in-out;
  transition: width 0.5s ease-in-out;
}
#panel-wrapper-project {
  -webkit-transition: width 0.5s ease-in-out, right 0.5s ease-in-out,
    min-width 0.5s ease-in-out;
  -moz-transition: width 0.5s ease-in-out, right 0.5s ease-in-out,
    min-width 0.5s ease-in-out;
  -o-transition: width 0.5s ease-in-out, right 0.5s ease-in-out,
    min-width 0.5s ease-in-out;
  transition: width 0.5s ease-in-out, right 0.5s ease-in-out,
    min-width 0.5s ease-in-out;
}
.row {
  gap: 24px;
}
/* ::v-deep .inaadressSearchContainer {
  background: white;
} */

::v-deep .inaadressSearch,
::v-deep .ui-input-search {
  ul {
    //border: 1px solid #e6e8ec !important;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    background-color: white !important;
  }
  input {
    width: 80% !important;
    padding: 8px 12px;
    height: 52px;

    &:focus-visible {
      outline: none;
      border: none;
      //background-color: white !important;
    }

    &::after {
      content: url("../../assets/images/Dollar.svg");
    }
  }
}

::v-deep .ui-input-clear,
::v-deep .inAdsCopyright {
  display: none;
}
::v-deep #InAadressDiv {
  z-index: 5;
}
::v-deep .ui-filterable {
  border: 1px solid #e6e8ec !important;
  border-radius: 8px;
  background: white;
  &:focus-visible {
    border: none;
    outline: none;
  }
}

.no-active2 {
  width: 256px !important;
  max-height: 52px;
  z-index: 110;
}
.save-button {
  color: #ffffff;
  background: #000000;
  border: none;
  cursor: pointer;
  padding: 12px 16px;
  font-size: 14px;
  font-family: Inter;
  line-height: 16px;
  text-transform: none;
  width: 103px;
  height: 40px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: center;
}
.add-files-button {
  color: #000000;
  background: #ffffff;
  cursor: pointer;
  padding: 8px 12px;
  font-size: 14px;
  font-family: Inter;
  line-height: 16px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: center;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 8;
}

.top-row {
  width: 100%;
  justify-content: space-between;
}
.close-button {
  background: #f4f5f7;
  border-radius: 8px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -10px;
}
.divider {
  width: 100%;
  border: 1px solid #e6e8ec;
  margin-bottom: 16px;
  margin-top: 24px;
  left: 0;
  right: 0;
}
.no-active {
  width: 256px !important;
  max-height: 52px;
  z-index: 110;
}
.sticky-header {
  justify-content: flex-end;
  z-index: 111;
  padding-top: 40px;
  position: fixed;
  background: white;
  height: 97px;
  width: 560px;
}
.textarea {
  width: 100%;
  height: 96px;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #e6e8ec;
  font-family: Inter;
  font-size: 16px;
  line-height: 20px;
}
.textarea:focus {
  border: 1px solid #e6e8ec;
  outline: none;
  border-radius: 8px;
}
::v-deep .v-text-field--outlined fieldset {
  border: 1px solid #e6e8ec !important;
}
.probability {
  padding-right: 20%;
}
.field-row {
  margin-top: 24px !important;
}
.field-row2 {
  margin-top: 24px !important;
}
.address-selected {
  padding-top: 4px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  /* or 143% */

  display: flex;
  align-items: center;

  /* black */

  color: #000000;
}
.pipeline-name {
  display: none;
  white-space: nowrap;
  margin-left: 5px;
}
.pipeline-name-white {
  display: none;
  white-space: nowrap;
  margin-left: 5px;
  color: white !important;
}
.chosen {
  background-color: #f4f5f7;
}
.pipeline-middle {
  display: flex;
  flex-direction: row;
  flex: 0;
  align-items: center;
  width: 56px;
  height: 40px;
  padding-right: 0px;
  background-color: #e6e8ec;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.pipeline-image-front {
  width: 12px;
  path {
    fill: #e6e8ec !important;
  }
}
.path-colored {
  path {
    fill: #fff0d4 !important;
  }
}
.path-selected {
  path {
    fill: #000000 !important;
  }
}
.delete-button {
  margin-right: 168px;
  background-color: transparent !important;
  box-shadow: 0px 0 2px #ff1e24;
  text-transform: none;
  cursor: pointer;
  padding: 12px 16px;
  font-size: 14px;
  font-family: Inter;
  border-radius: 8px;
  color: #ff1e24;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: center;
  height: 40px;
}
.pipeline-left {
  position: absolute;
  left: -1px;
}
.pipeline-left-black {
  background-color: #000000 !important;
}
.pipeline-right-black {
  background-color: #000000 !important;
}

.buttons-container {
  justify-content: flex-end;
  padding-bottom: 64px;
  z-index: 109;
  position: fixed;
  background: white;
  bottom: 0;
  height: 104px;
  width: 560px;
}
::v-deep .ui-input-search {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  &:after {
    margin-right: 12px;
    margin-top: 6px;
    content: url("../../assets/images/search.svg");
  }
}
::v-deep .v-text-field__slot input {
  color: #000000 !important;
  //caret-color: black !important;
  font-family: Inter !important;
}

.pipeline-right {
  position: absolute;
  right: -1px;
}
.chosen-step-nr {
  color: white !important;
}
.chosen-step-name {
  display: initial;
  color: white !important;
}
.chosen-step-container {
  background-color: black !important;
  width: fit-content;
  padding-right: 20px;
}
.hover-step-container {
  background-color: #fff0d4;
  width: fit-content;
  padding-right: 20px;
}
//Unscrolling
.modal-open {
  overflow-y: hidden;
}
::v-deep .theme--light.v-input,
.theme--light.v-input input,
.theme--light.v-input textarea {
  color: #000000;
  font-family: Inter;
  line-height: 20px;
}
::v-deep .black--text {
  color: #ff5c01 !important;
  caret-color: #ff5c01 !important;
}
::v-deep .theme--light.v-input,
.theme--light.v-input input,
.theme--light.v-input textarea {
  color: #ff5c01 !important;
}

::v-deep .not-valid fieldset {
  border: 2px solid #ff1e24 !important;
}

::v-deep .not-valid input {
  border: 2px solid #ff1e24 !important;
}
</style>
