var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    key: 'deal-completed-' + _vm.elementKeyUpdater,
    staticClass: "deal-completed"
  }, [_c('div', {
    staticClass: "deal-completed-header"
  }, [_c('h5', [_vm._v(_vm._s(_vm.$t("DealSummary")))]), _vm.deal.pipelineStep !== 'archived' ? [_c('BaseButton', {
    attrs: {
      "icon-left": 'edit_16_16.svg',
      "label": _vm.$t('Edit'),
      "size": "small"
    },
    on: {
      "click": function ($event) {
        _vm.editProjectCompleteOpen = true;
      }
    }
  })] : _vm._e()], 2), _c('div', {
    staticClass: "deal-completed-value-container"
  }, [_c('div', {
    staticClass: "project-end-label content-bold",
    class: {
      'project-won': _vm.projectEndState === 'Won',
      'project-lost': _vm.projectEndState === 'Lost' || _vm.projectEndState === 'Cancelled'
    }
  }, [_vm._v(_vm._s(_vm.$t(_vm.projectEndState)) + " ")]), _vm.projectFinalValue ? _c('div', {
    staticClass: "project-value-details"
  }, [_c('div', {
    staticClass: "content-small"
  }, [_vm._v(_vm._s(_vm.$t("FinalDealValue")))]), _c('div', {
    staticClass: "font-20-bold"
  }, [_vm._v(_vm._s(parseInt(_vm.projectFinalValue.replace(" ", "")).toLocaleString("fr") + " €"))])]) : _vm._e(), _vm.projectBrokerageFee ? _c('div', {
    staticClass: "project-value-details"
  }, [_c('div', {
    staticClass: "content-small"
  }, [_vm._v(_vm._s(_vm.$t("FinalBrokerageFee")))]), _c('div', {
    staticClass: "font-20-bold"
  }, [_vm._v(_vm._s(_vm.projectBrokerageFee))])]) : _vm._e(), _vm.projectTransactionDate ? _c('div', {
    staticClass: "project-value-details"
  }, [_c('div', {
    staticClass: "content-small"
  }, [_vm._v(_vm._s(_vm.$t("DateOfTransaction")))]), _c('div', {
    staticClass: "font-20-bold"
  }, [_vm._v(_vm._s(_vm.formatDate(_vm.projectTransactionDate)))])]) : _vm._e()]), _vm.backedOutReason.who || _vm.backedOutReason.why ? _c('div', {
    staticClass: "deal-comment-container"
  }, [_vm.backedOutReason.who ? _c('h6', [_vm._v(_vm._s(_vm.formatBackedOut(_vm.backedOutReason.who)))]) : _vm._e(), _vm.backedOutReason.why ? _c('p', {
    staticClass: "content-normal"
  }, [_vm._v(_vm._s(_vm.backedOutReason.why))]) : _vm._e()]) : _vm._e(), _c('div', {
    staticClass: "deal-completed-listings-container"
  }), _vm.listingPanelOpen ? _c('ListingSidepanel', {
    attrs: {
      "listing-id": _vm.chosenListingId
    },
    on: {
      "overlayClicked": function ($event) {
        _vm.listingPanelOpen = false;
      }
    }
  }) : _vm._e(), _vm.editProjectCompleteOpen ? _c('ProjectEndModal', {
    attrs: {
      "deal": _vm.deal
    },
    on: {
      "projectWon": _vm.dealEndStateUpdated,
      "saveResponse": _vm.dealEndStateUpdated,
      "closed": function ($event) {
        _vm.editProjectCompleteOpen = false;
      }
    }
  }) : _vm._e(), _vm.showAnimation ? _c('div', {
    staticClass: "deal-won-animation"
  }, [_c('Lottie', {
    attrs: {
      "options": _vm.animationOptions
    },
    on: {
      "animCreated": _vm.handleAnimation
    }
  })], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }