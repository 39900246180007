var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "delete-overlay"
  }, [_c('div', {
    staticClass: "main-wrapper"
  }, [_c('v-row', {
    staticStyle: {
      "display": "flex",
      "flex-wrap": "nowrap"
    }
  }, [_c('img', {
    staticStyle: {
      "margin-right": "10px",
      "width": "20px",
      "height": "20px"
    },
    attrs: {
      "src": require("../../assets/images/info.svg"),
      "alt": ""
    }
  }), _c('p', {
    staticClass: "content"
  }, [_vm._v(_vm._s(_vm.$t('StageChangeWarning')))])]), _c('div', {
    staticClass: "buttons-row"
  }, [_c('button', {
    staticClass: "cancel content",
    staticStyle: {
      "line-height": "16px"
    },
    on: {
      "click": _vm.canceled
    }
  }, [_vm._v(_vm._s(_vm.$t("Cancel")))]), _c('button', {
    staticClass: "delete content",
    staticStyle: {
      "background-color": "black",
      "color": "white",
      "line-height": "16px"
    },
    on: {
      "click": _vm.approved
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('Yes')) + " ")])])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }