<template>
  <div v-if="contracts" class="contracts-content">
    <div
      class="contracts-card"
      :class="{
        'contract-card-first': index === 0,
        'contract-card-middle':
          index > 0 && index < contracts.length - 1,
        'contract-card-last': index === contracts.length - 1,
        'contract-card-only': contracts.length === 1,
      }"
      v-for="(contract, index) in contracts"
      :key="'marketing-card' + index"
    >
      <div
        class="contract-card-columns-container" @click="openContract(contract)" style="padding: 12px; gap: 8px; cursor:pointer"
      >         
          <v-row class="content" style="height:20px; width:366px;">
            <div >
            {{ contract.number }}    
            </div>
            <span style="margin-left: 8px; margin-right: 8px"> • </span>
            <div v-bind:class="{'project-class': !developmentView, 
            'development-class': developmentView}">
            {{formatContractType(contract.type)}} 
            </div>
          </v-row>
          
          <v-row class="content" style="height:20px; margin-top:0px; background: #F4F5F7; padding: 0px 4px; border-radius: 4px; max-width: fit-content">{{contract.startDate ? formatDate(contract.startDate) : "..."}} - {{contract.endDate ? formatDate(contract.endDate) : '...'}}</v-row>
      </div>
    </div>
    <v-row
        class="content"
        v-if="contracts.length === 0"
    >
      <p style="color: #75787a">
        {{ $t("NoContractsAdded") }}
      </p></v-row>
  </div>

</template>

<script>
import axios from "axios";
import moment from "moment";
//import nanoid from "nanoid";

export default {
  name: "ContractsList",
  components: {   
  },
  props: [
    "addButtonOutside",
    "projectId",
    "dealContracts",
    "updater",
    "developmentView"
  ],
  data() {
    return {
      contracts: [],
    };
  },
  watch: {
    contracts() {
      this.$emit("contractsCounter", this.contracts.length);
    },
    async updater() {
      await this.getContracts()
    }
  },
  created() {
    this.contracts = this.dealContracts
  },
  mounted() {
  },
  methods: {
    formatContractType(type) {
     if (type == "oral_exclusive_representation") {
        return this.$t("OralContractExclusiveRepresentation");
      } else if (type == "written_exclusive_representation") {
        return this.$t("WrittenContractExclusiveRepresentation");
      } else if (
          type === "oral_contract_representation"
      ) {
        return this.$t("OralContractRepresentation");
      } else if (
          type === "written_contract_representation"
      ) {
        return this.$t("WrittenContractRepresentation");
      } else if (type == "rental_representation_contract") {
        return this.$t("RentalRepresentationContract");
      }
      else if (type == "purchasing_representation_contract") {
        return this.$t("PurchasingRepresentationContract");
      }
      else if (type == "development_project_contract") {
        return this.$t("DevelopmentProjectContract");
      }
    },
    async getContracts() {
      let response
      if (this.developmentModule) {
        response = await axios.get(`/api/project/contracts/${this.projectId}`);
      } else {
        response = await axios.get(`/api/development/contracts/${this.projectId}`);
      }
      this.contracts = response.data.contracts[0].contracts;
    },
    formatDate(date) {
      if (date) {
        return moment(date).format("DD.MM.YYYY");
      } 
    },
    openContract(contract) {
      this.$emit("openContract", contract)
    },
   
  },
};
</script>

<style scoped>
.contracts-content {
  display: flex;
  flex-direction: column;
}

.contracts-card {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.contract-card-first {
  border: 1px solid #e6e8ec;
  border-radius: 8px 8px 0 0;
}

.contract-card-middle {
  border-left: 1px solid #e6e8ec;
  border-right: 1px solid #e6e8ec;
  border-bottom: 1px solid #e6e8ec;
}

.contract-card-last {
  border-bottom: 1px solid #e6e8ec;
  border-left: 1px solid #e6e8ec;
  border-right: 1px solid #e6e8ec;
  border-radius: 0 0 8px 8px;
}

.contract-card-only {
  border: 1px solid #e6e8ec;
  border-radius: 8px 8px 8px 8px;
}


.contract-card-columns-container {
  display: flex;
  width: 100%;
  min-height: 68px;
  justify-content: space-between;
  flex-direction: column;
}
.project-class {
  overflow: hidden;max-width: 67%;white-space: nowrap;text-overflow: ellipsis;
}
.development-class {
  overflow: hidden;max-width: 50%;white-space: nowrap;text-overflow: ellipsis;
}
</style>