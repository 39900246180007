<template>
  <div :key="refresher" class="object-wrapper">
    <!--  <div
      v-bind:class="{
        'object-header': isOpen,
        'object-header bottom-radius ': !isOpen,
      }"
    >
      <h5>{{ object.address }}</h5>
      <img
        v-bind:class="{
          open: isOpen,
          closed: !isOpen,
        }"
        src="../../../assets/images/collapse-down.svg"
        alt=""
      />
    </div> -->
    <!--  <div
      v-if="isOpen"
      v-bind:class="{
        'view-selectors margin-bottom-8': isOpen,
        'view-selectors ': !isOpen,
      }"
      class="view-selectors"
    >
      <p
        @click="view = 'listings'"
        v-bind:class="{
          'content-small': view === 'listings',
          'content-small not-active': view !== 'listings',
        }"
      >
        {{ $t("Listings") }}
      </p>
      <p
        @click="view = 'units'"
        v-bind:class="{
          'content-small': view === 'units',
          'content-small not-active': view !== 'units',
        }"
      >
        {{ $t("Units") }}
      </p>
    </div> -->
    <!-- <div class="listings-toolbar" v-if="isOpen && view === 'listings'">
      <button
        @click="$router.push({
        name: 'ListingsAddToProject',
        query: { dealId: $route.params.id, objectId: object._id }})"
        class="outlined-small-button"
        style="background-color: black !important"
      >
        <img
          src="../../../assets/images/plusSign.svg"
          style="
            filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(317deg)
              brightness(103%) contrast(102%);
          "
          alt=""
        />
        <p
          style="
            filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(317deg)
              brightness(103%) contrast(102%);
          "
        >
          {{ $t("AddListing") }}
        </p>
      </button>
      <button
        style=""
        @click="openLeads"
        v-bind:class="{
          'outlined-small-button semi-bold white-background': isLeadsOpen,
          'outlined-small-button': !isLeadsOpen,
        }"
      >
        <img src="../../../assets/images/leads.svg" alt="" />{{
          $t("ShowContacts")
        }}
      </button>
      <img class="point-separator" src="../../../assets/images/•.svg" alt="" />

      <div style="margin-left: 0px">
        <button class="outlined-small-button search-button">
          <img src="../../../assets/images/search.svg" alt="" />
          {{ $t("Search") }}
        </button>
      </div>
    </div>
 -->
    <Listings
      v-if="isOpen && view === 'listings'"
      :object-listings="object.listings"
      :listing-refresh-key="listingRefreshKey"
      :deal-identificator="dealId"
      :openHouse="openHouse"
      :is-project-inactive="isProjectInactive"
      :deal-pipeline-step="dealPipelineStep"
      @noListings="$emit('noListings')"
      @listingsExist="$emit('listingsExist')"
      @noArchivedListings="$emit('noArchivedListings')"
      @archivedListingsExist="$emit('archivedListingsExist')"
    />
    <Units
      @refresh="
        $emit('refresh');
        unitsRefresher++;
      "
      :project-type="projectType"
      :object="object"
      :refresh-key="refreshKey"
      v-if="isOpen && view === 'units'"
    />
  </div>
</template>

<script>
import Units from "@/components/Deals/Detail/Units";
import Listings from "@/components/Deals/Detail/Listings";
export default {
  name: "ProjectObject",
  props: ["object", "refreshKey", "projectType", "openHouse", "dealId", "listingRefreshKey", "isProjectInactive", "dealPipelineStep"],
  components: {
    Listings,
    Units,
  },
  data: () => {
    return {
      search: "",
      isOpen: true,
      view: "listings",
      unitsRefresher: 0,
      refresher: 0,
      isLeadsOpen: false,
    };
  },
  methods: {  
    
    /*  openLeads() {
      this.isLeadsOpen = !this.isLeadsOpen;
      let listingHeader = document.getElementById("listing-header");
      let listingContent = document.getElementById("listing-content");
      let sidepanelLeads = document.getElementById("sidepanel-leads");
      if (this.isLeadsOpen) {
        listingHeader.classList.remove("slide-animation-close");
        listingContent.classList.remove("slide-animation-close");
        sidepanelLeads.classList.remove("slide-close");
        listingHeader.classList.add("slide-animation-open");
        listingContent.classList.add("slide-animation-open");
        sidepanelLeads.classList.add("slide-open");
      } else {
        listingHeader.classList.remove("slide-animation-open");
        listingContent.classList.remove("slide-animation-open");
        sidepanelLeads.classList.remove("slide-open");
        listingHeader.classList.add("slide-animation-close");
        listingContent.classList.add("slide-animation-close");
        sidepanelLeads.classList.add("slide-close");
      }
    }, */
  },
};
</script>

<style lang="scss" scoped>
.object-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 72px;
  //max-width: 1300px;
  //background: #e6e8ec;
  //border: 1px solid #e6e8ec;
  box-sizing: border-box;
  border-radius: 8px;
  //margin: 16px 0px;
}
.object-header {
  display: flex;
  padding: 24px;
  flex-direction: row;
  align-items: center;
  background: white;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}
.bottom-radius {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.view-selectors {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px 24px 16px 24px;
  gap: 32px;

  width: 100%;
  height: 48px;
  background: #ffffff;
  p {
    cursor: pointer;
  }
}
.margin-bottom-8 {
  margin-bottom: 8px;
}
.open {
  transform: rotate(180deg);
}
.closed {
  transform: rotate(0deg);
}
.not-active {
  font-weight: normal;
}

.listings-toolbar {
  display: flex;
  width: 100%;
  background: white;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 24px;
  gap: 16px;
}
.left-row {
  justify-content: flex-start;
  .v-select {
    max-width: 250px;
  }
  .sector-type {
    margin-right: 42px;
  }
}
.right-row {
  justify-content: flex-end;
  .autocomplete {
    max-width: 300px;
    margin-right: 16px;
  }
  .icon-button {
    border: 1px solid #e4e4e4;
    height: 40px;
    width: 40px;
    display: flex;
    outline: none;
    padding: 16px;
    align-items: center;
    user-select: none;
    margin-right: 16px;
    margin-left: 16px;
    border-radius: 8px;

    justify-content: center;
  }
}
.filter-menu {
}
.filter-wrapper {
  padding: 22px 128px;
  background-color: white;
  flex-flow: wrap;

  display: flex;
  gap: 26px;
}
.v-menu__content {
  max-width: 100% !important;
}
.columns-wrapper {
  display: flex;
  max-width: 100%;
}
.right-column {
  margin-left: 26px;
}
.filter-row {
  flex-flow: nowrap;
  gap: 27px;
  align-items: baseline;
  .selects {
    gap: 20px;
  }
}
.select-filter {
  max-width: 300px;
}
.select-filter.v-text-field--outlined ::v-deep fieldset {
  border: none !important;
  background-color: #f4f5f7;
}
#detailView {
  padding-bottom: 22px;
}
#boardView {
  margin-left: 24px;
  padding-bottom: 22px;
}
#detailView,
#boardView {
  cursor: pointer;
}
.active-view {
  border-bottom: 1px solid black;
}
hr {
  flex-basis: 100%;
  height: 0;
  margin: 0;
  border: 0;
}
.v-text-field .v-input__control .v-input__slot {
  min-height: 34px !important;
  max-height: 34px !important;
}
.v-text-field--enclosed .v-input__prepend-inner {
  margin-top: 5.5px;
}
.v-icon.v-icon {
  font-size: 18px;
}
.theme--light.v-icon {
  color: black;
}
.v-text-field__slot input {
  font: revert !important;
}
</style>
