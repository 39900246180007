var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "height": "100%",
      "width": "100%"
    }
  }, [_c('div', {
    staticClass: "input-container",
    class: {
      'input-container-empty': !_vm.inputValid(),
      'input-container-value': _vm.inputValid(),
      required: _vm.required,
      'input-container-error': _vm.showError
    },
    style: _vm.customStyle,
    attrs: {
      "id": _vm.id
    },
    on: {
      "click": _vm.clickInput
    }
  }, [_vm.type === 'checkbox' && _vm.type !== 'map' && !_vm.disabled ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.value,
      expression: "value"
    }],
    staticClass: "input-field",
    attrs: {
      "id": _vm.id + '-input',
      "name": "name",
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.value) ? _vm._i(_vm.value, null) > -1 : _vm.value
    },
    on: {
      "focusin": _vm.setPlaceholderLineWidth,
      "focusout": _vm.inputValid,
      "change": function ($event) {
        var $$a = _vm.value,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_vm.value = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.value = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.value = $$c;
        }
      }
    }
  }) : _vm.type === 'radio' && _vm.type !== 'map' && !_vm.disabled ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.value,
      expression: "value"
    }],
    staticClass: "input-field",
    attrs: {
      "id": _vm.id + '-input',
      "name": "name",
      "type": "radio"
    },
    domProps: {
      "checked": _vm._q(_vm.value, null)
    },
    on: {
      "focusin": _vm.setPlaceholderLineWidth,
      "focusout": _vm.inputValid,
      "change": function ($event) {
        _vm.value = null;
      }
    }
  }) : _vm.type !== 'map' && !_vm.disabled ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.value,
      expression: "value"
    }],
    staticClass: "input-field",
    attrs: {
      "id": _vm.id + '-input',
      "name": "name",
      "type": _vm.type
    },
    domProps: {
      "value": _vm.value
    },
    on: {
      "focusin": _vm.setPlaceholderLineWidth,
      "focusout": _vm.inputValid,
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.value = $event.target.value;
      }
    }
  }) : _vm._e(), _vm.type === 'map' || _vm.view === 'organization-add' ? _c('GmapAutocomplete', {
    staticClass: "input-field",
    class: {
      hidden: _vm.disabled
    },
    attrs: {
      "placeholder": "",
      "id": _vm.id + '-input'
    },
    on: {
      "input": function ($event) {
        return _vm.mapInput($event);
      },
      "focus": _vm.setPlaceholderLineWidth,
      "focusout": _vm.inputValid,
      "place_changed": _vm.setPlace
    },
    model: {
      value: _vm.value,
      callback: function ($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  }) : _vm._e(), _vm.disabled ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.value,
      expression: "value"
    }],
    staticClass: "input-field",
    class: {
      'large-text': this.size === 'large',
      'small-text': this.size === 'small'
    },
    attrs: {
      "disabled": ""
    },
    domProps: {
      "value": _vm.value
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.value = $event.target.value;
      }
    }
  }) : _vm._e(), _vm.placeholder ? _c('label', {
    class: {
      'large-placeholder': this.size.toLowerCase() === 'large',
      'small-placeholder': this.size.toLowerCase() === 'small'
    },
    staticStyle: {
      "overflow": "hidden"
    },
    attrs: {
      "id": _vm.id + '-label'
    }
  }, [_vm._v(_vm._s(_vm.placeholder))]) : _vm._e(), _c('span', {
    staticClass: "input-container-label-background",
    attrs: {
      "id": _vm.id + '-label-background'
    }
  }), _vm.suffixIcon ? _c('img', {
    attrs: {
      "src": require(`@/assets/images/` + _vm.suffixIcon),
      "alt": ""
    }
  }) : _vm._e()], 1), _vm.showError && _vm.errorMessage ? _c('div', {
    staticStyle: {
      "color": "#ff1e24",
      "margin-top": "4px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("FormErrors." + _vm.errorMessage)) + " ")]) : _vm._e(), _c('div', {
    staticStyle: {
      "visibility": "hidden",
      "position": "absolute",
      "display": "inline-flex"
    },
    attrs: {
      "id": _vm.id + '-visibility'
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }