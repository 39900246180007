var render = function render(){
  var _this = this;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.deal ? _c('div', {
    key: _vm.dealUpdater,
    staticClass: "content",
    attrs: {
      "id": "overlay-project"
    }
  }, [_c('div', {
    staticClass: "panel-wrapper",
    attrs: {
      "id": "panel-wrapper-deal"
    }
  }, [!_vm.deal ? _c('div', {
    staticClass: "overlay"
  }, [_vm._m(0)]) : _vm._e(), _c('div', [_c('v-row', {
    staticClass: "inputFields",
    staticStyle: {
      "margin-top": "0px",
      "padding-top": "0px"
    }
  }, [_c('div', {
    staticClass: "stageSelector",
    staticStyle: {
      "padding": "16px",
      "padding-left": "32px"
    }
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "gap": "24px",
      "width": "100%"
    }
  }, [_vm.deal.pipelineStep !== 'archived' ? _c('h5', {
    staticStyle: {
      "white-space": "nowrap"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("ProjectStage")) + " ")]) : _vm._e(), _c('div', {
    staticStyle: {
      "display": "flex",
      "width": "100%"
    }
  }, [_vm.deal.pipelineStep !== 'archived' ? _vm._l(_vm.pipelineSteps, function (pipelineStep, index) {
    return _c('div', {
      key: pipelineStep.name,
      staticStyle: {
        "display": "flex",
        "flex-direction": "row",
        "cursor": "pointer"
      },
      on: {
        "mouseover": function ($event) {
          return _vm.hoverOpen($event, pipelineStep.name, pipelineStep.isChosen, index, pipelineStep.finalSelection);
        },
        "mouseleave": function ($event) {
          return _vm.hoverClose($event, pipelineStep.name, pipelineStep.isChosen, index, pipelineStep.finalSelection);
        },
        "click": function ($event) {
          return _vm.changePipelineStep(pipelineStep);
        }
      }
    }, [_c('div', {
      staticStyle: {
        "position": "relative"
      }
    }, [_c('div', {
      class: {
        'pipeline-middle': !pipelineStep.isChosen,
        'pipeline-middle chosen-step-container': pipelineStep.isChosen,
        'pipeline-left-black': index < _vm.pipelineSteps.indexOf(_vm.pipelineSteps.find(function (step) {
          return step.isChosen;
        })) || _vm.deal.pipelineStep === 'archived'
      },
      attrs: {
        "id": index + 'd',
        "alt": ""
      }
    }, [_c('img', {
      staticClass: "pipeline-left",
      attrs: {
        "src": require("../../../assets/images/pipeline-left.svg"),
        "alt": ""
      }
    }), _c('p', {
      class: {
        '': !pipelineStep.isChosen,
        'chosen-step-nr': pipelineStep.isChosen,
        'step-nr-white': index < _vm.pipelineSteps.indexOf(_vm.pipelineSteps.find(function (step) {
          return step.isChosen;
        })) || _vm.deal.pipelineStep === 'archived'
      },
      staticStyle: {
        "margin-left": "24px"
      },
      attrs: {
        "id": pipelineStep.name + 'nr'
      }
    }, [_vm._v(" " + _vm._s(index + 1) + " ")]), _c('p', {
      staticClass: "pipeline-name",
      class: {
        'pipeline-name': !pipelineStep.isChosen,
        'pipeline-name chosen-step-name': pipelineStep.isChosen,
        'step-nr-white': index < _vm.pipelineSteps.indexOf(_vm.pipelineSteps.find(function (step) {
          return step.isChosen;
        }))
      },
      attrs: {
        "id": pipelineStep.name + 'p'
      }
    }, [_vm._v(" " + _vm._s(pipelineStep.name) + " ")]), _c('img', {
      staticClass: "pipeline-right",
      attrs: {
        "src": require("../../../assets/images/pipeline-right.svg"),
        "alt": ""
      }
    })])])]);
  }) : _vm._e(), _vm.deal.pipelineStep === 'Done' && _vm.deal.pipelineStep !== 'archived' ? _c('div', {
    staticStyle: {
      "padding-left": "24px",
      "display": "flex",
      "align-items": "center"
    }
  }, [_c('div', {
    staticClass: "link",
    staticStyle: {
      "font-weight": "bold"
    },
    on: {
      "click": _vm.openArchiveConfirmModal
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("ArchiveVerb")) + " ")])]) : _vm._e(), _vm.deal.pipelineStep === 'archived' ? _c('div', {
    staticStyle: {
      "display": "flex",
      "justify-content": "space-between",
      "width": "100%",
      "align-items": "center"
    }
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "gap": "16px",
      "align-items": "center"
    }
  }, [_c('div', {
    staticClass: "archive-label"
  }, [_vm._v(_vm._s(_vm.$t("Archived")))]), _vm.deal.doneDate ? _c('div', {
    staticStyle: {
      "display": "flex",
      "align-items": "center"
    },
    style: {
      paddingLeft: _vm.deal.pipelineStep === 'archived' ? '0' : '24px'
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("ProjectCompleted") + " " + _vm.deal.doneDate) + " ")]) : _vm._e()]), _c('div', {
    staticStyle: {
      "display": "flex",
      "gap": "8px",
      "align-items": "center"
    }
  }, [_vm.creatingDuplicateProject ? _c('div', {
    staticStyle: {
      "height": "32px",
      "width": "32px",
      "margin-left": "52px"
    }
  }, [_c('div', {
    staticClass: "loader",
    staticStyle: {
      "height": "100%",
      "width": "100%"
    }
  })]) : [_c('BaseButton', {
    attrs: {
      "label": _vm.$t('DuplicateProjectAsNew'),
      "icon-left": 'duplicate_black_16_16.svg'
    },
    on: {
      "click": function ($event) {
        _vm.createDuplicateProjectConfirmationModalOpen = true;
      }
    }
  })], _vm.user.access.accessLevel === 'company_admin' || _vm.user.access.accessLevel === 'group_admin' ? _c('BaseButton', {
    attrs: {
      "label": _vm.$t('Restore'),
      "icon-left": 'undo_black_16_16.svg',
      "icon-right": 'chevron_24_24.svg',
      "items": _vm.pipelineSteps,
      "display-key": 'name',
      "display-value": 'value'
    },
    on: {
      "onConfirm": function (step) {
        return _vm.selectPipeline(null, step.name, step.isChosen, step.value, false, true);
      }
    }
  }) : _vm._e()], 2)]) : _vm._e()], 2)]), _vm.deal.pipelineStep !== 'archived' ? _c('div', {
    staticStyle: {
      "min-width": "fit-content",
      "margin-right": "8px"
    }
  }, [_vm.creatingDuplicateProject ? _c('div', {
    staticStyle: {
      "height": "32px",
      "width": "32px",
      "margin-left": "52px"
    }
  }, [_c('div', {
    staticClass: "loader",
    staticStyle: {
      "height": "100%",
      "width": "100%"
    }
  })]) : [_c('BaseButton', {
    attrs: {
      "label": _vm.$t('DuplicateProjectAsNew'),
      "icon-left": 'duplicate_black_16_16.svg'
    },
    on: {
      "click": function ($event) {
        _vm.createDuplicateProjectConfirmationModalOpen = true;
      }
    }
  })]], 2) : _vm._e(), _vm.deal.pipelineStep !== 'Done' && _vm.deal.pipelineStep !== 'archived' ? _c('div', {
    staticClass: "end-project-button",
    staticStyle: {
      "height": "32px",
      "white-space": "nowrap"
    },
    on: {
      "click": function ($event) {
        return _vm.openProjectEndPanel();
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("../../../assets/images/projectIcon16x16_white.svg"),
      "alt": ""
    }
  }), _c('p', [_vm._v(_vm._s(_vm.$t("CompleteProject")))])]) : _vm._e(), _c('v-menu', {
    key: _vm.updateEndState,
    attrs: {
      "close-on-content-click": true,
      "nudge-width": 124,
      "bottom": ""
    }
  }, [_vm.deal.endState ? _c('div', {
    staticClass: "outputs-modal",
    staticStyle: {
      "background-color": "white",
      "border-radius": "8px",
      "padding": "16px"
    }
  }, [_vm.deal.endState && _vm.deal.endState[_vm.deal.endState.length - 1].projectEndReason === 'Lost' ? _c('div', [_c('v-row', [_c('p', {
    staticClass: "content-medium"
  }, [_vm._v(" " + _vm._s(_vm.formatBackedOut(_vm.deal.endState[_vm.deal.endState.length - 1].backedOutPerson)) + " ")])]), _c('v-row', {
    staticStyle: {
      "margin-top": "8px"
    }
  }, [_c('p', {
    staticClass: "content-normal"
  }, [_vm._v(" " + _vm._s(_vm.deal.endState[_vm.deal.endState.length - 1].lostReason) + " ")])])], 1) : _vm._e(), _vm.deal.endState && _vm.deal.endState[_vm.deal.endState.length - 1].projectEndReason === 'Cancelled' ? _c('div', [_c('v-row', [_c('p', {
    staticClass: "content-medium"
  }, [_vm._v(" " + _vm._s(_vm.formatBackedOut(_vm.deal.endState[_vm.deal.endState.length - 1].cancelledPerson)) + " ")])]), _c('v-row', {
    staticStyle: {
      "margin-top": "8px"
    }
  }, [_c('p', {
    staticClass: "content-normal"
  }, [_vm._v(" " + _vm._s(_vm.deal.endState[_vm.deal.endState.length - 1].cancelledReason) + " ")])])], 1) : _vm._e(), _vm.deal.endState && _vm.deal.endState[_vm.deal.endState.length - 1].projectEndReason === 'Won' ? _c('div', [_c('v-row', [_c('p', {
    staticClass: "content-normal"
  }, [_vm._v(" " + _vm._s(_vm.$t("DealValue") + ": " + _vm.deal.endState[_vm.deal.endState.length - 1].amount + " " + _vm.deal.endState[_vm.deal.endState.length - 1].currency) + " ")])]), _vm.deal.endState[_vm.deal.endState.length - 1].dateOfTransaction ? _c('v-row', [_c('p', {
    staticClass: "content-normal"
  }, [_vm._v(" " + _vm._s(_vm.$t("DateOfTransaction") + ": " + _vm.formatDate(_vm.deal.endState[_vm.deal.endState.length - 1].dateOfTransaction)) + " ")])]) : _vm._e()], 1) : _vm._e()]) : _vm._e()])], 1)]), _c('v-row', {
    staticStyle: {
      "gap": "24px",
      "margin-top": "0px",
      "padding-bottom": "48px"
    }
  }, [_c('BaseDetailviewContainer', {
    attrs: {
      "width": '440px'
    }
  }, [_c('BaseAccordion', {
    attrs: {
      "id": "'generalData'",
      "identifier": 'generalData',
      "parent-name": 'project',
      "show-bot-divider": true,
      "title": _vm.$t('ProjectInfo'),
      "button-name": _vm.$t('Edit'),
      "padding-top": '0px',
      "button-icon": 'edit_16_16.svg',
      "show-button": _vm.deal.pipelineStep !== 'archived'
    },
    on: {
      "buttonPressed": function ($event) {
        return _vm.openSidepanelAdd(_vm.deal);
      }
    }
  }, [_vm.deal.dealType === 'Sale' && _vm.deal.aml[_vm.deal.aml.length - 1] && (_vm.deal.aml[_vm.deal.aml.length - 1].subjectOfSanctions == 1 || _vm.deal.aml[_vm.deal.aml.length - 1].politicallyExposedPerson == 1) ? _c('v-row', {
    staticStyle: {
      "background": "#fff0f0",
      "border-radius": "4px",
      "display": "flex",
      "justify-content": "center"
    }
  }, [_c('p', {
    staticClass: "content-bold",
    staticStyle: {
      "color": "#ff1e24"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("WarningHighRiskClient")) + " ")])]) : _vm._e(), _vm.deal.dealType ? _c('v-row', {
    staticClass: "customer-field-row content-bold"
  }, [_c('p', {
    staticClass: "deal-item",
    staticStyle: {
      "width": "176px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("DealType1")) + " ")]), _c('p', {
    class: {
      'content-bold content-small-green green-font': _vm.deal.dealType == 'Sale',
      'content-bold content-small-blue blue-font': _vm.deal.dealType == 'Purchase',
      'content-bold content-small-pink pink-font': _vm.deal.dealType.replace(/ /g, '').toLowerCase() == 'leaseout',
      'content-bold content-small-orange orange-font': _vm.deal.dealType.replace(/ /g, '').toLowerCase() == 'aquirelease',
      'content-bold content-small-yellow yellow-font': _vm.deal.dealType == 'Referral'
    },
    attrs: {
      "id": "deal-type"
    }
  }, [_vm._v(" " + _vm._s(_vm.formatDealType(_vm.deal.dealType)) + " ")])]) : _vm._e(), _vm.deal.probability ? _c('v-row', {
    staticClass: "customer-field-row top24"
  }, [_c('p', {
    staticClass: "deal-item",
    staticStyle: {
      "width": "176px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Probability")) + " ")]), _c('p', {
    staticClass: "deal-item-name",
    attrs: {
      "id": "deal-probability"
    }
  }, [_vm._v(" " + _vm._s(_vm.deal.probability) + " % ")])]) : _vm._e(), !_vm.deal.aml.length && _vm.deal.dealType == 'Sale' ? _c('v-row', {
    staticClass: "customer-field-row"
  }, [_c('p', {
    staticClass: "deal-item",
    staticStyle: {
      "width": "176px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("KnowYourCustomer")) + " ")]), _c('p', {
    staticClass: "deal-item-name",
    staticStyle: {
      "text-decoration": "underline",
      "font-weight": "600",
      "color": "#ff1e24",
      "cursor": "pointer"
    },
    on: {
      "click": function ($event) {
        return _vm.openAml();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("NotFilled")) + " ")])]) : _vm._e(), _vm.deal.aml.length && _vm.deal.aml[_vm.deal.aml.length - 1].activeBusiness && _vm.deal.dealType == 'Sale' ? _c('v-row', {
    staticClass: "customer-field-row"
  }, [_c('p', {
    staticClass: "deal-item",
    staticStyle: {
      "width": "176px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("KnowYourCustomer")) + " ")]), _c('p', {
    staticClass: "deal-item-name",
    staticStyle: {
      "text-decoration": "underline",
      "font-weight": "600",
      "color": "#0aaf60",
      "cursor": "pointer"
    },
    on: {
      "click": function ($event) {
        return _vm.openAmlDetailPanel();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Done")) + " ")])]) : _vm._e(), _vm.deal.service ? _c('v-row', {
    staticClass: "customer-field-row"
  }, [_c('p', {
    staticClass: "deal-item",
    staticStyle: {
      "width": "176px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Service")) + " ")]), _c('p', {
    staticClass: "deal-item-name"
  }, [_vm._v(_vm._s(_vm.deal.service))])]) : _vm._e(), _vm.deal.contactPerson.contactName != null ? _c('v-row', {
    staticClass: "customer-field-row",
    staticStyle: {
      "flex-wrap": "nowrap",
      "gap": "24px"
    }
  }, [_c('p', {
    staticClass: "deal-item",
    staticStyle: {
      "min-width": "176px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Contact")) + " ")]), _vm.deletedUser ? _c('p', {
    staticClass: "deal-item-name link",
    staticStyle: {
      "text-align": "initial",
      "word-break": "break-word"
    },
    on: {
      "click": function ($event) {
        return _vm.openCustomerDetailpanel(_vm.deal.contactPerson);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.deal.contactPerson.contactName) + " " + _vm._s(_vm.deletedUser) + " ")]) : _vm._e(), !_vm.deletedUser ? _c('p', {
    staticClass: "deal-item-name link",
    staticStyle: {
      "text-align": "initial",
      "word-break": "break-word"
    },
    on: {
      "click": function ($event) {
        return _vm.openCustomerDetailpanel(_vm.deal.contactPerson);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.deal.contactPerson.contactName) + " ")]) : _vm._e()]) : _vm._e(), _vm.deal.client ? _c('v-row', {
    staticStyle: {
      "flex-wrap": "nowrap",
      "gap": "24px"
    }
  }, [_c('p', {
    staticClass: "deal-item",
    staticStyle: {
      "min-width": "176px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Client")) + " ")]), _vm.deal.client.company_name ? _c('p', {
    staticClass: "deal-item-name link",
    staticStyle: {
      "text-align": "initial",
      "word-break": "break-word"
    },
    on: {
      "click": function ($event) {
        return _vm.openOrganization(_vm.deal.client);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.deal.client.company_name) + " ")]) : _vm._e(), !_vm.deal.client.company_name && _vm.deletedClient ? _c('p', {
    staticClass: "deal-item-name link",
    staticStyle: {
      "text-align": "initial",
      "word-break": "break-word"
    },
    on: {
      "click": function ($event) {
        return _vm.openCustomerDetailpanel(_vm.deal.client);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.deal.client.contactName) + " " + _vm._s(_vm.deletedClient) + " ")]) : _vm._e(), !_vm.deal.client.company_name && !_vm.deletedClient ? _c('p', {
    staticClass: "deal-item-name link",
    staticStyle: {
      "text-align": "initial",
      "word-break": "break-word"
    },
    on: {
      "click": function ($event) {
        return _vm.openCustomerDetailpanel(_vm.deal.client);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.deal.client.contactName) + " ")]) : _vm._e()]) : _vm._e(), _vm.deal.estimatedCloseDate ? _c('v-row', {
    staticClass: "customer-field-row"
  }, [_c('p', {
    staticClass: "deal-item",
    staticStyle: {
      "width": "176px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("ExpectedClosingDate")) + " ")]), _c('p', {
    staticClass: "deal-item-name",
    attrs: {
      "id": "deal-closing-date"
    }
  }, [_vm._v(" " + _vm._s(_vm.formatDate(_vm.deal.estimatedCloseDate)) + " ")])]) : _vm._e(), _vm.deal.amount && _vm.deal.priceHistoryLog ? _c('v-row', {
    staticClass: "customer-field-row price-info-dropdown"
  }, [_c('p', {
    staticClass: "deal-item",
    staticStyle: {
      "width": "176px"
    },
    attrs: {
      "id": "deal-value"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("ProjectValue")) + " ")]), _c('p', {
    staticClass: "deal-item-name",
    class: {
      'price-history-log': _vm.deal.priceHistoryLog && _vm.deal.priceHistoryLog.length
    },
    attrs: {
      "id": "deal-value-eur"
    },
    on: {
      "click": _vm.openPriceLogDropdown
    }
  }, [_vm._v(" " + _vm._s(_vm.$nMapper.price(_vm.deal.amount, "€")) + " ")]), _vm.deal.priceHistoryLog && _vm.deal.priceHistoryLog.length && _vm.dropdownIsOpen ? _c('div', {
    staticClass: "price-info-container customer-field-row price-info-container-hover"
  }, [_vm.deal.priceHistoryLog && _vm.deal.priceHistoryLog.length && _vm.dropdownIsOpen ? _c('div', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.closePriceLogDropdown,
      expression: "closePriceLogDropdown"
    }],
    staticClass: "price-history-info-dropdown price-info-container customer-field-row price-info-container-hover"
  }, _vm._l(_vm.deal.priceHistoryLog.slice(-20), function (info, index) {
    return _c('div', {
      key: 'price-info-' + index,
      staticClass: "price-info"
    }, [_c('div', {
      staticClass: "content-normal"
    }, [_vm._v(" " + _vm._s(_vm.formatDate(info.date)) + " ")]), _c('div', {
      staticClass: "content-semibold",
      staticStyle: {
        "width": "100%",
        "text-align": "right"
      }
    }, [_vm._v(" " + _vm._s(_vm.$nMapper.price(info.to, "€")) + " ")])]);
  }), 0) : _vm._e()]) : _vm._e()]) : _vm._e(), _vm.deal.brokerFeePercentage || _vm.deal.brokerageFee || _vm.deal.minimumFee ? _c('v-row', {
    staticClass: "customer-field-row"
  }, [_c('p', {
    staticClass: "deal-item",
    staticStyle: {
      "width": "176px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("BrokerageFee")) + " ")]), _c('p', {
    staticClass: "deal-item-name"
  }, [_vm._v(" " + _vm._s(_vm.brokerFee()) + " ")])]) : _vm._e(), _vm.deal.description ? _c('div', {
    staticClass: "separator",
    staticStyle: {
      "margin-top": "24px"
    }
  }) : _vm._e(), _vm.deal.description ? _c('v-row', [_c('div', {
    staticClass: "description",
    staticStyle: {
      "margin-top": "0px"
    }
  }, [_c('v-row', {
    staticClass: "description-item",
    staticStyle: {
      "margin-top": "0px",
      "padding-top": "0px"
    }
  }, [!_vm.showFullText ? _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.deal.description,
      expression: "deal.description"
    }],
    staticClass: "description-item1",
    staticStyle: {
      "padding-top": "24px"
    },
    attrs: {
      "outlined": "",
      "rows": "3",
      "id": "text-area",
      "disabled": !_vm.editMode
    },
    domProps: {
      "value": _vm.deal.description
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.deal, "description", $event.target.value);
      }
    }
  }) : _vm._e()]), _c('button', {
    staticStyle: {
      "padding-bottom": "12px",
      "text-decoration-line": "underline"
    },
    attrs: {
      "hidden": _vm.showFullText,
      "for": "expanded",
      "id": "textButton"
    },
    on: {
      "click": function ($event) {
        return _vm.showText();
      }
    }
  }, [_vm.deal.description && (_vm.deal.description.length > 274 || this.rowCount > 3) ? _c('p', {
    staticStyle: {
      "font-size": "12px",
      "line-height": "16px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("ShowMore")) + " ")]) : _vm._e()]), _c('v-row', {
    staticStyle: {
      "margin-top": "0px",
      "padding-top": "12px",
      "width": "410px"
    }
  }, [_vm.showFullText ? _c('v-textarea', {
    staticClass: "description-textarea",
    attrs: {
      "hide-details": "",
      "name": "input-7-1",
      "auto-grow": "",
      "disabled": ""
    },
    model: {
      value: _vm.deal.description,
      callback: function ($$v) {
        _vm.$set(_vm.deal, "description", $$v);
      },
      expression: "deal.description"
    }
  }) : _vm._e()], 1), _c('button', {
    staticStyle: {
      "padding-left": "0px",
      "padding-bottom": "24px",
      "text-decoration-line": "underline"
    },
    attrs: {
      "hidden": !_vm.showFullText,
      "for": "expanded",
      "id": "textButton"
    },
    on: {
      "click": function ($event) {
        return _vm.showTextLess();
      }
    }
  }, [_c('p', {
    staticStyle: {
      "font-size": "12px",
      "line-height": "16px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("ShowLess")) + " ")])])], 1)]) : _vm._e()], 1), _c('BaseAccordion', {
    attrs: {
      "button-opens-accordion": true,
      "title": _vm.$t('TasksAndEvents'),
      "identifier": 'tasks',
      "counter-bg-color-red": true,
      "parent-name": 'project',
      "show-bot-divider": true,
      "counter": _vm.tasksCounter,
      "button-icon": 'plus.svg',
      "button-name": _vm.$t('Add')
    },
    on: {
      "buttonPressed": function ($event) {
        _vm.openTaskAddWidgetCounter++;
      }
    }
  }, [_c('BaseTasksAndEvents', {
    attrs: {
      "open-new-task-add-widget": _vm.openTaskAddWidgetCounter,
      "labels-and-order": {
        overDueTasks: {
          label: false,
          order: 2
        },
        noDueDateTasks: {
          label: false,
          order: 1
        },
        todayTasks: {
          label: false,
          order: 3
        },
        upcomingTasks: {
          label: false,
          order: 4
        }
      },
      "view": {
        name: 'project',
        itemId: _vm.$route.params.id,
        itemName: _vm.deal.name
      },
      "show-toggle-button": true
    },
    on: {
      "tasks": _vm.tasksUpdated
    }
  })], 1), _c('BaseAccordion', {
    attrs: {
      "title": _vm.$t('Notes'),
      "is-add-button": true,
      "separator-line": true,
      "identifier": 'notes',
      "parent-name": 'project',
      "button-name": _vm.$t('Add'),
      "button-icon": 'plus.svg',
      "show-bot-divider": true,
      "button-opens-accordion": true,
      "counter": _vm.totalNotesCounter
    },
    on: {
      "buttonPressed": function ($event) {
        _vm.addNewNoteCounter++;
      }
    }
  }, [_c('BaseNotes', {
    attrs: {
      "add-new-note-counter": _vm.addNewNoteCounter,
      "view": {
        name: 'project',
        itemId: _vm.$route.params.id,
        itemName: _vm.deal.name
      }
    },
    on: {
      "notesCounter": function (value) {
        _this.totalNotesCounter = value;
      }
    }
  })], 1), _c('BaseAccordion', {
    attrs: {
      "show-bot-divider": true,
      "parent-name": 'project',
      "identifier": 'relatedPersons',
      "button-name": _vm.$t('Add'),
      "button-icon": 'plus.svg',
      "counter": _vm.countRelatedPersons(),
      "title": _vm.$t('Subblock.RelatedPersons.COMP'),
      "button-opens-accordion": true
    },
    on: {
      "buttonPressed": function ($event) {
        return _vm.openInlineAdd();
      }
    }
  }, [_c('v-col', {
    staticStyle: {
      "max-width": "456px",
      "padding": "0"
    }
  }, [_vm.addNewRelatedPerson ? _c('v-row', [_c('ClientListSearch', {
    attrs: {
      "placeholder": _vm.$t('UserSearch'),
      "icon-left": 'clients.svg'
    },
    on: {
      "addNew": _vm.addNewContact,
      "itemChosen": _vm.addContactToRelatedPeople
    }
  })], 1) : _vm._e(), _vm._l(_vm.dealRelatedCustomers, function (customer) {
    return _c('div', {
      key: customer.customerId
    }, [_c('v-row', {
      staticClass: "related-person-row"
    }, [_c('div', {
      staticClass: "relationIcon",
      on: {
        "click": function ($event) {
          return _vm.openCustomerDetailpanel(customer);
        }
      }
    }, [_c('span', {
      staticClass: "font-20-medium"
    }, [_vm._v(" " + _vm._s(customer.customerName[0].toUpperCase()) + " ")])]), _c('v-row', {
      staticStyle: {
        "justify-content": "space-between"
      }
    }, [_c('div', {
      staticClass: "related-person-name"
    }, [_c('span', {
      staticClass: "content-medium related-person-customer-name",
      on: {
        "click": function ($event) {
          return _vm.openCustomerDetailpanel(customer);
        }
      }
    }, [_vm._v(" " + _vm._s(customer.customerName) + " ")]), customer.organization ? _c('span', [_vm._v(" – ")]) : _vm._e(), customer.organization ? _c('span', [_vm._v(" " + _vm._s(customer.organization) + " ")]) : _vm._e(), customer.isPartner ? _c('span', [_vm._v("( Partner )")]) : _vm._e()]), _c('div', {
      staticStyle: {
        "display": "flex"
      }
    }, [_c('p', {
      staticClass: "content-medium",
      staticStyle: {
        "display": "flex",
        "align-items": "center",
        "cursor": "pointer"
      },
      attrs: {
        "id": "delete-modal-button"
      },
      on: {
        "click": function ($event) {
          return _vm.openDeleteModal(customer.customerId);
        }
      }
    }, [_c('img', {
      attrs: {
        "src": require("@/assets/images/close_grey.svg"),
        "alt": ""
      }
    })])])])], 1)], 1);
  }), _vm.dealRelatedCustomers && _vm.dealRelatedCustomers.length === 0 && !_vm.addNewRelatedPerson ? _c('v-row', {
    staticClass: "content",
    staticStyle: {
      "margin-top": "0px",
      "background-color": "white",
      "padding-left": "0px",
      "padding-top": "0px",
      "padding-bottom": "0px",
      "padding-right": "0px"
    }
  }, [_c('p', {
    staticStyle: {
      "color": "#75787a"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("NoRelatedPersonsAdded")) + " ")])]) : _vm._e()], 2)], 1), _c('BaseAccordion', {
    attrs: {
      "button-opens-accordion": true,
      "title": _vm.$t('Contracts'),
      "identifier": 'contracts',
      "parent-name": 'project',
      "show-bot-divider": true,
      "counter": _vm.contractsCounter,
      "button-icon": 'plus.svg',
      "button-name": _vm.$t('Add')
    },
    on: {
      "buttonPressed": _vm.openContractSidepanel
    }
  }, [_c('ContractsList', {
    attrs: {
      "project-id": _vm.deal._id,
      "deal-contracts": _vm.deal.contracts,
      "updater": _vm.contractListUpdater
    },
    on: {
      "contractsCounter": _vm.setContractsCounter,
      "openContract": _vm.openSelectedContract
    }
  })], 1), _c('BaseAccordion', {
    attrs: {
      "title": _vm.$t('Invoices'),
      "button-name": _vm.$t('Add'),
      "button-icon": 'plus.svg',
      "show-bot-divider": true,
      "parent-name": 'project',
      "identifier": 'invoices',
      "counter": _vm.invoicesCounter,
      "button-opens-accordion": true
    },
    on: {
      "buttonPressed": function ($event) {
        return _vm.openInvoiceAddPanel(_vm.deal);
      }
    }
  }, [_c('InvoiceWidget', {
    attrs: {
      "project": _vm.deal,
      "invoices": _vm.invoices,
      "loading": _vm.loadingInvoices
    },
    on: {
      "reloadInvoices": _vm.getInvoices
    }
  })], 1), _c('BaseAccordion', {
    attrs: {
      "title": _vm.$t('MarketingExpenses'),
      "button-name": _vm.$t('Add'),
      "button-icon": 'plus.svg',
      "show-bot-divider": true,
      "parent-name": 'project',
      "identifier": 'marketingExpenses',
      "counter": _vm.marketingExpensesCounter,
      "button-dropdown-items": _vm.marketingExpenseOptions,
      "button-display-key": "name",
      "button-value-key": "value",
      "button-opens-accordion": true
    },
    on: {
      "dropdownItemSelected": _vm.openMarketingExpenseAdd
    }
  }, [_c('MarketingExpenses', {
    attrs: {
      "add-button-outside": _vm.addMarketingExpense,
      "project-id": _vm.deal._id
    },
    on: {
      "marketingExpenseCounter": _vm.setMarketingExpenseCounter,
      "resetType": function ($event) {
        _vm.addMarketingExpense = null;
      }
    }
  }), _vm.marketingExpensesCounter === 0 ? _c('v-row', {
    staticClass: "content",
    staticStyle: {
      "margin-top": "0px",
      "background-color": "white",
      "padding-left": "0px",
      "padding-top": "0px",
      "padding-right": "0px"
    }
  }, [_c('p', {
    staticStyle: {
      "color": "#75787a"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("NoMarketingExpenseAdded")) + " ")])]) : _vm._e()], 1), _c('BaseAccordion', {
    attrs: {
      "identifier": 'files',
      "parent-name": 'project',
      "title": _vm.$t('Files'),
      "counter": _vm.deal.files.length,
      "button-name": _vm.$t('Add'),
      "button-icon": 'plus.svg',
      "button-opens-accordion": true
    },
    on: {
      "buttonPressed": function ($event) {
        return _vm.fileUpload();
      }
    }
  }, [_c('FileUpload', {
    attrs: {
      "files": _vm.deal.files,
      "targetId": _vm.deal._id,
      "target-object": 'project',
      "showButtons": true,
      "id": 'project-general-upload',
      "aml-panel": 1
    },
    on: {
      "success": _vm.getDeal,
      "update:files": function ($event) {
        return _vm.$set(_vm.deal, "files", $event);
      }
    }
  }), _vm.deal.files.length === 0 ? _c('v-row', {
    staticClass: "content",
    staticStyle: {
      "margin-top": "0px",
      "background-color": "white",
      "padding-left": "0px",
      "padding-top": "0px",
      "padding-right": "0px"
    }
  }, [_c('p', {
    staticStyle: {
      "color": "#75787a"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("NoFilesAdded")) + " ")])]) : _vm._e()], 1)], 1), _vm.deal.dealType == 'Sale' || _vm.deal.dealType.replace(/ /g, '').toLowerCase() == 'leaseout' || _vm.deal.dealType == 'Referral' || _vm.deal.dealType == 'AquireLease' || _vm.deal.dealType == 'Purchase' ? _c('v-col', {
    staticClass: "right-col",
    staticStyle: {
      "height": "fit-content"
    }
  }, [_c('div', {
    staticClass: "project-second-header"
  }, [_c('div', {
    staticClass: "project-actions-wrapper"
  }, [_vm.deal.pipelineStep !== 'archived' ? [_c('ButtonDropdown', {
    attrs: {
      "items": _vm.documentTemplates,
      "display-key": 'name',
      "value-key": 'value',
      "icon": 'document.svg',
      "button-label": _vm.$t('GenerateDocument'),
      "color-reverse": true,
      "custom-style-button": "padding: 8px 12px"
    },
    on: {
      "itemSelected": _vm.openDocument
    }
  })] : _vm._e(), _vm.deal.dealType == 'Sale' || _vm.deal.dealType.replace(/ /g, '').toLowerCase() == 'leaseout' ? _c('div', {
    staticClass: "client-report-preview-button",
    on: {
      "click": _vm.openClientReport
    }
  }, [_c('img', {
    attrs: {
      "src": require("../../../assets/images/report.svg"),
      "alt": ""
    }
  }), _c('p', [_vm._v(_vm._s(_vm.$t("ClientReport")))])]) : _vm._e(), _c('div', {
    staticClass: "client-report-preview-button",
    on: {
      "click": _vm.openTransactionDetails
    }
  }, [_c('img', {
    staticClass: "transactions-icon img16",
    staticStyle: {
      "filter": "invert(1)"
    },
    attrs: {
      "src": require("../../../assets/images/transactions.svg"),
      "alt": ""
    }
  }), _c('p', [_vm._v(_vm._s(_vm.$t("TransactionDetails")))])])], 2)]), _c('div', [_c('div', {
    staticClass: "separator",
    staticStyle: {
      "border-top": "8px solid #f4f5f7"
    }
  }), _vm.deal.dealType == 'Sale' || _vm.deal.dealType.replace(/ /g, '').toLowerCase() == 'leaseout' ? _c('v-col', {
    staticClass: "objects-wrapper",
    class: {
      'align-center': _vm.deal.objects.length < 1,
      'align-start': _vm.deal.objects.length
    },
    staticStyle: {
      "padding": "0"
    }
  }, [(_vm.deal.pipelineStep === 'Done' || _vm.deal.pipelineStep === 'archived') && _vm.deal.endState && _vm.deal.endState.length > 0 ? [_c('CompletedContainer', {
    key: 'complete-container-key-' + _vm.objectRefresher,
    attrs: {
      "deal": _vm.deal
    },
    on: {
      "updateDealEndState": _vm.getDeal
    }
  }), _c('div', {
    staticClass: "separator",
    staticStyle: {
      "border-top": "8px solid #f4f5f7"
    }
  })] : _vm._e(), [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.noListings,
      expression: "!noListings"
    }],
    staticStyle: {
      "width": "100%"
    }
  }, _vm._l(_vm.deal.objects, function (object) {
    return _c('ProjectObject', {
      key: object._id,
      attrs: {
        "object": object,
        "deal-id": _vm.deal._id,
        "project-type": _vm.deal.dealType,
        "refresh-key": _vm.objectRefresher,
        "listing-refresh-key": _vm.listingNameRefresher,
        "openHouse": _vm.openHouse,
        "is-project-inactive": _vm.isProjectInactive,
        "deal-pipeline-step": _vm.deal.pipelineStep
      },
      on: {
        "refresh": _vm.getDeal,
        "noListings": function ($event) {
          return _vm.showActiveListingsStatus(true);
        },
        "listingsExist": function ($event) {
          return _vm.showActiveListingsStatus(false);
        },
        "noArchivedListings": function ($event) {
          return _vm.showArchivedListingExist(true);
        },
        "archivedListingsExist": function ($event) {
          return _vm.showArchivedListingExist(false);
        }
      }
    });
  }), 1), _c('v-col', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.noListings && !_vm.archivedListingsEmpty,
      expression: "noListings && !archivedListingsEmpty"
    }],
    staticStyle: {
      "background-color": "white",
      "width": "100%",
      "display": "flex",
      "align-content": "flex-end",
      "align-items": "center",
      "justify-content": "center",
      "border-radius": "8px"
    }
  }, [_c('div', {
    staticClass: "add-object-to-start",
    staticStyle: {
      "display": "flex",
      "justify-content": "flex-end",
      "flex-direction": "column"
    }
  }, [_c('v-row', [_vm._v(_vm._s(_vm.$t("NoListingsInProject")))]), _vm.deal.statusCode !== 0 ? _c('v-row', {
    staticStyle: {
      "display": "flex",
      "justify-content": "center"
    }
  }, [_c('button', {
    staticClass: "outlined-small-button",
    staticStyle: {
      "background-color": "black !important",
      "margin-top": "16px",
      "margin-left": "24px",
      "height": "32px",
      "align-items": "center",
      "border": "0"
    },
    attrs: {
      "name": "add-contact-button"
    },
    on: {
      "click": function ($event) {
        return _vm.addListing();
      }
    }
  }, [_c('img', {
    staticStyle: {
      "filter": "invert(100%) sepia(0%) saturate(0%)"
    },
    attrs: {
      "src": require("@/assets/images/plusSign.svg"),
      "alt": ""
    }
  }), _c('p', {
    staticStyle: {
      "filter": "invert(100%) sepia(0%) saturate(0%)"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("AddListing")) + " ")])])]) : _vm._e()], 1)]), _c('v-col', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.noListings && _vm.archivedListingsEmpty,
      expression: "noListings && archivedListingsEmpty"
    }],
    staticStyle: {
      "background-color": "white",
      "width": "100%",
      "display": "flex",
      "align-content": "flex-end",
      "align-items": "center",
      "justify-content": "center",
      "border-radius": "8px",
      "margin-bottom": "0px"
    }
  }, [_c('div', {
    staticClass: "add-object-to-start",
    staticStyle: {
      "display": "flex",
      "justify-content": "flex-end",
      "flex-direction": "column"
    }
  }, [_c('v-row', [_vm._v(_vm._s(_vm.$t("NoListingsArchived")))])], 1)])]], 2) : _c('v-col', [[_c('v-row', {
    staticStyle: {
      "display": "flex",
      "justify-content": "space-between",
      "padding": "32px 0px",
      "align-items": "center"
    }
  }, [_c('div', {
    staticStyle: {
      "padding-left": "32px"
    }
  }, [_c('h5', [_vm._v(_vm._s(_vm.$t("Interests")))])]), _c('div', [_c('div', {
    staticStyle: {
      "display": "flex",
      "align-items": "center",
      "padding-right": "32px"
    }
  }, [_c('button', {
    staticClass: "outlined-small-button",
    on: {
      "click": function ($event) {
        return _vm.openInterests();
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("../../../assets/images/plusSign.svg"),
      "alt": ""
    }
  }), _c('p', [_vm._v(" " + _vm._s(_vm.$t("AddInterest")) + " ")])])])])]), _c('v-col', {
    staticClass: "objects-wrapper",
    class: {
      'align-center': _vm.interests.length < 1,
      'align-start': _vm.interests.length
    },
    staticStyle: {
      "background-color": "white",
      "padding-left": "24px",
      "padding-right": "32px"
    }
  }, [_vm.interestsLoaded && _vm.interests.length < 1 ? _c('div', {
    staticClass: "padding-wrapper",
    staticStyle: {
      "padding": "50px"
    },
    attrs: {
      "id": "add-object-start-text2"
    }
  }, [_c('p', {
    staticClass: "add-object-to-start"
  }, [_vm._v(" " + _vm._s(_vm.$t("AddAnInterestToStart")) + " ")])]) : _vm._e(), _c('ProjectInterests', {
    key: _vm.interestsUpdater,
    attrs: {
      "project-id": _vm.deal._id,
      "deal-type": _vm.deal.dealType,
      "listing-key": _vm.listingKey
    },
    on: {
      "gotInterests": _vm.setInterests,
      "openListingPanel": _vm.openListingPanel,
      "funnelRemoved": function ($event) {
        return _vm.$emit('funnelRemoved');
      },
      "closeListingPanel": _vm.closeListingPanel,
      "refreshLeads": function ($event) {
        return _vm.$emit('refreshLeads');
      },
      "editInterest": _vm.openInterests
    }
  })], 1)]], 2)], 1)]) : _vm._e()], 1)], 1)]), _vm.interestsOpen ? _c('InterestPanel', {
    attrs: {
      "existing-interest": _vm.interestToEdit,
      "project-id": _vm.deal._id,
      "deal-type": _vm.deal.dealType,
      "project-interests": true
    },
    on: {
      "closed": function ($event) {
        return _vm.closeInterests();
      }
    }
  }) : _vm._e(), _vm.editModalOpen ? _c('SidepanelAdd', {
    attrs: {
      "dealData": _vm.deal,
      "contact-person": _vm.deal.contactPerson,
      "isEdit": true
    },
    on: {
      "closed": function ($event) {
        return _vm.closeSidepanelAdd();
      }
    }
  }) : _vm._e(), _vm.notarySidepanelOpen ? _c('NotarySidepanel', {
    attrs: {
      "fromAnotherPanel": false,
      "deal": this.deal
    },
    on: {
      "panelClosed": function ($event) {
        _vm.closeNotarySidepanel();
        _vm.getDeal();
      }
    }
  }) : _vm._e(), _vm.notaryBaseDocSidepanelOpen ? _c('NotaryBaseDocSidepanel', {
    attrs: {
      "fromAnotherPanel": false,
      "deal": this.deal
    },
    on: {
      "panelClosed": function ($event) {
        _vm.closeNotaryBaseDocSidepanel();
        _vm.getDeal();
      }
    }
  }) : _vm._e(), _vm.detailPanelOpen ? _c('ClientSidepanel', {
    attrs: {
      "customerData": _vm.sidePanelData,
      "from-project-general": true
    },
    on: {
      "panelClosed": function ($event) {
        return _vm.closeCustomerDetailPanel();
      }
    }
  }) : _vm._e(), _vm.relatedCustomersModalOpen ? _c('Modal', {
    attrs: {
      "customerData": _vm.customer,
      "from-project-gen": true
    },
    on: {
      "modalClosed": function ($event) {
        return _vm.closeRelatedCustomersModal();
      },
      "addRelatedPerson": _vm.addRelatedPerson
    }
  }) : _vm._e(), _vm.isDeleteModalOpen ? _c('DeleteModal', {
    attrs: {
      "removing": true
    },
    on: {
      "canceled": function ($event) {
        _vm.isDeleteModalOpen = false;
      },
      "approved": function ($event) {
        return _vm.deleteRelatedCustomer();
      }
    }
  }) : _vm._e(), _vm.selectedPipelineStep ? _c('StageChangeWarningModal', {
    on: {
      "canceled": function ($event) {
        _vm.selectedPipelineStep = null;
      },
      "approved": function ($event) {
        return _vm.selectPipeline(null, _vm.selectedPipelineStep.name, _vm.selectedPipelineStep.isChosen, _vm.selectedPipelineStep.value, _vm.selectedPipelineStep.index);
      },
      "closePanel": function ($event) {
        _vm.selectedPipelineStep = null;
      }
    }
  }) : _vm._e(), _vm.openAmlPanel ? _c('AmlSidepanelAdd', {
    attrs: {
      "deal": _vm.deal
    },
    on: {
      "refresher": function ($event) {
        return _vm.getDeal();
      },
      "closed": function ($event) {
        return _vm.closeAml();
      },
      "saveResponse": function ($event) {
        return _vm.getDeal();
      }
    }
  }) : _vm._e(), _vm.openAmlDetail ? _c('AmlDetailSidepanel', {
    attrs: {
      "deal": _vm.deal
    },
    on: {
      "refresh": function ($event) {
        return _vm.getDeal();
      },
      "closed": _vm.closeAmlDetailPanel,
      "amlDeleted": function ($event) {
        return _vm.amlDeleted();
      }
    }
  }) : _vm._e(), _vm.contractSidepanelAddOpen ? _c('ContractSidepanelAdd', {
    attrs: {
      "project": _vm.deal,
      "contract": _vm.selectedContract
    },
    on: {
      "closePanel": function ($event) {
        _vm.contractSidepanelAddOpen = false;
      },
      "newContractAdded": function ($event) {
        _vm.contractListUpdater++;
      },
      "contractDeleted": function ($event) {
        _vm.contractListUpdater++;
      },
      "contractUpdated": _vm.updateSelectedContract
    }
  }) : _vm._e(), _vm.listingPanelOpen ? _c('ListingSidepanel', {
    attrs: {
      "listing-preview-id": _vm.chosenListingPreviewId,
      "project-view": true
    },
    on: {
      "closeListingPanel": function ($event) {
        return _vm.closePanel($event);
      }
    }
  }) : _vm._e(), _vm.orgDetailOpen ? _c('OrganizationSidepanel', {
    attrs: {
      "selected-organization": _vm.clickedOrg,
      "project-view": true
    },
    on: {
      "panelClosed": function ($event) {
        return _vm.orgDetailClose();
      }
    }
  }) : _vm._e(), _vm.addObjectOpen ? _c('AddObjectPopover', {
    attrs: {
      "deal": _vm.deal,
      "position": _vm.position
    },
    on: {
      "closed": function ($event) {
        _vm.addObjectOpen = false;
      },
      "refreshDeal": _vm.getDeal
    }
  }) : _vm._e(), _vm.widgetOpen ? _c('CalendarWidget', {
    attrs: {
      "taskValue": _vm.taskValue,
      "project": _vm.deal,
      "contact": {
        id: _vm.deal.client.contactId,
        name: _vm.deal.client.contactName
      }
    },
    on: {
      "update": function ($event) {
        _vm.taskUpdater++;
      },
      "close": function ($event) {
        _vm.getTasks();
        _vm.taskValue = null;
        _vm.widgetOpen = false;
      }
    }
  }) : _vm._e(), _vm.taskWidgetOpen ? _c('EditPanel', {
    attrs: {
      "clicked-task": _vm.taskWidgetTask
    },
    on: {
      "deleteTask": _vm.deleteTask,
      "updateKey": _vm.getTasks,
      "closePanel": function ($event) {
        _vm.taskWidgetOpen = false;
      }
    }
  }) : _vm._e(), _vm.clientAddOpen ? _c('AddModal', {
    staticStyle: {
      "z-index": "1200"
    },
    attrs: {
      "not-contact-view-route": true
    },
    on: {
      "closed": function ($event) {
        _vm.clientAddOpen = false;
      },
      "newAddedContact": _vm.addContactToRelatedPeople
    }
  }) : _vm._e(), _vm.documentSidepanelOpen ? _c('DocumentSidepanel', {
    attrs: {
      "template-id": _vm.templateId,
      "project-id": _vm.chosenObject,
      "customers": _vm.customers
    },
    on: {
      "closePanel": function ($event) {
        _vm.documentSidepanelOpen = false;
      }
    }
  }) : _vm._e(), _vm.projectEndModal ? _c('ProjectEndModal', {
    attrs: {
      "deal": _vm.deal
    },
    on: {
      "closed": function ($event) {
        return _vm.closeProjectEndPanel();
      },
      "projectWon": _vm.playAnimation,
      "saveResponse": function () {
        return _vm.getDeal();
      }
    }
  }) : _vm._e(), _vm.transactionPanelOpened ? _c('TransactionsDetail', {
    attrs: {
      "transactionProjectId": _vm.deal._id,
      "update-detail-panel": _vm.detailPanelUpdater
    },
    on: {
      "updateTransaction": _vm.updateTransaction,
      "openStatusDropdown": _vm.openStatusDropDown,
      "closePanel": _vm.closeDetailPanel
    }
  }) : _vm._e(), _vm.statusOpen ? _c('PaymentStatusDropdown', {
    attrs: {
      "position": _vm.position,
      "transaction": _vm.selectedTransaction
    },
    on: {
      "closed": function ($event) {
        _vm.statusOpen = false;
      },
      "statusChanged": _vm.changeTransactionStatus
    }
  }) : _vm._e(), _vm.archiveProjectConfirmModalOpen || _vm.createDuplicateProjectConfirmationModalOpen ? _c('ConfirmationModal', {
    attrs: {
      "text-start-icon": 'info.svg'
    },
    on: {
      "onConfirm": _vm.approveConfirmationModal,
      "onCancel": _vm.closeConfirmationModal
    }
  }, [_c('p', {
    staticClass: "content-normal",
    staticStyle: {
      "padding": "2px 0"
    }
  }, [_vm._v(" " + _vm._s(_vm.archiveProjectConfirmModalOpen ? _vm.$t("ConfirmProjectArchive") : _vm.$t("ConfirmProjectDuplicate")) + " ")])]) : _vm._e(), _vm.showAnimation ? _c('div', {
    staticClass: "deal-won-animation"
  }, [_c('Lottie', {
    attrs: {
      "options": _vm.animationOptions
    },
    on: {
      "animCreated": _vm.handleAnimation
    }
  })], 1) : _vm._e()], 1) : _vm._e();

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "overlay__inner"
  }, [_c('div', {
    staticClass: "overlay__content"
  }, [_c('span', {
    staticClass: "spinner"
  })])]);

}]

export { render, staticRenderFns }