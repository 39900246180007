<template>
  <div v-if="marketingExpenses" class="marketing-expenses-content">
    <div
      class="marketing-expenses-card"
      :class="{
        'marketing-expenses-card-first': index === 0,
        'marketing-expenses-card-middle':
          index > 0 && index < marketingExpenses.length - 1,
        'marketing-expenses-card-last': index === marketingExpenses.length - 1,
        'marketing-expenses-card-only': marketingExpenses.length === 1,
      }"
      v-for="(marketingExpense, index) in marketingExpenses"
      :key="'marketing-card' + index"
    >
      <div
        style="width: 100%; padding: 12px; min-height: 52px"
        v-if="
          addNewMarketingExpenseOpen &&
          index === 0 &&
          addNewMarketingExpenseType === 'levelService'
        "
      >
        <div>
          <div
            style="
              display: flex;
              flex-direction: column;
              gap: 16px;
              height: 199px;
              padding: 16px;
              border-top: 1px solid #e6e8ec;
              border-right: 1px solid #e6e8ec;
              border-left: 1px solid #e6e8ec;
              border-radius: 8px 8px 0 0;
            "
          >
            <div
              class="required"
              style="display: flex; gap: 8px; height: 32px; flex-wrap: nowrap"
            >
              <label
                class="label12"
                style="width: 96px; height: 16px; color: #75787a"
                >{{ $t("Portal") }}</label
              >
              <div style="display: flex; flex-wrap: nowrap; flex: 1 1 0">
                <div
                  @click="setPortal('KV')"
                  class="content-small portal-option"
                  :class="{
                    'selected-portal': newMarketingService.portal === 'KV',
                    'selected-portal-error-1': servicePortalError,
                  }"
                  style="border: 1px solid #e6e8ec; border-radius: 8px 0 0 8px"
                >
                  KV
                </div>
                <div
                  @click="setPortal('KV24')"
                  class="content-small portal-option"
                  :class="{
                    'selected-portal': newMarketingService.portal === 'KV24',
                    'selected-portal-error-2': servicePortalError,
                  }"
                  style="
                    border-right: 1px solid #e6e8ec;
                    border-top: 1px solid #e6e8ec;
                    border-bottom: 1px solid #e6e8ec;
                  "
                >
                  KV24
                </div>
                <div
                  @click="setPortal('CITY24')"
                  class="content-small portal-option"
                  :class="{
                    'selected-portal': newMarketingService.portal === 'CITY24',
                    'selected-portal-error-3': servicePortalError,
                  }"
                  style="
                    border-right: 1px solid #e6e8ec;
                    border-top: 1px solid #e6e8ec;
                    border-bottom: 1px solid #e6e8ec;
                    border-radius: 0 8px 8px 0;
                  "
                >
                  City24
                </div>
              </div>
            </div>
            <div
              style="display: flex; gap: 8px; height: 32px; flex-wrap: nowrap"
            >
              <label
                class="label12"
                style="width: 96px; height: 16px; color: #75787a"
                >{{ $t("DailyCost") }}</label
              >
              <div style="display: flex">
                <Input
                  @value="setDailyCost"
                  :prefilled-value="newMarketingService.dailyCost"
                  :id="'input-container'"
                  type="number"
                  size="small"
                  suffix-icon="euro_16_16.svg"
                />
              </div>
            </div>
            <div
              style="display: flex; gap: 8px; height: 32px; flex-wrap: nowrap"
            >
              <label
                class="label12"
                style="width: 96px; color: #75787a; height: 16px"
                >{{ $t("Duration") }}</label
              >
              <div style="display: flex; flex-wrap: nowrap; gap: 4px">
                <date-pick
                  @input="
                    (date) => {
                      setStartDate(date);
                    }
                  "
                >
                  <template v-slot:default="{ toggle }">
                    <div
                      style="
                        display: flex;
                        flex-wrap: nowrap;
                        gap: 8px;
                        width: 99px;
                        height: 32px;
                        padding: 7px 11px;
                        border-radius: 8px;
                        border: 1px solid #e6e8ec;
                      "
                      @click="toggle"
                    >
                      <img
                        :src="require(`@/assets/images/calendar.svg`)"
                        alt=""
                      />
                      <div
                        v-if="
                          !newMarketingService.dates ||
                          !newMarketingService.dates.startDate
                        "
                        style="
                          display: flex;
                          align-items: center;
                          color: #939597;
                        "
                      >
                        {{ $t("Start") }}
                      </div>
                      <div
                        v-if="
                          newMarketingService.dates ||
                          newMarketingService.dates.startDate
                        "
                        style="
                          display: flex;
                          font-size: 8px;
                          align-items: center;
                          color: #939597;
                        "
                      >
                        {{ formatDate(newMarketingService.dates.startDate) }}
                      </div>
                    </div>
                  </template>
                </date-pick>
                <div style="display: flex; align-items: center">-</div>
                <date-pick
                  @input="
                    (date) => {
                      setEndDate(date);
                    }
                  "
                >
                  <template v-slot:default="{ toggle }">
                    <div
                      style="
                        display: flex;
                        flex-wrap: nowrap;
                        gap: 8px;
                        width: 99px;
                        height: 32px;
                        padding: 7px 11px;
                        border-radius: 8px;
                        border: 1px solid #e6e8ec;
                      "
                      @click="toggle"
                    >
                      <img
                        :src="require(`@/assets/images/calendar.svg`)"
                        alt=""
                      />
                      <div
                        v-if="
                          !newMarketingService.dates ||
                          !newMarketingService.dates.endDate
                        "
                        style="
                          display: flex;
                          align-items: center;
                          color: #939597;
                        "
                      >
                        {{ $t("End") }}
                      </div>
                      <div
                        v-if="
                          newMarketingService.dates ||
                          newMarketingService.dates.endDate
                        "
                        style="
                          display: flex;
                          font-size: 8px;
                          align-items: center;
                          color: #939597;
                        "
                      >
                        {{ formatDate(newMarketingService.dates.endDate) }}
                      </div>
                    </div>
                  </template>
                </date-pick>
              </div>
            </div>
            <div
              style="display: flex; gap: 8px; height: 24px; flex-wrap: nowrap"
            >
              <label class="label12" style="width: 96px; height: 16px">{{
                $t("TotalCost")
              }}</label>
              <div style="width: 96px" class="font-20-bold">
                {{ newMarketingService.totalCost }} €
              </div>
            </div>
          </div>
          <div
            style="
              height: 58px;
              display: flex;
              justify-content: flex-end;
              align-items: center;
              border: 1px solid #e6e8ec;
              border-radius: 0 0 8px 8px;
              padding: 12px;
            "
          >
            <div style="display: flex; gap: 8px; flex-wrap: nowrap">
              <div
                class="outlined-small-button"
                style="cursor: pointer"
                @click="cancelNewExpenseOptionAdd"
              >
                {{ $t("Cancel") }}
              </div>
              <div
                @click="saveMarketingService"
                class="button-small"
                style="
                  padding: 8px 12px;
                  background-color: black;
                  border-radius: 8px;
                  color: white;
                  cursor: pointer;
                "
              >
                {{ $t("Save") }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        style="width: 100%; padding: 8px; min-height: 52px"
        v-else-if="
          addNewMarketingExpenseOpen &&
          index === 0 &&
          addNewMarketingExpenseType === 'advertisement'
        "
      >
        <div
          ref="dropdownOptions"
          @focusout="cancelNewExpenseOptionAdd"
          tabindex="0"
          v-if="addNewMarketingExpenseStep === 1"
          style="
            height: 100%;
            width: 100%;
            border: 1px solid #e6e8ec;
            border-radius: 8px;
            display: flex;
            flex-direction: column;
            outline: none;
          "
        >
          <div
            v-for="(option, index) in options"
            :key="option.name"
            style="width: 100%"
            :class="{ 'divider-line': index < options.length - 1 }"
          >
            <div
              @click="chooseNewExpenseOption(option)"
              :class="{
                'first-option-name': index === 0,
                'last-option-name': index === options.length - 1,
              }"
              class="content dropdown-option-name"
            >
              {{ option.name }}
            </div>
          </div>
        </div>
        <v-text-field
            @focusout="addMarketingExpense"
            :placeholder="$t('AddDescription')"
            @keydown.enter="$event.target.blur()"
            ref="marketingAddAdvertisement"
            hide-details
            dense
            outlined
            v-if="addNewMarketingExpenseStep === 2"
            v-model="addNewMarketingExpenseName"
        ></v-text-field>
      </div>

      <div
        v-if="
          (addNewMarketingExpenseOpen && index > 0) ||
          !addNewMarketingExpenseOpen
        "
        class="marketing-expenses-card-columns-container"
      >
        <div class="marketing-expenses-card-first-column">
          <div class="marketing-expenses-card-photo">
            <img
              v-if="marketingExpense.type === 'KV'"
              src="../../assets/images/KV_48_48.svg"
            />
            <img
              v-if="marketingExpense.type === 'KV24'"
              src="../../assets/images/KV24_48_48.svg"
            />
            <img
              v-if="marketingExpense.type === 'CITY24'"
              src="../../assets/images/CITY24_48_48.svg"
            />
            <div
              v-if="
                marketingExpense.images && marketingExpense.images.length === 0
              "
              class="marketing-expenses-photo-card"
            ></div>
            <img
              v-if="
                marketingExpense.images && marketingExpense.images.length > 0
              "
              class="marketing-expenses-photo-card"
              :src="marketingExpense.images[0].path.name"
              alt=""
            />
          </div>
          <div class="marketing-expenses-card-content">
            <div class="Labels">
              <p v-if="marketingExpense.type">
                {{ formatExpenseType(marketingExpense.type) }}
              </p>
              <p
                v-if="
                  marketingExpense.type &&
                  marketingExpense.dates &&
                  (marketingExpense.dates.startDate ||
                    marketingExpense.dates.endDate)
                "
                style="padding-left: 4px; padding-right: 4px"
              >
                •
              </p>
              <p
                v-if="
                  marketingExpense.dates &&
                  marketingExpense.dates.startDate &&
                  marketingExpense.dates.endDate
                "
              >
                {{ formatDate(marketingExpense.dates.startDate) }}
                -
                {{ formatDate(marketingExpense.dates.endDate) }}
              </p>
              <p
                v-if="
                  marketingExpense.dates &&
                  marketingExpense.dates.startDate &&
                  !marketingExpense.dates.endDate
                "
              >
                {{ formatDate(marketingExpense.dates.startDate) }}
                - ...
              </p>
              <p
                v-if="
                  marketingExpense.dates &&
                  !marketingExpense.dates.startDate &&
                  marketingExpense.dates.endDate
                "
              >
                ... -
                {{ formatDate(marketingExpense.dates.endDate) }}
              </p>
            </div>

            <div
              v-if="
                marketingExpense.type === 'KV' ||
                marketingExpense.type === 'KV24' ||
                marketingExpense.type === 'CITY24'
              "
              style="display: flex; flex-wrap: wrap;"
              class="marketing-expenses-card-content-description"
            >
              <p style="display: inline; padding-right: 4px" class="content-semibold">
                {{ $t("LevelService") }}
              </p>
              <template
                v-if="marketingExpense.dailyCost || marketingExpense.cost"
              >
                <p
                  v-if="marketingExpense.cost"
                  style="display: inline; white-space: nowrap; height: 20px"
                  class="content"
                >
                  - {{ marketingExpense.cost }} €
                </p>
                <p
                  v-if="marketingExpense.dailyCost"
                  style="display: inline; padding-left: 4px; color: #75787a; white-space: nowrap"
                  class="content"
                >
                  ({{ marketingExpense.dailyCost }} €/{{ $t("day") }})
                </p>
              </template>
            </div>
            <div
              v-if="
                marketingExpense.type !== 'KV' &&
                marketingExpense.type !== 'KV24' &&
                marketingExpense.type !== 'CITY24'
              "
              v-bind:class="{
                '': !dailyCostHidden,
                'marketing-expenses-wrapper': dailyCostHidden,
              }"
              class="marketing-expenses-card-content-description"
            >
                <div style="padding-right: 4px; display: block; word-break: keep-all"
                     class="content-semibold">
                  <p style="display: inline;">{{ marketingExpense.name }}</p>
                  <span v-if="marketingExpense.sellingPrice || marketingExpense.cost">
                    <p
                        v-if="marketingExpense.sellingPrice"
                        style="display: inline; white-space: nowrap; height: 20px"
                        class="content"
                    >
                    - {{ marketingExpense.sellingPrice }} €
                  </p>
                  <p
                      v-if="marketingExpense.cost && selfServicePriceFormat && !dailyCostHidden"
                      style="display: inline; white-space: nowrap; height: 20px"
                      class="content"
                  >
                    - {{ marketingExpense.cost }} €
                  </p>
                  <p
                      v-if="marketingExpense.cost && !dailyCostHidden"
                      style="display: inline; padding-left: 4px; color: #75787a; white-space: nowrap"
                      class="content"
                  >
                    ({{ marketingExpense.cost }} €)
                  </p>
                  </span>
                </div>
            </div>
            <div
              v-if="
                marketingExpense.type !== 'KV' &&
                marketingExpense.type !== 'KV24' &&
                marketingExpense.type !== 'CITY24'
              "
              style="display: flex"
            >
              <div
                :id="'marketing-expense-file-upload-button-' + index"
                @click="openUploadWidget(marketingExpense, index)"
                style="display: flex"
              >
                <p
                  style="cursor: pointer"
                  class="link-small"
                  v-if="
                    (!marketingExpense.files &&
                      !marketingExpense.images &&
                      !hideFileAdding) ||
                    (marketingExpense.files &&
                      marketingExpense.files.length === 0 &&
                      marketingExpense.images &&
                      marketingExpense.images.length === 0 &&
                      !hideFileAdding)
                  "
                >
                  {{ $t("AddFiles") }}
                </p>
                <p
                  style="cursor: pointer"
                  class="link-small"
                  v-if="
                    (marketingExpense.files &&
                      marketingExpense.files.length > 0) ||
                    (marketingExpense.images &&
                      marketingExpense.images.length > 0)
                  "
                >
                  {{
                    marketingExpense.files.length +
                    marketingExpense.images.length
                  }}
                  <span
                    v-if="
                      marketingExpense.files.length +
                        marketingExpense.images.length ===
                      1
                    "
                    >{{ $t("file") }}</span
                  >
                  <span v-else>{{ $t("files") }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="!showDetailButton"
          class="marketing-expenses-card-last-column"
        >
          <div class="marketing-expenses-card-menu-button-container">
            <img
              :id="'marketing-expense-details-button-' + index"
              @click="openDetailsWidget(marketingExpense, index)"
              style="cursor: pointer"
              src="../../assets/images/dot_menu_grey.svg"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <MarketingExpensesFileDropdown
      v-if="fileUploadDropdownOpen"
      :marketing-expense="clickedMarketingExpense"
      :hideFileUpload="hideUploadAndDelete"
      @closeWidget="closeUploadWidget"
      @fileUploaded="refreshExpenses"
      @fileDeleted="refreshExpenses"
    />
    <DetailsDropdown
      v-if="marketingDetailsDropdownOpen"
      :position="marketingDetailsPosition"
      :data="clickedMarketingExpenseDropdownData"
      @delete="deleteExpense"
      @action="expenseAction"
      :show-delete="true"
      :item="clickedMarketingExpense"
      @closeWidget="closeDetailsWidget"
    />
  </div>
</template>

<script>
import MarketingExpensesFileDropdown from "@/components/common/MarketingExpensesFileDropdown";
import DetailsDropdown from "@/components/common/DetailsDropdown";
import DatePick from "vue-date-pick";
import Input from "@/components/common/Input";
import axios from "axios";
import moment from "moment";
import nanoid from "nanoid";

export default {
  name: "MarketingExpenses",
  components: {
    DetailsDropdown,
    MarketingExpensesFileDropdown,
    Input,
    DatePick,
  },
  props: [
    "addButtonOutside",
    "dataFromOutside",
    "projectId",
    "showDetailButton",
    "dailyCostHidden",
    "dateFormat",
    "hideFileAdding",
    "hideUploadAndDelete",
    "selfServicePriceFormat",
  ],
  data() {
    return {
      marketingExpenses: [],
      addNewMarketingExpenseName: "",
      marketingValues: null,
      addNewMarketingExpenseOpen: false,
      addNewMarketingExpenseType: null,
      addNewMarketingExpenseStep: 0,
      fileUploadDropdownOpen: false,
      fileDropdownUpdater: 0,
      clickedMarketingExpense: null,
      clickedMarketingExpenseDropdownData: null,
      marketingDetailsDropdownOpen: false,
      marketingDetailsPosition: {},
      marketingExpense: {
        type: null,
        name: null,
        dates: {
          startDate: null,
          endDate: null,
        },
        createdAt: null,
        cost: null,
        sellingPrice: null,
        files: [],
        images: [],
      },
      options: [
        {
          name: this.$t("MarketingExpenseType.SocialMedia"),
          value: "Social media",
        },
        {
          name: this.$t("MarketingExpenseType.Outdoor"),
          value: "Outdoor",
        },
        {
          name: this.$t("MarketingExpenseType.Online"),
          value: "Online",
        },
        {
          name: this.$t("MarketingExpenseType.Print"),
          value: "Print",
        },
      ],
      newMarketingService: {
        portal: "",
        dailyCost: "0",
        dates: {
          startDate: "",
          endDate: "",
        },
        totalCost: "0",
      },
      servicePortalExists: false,
      servicePortalError: false,
    };
  },
  watch: {
    addButtonOutside() {
      if (!this.addNewMarketingExpenseOpen) {
        const button = this.addButtonOutside;
        if (button && button.value === "advertisement") {
          this.addNewMarketingExpenseType = button.value;
          this.addMarketingExpenseAdvertisement();
        }
        if (button && button.value === "levelService") {
          this.addNewMarketingExpenseType = button.value;
          this.addMarketingExpenseLevelService();
        }
      }
      this.$emit("resetType");
    },
    marketingExpenses() {
      this.$emit("marketingExpenseCounter", this.marketingExpenses.length);
    },
  },
  created() {
    if (this.dataFromOutside) {
      this.marketingExpenses = this.dataFromOutside;
    } else {
      this.getMarketingExpenses();
    }
  },
  mounted() {
    this.$emit("resetType");
    if (!this.addNewMarketingExpenseOpen) {
      const button = this.addButtonOutside;
      if (button && button.value === "advertisement") {
        this.addNewMarketingExpenseType = button.value;
        this.addMarketingExpenseAdvertisement();
      }
      if (button && button.value === "levelService") {
        this.addNewMarketingExpenseType = button.value;
        this.addMarketingExpenseLevelService();
      }
    }

  },
  methods: {
    addMarketingExpenseAdvertisement() {
      if (this.addNewMarketingExpenseStep === 0) {
        this.addNewMarketingExpenseName = "";
        this.addNewMarketingExpenseStep = 1;
        const newExpense = {
          id: nanoid(8),
          type: null,
          name: null,
          dates: {
            startDate: null,
            endDate: null,
          },
          createdAt: null,
          cost: null,
          sellingPrice: null,
          files: [],
          images: [],
        };
        this.marketingExpenses.splice(0, 0, newExpense);
        this.addNewMarketingExpenseOpen = true;
        this.$emit("resetType");
        this.$nextTick(() => this.$refs.dropdownOptions[0].focus());
      }
    },
    formatDate(date) {
      if (date) {
        return moment(date).format("DD.MM.YYYY");
      } else {
        return
      }
    },
    addMarketingExpenseLevelService() {
      if (this.addNewMarketingExpenseStep !== 0) {
        return;
      }
      this.addNewMarketingExpenseName = "";
      this.addNewMarketingExpenseStep = 1;
      const newExpense = {
        id: nanoid(8),
        type: null,
        name: null,
        dates: {
          startDate: null,
          endDate: null,
        },
        createdAt: null,
        cost: null,
        sellingPrice: null,
        files: [],
        images: [],
      };
      this.$emit("resetType");
      this.marketingExpenses.splice(0, 0, newExpense);
      this.addNewMarketingExpenseOpen = true;
    },
    cancelNewExpenseOptionAdd() {
      if (!this.marketingExpenses[0].type && this.addNewMarketingExpenseStep === 1) {
        this.marketingExpenses.splice(0, 1);
        this.addNewMarketingExpenseStep = 0;
        this.addNewMarketingExpenseOpen = false;
      }
      if (!this.marketingExpenses[0].name && this.addNewMarketingExpenseStep === 2) {
        return;
      }

      this.addNewMarketingExpenseOpen = false;
      this.addNewMarketingExpenseStep = 0;
      this.$emit("resetType");
    },
    addMarketingExpense() {
      if (
        this.addNewMarketingExpenseName === "" ||
        /^\s*$/.test(this.addNewMarketingExpenseName)
      ) {
        this.marketingExpenses.splice(0, 1);
        this.addNewMarketingExpenseOpen = false;
        this.addNewMarketingExpenseStep = 0;
        this.$emit("resetType");
        return;
      }
      this.$emit("resetType");
      this.addNewMarketingExpenseStep = 0;
      this.addNewMarketingExpenseOpen = false;
      this.marketingExpenses[0].name = this.addNewMarketingExpenseName;
      this.uploadMarketingExpenses();
    },
    chooseNewExpenseOption(option) {
      this.marketingExpenses[0].type = option.value;
      this.addNewMarketingExpenseStep = 2;
      this.$nextTick(() => this.$refs.marketingAddAdvertisement[0].focus());
    },
    async uploadMarketingExpenses() {
      const dealId = this.$route.params.id;
      axios
        .patch(
          `/api/project/marketing-expenses/${dealId}`,
          this.marketingExpenses
        )
        .then((res) => console.log("RESPONSE FOR UPDATE:", res));
    },
    async getMarketingExpenses() {
      if (!this.projectId) {
        const dealId = this.$route.params.id;
        const response = await axios.get(
          `/api/project/marketing-expenses/${dealId}`
        );
        this.marketingExpenses =
          response.data.marketingExpenses[0].marketingExpenses;
      } else {
        this.marketingExpenses = [];
        const response = await axios.get(
          `/api/self-service/marketing-expenses/${this.projectId}`
        );
        response.data.marketingExpenses[0].marketingExpenses.forEach((expense) => {
          this.marketingExpenses.push(expense);
        })
        this.marketingValues = this.marketingExpenses;
        this.$emit("getExpensesValues", this.marketingValues);
      }
    },
    async refreshExpenses() {
      await this.getMarketingExpenses();
      this.closeUploadWidget();
    },
    openUploadWidget(marketingExpense, index) {
      const button = document.getElementById(
        "marketing-expense-file-upload-button-" + index
      );
      const buttonRect = button.getBoundingClientRect();

      marketingExpense.top = buttonRect.y;
      marketingExpense.left = buttonRect.x;
      this.clickedMarketingExpense = marketingExpense;
      this.fileUploadDropdownOpen = true;
    },
    closeUploadWidget() {
      this.clickedMarketingExpense = null;
      this.fileUploadDropdownOpen = false;
    },
    formatExpenseType(expenseType) {
      if (expenseType === "KV" || expenseType === "CITY24") {
        return expenseType;
      } else if (expenseType === "KV24") {
        return "KINNISVARA24";
      }
      return this.options
        .filter((option) => option.value === expenseType)[0]
        .name.toUpperCase();
    },
    async openDetailsWidget(marketingExpense, index) {
      this.clickedMarketingExpenseDropdownData =
        this.getMarketingDetails(marketingExpense);
      this.clickedMarketingExpense = marketingExpense;

      const button = document.getElementById(
        "marketing-expense-details-button-" + index
      );
      const buttonRect = button.getBoundingClientRect();
      this.marketingDetailsPosition.top = buttonRect.y;
      this.marketingDetailsPosition.left = buttonRect.x;
      this.marketingDetailsDropdownOpen = true;
    },
    closeDetailsWidget() {
      this.clickedMarketingExpense = null;
      this.marketingDetailsDropdownOpen = false;
    },
    getMarketingDetails(marketingExpense) {
      const data = [];
      if (
        marketingExpense.type !== "KV" &&
        marketingExpense.type !== "KV24" &&
        marketingExpense.type !== "CITY24"
      ) {
        data.push({
          icon: "ads_20_20.svg",
          title: this.$t("Type"),
          editType: "options",
          options: [
            {
              name: this.$t("MarketingExpenseType.SocialMedia"),
              value: "Social media",
            },
            {
              name: this.$t("MarketingExpenseType.Outdoor"),
              value: "Outdoor",
            },
            {
              name: this.$t("MarketingExpenseType.Online"),
              value: "Online",
            },
            {
              name: this.$t("MarketingExpenseType.Print"),
              value: "Print",
            },
          ],
          value: marketingExpense.type,
        });
        data.push({
          icon: "calendar_20_20.svg",
          title: this.$t("StartDate"),
          editType: "calendar",
          value: marketingExpense.dates.startDate
            ? marketingExpense.dates.startDate
            : "-",
          clearable: true,
        });
        data.push({
          title: this.$t("EndDate"),
          editType: "calendar",
          value: marketingExpense.dates.endDate
            ? marketingExpense.dates.endDate
            : "-",
          clearable: true,
        });
        data.push({
          icon: "euroSign.svg",
          title: this.$t("SellingPrice"),
          font: "normal",
          editType: "text",
          value: marketingExpense.sellingPrice
            ? marketingExpense.sellingPrice
            : "-",
          valueSuffix: "€",
        });
        data.push({
          title: this.$t("Cost"),
          font: "normal",
          editType: "text",
          value: marketingExpense.cost ? marketingExpense.cost : "-",
          valueSuffix: "€",
        });
        return data;
      }
      data.push({
        icon: "calendar_20_20.svg",
        title: this.$t("StartDate"),
        editType: "calendar",
        value: marketingExpense.dates.startDate
          ? marketingExpense.dates.startDate
          : "-",
        clearable: true,
      });
      data.push({
        title: this.$t("EndDate"),
        editType: "calendar",
        value: marketingExpense.dates.endDate
          ? marketingExpense.dates.endDate
          : "-",
        clearable: true,
      });
      data.push({
        icon: "euroSign.svg",
        title: this.$t("DailyCost"),
        font: "normal",
        editType: "text",
        value: marketingExpense.dailyCost ? marketingExpense.dailyCost : "-",
        valueSuffix: "€",
      });
      data.push({
        title: this.$t("TotalCost"),
        font: "normal",
        value: marketingExpense.cost ? marketingExpense.cost : "-",
        valueSuffix: "€",
      });
      return data;
    },
    deleteExpense(item) {
      this.marketingExpenses = this.marketingExpenses.filter(
        (expense) => expense.id !== item.id
      );
      this.uploadMarketingExpenses();
      this.closeDetailsWidget();
    },
    expenseAction(item) {
      if (item.title === this.$t("SellingPrice")) {
        if (item.value === "-") {
          this.clickedMarketingExpense.sellingPrice = null;
        } else {
          this.clickedMarketingExpense.sellingPrice = parseFloat(item.value);
        }
      }
      if (item.title === this.$t("Cost")) {
        if (item.value === "-") {
          this.clickedMarketingExpense.cost = null;
        } else {
          this.clickedMarketingExpense.cost = parseFloat(item.value);
        }
      }
      if (item.title === this.$t("StartDate")) {
        if (item.value === "-") {
          this.clickedMarketingExpense.dates.startDate = null;
        } else {
          this.clickedMarketingExpense.dates.startDate = new Date(item.value);
        }
        if (
          this.clickedMarketingExpense.dailyCost &&
          this.clickedMarketingExpense.dates.startDate &&
          this.clickedMarketingExpense.dates.endDate
        ) {
          const timeDifference =
            new Date(this.clickedMarketingExpense.dates.endDate).getTime() -
            new Date(this.clickedMarketingExpense.dates.startDate).getTime();
          const dayDifference = timeDifference / (1000 * 3600 * 24);
          this.clickedMarketingExpense.cost = parseFloat(
            (dayDifference * this.clickedMarketingExpense.dailyCost).toFixed(2)
          );
        }
      }
      if (item.title === this.$t("EndDate")) {
        if (item.value === "-") {
          this.clickedMarketingExpense.dates.endDate = null;
        } else {
          this.clickedMarketingExpense.dates.endDate = new Date(item.value);
        }
        if (
          this.clickedMarketingExpense.dailyCost &&
          this.clickedMarketingExpense.dates.startDate &&
          this.clickedMarketingExpense.dates.endDate
        ) {
          const timeDifference =
            new Date(this.clickedMarketingExpense.dates.endDate).getTime() -
            new Date(this.clickedMarketingExpense.dates.startDate).getTime();
          const dayDifference = timeDifference / (1000 * 3600 * 24);
          this.clickedMarketingExpense.cost = parseFloat(
            (dayDifference * this.clickedMarketingExpense.dailyCost).toFixed(2)
          );
        }
      }
      if (item.title === this.$t("Type")) {
        this.clickedMarketingExpense.type = item.value;
      }
      if (item.title === this.$t("DailyCost")) {
        if (item.value === "-") {
          this.clickedMarketingExpense.dailyCost = null;
        } else {
          this.clickedMarketingExpense.dailyCost = parseFloat(item.value);
        }
        if (
          this.clickedMarketingExpense.dailyCost &&
          this.clickedMarketingExpense.dates.startDate &&
          this.clickedMarketingExpense.dates.endDate
        ) {
          const timeDifference =
            new Date(this.clickedMarketingExpense.dates.endDate).getTime() -
            new Date(this.clickedMarketingExpense.dates.startDate).getTime();
          const dayDifference = timeDifference / (1000 * 3600 * 24);
          this.clickedMarketingExpense.cost = parseFloat(
            (dayDifference * this.clickedMarketingExpense.dailyCost).toFixed(2)
          );
        } else {
          this.clickedMarketingExpense.cost = 0;
        }
      }
      this.clickedMarketingExpenseDropdownData = this.getMarketingDetails(
        this.clickedMarketingExpense
      );
      this.uploadMarketingExpenses();
    },
    setPortal(portal) {
      this.newMarketingService.portal = portal;
      this.servicePortalError = false;
      this.servicePortalExists = true;
    },
    setDailyCost(value) {
      this.newMarketingService.dailyCost = value;
      this.setTotalCost();
    },
    setStartDate(date) {
      this.newMarketingService.dates.startDate = date;
      this.setTotalCost();
    },
    setEndDate(date) {
      this.newMarketingService.dates.endDate = date;
      this.setTotalCost();
    },
    setTotalCost() {
      if (
        this.newMarketingService &&
        this.newMarketingService.dailyCost &&
        this.newMarketingService.dates.startDate &&
        this.newMarketingService.dates.endDate
      ) {
        const timeDifference =
          new Date(this.newMarketingService.dates.endDate).getTime() -
          new Date(this.newMarketingService.dates.startDate).getTime();
        const dayDifference = timeDifference / (1000 * 3600 * 24);
        this.newMarketingService.totalCost = parseFloat(
          (dayDifference * this.newMarketingService.dailyCost).toFixed(2)
        );
      }
    },
    async saveMarketingService() {
      if (!this.servicePortalExists) {
        this.servicePortalError = true;
        return;
      }
      const newExpense = {
        id: nanoid(8),
        type: null,
        name: null,
        dates: {
          startDate: null,
          endDate: null,
        },
        createdAt: null,
        cost: null,
        dailyCost: null,
      };
      if (this.newMarketingService.portal === "KV") {
        newExpense.type = "KV";
        newExpense.name = "KV";
      } else if (this.newMarketingService.portal === "KV24") {
        newExpense.type = "KV24";
        newExpense.name = "KINNISVARA24";
      } else if (this.newMarketingService.portal === "CITY24") {
        newExpense.type = "CITY24";
        newExpense.name = "CITY24";
      }
      newExpense.dates.startDate = this.newMarketingService.dates.startDate;
      newExpense.dates.endDate = this.newMarketingService.dates.endDate;
      newExpense.cost = this.newMarketingService.totalCost;
      newExpense.dailyCost = this.newMarketingService.dailyCost;

      this.marketingExpenses[0] = newExpense;
      await this.uploadMarketingExpenses().then(() => {
        this.cancelNewExpenseOptionAdd();
        this.newMarketingService = {
          portal: "",
          dailyCost: "0",
          dates: {
            startDate: "",
            endDate: "",
          },
          totalCost: "0",
        };
      });
    },
  },
};
</script>

<style scoped>
.marketing-expenses-content {
  display: flex;
  flex-direction: column;
}

.marketing-expenses-card {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.marketing-expenses-card-photo {
  margin: 7px 12px 0 12px;
}

.marketing-expenses-card-first {
  border: 1px solid #e6e8ec;
  border-radius: 8px 8px 0 0;
}

.marketing-expenses-card-middle {
  border-left: 1px solid #e6e8ec;
  border-right: 1px solid #e6e8ec;
  border-bottom: 1px solid #e6e8ec;
}

.marketing-expenses-card-last {
  border-bottom: 1px solid #e6e8ec;
  border-left: 1px solid #e6e8ec;
  border-right: 1px solid #e6e8ec;
  border-radius: 0 0 8px 8px;
}

.marketing-expenses-card-only {
  border: 1px solid #e6e8ec;
  border-radius: 8px 8px 8px 8px;
}

.marketing-expenses-card-first-column {
  display: flex;
  flex-wrap: nowrap;
}

.marketing-expenses-card-menu-button-container {
  margin: 7px 12px 0 12px;
}

.marketing-expenses-card-content {
  margin-top: 7px;
  margin-bottom: 7px;
}

.marketing-expenses-card-content-description {
  display: flex;
}
.marketing-expenses-wrapper {
  flex-wrap: wrap;
}
.price-location {
  align-items: flex-end;
  padding-right: 12px;
}

.Labels {
  font-family: Inter, sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0;
  text-align: left;
  color: #75787a;
  display: flex;
  flex-wrap: nowrap;
}

.Labels p {
  font-family: Inter, sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0;
  text-align: left;
  color: #75787a;
}

.marketing-expenses-photo-card {
  background-color: #f4f5f7;
  height: 48px;
  width: 48px;
  border-radius: 8px;
  object-fit: cover;
}

.marketing-expenses-card-photo {
  height: 48px;
  width: 48px;
  border-radius: 8px;
}

.marketing-expenses-card-columns-container {
  display: flex;
  width: 100%;
  min-height: 68px;
  justify-content: space-between;
}

.divider-line {
  border-bottom: 1px solid #e6e8ec;
}

.dropdown-option-name {
  cursor: pointer;
  width: 100%;
  padding: 6px 12px;
}

.first-option-name {
  border-radius: 8px 8px 0px 0px;
}
.middle-option-name {
  border-radius: 0px;
}
.last-option-name {
  border-radius: 0px 0px 8px 8px;
}

.dropdown-option-name:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.selected-portal {
  background-color: #f4f5f7;
  font-weight: 500;
}

.portal-option {
  justify-content: center;
  height: 32px;
  flex: 1 1 0;
  color: #75787a;
  cursor: pointer;
}

.selected-portal-error-1 {
  border-left: 1px solid red !important;
  border-top: 1px solid red !important;
  border-bottom: 1px solid red !important;
}

.selected-portal-error-2 {
  border-top: 1px solid red !important;
  border-bottom: 1px solid red !important;
}

.selected-portal-error-3 {
  border-top: 1px solid red !important;
  border-right: 1px solid red !important;
  border-bottom: 1px solid red !important;
}
</style>