var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.listings && _vm.listings.length > 0 ? _c('div', {
    staticClass: "main-content",
    attrs: {
      "id": "main-container"
    }
  }, [_vm._l(_vm.listings, function (listing, index) {
    return _c('div', {
      key: listing._id,
      staticClass: "listing-card"
    }, [_c('ProjectListingHeader', {
      attrs: {
        "listing": listing,
        "listing-index": index,
        "open-house": _vm.openHouse,
        "additional-activities": _vm.additional_activities,
        "listing-refresh-key": _vm.projectListingRefreshKey,
        "is-project-inactive": _vm.isProjectInactive,
        "deal-pipeline-step": _vm.dealPipelineStep
      },
      on: {
        "statusChanged": _vm.changeListingStatuses,
        "openListingPanel": _vm.openListingPanel,
        "openStatusDropdown": _vm.openStatusDropdown,
        "selectActivity": _vm.selectActivity,
        "changeActiveUntil": _vm.changeActiveUntil
      }
    }), _c('div', {
      staticClass: "kanban",
      attrs: {
        "id": 'kanbanRow' + listing._id
      }
    }, [listing.funnels ? _c('div', {
      staticStyle: {
        "overflow-y": "scroll",
        "display": "flex"
      }
    }, [_c('LeadsKanban', {
      staticStyle: {
        "cursor": "auto",
        "min-width": "1440px"
      },
      attrs: {
        "isProjectInactive": _vm.isProjectInactive,
        "kanban-updater": _vm.kanbanUpdater,
        "listing": listing,
        "project": true
      },
      on: {
        "leadOpened": _vm.openKanbanLead,
        "leadsOpened": function ($event) {
          _vm.open = true;
        },
        "leadsClosed": function ($event) {
          _vm.open = false;
        }
      }
    })], 1) : _vm._e()])], 1);
  }), _vm.listingPanelOpen ? _c('ListingSidepanel', {
    attrs: {
      "listing-id": _vm.chosenListingId
    },
    on: {
      "overlayClicked": _vm.closeListingPanel,
      "updateStatuses": _vm.updateFromSidepanel,
      "statusChanged": _vm.changeListingStatuses
    }
  }) : _vm._e(), _vm.statusesOpen ? _c('StatusDropdown', {
    attrs: {
      "position": _vm.position,
      "listing": _vm.selectedListing
    },
    on: {
      "closed": function ($event) {
        _vm.statusesOpen = false;
      },
      "statusChanged": _vm.changeListingStatuses
    }
  }) : _vm._e(), _vm.leadPanelKanbanOpen ? _c('ClientSidepanel', {
    attrs: {
      "customer-data": _vm.clickedLead.leadId,
      "customer-data-leads-list": _vm.clickedLead.customerId
    },
    on: {
      "panelClosed": function ($event) {
        _vm.leadPanelKanbanOpen = false;
      },
      "refreshLeads": _vm.getObjectListings
    }
  }) : _vm._e(), _vm.isDeleteModalOpen ? _c('DeleteModal', {
    on: {
      "canceled": function ($event) {
        _vm.isDeleteModalOpen = false;
      },
      "approved": function ($event) {
        return _vm.deleteListing();
      }
    }
  }) : _vm._e(), _vm.confirmModalOpen ? _c('ConfirmationModal', {
    on: {
      "onConfirm": _vm.createNewId,
      "onCancel": function () {
        return _vm.confirmModalOpen = false;
      }
    }
  }, [_c('div', {
    staticClass: "text-center"
  }, [_vm._v(" " + _vm._s(_vm.$t("CreateNewId")) + " ")])]) : _vm._e()], 2) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }