<template>
  <div class="overlay" id="overlay-project">
    <div id="panel-wrapper-project" class="panel-wrapper">
      <div v-if="loadingSave" class="loader-overlay">
        <div class="loader units-add-loader"></div>
      </div>
      <div id="content-wrapper">
        <div class="sticky-header">
          <v-row
            class="top-row"
            style="height: 24px; padding-left: 32px; padding-right: 32px"
            ><h4 style="height: 24px">
              {{ $t("AddUnits") }}
            </h4>
          </v-row>
          <v-row
            style="
              width: 100%;
              height: 42px;
              margin-top: 32px;
              padding-left: 32px;
            "
          >
            <div
              v-bind:class="{
                'active-view': activeView === 'single',
                '': activeView !== 'single',
              }"
              @click="activeView = 'single'"
              id="singleView"
            >
              <p
                v-if="activeView === 'single'"
                class="content-small view-link"
                style="font-weight: 600"
              >
                {{ $t("AddSingleUnit") }}
              </p>
              <p
                v-if="activeView !== 'single'"
                class="content-small view-link"
                style="font-weight: 400"
              >
                {{ $t("AddSingleUnit") }}
              </p>
            </div>
            <div
              v-bind:class="{
                'active-view': activeView === 'bulk',
                '': activeView !== 'bulk',
              }"
              @click="changeToBulk"
              id="bulkView"
            >
              <p
                v-if="activeView === 'bulk'"
                class="content-small view-link"
                style="font-weight: 600"
              >
                {{ $t("AddBulk") }}
              </p>
              <p
                v-if="activeView !== 'bulk'"
                class="content-small view-link"
                style="font-weight: 400"
              >
                {{ $t("AddBulk") }}
              </p>
            </div>
          </v-row>
          <div
            style="border: 0.1px solid #e6e8ec; width: 560px"
            class="separator-row-main"
          ></div>
        </div>
        <div v-if="activeView === 'single'">
          <v-row
            style="padding-top: 168px; padding-left: 32px; padding-right: 32px"
            ><h5>{{ $t("UnitDetails") }}</h5></v-row
          >
          <!--   <v-row
            style="
              margin-top: 24px !important;
              width: 100%;
              flex-wrap: nowrap;
              gap: 8px;
            "
          >
            <v-select
              v-model="form.object"
              outlined
              :error="isErrorObject"
              :item-text="
                (item) =>
                  item.street +
                  ' ' +
                  item.houseNumber +
                  ', ' +
                  item.district +
                  ', ' +
                  item.city
              "
              :item-value="(item) => item"
              :items="objects"
              :label="$t('Object')"
              class="no-active"
              style="height: 52px !important"
            >
            </v-select>
          </v-row> -->
          <v-row
            style="margin-top: 24px; padding-left: 32px; padding-right: 32px"
            class="required"
            ><v-text-field
              v-model="form.floor"
              outlined
              :error="isErrorFloor"
              type="number"
              :label="$t('Floor')"
              class="no-active"
              style="width: 100% !important"
            >
            </v-text-field
          ></v-row>
          <v-row
            class="field-row"
            style="
              margin-top: 14px !important;
              padding-left: 32px;
              padding-right: 32px;
            "
          >
            <v-text-field
              v-model="form.unitSize"
              outlined
              type="number"
              :label="$t('UnitSize')"
              class="no-active"
              style="width: 100% !important"
              ><template v-slot:append>
                <p style="margin-top: 2px">&#13217;</p>
              </template>
            </v-text-field></v-row
          >
          <v-row
            class="field-row"
            style="
              margin-top: 14px !important;
              padding-left: 32px;
              padding-right: 32px;
            "
          >
            <v-text-field
              v-model="form.price"
              outlined
              type="number"
              :label="$t('Price')"
              class="no-active"
              style="width: 100% !important"
              ><template v-slot:append>
                <img
                  src="../../assets/images/euroSign.svg"
                  style="margin-top: 2px"
                />
              </template>
            </v-text-field>
          </v-row>
          <v-row
            class="field-row"
            style="
              margin-top: 14px !important;
              padding-left: 32px;
              padding-right: 32px;
            "
          >
            <v-select
              v-model="form.client"
              outlined
              clearable
              item-text="customerName"
              return-object
              :items="clients"
              :label="$t('Client')"
              class="no-active"
              style="height: 52px !important"
            >
            </v-select>
          </v-row>

          <v-row
            style="
              margin-top: 14px !important;
              padding-left: 32px;
              padding-right: 32px;
            "
          >
            <v-select
              clearable
              v-model="form.status"
              outlined
              item-value="value"
              item-text="name"
              :items="statuses"
              :label="$t('Status')"
              class="no-active"
            >
            </v-select>
          </v-row>

          <v-row
            style="margin-top: 32px; padding-left: 32px; padding-right: 32px"
            ><h5>{{ $t("Description") }}</h5></v-row
          >
          <v-row
            class="field-row"
            style="
              margin-top: 24px !important;
              padding-bottom: 328px;
              padding-left: 32px;
              padding-right: 32px;
            "
          >
            <textarea
              v-model="form.description"
              class="textarea"
              :placeholder="$t('Description')"
            >
            </textarea>
          </v-row>

          <div class="buttons-container">
            <v-divider></v-divider>
            <div class="buttons-row">
              <button @click="closeModal()" class="cancel">
                {{ $t("Cancel") }}
              </button>

              <button class="add-button" @click="saveUnits(form)">
                {{ $t("AddUnit") }}
              </button>
            </div>
          </div>
        </div>
        <div v-if="activeView === 'bulk'">
          <v-row
            style="padding-top: 168px; padding-left: 32px; padding-right: 32px"
            ><h5>{{ $t("UnitDetails") }}</h5></v-row
          >
          <!-- <v-row
            style="
              margin-top: 24px !important;
              width: 100%;
              flex-wrap: nowrap;
              gap: 8px;
            "
          >
            <v-select
              v-model="formBulk.object"
              outlined
              :error="isErrorObject"
              :item-text="
                (item) =>
                  item.street +
                  ' ' +
                  item.houseNumber +
                  ', ' +
                  item.district +
                  ', ' +
                  item.city
              "
              :item-value="(item) => item"
              :items="objects"
              :label="$t('Object')"
              class="no-active"
              style="height: 52px !important"
            >
            </v-select>
          </v-row> -->
          <v-row
            style="
              flex-wrap: nowrap;
              margin-top: 24px;
              gap: 12px;
              padding-left: 32px;
              padding-right: 32px;
            "
            ><v-text-field
              v-model="formBulk.floorFrom"
              outlined
              :error="isErrorFloorFrom"
              type="number"
              :label="$t('FirstFloor')"
              class="no-active required"
              style="width: 234px !important"
            >
            </v-text-field>
            <v-text-field
              v-model="formBulk.floorTo"
              outlined
              :error="isErrorFloorTo"
              type="number"
              :label="$t('LastFloor')"
              class="no-active required"
              style="width: 234px !important"
            >
            </v-text-field>
          </v-row>
          <v-row
            class="field-row"
            style="
              margin-top: 14px !important;
              padding-left: 32px;
              padding-right: 32px;
            "
          >
            <v-text-field
              v-model="formBulk.unitsPerFloor"
              outlined
              type="number"
              :label="$t('UnitsPerFloor')"
              class="no-active required"
              style="width: 100% !important"
            >
            </v-text-field
          ></v-row>
          <v-row
            class="field-row"
            style="
              margin-top: 14px !important;
              padding-left: 32px;
              padding-right: 32px;
            "
          >
            <v-text-field
              v-model="formBulk.unitSize"
              outlined
              type="number"
              :label="$t('UnitSize')"
              class="no-active"
              style="width: 100% !important"
              ><template v-slot:append>
                <p style="margin-top: 2px">&#13217;</p>
              </template>
            </v-text-field></v-row
          >
          <v-row
            class="field-row"
            style="
              margin-top: 14px !important;
              padding-left: 32px;
              padding-right: 32px;
            "
          >
            <v-text-field
              v-model="formBulk.price"
              outlined
              type="number"
              :label="$t('Price')"
              class="no-active"
              style="width: 100% !important"
              ><template v-slot:append>
                <img
                  src="../../assets/images/euroSign.svg"
                  style="margin-top: 2px"
                />
              </template>
            </v-text-field>
          </v-row>
          <v-row
            class="field-row"
            style="
              margin-top: 14px !important;
              padding-left: 32px;
              padding-right: 32px;
            "
          >
            <v-select
              v-model="formBulk.client"
              outlined
              clearable
              item-text="customerName"
              return-object
              :items="clients"
              :label="$t('Client')"
              class="no-active"
              style="height: 52px !important"
            >
            </v-select>
          </v-row>

          <v-row
            style="
              margin-top: 14px !important;
              padding-left: 32px;
              padding-right: 32px;
            "
          >
            <v-select
              clearable
              v-model="formBulk.status"
              outlined
              item-value="value"
              item-text="name"
              :items="statuses"
              :label="$t('Status')"
              class="no-active"
            >
            </v-select>
          </v-row>

          <v-row
            style="margin-top: 32px; padding-left: 32px; padding-right: 32px"
            ><h5>{{ $t("Description") }}</h5>
          </v-row>
          <v-row
            class="field-row"
            style="
              margin-top: 24px !important;
              padding-bottom: 328px;
              padding-left: 32px;
              padding-right: 32px;
            "
          >
            <textarea
              v-model="formBulk.description"
              class="textarea"
              :placeholder="$t('Description')"
            >
            </textarea>
          </v-row>

          <div class="buttons-container">
            <v-divider></v-divider>

            <div class="buttons-row">
              <button @click="closeModal()" class="cancel">
                {{ $t("Cancel") }}
              </button>

              <button class="add-button" @click="saveUnits">
                {{ $t("AddUnit") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  components: {},
  name: "UnitsAdd",
  props: ["object", "clients"],

  data: () => ({
    loadingSave: false,
    activeView: "single",
    dealTypes: ["Müügitehing", "Üüritehing"],
    currencies: ["EUR", "USD"],
    services: ["Maaklerteenus"],
    menu: false,
    isErrorObject: false,
    isErrorFloor: false,
    isErrorFloorFrom: false,
    isErrorFloorTo: false,
    status: null,
    timeouts: [],
    form: {
      object: null, //mandatory
      floor: null, //mandatory
      unitSize: null,
      price: null,
      client: null,
      clientName: null,
      clientId: null,
      description: null,
    },
    formBulk: {
      object: null, //mandatory
      floorFrom: null, //mandatory
      floorTo: null, //mandatory
      unitsPerFloor: null, //mandatory
      unitSize: null,
      price: null,
      client: null,
      clientName: null,
      clientId: null,
      description: null,
    },
  }),
  created() {},
  mounted() {
    window.onclick = (e) => {
      if (e.target.classList[0] === "overlay") {
        this.closeModal();
        e.target.style.display = "none";
      }
    };
  },
  computed: {
    statuses() {
      return [
        { name: this.$t("Free"), value: "free" },
        { name: this.$t("Booked"), value: "booked" },
        { name: this.$t("Rented"), value: "rented" },
      ];
    },
  },
  watch: {},
  methods: {
    changeToBulk() {
      this.activeView = "bulk";
    },
    closeModal() {
      this.$emit("closed");
    },
    containsWord(str, word) {
      return str.match(new RegExp("\\b" + word + "\\b")) != null;
    },

    async saveUnits() {
      //SINGLE ADD
      this.form.object = this.object;
      this.formBulk.object = this.object;
      let activeView = this.activeView;
      let unit = this.form;
      if (activeView === "single") {
        let objectFilled = false;
        let floorFilled = false;

        if (unit.object) {
          objectFilled = true;
        } else {
          this.isErrorObject = true;
        }
        if (unit.floor) {
          floorFilled = true;
        } else {
          this.isErrorFloor = true;
        }
        if (objectFilled && floorFilled) {
          let objectId = this.form.object._id;
          if (this.form.client) {
            this.form.clientId = this.form.client.customerId;
            this.form.clientName = this.form.client.customerName;
          }
          let body = { unit: this.form, objectId };
          await axios.post(
            `/api/project/unit/add/${this.$route.params.id}`,
            body
          );
          this.$emit("saved");
        } else {
          return;
        }

        // BULK ADD
      } else if (activeView === "bulk") {
        let objectFilled = false;
        let floorFilledFrom = false;
        let floorFilledTo = false;

        // clone form from data

        let totalUnits =
          (parseInt(this.formBulk.floorTo) -
            parseInt(this.formBulk.floorFrom)) *
          parseInt(this.formBulk.unitsPerFloor);
        if (totalUnits > 50) {
          alert("OBJECT UNITSLIMIT 50 ");
          return;
        }
        if (this.formBulk.object.units.length > 50) {
          alert("OBJECT UNITS LIMIT 50 ");
          return;
        }
        let bulkUnit = Object.assign({}, this.formBulk);
        if (this.formBulk.client) {
          bulkUnit.clientId = this.formBulk.client.customerId;
          bulkUnit.clientName = this.formBulk.client.customerName;
        }

        if (bulkUnit.object) {
          objectFilled = true;
        } else {
          this.isErrorObject = true;
        }
        if (bulkUnit.floorFrom) {
          floorFilledFrom = true;
        } else {
          this.isErrorFloorFrom = true;
        }
        if (bulkUnit.floorTo) {
          floorFilledTo = true;
        } else {
          this.isErrorFloorTo = true;
        }
        if (objectFilled && floorFilledFrom && floorFilledTo) {
          /*let objectId = bulkUnit.object._id;*/
          let units = [];
          console.log(bulkUnit);
          for (
            let i = parseInt(bulkUnit.floorFrom);
            i <= parseInt(bulkUnit.floorTo);
            i++
          ) {
            i = parseInt(i);
            let oneUnit = Object.assign({}, bulkUnit);

            oneUnit.floor = i;

            for (let i = 0; i < bulkUnit.unitsPerFloor; i++) {
              units.push(oneUnit);
            }
          }
          console.log("unitid: ", units);
          let objectId = bulkUnit.object._id;
          let body = { units, objectId };
          this.loadingSave = true;
          const saveResp = await axios.post(
            `/api/project/units/add/${this.$route.params.id}`,
            body
          );
          let saveRespData = saveResp.data;
          let savedUnitsIds = saveRespData.addedUnits;
          let savedBody = { units, objectId, savedUnitsIds };
          this.loadingSave = false;
          this.$emit("saved", 1, savedBody);
        } else {
          return;
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "src/global_variables";

#wrapped-header-project {
  display: none;
  position: relative;
  width: 100%;
  height: 100%;
  opacity: 0;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
  flex-direction: column;
  justify-content: flex-start;
  padding: 48px 24px 24px;
  border-right: 1px solid $grey500;

  h4 {
    white-space: nowrap;

    overflow: hidden;
    text-overflow: ellipsis;
    transform: rotate(180deg);
    writing-mode: vertical-lr;
    text-align: center;
    position: relative;
    justify-self: center;
    align-self: center;
  }
}
.v-text-field--outlined ::v-deep fieldset {
  border: 1px solid #e6e8ec;
}
.buttons-row {
  justify-content: flex-end;
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: 24px 16px 64px;
  background-color: white;
  width: 526px;
  .cancel {
    background-color: transparent !important;
    box-shadow: 0px 0 2px rgba(28, 14, 3, 0.3);
    text-transform: none;
    padding: 12px;
    display: flex;
    align-items: center;
    height: 40px;
    font-weight: 400;
    line-height: 16px;
  }
}
.cancel-button {
  background-color: transparent !important;
  box-shadow: 0px 0 2px rgba(28, 14, 3, 0.3);
  text-transform: none;
  cursor: pointer;
  padding: 12px 16px;
  font-size: 14px;
  font-family: Inter;
  border-radius: 8px;
}
.pipeline-header {
  margin-top: 48px;
  width: 30%;
  p {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
  }
}
.pipelineFields {
  font-size: 14px;
  font-family: Inter;
  line-height: 1.2;
  gap: 8px 24px;
  display: flex;
  flex-flow: row wrap;
  padding-top: 24px;
  padding-bottom: 48px;
}
.pipelineSelector {
  font-size: 14px;
  font-family: Inter;
  line-height: 1.2;
  width: 100%;
  display: flex;
}
.pipelineSelector > input {
  margin: 0;
  padding: 0;
  -moz-appearance: none;
  -webkit-appearance: none;
}
.arrowLabel {
  width: 173px;
  cursor: pointer;
  height: 40px;
  display: flex;
  position: relative;
  font-size: 12px;
  background: #e6e8ec;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.pipelineSelector > input:checked + label:before {
  border-left-color: #75787a;
}
.arrowLabel:before {
  right: -10px;
  z-index: 2;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  z-index: 55;
  left: 8px;
  cursor: pointer;
}
input::-webkit-datetime-edit {
  position: relative;
  left: 18px;
}
input::-webkit-datetime-edit-fields-wrapper {
  position: relative;
  left: 18px;
}

.birth-class {
  border: 1px solid #e6e8ec;
  border-radius: 8px;
  width: 49.2%;
  height: 56px;
  padding-top: 0px !important;
  padding: 0px !important;
  padding-bottom: 0px !important;
  &:focus {
    outline: none !important;
  }
}

.arrowLabel:before {
  width: 0;
  bottom: 0;
  height: 0;
  content: "";
  position: absolute;
  border-top: 20px solid transparent;
  border-left: 10px solid #e6e8ec;
  border-bottom: 20px solid transparent;
}
.pipelineSelector > input:checked + label {
  color: #ffffff;
  background: #75787a;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 101;
}
.panel-wrapper {
  position: absolute;
  right: 0;
  top: 0%;
  bottom: 0%;
  background-color: #ffffff;
  //padding-left: 32px;
  //padding-top: 48px;
  //padding-right: 32px;
  z-index: 7;
  width: 560px;
  border: 1px solid #e6e8ec;
  overflow-y: scroll;
  -webkit-transition: width 0.5s ease-in-out;
  -moz-transition: width 0.5s ease-in-out;
  -o-transition: width 0.5s ease-in-out;
  transition: width 0.5s ease-in-out;
}
#panel-wrapper-project {
  -webkit-transition: width 0.5s ease-in-out, right 0.5s ease-in-out,
    min-width 0.5s ease-in-out;
  -moz-transition: width 0.5s ease-in-out, right 0.5s ease-in-out,
    min-width 0.5s ease-in-out;
  -o-transition: width 0.5s ease-in-out, right 0.5s ease-in-out,
    min-width 0.5s ease-in-out;
  transition: width 0.5s ease-in-out, right 0.5s ease-in-out,
    min-width 0.5s ease-in-out;
}
.row {
  gap: 24px;
}

::v-deep .inaadressSearch,
::v-deep .ui-input-search {
  ul {
    //border: 1px solid #e6e8ec !important;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    background-color: white !important;
  }
  input {
    width: 80% !important;
    padding: 8px 12px;
    height: 52px;

    &:focus-visible {
      outline: none;
      border: none;
      //background-color: white !important;
    }

    &::after {
      content: url("../../assets/images/Dollar.svg");
    }
  }
}

::v-deep .ui-input-clear,
::v-deep .inAdsCopyright {
  display: none;
}
::v-deep #InAadressDiv {
  z-index: 5;
}
::v-deep .ui-filterable {
  border: 1px solid #e6e8ec !important;
  border-radius: 8px;
  background: white;
  &:focus-visible {
    border: none;
    outline: none;
  }
}

.no-active2 {
  width: 256px !important;
  max-height: 52px;
  z-index: 110;
}
.save-button {
  color: #ffffff;
  background: #000000;
  border: none;
  cursor: pointer;
  padding: 12px 16px;
  font-size: 14px;
  font-family: Inter;
  text-transform: none;
  border-radius: 8px;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 8;
}

.top-row {
  width: 100%;
  justify-content: space-between;
}
.close-button {
  background: #f4f5f7;
  border-radius: 8px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -10px;
}
.divider {
  width: 100%;
  border: 1px solid #e6e8ec;
  margin-bottom: 16px;
  margin-top: 24px;
  left: 0;
  right: 0;
}
.no-active {
  width: 256px !important;
  max-height: 52px;
}
.sticky-header {
  justify-content: flex-end;
  z-index: 111;
  padding-top: 40px;
  position: fixed;
  background: white;
  width: 558px;
}
.textarea {
  width: 100%;
  height: 96px;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #e6e8ec;
  font-family: Inter;
  font-size: 16px;
  line-height: 20px;
}
.textarea:focus {
  border: 1px solid #e6e8ec;
  outline: none;
  border-radius: 8px;
}
.no-active.v-input.v-input__slot {
  border-radius: 20px;
  border: 1px solid #e6e8ec !important;
}
.active-box {
  border: 1px solid #ff5c01;
  background: #ff5c01;
}
.v-text-field--outlined >>> fieldset {
  border: 1px solid #e6e8ec;
}
.probability {
  padding-right: 20%;
}
.field-row {
  margin-top: 24px !important;
}
.address-selected {
  padding-top: 4px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  /* or 143% */

  display: flex;
  align-items: center;

  /* black */

  color: #000000;
}
.pipeline-name {
  display: none;
  white-space: nowrap;
  margin-left: 5px;
}
.chosen {
  background-color: #f4f5f7;
}
.pipeline-middle {
  display: flex;
  flex-direction: row;
  flex: 0;
  align-items: center;
  width: 56px;
  height: 40px;
  padding-right: 0px;
  background-color: #e6e8ec;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.pipeline-image-front {
  width: 12px;
  path {
    fill: #e6e8ec !important;
  }
}
.path-colored {
  path {
    fill: #fff0d4 !important;
  }
}
.path-selected {
  path {
    fill: #000000 !important;
  }
}
.delete-button {
  margin-right: 244px;
  background-color: transparent !important;
  box-shadow: 0px 0 2px #ff1e24;
  text-transform: none;
  cursor: pointer;
  padding: 12px 16px;
  font-size: 14px;
  font-family: Inter;
  border-radius: 8px;
  color: #ff1e24;
}
.pipeline-left {
  position: absolute;
  left: -1px;
}
.buttons-container {
  justify-content: flex-end;
  padding-bottom: 64px;
  z-index: 110;
  position: fixed;
  background: white;
  bottom: 0;
  height: 112px;
  width: 558px;
}
::v-deep .ui-input-search {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  &:after {
    margin-right: 12px;
    margin-top: 6px;
    content: url("../../assets/images/search.svg");
  }
}
.pipeline-right {
  position: absolute;
  right: -1px;
}
.chosen-step-nr {
  color: white !important;
}
.chosen-step-name {
  display: initial;
  color: white !important;
}
.chosen-step-container {
  background-color: black !important;
  width: fit-content;
  padding-right: 20px;
}
.hover-step-container {
  background-color: #fff0d4;
  width: fit-content;
  padding-right: 20px;
}
//Unscrolling
.modal-open {
  overflow-y: hidden;
}
#singleView {
  padding-bottom: 22px;
}
#bulkView {
  padding-bottom: 22px;
}
#singleView,
#bulkView {
  cursor: pointer;
}
.active-view {
  border-bottom: 2px solid black;
}
.loader-overlay {
  right: 0;
  top: 0;
  width: 600px;
  bottom: 0;
  background-color: black;
  opacity: 0.1;
  position: fixed;
  z-index: 999;
  padding: inherit;
}
.units-add-loader {
  margin-top: 32px;
  position: fixed;
  top: 50%;
  right: 300px;
  z-index: 102;
}
::v-deep .theme--light.v-select .v-select__selections {
  color: #000000;
  line-height: 20px;
}
</style>
