var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "overlay",
    attrs: {
      "id": "overlay-project"
    }
  }, [_c('div', {
    staticClass: "panel-wrapper",
    attrs: {
      "id": "panel-wrapper-project"
    }
  }, [_vm.loadingSave ? _c('div', {
    staticClass: "loader-overlay"
  }, [_c('div', {
    staticClass: "loader units-add-loader"
  })]) : _vm._e(), _c('div', {
    attrs: {
      "id": "content-wrapper"
    }
  }, [_c('div', {
    staticClass: "sticky-header"
  }, [_c('v-row', {
    staticClass: "top-row",
    staticStyle: {
      "height": "24px",
      "padding-left": "32px",
      "padding-right": "32px"
    }
  }, [_c('h4', {
    staticStyle: {
      "height": "24px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("AddUnits")) + " ")])]), _c('v-row', {
    staticStyle: {
      "width": "100%",
      "height": "42px",
      "margin-top": "32px",
      "padding-left": "32px"
    }
  }, [_c('div', {
    class: {
      'active-view': _vm.activeView === 'single',
      '': _vm.activeView !== 'single'
    },
    attrs: {
      "id": "singleView"
    },
    on: {
      "click": function ($event) {
        _vm.activeView = 'single';
      }
    }
  }, [_vm.activeView === 'single' ? _c('p', {
    staticClass: "content-small view-link",
    staticStyle: {
      "font-weight": "600"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("AddSingleUnit")) + " ")]) : _vm._e(), _vm.activeView !== 'single' ? _c('p', {
    staticClass: "content-small view-link",
    staticStyle: {
      "font-weight": "400"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("AddSingleUnit")) + " ")]) : _vm._e()]), _c('div', {
    class: {
      'active-view': _vm.activeView === 'bulk',
      '': _vm.activeView !== 'bulk'
    },
    attrs: {
      "id": "bulkView"
    },
    on: {
      "click": _vm.changeToBulk
    }
  }, [_vm.activeView === 'bulk' ? _c('p', {
    staticClass: "content-small view-link",
    staticStyle: {
      "font-weight": "600"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("AddBulk")) + " ")]) : _vm._e(), _vm.activeView !== 'bulk' ? _c('p', {
    staticClass: "content-small view-link",
    staticStyle: {
      "font-weight": "400"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("AddBulk")) + " ")]) : _vm._e()])]), _c('div', {
    staticClass: "separator-row-main",
    staticStyle: {
      "border": "0.1px solid #e6e8ec",
      "width": "560px"
    }
  })], 1), _vm.activeView === 'single' ? _c('div', [_c('v-row', {
    staticStyle: {
      "padding-top": "168px",
      "padding-left": "32px",
      "padding-right": "32px"
    }
  }, [_c('h5', [_vm._v(_vm._s(_vm.$t("UnitDetails")))])]), _c('v-row', {
    staticClass: "required",
    staticStyle: {
      "margin-top": "24px",
      "padding-left": "32px",
      "padding-right": "32px"
    }
  }, [_c('v-text-field', {
    staticClass: "no-active",
    staticStyle: {
      "width": "100% !important"
    },
    attrs: {
      "outlined": "",
      "error": _vm.isErrorFloor,
      "type": "number",
      "label": _vm.$t('Floor')
    },
    model: {
      value: _vm.form.floor,
      callback: function ($$v) {
        _vm.$set(_vm.form, "floor", $$v);
      },
      expression: "form.floor"
    }
  })], 1), _c('v-row', {
    staticClass: "field-row",
    staticStyle: {
      "margin-top": "14px !important",
      "padding-left": "32px",
      "padding-right": "32px"
    }
  }, [_c('v-text-field', {
    staticClass: "no-active",
    staticStyle: {
      "width": "100% !important"
    },
    attrs: {
      "outlined": "",
      "type": "number",
      "label": _vm.$t('UnitSize')
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function () {
        return [_c('p', {
          staticStyle: {
            "margin-top": "2px"
          }
        }, [_vm._v("㎡")])];
      },
      proxy: true
    }], null, false, 4208327700),
    model: {
      value: _vm.form.unitSize,
      callback: function ($$v) {
        _vm.$set(_vm.form, "unitSize", $$v);
      },
      expression: "form.unitSize"
    }
  })], 1), _c('v-row', {
    staticClass: "field-row",
    staticStyle: {
      "margin-top": "14px !important",
      "padding-left": "32px",
      "padding-right": "32px"
    }
  }, [_c('v-text-field', {
    staticClass: "no-active",
    staticStyle: {
      "width": "100% !important"
    },
    attrs: {
      "outlined": "",
      "type": "number",
      "label": _vm.$t('Price')
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function () {
        return [_c('img', {
          staticStyle: {
            "margin-top": "2px"
          },
          attrs: {
            "src": require("../../assets/images/euroSign.svg")
          }
        })];
      },
      proxy: true
    }], null, false, 2145473925),
    model: {
      value: _vm.form.price,
      callback: function ($$v) {
        _vm.$set(_vm.form, "price", $$v);
      },
      expression: "form.price"
    }
  })], 1), _c('v-row', {
    staticClass: "field-row",
    staticStyle: {
      "margin-top": "14px !important",
      "padding-left": "32px",
      "padding-right": "32px"
    }
  }, [_c('v-select', {
    staticClass: "no-active",
    staticStyle: {
      "height": "52px !important"
    },
    attrs: {
      "outlined": "",
      "clearable": "",
      "item-text": "customerName",
      "return-object": "",
      "items": _vm.clients,
      "label": _vm.$t('Client')
    },
    model: {
      value: _vm.form.client,
      callback: function ($$v) {
        _vm.$set(_vm.form, "client", $$v);
      },
      expression: "form.client"
    }
  })], 1), _c('v-row', {
    staticStyle: {
      "margin-top": "14px !important",
      "padding-left": "32px",
      "padding-right": "32px"
    }
  }, [_c('v-select', {
    staticClass: "no-active",
    attrs: {
      "clearable": "",
      "outlined": "",
      "item-value": "value",
      "item-text": "name",
      "items": _vm.statuses,
      "label": _vm.$t('Status')
    },
    model: {
      value: _vm.form.status,
      callback: function ($$v) {
        _vm.$set(_vm.form, "status", $$v);
      },
      expression: "form.status"
    }
  })], 1), _c('v-row', {
    staticStyle: {
      "margin-top": "32px",
      "padding-left": "32px",
      "padding-right": "32px"
    }
  }, [_c('h5', [_vm._v(_vm._s(_vm.$t("Description")))])]), _c('v-row', {
    staticClass: "field-row",
    staticStyle: {
      "margin-top": "24px !important",
      "padding-bottom": "328px",
      "padding-left": "32px",
      "padding-right": "32px"
    }
  }, [_c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.description,
      expression: "form.description"
    }],
    staticClass: "textarea",
    attrs: {
      "placeholder": _vm.$t('Description')
    },
    domProps: {
      "value": _vm.form.description
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.form, "description", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "buttons-container"
  }, [_c('v-divider'), _c('div', {
    staticClass: "buttons-row"
  }, [_c('button', {
    staticClass: "cancel",
    on: {
      "click": function ($event) {
        return _vm.closeModal();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")]), _c('button', {
    staticClass: "add-button",
    on: {
      "click": function ($event) {
        return _vm.saveUnits(_vm.form);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("AddUnit")) + " ")])])], 1)], 1) : _vm._e(), _vm.activeView === 'bulk' ? _c('div', [_c('v-row', {
    staticStyle: {
      "padding-top": "168px",
      "padding-left": "32px",
      "padding-right": "32px"
    }
  }, [_c('h5', [_vm._v(_vm._s(_vm.$t("UnitDetails")))])]), _c('v-row', {
    staticStyle: {
      "flex-wrap": "nowrap",
      "margin-top": "24px",
      "gap": "12px",
      "padding-left": "32px",
      "padding-right": "32px"
    }
  }, [_c('v-text-field', {
    staticClass: "no-active required",
    staticStyle: {
      "width": "234px !important"
    },
    attrs: {
      "outlined": "",
      "error": _vm.isErrorFloorFrom,
      "type": "number",
      "label": _vm.$t('FirstFloor')
    },
    model: {
      value: _vm.formBulk.floorFrom,
      callback: function ($$v) {
        _vm.$set(_vm.formBulk, "floorFrom", $$v);
      },
      expression: "formBulk.floorFrom"
    }
  }), _c('v-text-field', {
    staticClass: "no-active required",
    staticStyle: {
      "width": "234px !important"
    },
    attrs: {
      "outlined": "",
      "error": _vm.isErrorFloorTo,
      "type": "number",
      "label": _vm.$t('LastFloor')
    },
    model: {
      value: _vm.formBulk.floorTo,
      callback: function ($$v) {
        _vm.$set(_vm.formBulk, "floorTo", $$v);
      },
      expression: "formBulk.floorTo"
    }
  })], 1), _c('v-row', {
    staticClass: "field-row",
    staticStyle: {
      "margin-top": "14px !important",
      "padding-left": "32px",
      "padding-right": "32px"
    }
  }, [_c('v-text-field', {
    staticClass: "no-active required",
    staticStyle: {
      "width": "100% !important"
    },
    attrs: {
      "outlined": "",
      "type": "number",
      "label": _vm.$t('UnitsPerFloor')
    },
    model: {
      value: _vm.formBulk.unitsPerFloor,
      callback: function ($$v) {
        _vm.$set(_vm.formBulk, "unitsPerFloor", $$v);
      },
      expression: "formBulk.unitsPerFloor"
    }
  })], 1), _c('v-row', {
    staticClass: "field-row",
    staticStyle: {
      "margin-top": "14px !important",
      "padding-left": "32px",
      "padding-right": "32px"
    }
  }, [_c('v-text-field', {
    staticClass: "no-active",
    staticStyle: {
      "width": "100% !important"
    },
    attrs: {
      "outlined": "",
      "type": "number",
      "label": _vm.$t('UnitSize')
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function () {
        return [_c('p', {
          staticStyle: {
            "margin-top": "2px"
          }
        }, [_vm._v("㎡")])];
      },
      proxy: true
    }], null, false, 4208327700),
    model: {
      value: _vm.formBulk.unitSize,
      callback: function ($$v) {
        _vm.$set(_vm.formBulk, "unitSize", $$v);
      },
      expression: "formBulk.unitSize"
    }
  })], 1), _c('v-row', {
    staticClass: "field-row",
    staticStyle: {
      "margin-top": "14px !important",
      "padding-left": "32px",
      "padding-right": "32px"
    }
  }, [_c('v-text-field', {
    staticClass: "no-active",
    staticStyle: {
      "width": "100% !important"
    },
    attrs: {
      "outlined": "",
      "type": "number",
      "label": _vm.$t('Price')
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function () {
        return [_c('img', {
          staticStyle: {
            "margin-top": "2px"
          },
          attrs: {
            "src": require("../../assets/images/euroSign.svg")
          }
        })];
      },
      proxy: true
    }], null, false, 2145473925),
    model: {
      value: _vm.formBulk.price,
      callback: function ($$v) {
        _vm.$set(_vm.formBulk, "price", $$v);
      },
      expression: "formBulk.price"
    }
  })], 1), _c('v-row', {
    staticClass: "field-row",
    staticStyle: {
      "margin-top": "14px !important",
      "padding-left": "32px",
      "padding-right": "32px"
    }
  }, [_c('v-select', {
    staticClass: "no-active",
    staticStyle: {
      "height": "52px !important"
    },
    attrs: {
      "outlined": "",
      "clearable": "",
      "item-text": "customerName",
      "return-object": "",
      "items": _vm.clients,
      "label": _vm.$t('Client')
    },
    model: {
      value: _vm.formBulk.client,
      callback: function ($$v) {
        _vm.$set(_vm.formBulk, "client", $$v);
      },
      expression: "formBulk.client"
    }
  })], 1), _c('v-row', {
    staticStyle: {
      "margin-top": "14px !important",
      "padding-left": "32px",
      "padding-right": "32px"
    }
  }, [_c('v-select', {
    staticClass: "no-active",
    attrs: {
      "clearable": "",
      "outlined": "",
      "item-value": "value",
      "item-text": "name",
      "items": _vm.statuses,
      "label": _vm.$t('Status')
    },
    model: {
      value: _vm.formBulk.status,
      callback: function ($$v) {
        _vm.$set(_vm.formBulk, "status", $$v);
      },
      expression: "formBulk.status"
    }
  })], 1), _c('v-row', {
    staticStyle: {
      "margin-top": "32px",
      "padding-left": "32px",
      "padding-right": "32px"
    }
  }, [_c('h5', [_vm._v(_vm._s(_vm.$t("Description")))])]), _c('v-row', {
    staticClass: "field-row",
    staticStyle: {
      "margin-top": "24px !important",
      "padding-bottom": "328px",
      "padding-left": "32px",
      "padding-right": "32px"
    }
  }, [_c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formBulk.description,
      expression: "formBulk.description"
    }],
    staticClass: "textarea",
    attrs: {
      "placeholder": _vm.$t('Description')
    },
    domProps: {
      "value": _vm.formBulk.description
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formBulk, "description", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "buttons-container"
  }, [_c('v-divider'), _c('div', {
    staticClass: "buttons-row"
  }, [_c('button', {
    staticClass: "cancel",
    on: {
      "click": function ($event) {
        return _vm.closeModal();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")]), _c('button', {
    staticClass: "add-button",
    on: {
      "click": _vm.saveUnits
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("AddUnit")) + " ")])])], 1)], 1) : _vm._e()])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }