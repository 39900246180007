<template>
  <div
    v-click-outside="() => (isOpen = false)"
    class="main"
    @focusin="openItems()"
  >
    <v-text-field
      prepend-inner-icon="mdi-magnify"
      autocomplete="off"
      @focusin="chosenObject ? (isOpen = false) : (isOpen = true)"
      v-model="search"
      :label="placeholder"
      :error="errorKey"
      outlined
      @click:clear="clearSelection(chosenObjectApi, chosenObject)"
      clearable
      hide-details
      style="background: white"
    >
    </v-text-field>
    <div
      v-if="foundItemsApi.length > -1 && isOpen && !this.redirected"
      class="items"
    >
      <div class="item-wrapper">
        <div>
          <div
            @click="chooseResultApi(item['contactName'], item)"
            v-for="(item, index) in foundItemsApi"
            :key="index"
            class="item"
          >
            <p v-if="!item.isPrivatePerson">
              {{ item["contactName"] }}
              <span> - {{ item["company_name"] }} </span>
            </p>
            <p v-else>
              {{ item["contactName"] }}
              <span> - {{ $t("PrivatePerson.Label").toLowerCase() }}</span>
            </p>
          </div>
        </div>
      </div>
      <div
        class="item-wrapper"
        v-if="
          search &&
          search.length > 1 &&
          !resultChosen &&
          foundItemsApi.length < 1
        "
      >
        <div class="item no-hover">No result found for "{{ search }}"</div>
      </div>
      <div class="separator"></div>
      <div class="add-new" @click="addNew">
        <img src="../../assets/images/plusSign.svg" alt="" />
        {{ $t("AddNew") }}
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Autocomplete",
  props: [
    "closeMenu",
    "items",
    "placeholder",
    "errorKey",
    "chosenObject",
    "alreadyChosen",
  ],
  data: () => ({
    search: null,
    resultChosen: false,
    chosenObjectApi: null,
    foundItemsApi: [],
    foundItemsApiClone: [],
    displayKeyApi: null,
    fetchingItems: false,
    isOpen: false,
    selectedObject: null,
    loadingItems: false,
    redirected: false,
    preselected: false,
  }),
  created() {},
  async mounted() {
    console.log("look here for chosenobject", this.chosenObject);
    if (this.chosenObject) {
      this.redirected = true;
      this.isOpen = false;
      await this.findItemsFromApi(this.chosenObject.customerName);
      const chosenContact = this.foundItemsApi.filter(
        (item) => item.contactId === this.chosenObject.customerId
      )[0];
      if (chosenContact && !this.alreadyChosen) {
        await this.$emit("resultChosen", chosenContact);
      }
      if (chosenContact && chosenContact.company_name) {
        this.search =
          chosenContact.contactName + " - " + chosenContact.company_name;
      } else {
        if (this.chosenObject.company_name) {
          this.search =
            this.chosenObject.contactName +
            " - " +
            this.chosenObject.company_name;
        } else {
          this.search =
            this.chosenObject.contactName +
            " - " +
            this.$t("PrivatePerson.Label").toLowerCase();
        }
      }
    }
  },
  watch: {
    async chosenObject() {
      if (this.chosenObject) {
        this.redirected = true;
        this.isOpen = false;
        const nameToSearch = this.chosenObject.customerName ? this.chosenObject.customerName : this.chosenObject.contactName;
        await this.findItemsFromApi(nameToSearch);
        const chosenContact = await this.foundItemsApi.find((item) => {
          if (item.contactId && this.chosenObject.customerId) {
            if (item.contactId === this.chosenObject.customerId) {
              return item;
            }
          }
          if (this.chosenObject.company && item.company_name) {
            if (this.chosenObject.company === item.company_name) {
              return item;
            }
          }
        })
        if (chosenContact && !this.alreadyChosen) {
          this.$emit("resultChosen", chosenContact);
        }
        if (chosenContact && chosenContact.company_name) {
          this.search =
            chosenContact.contactName + " - " + chosenContact.company_name;
        } else {
          if (this.chosenObject.company_name) {
            this.search =
              this.chosenObject.contactName +
              " - " +
              this.chosenObject.company_name;
          } else {
            this.search =
              this.chosenObject.contactName +
              " - " +
              this.$t("PrivatePerson.Label").toLowerCase();
          }
        }
      }
    },

    closeMenu() {
      this.isOpen = false;
    },
    writtenText(val) {
      this.search = val;
    },
    async search(val) {
      if (!val) {
        this.foundItemsApi = [];
        this.isOpen = true;
        if (!this.alreadyChosen) this.$emit("resultChosen", null);
      } else {
        if (val.length > 0 && this.chosenObject) {
          this.isOpen = false;
        }
        if (val.length === 1 && !this.chosenObject) {
          await this.findItemsFromApi(val);
          this.isOpen = true;
        }
        if (val.length > 1 && !this.chosenObject) {
          const checkIfItemsLoaded = setInterval(() => {
            if (!this.loadingItems) {
              this.findItems(val);
              clearInterval(checkIfItemsLoaded);
            }
          }, 100);
        }
      }
    },
  },
  methods: {
    removeContactTypeFromField() {
      if (this.search && this.search.length > 0) {
        let stringToReplace = "";
        if (this.chosenObjectApi.isPrivatePerson) {
          stringToReplace =
            " - " + this.$t("PrivatePerson.Label").toLowerCase();
          this.search = this.search.replace(stringToReplace, "");
        } else {
          stringToReplace = " - " + this.chosenObjectApi.company_name;
          this.search = this.search.replace(stringToReplace, "");
        }
      }
    },
    openItems() {
      this.redirected = false;
      if (this.foundItemsApi.length) {
        this.isOpen = true;
      }
    },
    clearSelection() {
      this.foundItemsApi = [];
      this.$emit("resultChosen", null);
      this.search = null;
      this.chosenObjectApi = null;
      this.chosenObject = null;
      this.resultChosen = false;
    },
    async findItemsFromApi(val) {
      this.loadingItems = true;
      this.foundItemsApi = [];
      this.displayKeyApi = "company_name";
      const response = await axios.get(`/api/contact/search/${val}`);
      this.foundItemsApi = response.data;
      console.log("FOUND ITEMS API", this.foundItemsApi)
      this.foundItemsApiClone = [...response.data];
      this.loadingItems = false;
    },
    addNew() {
      this.$emit("addNew");
      this.isOpen = false;
    },
    /*async chooseResult(visualText, fullObject) {
      this.search = visualText;

      this.selectedObject = fullObject;
      await this.$emit("resultChosen", fullObject);
      this.isOpen = false;
    },*/
    async chooseResultApi(visualText, fullObject) {
      if (fullObject.isPrivatePerson) {
        this.search =
          fullObject.contactName +
          " - " +
          this.$t("PrivatePerson.Label").toLowerCase();
      } else {
        this.search = fullObject.contactName + " - " + fullObject.company_name;
      }

      this.resultChosen = true;
      this.chosenObjectApi = fullObject;
      await this.$emit("resultChosen", fullObject);
      this.isOpen = false;
    },
    findItems(val) {
      let searchWord = val.toLowerCase();
      this.loadingItems = true;
      let searchKey = "contactName";
      let searchKey2 = "company_name";
      /*const companySearch = this.foundItemsApiClone.filter(
        (o) =>
          o[searchKey].toLowerCase().includes(searchWord) ||
          o[searchKey2].toLowerCase().includes(searchWord)
      );
*/
      const companySearch = this.foundItemsApiClone.filter(function (
        currentElement
      ) {
        if (
          (currentElement[searchKey] &&
            currentElement[searchKey].toLowerCase().includes(searchWord)) ||
          (currentElement[searchKey2] &&
            currentElement[searchKey2].toLowerCase().includes(searchWord))
        ) {
          return true;
        }
      });

      if (companySearch) {
        this.foundItemsApi = companySearch;
      }
      setTimeout(() => {
        this.loadingItems = false;
      }, 300);
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  position: relative;
  width: 100%;
  max-height: 54px;
}

.items {
  box-sizing: border-box;
  //border: 1px solid #e6e8ec;
  margin-top: 8px;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  z-index: 111;
  position: absolute;
  background-color: white;
  width: 100%;
  border-radius: 8px;
}

.item-wrapper {
  max-height: 180px;
  overflow: auto;
}

.item {
  min-height: 44px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 12px;

  &:hover {
    background-color: #f4f5f7;
    cursor: pointer;
  }
}

.separator {
  border-top: 1px solid #e6e8ec;
  width: 100%;
  height: 1px;
}

.add-new {
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 4px;
  align-items: center;
  padding: 16px 12px 16px 12px;
  background-color: white;
  gap: 8px;

  &:hover {
    cursor: pointer;
  }
}

.no-hover {
  cursor: default !important;
  background-color: white !important;
}

p,
span {
  font-size: 14px;
  line-height: 20px;
}

span {
  color: #939597;
}
</style>
