var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content"
  }, [_c('v-row', {
    staticClass: "customer-row"
  }, [_c('div', {
    staticClass: "toolbar-row"
  }, [_c('button', {
    staticClass: "outlined-small-button",
    staticStyle: {
      "background-color": "black !important",
      "color": "white !important"
    },
    on: {
      "click": function ($event) {
        _vm.unitsAddOpen = true;
      }
    }
  }, [_c('img', {
    staticStyle: {
      "filter": "invert(100%) sepia(0%) saturate(0%) hue-rotate(317deg)"
    },
    attrs: {
      "src": require("../../../assets/images/plusSign.svg"),
      "alt": ""
    }
  }), _vm._v(" " + _vm._s(_vm.$t("AddUnit")) + " ")]), _c('button', {
    staticClass: "outlined-small-button search-button"
  }, [_c('img', {
    attrs: {
      "src": require("../../../assets/images/search.svg"),
      "alt": ""
    }
  }), _vm._v(" " + _vm._s(_vm.$t("Search")) + "... ")])]), _vm.loading ? _c('div', {
    staticClass: "loader",
    staticStyle: {
      "margin-top": "32px",
      "position": "absolute",
      "top": "30%",
      "left": "50%"
    }
  }) : _vm._e(), !_vm.loading && !_vm.object.units || !_vm.loading && _vm.object.units.length < 1 ? _c('div', {
    staticClass: "no-results"
  }, [_c('h4', [_vm._v(_vm._s(_vm.$t("NoUnits")))])]) : _vm._e(), _vm.object ? _c('v-row', {
    staticClass: "customer-row"
  }, [_c('v-row', {
    staticClass: "collection-row"
  }, [_c('div', {
    staticClass: "listings-wrapper",
    staticStyle: {
      "display": "flex"
    }
  }, [_vm.object.units.length > 0 ? _c('Table', {
    key: _vm.objectsRefresher,
    attrs: {
      "fields": _vm.tableFields,
      "dataset": _vm.object.units,
      "project-type": _vm.projectType,
      "object-id": _vm.object._id,
      "deal-id": _vm.$route.params.id,
      "customers": _vm.customers,
      "listing-view": true
    }
  }) : _vm._e()], 1)])], 1) : _vm._e()], 1), _vm.unitsAddOpen ? _c('UnitsAdd', {
    attrs: {
      "object": _vm.object,
      "clients": _vm.customers
    },
    on: {
      "saved": _vm.unitsAdded,
      "closed": function ($event) {
        _vm.unitsAddOpen = false;
      }
    }
  }) : _vm._e(), _vm.undoButton ? _c('UndoButton', {
    attrs: {
      "units-view": true,
      "object-to-undo": _vm.recentlyAddedBuildingId,
      "property-to-undo": _vm.$route.params.id,
      "items-to-undo": _vm.recentlyAddedItems
    },
    on: {
      "timerDone": _vm.handleTimerDone,
      "undone": function ($event) {
        _vm.undoButton = false;
        _vm.$router.go(0);
      }
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }