<template>
  <div class="deal-completed" :key="'deal-completed-' + elementKeyUpdater">
    <div class="deal-completed-header">
      <h5>{{$t("DealSummary")}}</h5>
      <template v-if="deal.pipelineStep !== 'archived'">
        <BaseButton @click="editProjectCompleteOpen = true" :icon-left="'edit_16_16.svg'" :label="$t('Edit')" size="small"/>
      </template>
    </div>
    <div class="deal-completed-value-container">
      <div class="project-end-label content-bold" :class="{'project-won': projectEndState === 'Won',
      'project-lost': projectEndState === 'Lost' || projectEndState === 'Cancelled'}">{{ $t(projectEndState) }}
      </div>
      <div v-if="projectFinalValue" class="project-value-details">
        <div class="content-small">{{ $t("FinalDealValue") }}</div>
        <div class="font-20-bold">{{ parseInt(projectFinalValue.replace(" ", "")).toLocaleString("fr") + " €" }}</div>
      </div>
      <div v-if="projectBrokerageFee" class="project-value-details">
        <div class="content-small">{{ $t("FinalBrokerageFee") }}</div>
        <div class="font-20-bold">{{ projectBrokerageFee }}</div>
      </div>
<!--      <div v-if="projectClosingDate" class="project-value-details">-->
<!--        <div class="content-small">{{ $t("ClosingDate") }}</div>-->
<!--        <div class="font-20-bold">{{ formatDate(projectClosingDate) }}</div>-->
<!--      </div>-->
      <div v-if="projectTransactionDate" class="project-value-details">
        <div class="content-small">{{ $t("DateOfTransaction") }}</div>
        <div class="font-20-bold">{{ formatDate(projectTransactionDate) }}</div>
      </div>
    </div>
    <div v-if="backedOutReason.who || backedOutReason.why" class="deal-comment-container">
      <h6 v-if="backedOutReason.who">{{ formatBackedOut(backedOutReason.who) }}</h6>
      <p v-if="backedOutReason.why" class="content-normal">{{ backedOutReason.why }}</p>
    </div>
    <div class="deal-completed-listings-container">
<!--      <div class="deal-completed-listings-container-listing-card" v-for="listing of projectListings" :key="listing._id">
        <div class="deal-completed-listings-container-listing-card-header">
          <img height="16" width="16" alt="" :src="require('@/assets/images/building16x16.svg')" />
          <div @click="openListingSidepanel(listing._id)" v-if="listing.listingName" class="link">{{listing.listingName}}</div>
        </div>
        <div class="deal-completed-listings-container-listing-card-content">
          <div>
            <p class="content-small">{{$t("Suggestions")}}</p>
            <div class="content-bold">{{ listing.funnels["Matches"].length }}</div>
          </div>
          <div>
            <p class="content-small">{{$t("Offers")}}</p>
            <div class="content-bold">{{ listing.funnels["Offers"].length }}</div>
          </div>
          <div>
            <p class="content-small">{{$t("Interested")}}</p>
            <div class="content-bold">{{ listing.funnels["Interested"].length }}</div>
          </div>
          <div>
            <p class="content-small">{{$t("Viewed")}}</p>
            <div class="content-bold">{{ listing.funnels["Viewed"].length }}</div>
          </div>
          <div>
            <p class="content-small">{{$t("Negotiation & Contract")}}</p>
            <div class="content-bold">{{ listing.funnels["Negotiation & Contract"].length }}</div>
          </div>
          <div>
            <p class="content-small">{{$t("Lost")}}</p>
            <div class="content-bold">{{ listing.funnels["Lost / Archived"].length }}</div>
          </div>
        </div>
      </div>-->
    </div>
    <ListingSidepanel @overlayClicked="listingPanelOpen = false" v-if="listingPanelOpen" :listing-id="chosenListingId" />
    <ProjectEndModal @projectWon="dealEndStateUpdated" @saveResponse="dealEndStateUpdated" @closed="editProjectCompleteOpen = false" :deal="deal" v-if="editProjectCompleteOpen" />
    <div v-if="showAnimation" class="deal-won-animation">
      <Lottie v-on:animCreated="handleAnimation" :options="animationOptions" />
    </div>
  </div>
</template>

<script>
import BaseButton from "@/components/common/BaseButton";
import moment from "moment";
import axios from "axios";
import listingApi from "@/http/listing";
import ListingSidepanel from "@/components/Listings/ListingSidepanel";
import ProjectEndModal from "@/components/Deals/ProjectEndModal";
import Lottie from 'vue-lottie/src/lottie';
import animationData from '@/assets/lottie/confetti.json';

export default {
  name: "CompletedContainer",
  components: {ListingSidepanel, BaseButton, ProjectEndModal, Lottie},
  props: ["deal"],
  data() {
    return {
      anim: null,
      elementKeyUpdater: 0,
      projectEndState: "",
      backedOutReason: {
        who: "",
        why: ""
      },
      projectClosingDate: "",
      projectTransactionDate: "",
      projectFinalValue: "",
      projectBrokerageFee: "",
      projectListings: [],
      listingPanelOpen: false,
      chosenListingId: "",
      editProjectCompleteOpen: false,
      showAnimation: false,
      animationOptions: {
        animationData: animationData,
        autoplay: false,
        loop: false,
        rendererSettings: {
          preserveAspectRatio: "xMidYMax slice"
        }
      }
    }
  },
  created() {
    const itemIndex = this.deal.endState.length - 1;

    this.projectEndState = this.deal.endState[itemIndex].projectEndReason;

    if (this.projectEndState === "Lost") {
      this.backedOutReason.who = this.deal.endState[itemIndex].backedOutPerson;
      this.backedOutReason.why = this.deal.endState[itemIndex].lostReason;
    } else if (this.projectEndState === "Cancelled") {
      this.backedOutReason.who = this.deal.endState[itemIndex].cancelledPerson;
      this.backedOutReason.why = this.deal.endState[itemIndex].cancelledReason;
    }

    this.projectClosingDate = this.deal.endState[itemIndex].closingDate;
    this.projectTransactionDate = this.deal.endState[itemIndex].dateOfTransaction;

    this.projectFinalValue = this.deal.endState[itemIndex].amount;

    this.projectBrokerageFee = this.calculateBrokerFee(this.deal.brokerFeePercentage, this.deal.minimumFee);

    this.getListings(this.deal.objects[0].listings);

  },
  methods: {
    handleAnimation(anim) {
      this.anim = anim;
    },
    animationFinished() {
      this.showAnimation = false;
      this.dealEndStateUpdated()
    },
    playAnimation() {
      this.showAnimation = true;
      this.$nextTick(() => {
        this.anim.addEventListener('complete', () => this.animationFinished());
        this.anim.play();
      })
    },
    dealEndStateUpdated() {
      this.$emit("updateDealEndState");
    },
    openListingSidepanel(listingId) {
      this.chosenListingId = listingId;
      this.listingPanelOpen = true;
    },
    calculateBrokerFee(feePercentage, minimumFee) {
      if (this.projectFinalValue && feePercentage && minimumFee) {
        const projectValue = parseInt(this.projectFinalValue.replace(/\s/g, ""))
        const calculatedValue = (feePercentage / 100) * projectValue
        if (calculatedValue < parseInt(minimumFee)) {
          return parseInt(minimumFee).toLocaleString("fr") + ` €`
        } else {
          return calculatedValue.toLocaleString("fr") + ` € (${feePercentage}%)`
        }
      }
      return "";
    },
    formatBackedOut(backedOutItem) {
      if (backedOutItem === "broker") {
        return this.$t("BrokerBackedOut");
      } else if (backedOutItem === "client") {
        return this.$t("ClientBackedOut");
      }
    },
    formatDate(date) {
      return moment(date).format("DD.MM.YYYY")
    },
    async getListings(listingIds) {
      await axios.post("/api/listings/full", {listingIds}).then((response) => {
        if (response.status && response.status === 200) {
          this.projectListings = response.data.listings
          this.projectListings.forEach(listing => this.getListingSuggestions(listing))
          this.elementKeyUpdater++;
        } else {
          console.error("Bad request, couldn't retrieve listings");
        }
      }).catch((err) => {
        console.error(err);
      });

    },
    async getListingSuggestions(listingToCheck) {
      let response = await listingApi.getListingAutoSuggestions(listingToCheck);
      listingToCheck.funnels["Matches"] = response.data;
      this.elementKeyUpdater++;
    },
  }
}
</script>

<style scoped>
.deal-completed {
  width: 100%;
  /*min-height: 466px;*/
  background: white;
  border-radius: 8px;
  padding: 32px 32px 0px 32px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.deal-completed-header {
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  justify-content: space-between;
}

.deal-completed-value-container {
  min-height: 44px;
  display: flex;
  gap: 32px;
  flex-wrap: wrap;
}

.font-20-bold {
  font-family: Inter, sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
}

.deal-comment-container {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  border-radius: 8px;
  border: 1px solid #e6e8ec;
}

.project-value-details {
  height: 44px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.project-value-details div {
  white-space: nowrap;
}

.project-end-label {
  height: 44px;
  padding: 12px 16px;
  border-radius: 8px;
  color: black;
}

.project-won {
  background: #B5E7CF;
}

.project-lost {
  background: #FFB4B6;
}

.deal-completed-listings-container {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
}

.deal-completed-listings-container-listing-card {
  border-radius: 8px;
  border: 1px solid #e6e8ec;
  width: 264px;
}

.deal-completed-listings-container-listing-card-header {
  padding: 16px;
  display: flex;
  height: 64px;
  flex-wrap: nowrap;
  text-overflow: ellipsis;
  gap: 8px;
}

.deal-completed-listings-container-listing-card-header div {
  width: 100%;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
}

.deal-completed-listings-container-listing-card-content {
  background: #fafafa;
  border-radius: 0 0 8px 8px;
  width: 100%;
  padding: 16px;
}

.deal-completed-listings-container-listing-card-content div {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  height: 28px;
}

.link {
  cursor: pointer;
}

.deal-won-animation {
  height: 110%;
  width: 100%;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -55%);
}

</style>