var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "widget-overlay",
    attrs: {
      "id": "widget-overlay"
    }
  }, [_c('div', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.closeWidget,
      expression: "closeWidget"
    }],
    attrs: {
      "id": "widget"
    }
  }, [this.task.type !== 'open_house' ? _c('span', [_c('v-row', [_c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.task.content,
      expression: "task.content"
    }],
    staticClass: "entry-title",
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "placeholder": _vm.$t('AddTitle'),
      "type": "text"
    },
    domProps: {
      "value": _vm.task.content
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.task, "content", $event.target.value);
      }
    }
  })]), _vm.showErrors && !_vm.isFieldValid('content') ? _c('v-row', {
    staticClass: "error-text",
    staticStyle: {
      "margin-top": "4px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("FormErrors." + _vm.errors.content)) + " ")]) : _vm._e(), _c('v-row', {
    staticStyle: {
      "margin-top": "4px"
    }
  }, [_c('div', {
    staticClass: "title-underline"
  })]), _c('v-row', {
    staticClass: "type-selector",
    staticStyle: {
      "margin": "24px 0"
    }
  }, [_c('div', {
    class: {
      'selected-type': _vm.task.category === 'event',
      'not-selected-type': _vm.task.category === 'task'
    },
    on: {
      "click": function ($event) {
        _vm.task.category = 'event';
      }
    }
  }, [_c('p', [_vm._v(_vm._s(_vm.$t("Event")))])]), _vm.task.type !== 'resource' ? _c('div', {
    class: {
      'selected-type': _vm.task.category === 'task',
      'not-selected-type': _vm.task.category === 'event'
    },
    on: {
      "click": function ($event) {
        _vm.task.category = 'task';
      }
    }
  }, [_c('p', [_vm._v(_vm._s(_vm.$t("Task")))])]) : _vm._e()])], 1) : _vm._e(), _c('v-row', [_c('div', {
    staticClass: "field-icon-con"
  }, [_c('date-pick', {
    on: {
      "input": _vm.setDate
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var toggle = _ref.toggle;
        return [_c('img', {
          staticClass: "field-icon",
          attrs: {
            "src": require("../../assets/images/calendar.svg")
          },
          on: {
            "click": toggle
          }
        })];
      }
    }])
  })], 1), _c('v-col', {
    staticClass: "col-4"
  }, [_c('date-pick', {
    on: {
      "input": _vm.setDate
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref2) {
        var toggle = _ref2.toggle;
        return [_c('div', {
          staticClass: "entry-field-label",
          staticStyle: {
            "cursor": "pointer"
          },
          on: {
            "click": toggle
          }
        }, [_vm._v(" " + _vm._s(_vm.task.category === "task" ? _vm.$t("Field.dueDate") : _vm.$t("Date")) + " ")]), _c('div', {
          on: {
            "click": toggle
          }
        }, [_vm.task.dates.endDate ? _c('span', {
          staticStyle: {
            "cursor": "pointer",
            "font-size": "12px"
          }
        }, [_vm._v(" " + _vm._s(_vm.formatDate(_vm.task.dates.endDate)) + " ")]) : _vm._e()])];
      }
    }])
  }), _c('div', {
    staticClass: "d-inline-flex",
    staticStyle: {
      "margin-top": "8px"
    }
  }, [_c('span', [_c('Checkbox', {
    attrs: {
      "checked": _vm.isFullDay,
      "customStyle": 'width: 16px; height: 16px; margin: 0'
    },
    on: {
      "clicked": function ($event) {
        _vm.isFullDay = !_vm.isFullDay;
      }
    }
  })], 1), _c('span', {
    staticClass: "entry-field-label",
    staticStyle: {
      "padding-left": "8px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("FullDay")) + " ")])])], 1), !_vm.isFullDay ? _c('v-col', {
    staticClass: "time-con"
  }, [!_vm.task.dates.endTime ? _c('span', {
    staticClass: "entry-field-label",
    staticStyle: {
      "cursor": "pointer"
    },
    on: {
      "click": function () {
        _vm.task.dates.startTime = '09:00';
        _vm.task.dates.endTime = '10:00';
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Add") + " " + _vm.$t("Time")) + " ")]) : _vm._e(), _c('span', [_c('input', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.task.dates.startTime && _vm.task.category !== 'task',
      expression: "task.dates.startTime && task.category !== 'task'"
    }, {
      name: "model",
      rawName: "v-model",
      value: _vm.task.dates.startTime,
      expression: "task.dates.startTime"
    }],
    staticClass: "time-picker",
    staticStyle: {
      "min-width": "86px"
    },
    attrs: {
      "type": "time",
      "step": "600",
      "id": "start-time-dropdown",
      "name": "end-time-dropdown"
    },
    domProps: {
      "value": _vm.task.dates.startTime
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.task.dates, "startTime", $event.target.value);
      }
    }
  })]), _vm.task.dates.startTime && _vm.task.dates.endTime && _vm.task.category !== 'task' ? _c('span', [_vm._v(" - ")]) : _vm._e(), _vm.task.dates.endTime ? _c('span', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.task.dates.endTime,
      expression: "task.dates.endTime"
    }],
    staticClass: "time-picker",
    staticStyle: {
      "min-width": "86px"
    },
    attrs: {
      "type": "time",
      "step": "600",
      "id": "end-time-dropdown",
      "name": "end-time-dropdown"
    },
    domProps: {
      "value": _vm.task.dates.endTime
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.task.dates, "endTime", $event.target.value);
      }
    }
  })]) : _vm._e()]) : _vm._e()], 1), _c('v-row', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: function () {
        _vm.showTypes = false;
      },
      expression: "\n        () => {\n          showTypes = false;\n        }\n      "
    }],
    staticStyle: {
      "cursor": "pointer"
    },
    on: {
      "click": _vm.typeClick
    }
  }, [_c('div', {
    staticClass: "field-icon-con"
  }, [_c('div', {
    staticClass: "type-con",
    style: 'background: ' + _vm.eventTypes.find(function (e) {
      return e.value === _vm.task.type;
    }).color
  }), _c('div', {
    staticClass: "dropdown-single scrollable",
    style: 'z-index: 1; display: ' + (_vm.showTypes ? 'flex;' : 'none;')
  }, _vm._l(_vm.eventTypes, function (type) {
    return _c('div', {
      key: type.value,
      staticClass: "dropdown-item-wrapper",
      on: {
        "click": function ($event) {
          return _vm.selectType(type);
        }
      }
    }, [_c('p', {
      style: _vm.task.type === type.value ? 'font-weight: bold' : null
    }, [_vm._v(" " + _vm._s(type.name) + " ")])]);
  }), 0)]), _c('v-col', {
    staticStyle: {
      "display": "inline-grid"
    }
  }, [_c('span', {
    staticClass: "entry-field-label"
  }, [_vm._v(" " + _vm._s(_vm.$t("Type")) + " ")]), _c('v-row', {
    staticClass: "entry-field-value"
  }, [_vm._v(" " + _vm._s(_vm.eventTypes.find(function (e) {
    return e.value === _vm.task.type;
  }).name) + " ")])], 1)], 1), _c('TaskRowWithDropdown', {
    attrs: {
      "items": _vm.projects,
      "display-key": 'name',
      "value-key": 'id',
      "label": 'Project',
      "icon": 'projects.svg',
      "selectedItem": _vm.task.linkedProject,
      "allow-delete": true,
      "fieldRequired": this.task.type === 'open_house',
      "update": _vm.projectUpdater
    },
    on: {
      "itemSelected": function (item) {
        _vm.task.linkedProject = item.id ? item : {
          id: null,
          name: null
        };
        _vm.projectUpdater++;
      }
    }
  }), _vm.showErrors && !_vm.isFieldValid('project') ? _c('v-row', {
    staticClass: "error-text",
    staticStyle: {
      "margin-top": "4px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("FormErrors." + _vm.errors.project)) + " ")]) : _vm._e(), _c('TaskRowWithDropdown', {
    attrs: {
      "items": _vm.contacts,
      "display-key": 'name',
      "value-key": 'id',
      "label": 'Contact',
      "icon": 'clients.svg',
      "selectedItem": _vm.task.linkedContact,
      "allow-delete": true,
      "updater": _vm.contactUpdater
    },
    on: {
      "itemSelected": function (item) {
        _vm.task.linkedContact = item.id ? item : {
          id: null,
          name: null
        };
        _vm.contactUpdater++;
      }
    }
  }), _c('TaskRowWithDropdown', {
    attrs: {
      "items": _vm.colleagues,
      "display-key": 'name',
      "value-key": 'userId',
      "label": 'Owner',
      "icon": 'users.svg',
      "selectedItem": _vm.task.assignedTo
    },
    on: {
      "itemSelected": function (item) {
        return _vm.task.assignedTo = item;
      }
    }
  }), _c('v-row', {
    staticClass: "justify-space-between",
    staticStyle: {
      "margin-top": "24px"
    }
  }, [_c('div', {
    staticClass: "flex-column"
  }, [_c('div', [_vm._v(" " + _vm._s(_vm.$t("Private") + " " + _vm.$t("Entry").toLowerCase()) + " ")]), _c('div', [_c('img', {
    staticStyle: {
      "cursor": "pointer"
    },
    attrs: {
      "src": _vm.task.isPrivate ? require('../../assets/images/toggle-active.svg') : require('../../assets/images/toggle-inactive.svg'),
      "alt": ""
    },
    on: {
      "click": function ($event) {
        _vm.task.isPrivate = !_vm.task.isPrivate;
      }
    }
  })])]), _c('div', [_c('v-row', {
    staticStyle: {
      "gap": "8px"
    }
  }, [_c('div', [_c('button', {
    staticClass: "cancel",
    on: {
      "click": _vm.closeWidget
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")])]), _c('div', {
    staticClass: "add-button",
    on: {
      "click": _vm.createTask
    }
  }, [_c('p', [_vm._v(_vm._s(_vm.$t("Save")))])])])], 1)])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }