var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "overlay",
    attrs: {
      "id": "overlay-project"
    }
  }, [_c('div', {
    staticClass: "modal-wrapper",
    attrs: {
      "id": "panel-wrapper-project"
    }
  }, [_c('div', {
    staticClass: "content-overlay",
    attrs: {
      "id": "content-wrapper"
    }
  }, [_c('div', {
    staticClass: "sticky-header",
    staticStyle: {
      "z-index": "126"
    }
  }, [_c('v-row', {
    staticClass: "top-row",
    staticStyle: {
      "height": "24px",
      "padding-left": "32px"
    }
  }, [_c('h4', {
    staticStyle: {
      "height": "24px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("CompleteProject")) + " ")])])], 1), _c('v-divider', {
    staticStyle: {
      "top": "97px",
      "z-index": "126",
      "position": "fixed",
      "width": "560px"
    }
  }), _c('div', {
    staticStyle: {
      "padding": "129px 0px",
      "padding-right": "32px"
    }
  }, [_c('v-row', {
    staticClass: "archive-info"
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/images/info.svg'),
      "alt": ""
    }
  }), _c('p', [_vm._v(_vm._s(_vm.$t("ListingsArchivedInfo")))])]), _c('v-row', {
    staticStyle: {
      "padding-bottom": "12px",
      "padding-left": "32px"
    }
  }, [_c('h5', [_vm._v(_vm._s(_vm.$t("Subblock.GeneralData")))])]), _c('v-row', {
    staticStyle: {
      "padding-left": "32px"
    }
  }, [_c('v-col', {
    staticClass: "pa-0"
  }, [_c('v-row', {
    staticClass: "faded-title required",
    attrs: {
      "title": "required"
    }
  }, [_c('p', {
    staticClass: "content-small-semibold"
  }, [_vm._v(" " + _vm._s(_vm.$t("EndingReason")) + " ")])]), _c('v-row', {
    staticStyle: {
      "gap": "8px"
    }
  }, _vm._l(_vm.projectEndReasons, function (projectReason, index) {
    return _c('span', {
      key: index,
      on: {
        "click": function ($event) {
          return _vm.setProjectReason(projectReason);
        }
      }
    }, [_c('ProjectEndReasonContainer', {
      class: 'project-type-con ' + (projectReason === _vm.form.projectEndReason ? 'project-type-selected-con-' + projectReason : null),
      attrs: {
        "project-end-reason": projectReason
      }
    })], 1);
  }), 0)], 1)], 1), _vm.form.projectEndReason === 'Won' ? _c('v-row', {
    staticStyle: {
      "gap": "12px",
      "display": "flex",
      "margin-top": "12px",
      "padding-left": "32px",
      "width": "100%",
      "flex-wrap": "nowrap"
    }
  }, [_c('BaseInput', {
    attrs: {
      "id": 'project-amount-field',
      "placeholder": _vm.$t('DealValue')
    },
    model: {
      value: _vm.form.amount,
      callback: function ($$v) {
        _vm.$set(_vm.form, "amount", $$v);
      },
      expression: "form.amount"
    }
  }), _c('BaseDropdown', {
    attrs: {
      "id": 'project-currency-field',
      "items": _vm.currencies,
      "show-dropdown-arrow": true,
      "dropdown-allow-null": false,
      "placeholder": _vm.$t('Currency'),
      "type": 'dropdown'
    },
    model: {
      value: _vm.form.currency,
      callback: function ($$v) {
        _vm.$set(_vm.form, "currency", $$v);
      },
      expression: "form.currency"
    }
  })], 1) : _vm._e(), _vm.form.projectEndReason === 'Won' ? _c('v-row', {
    staticStyle: {
      "padding-left": "32px",
      "margin-top": "12px"
    }
  }, [_c('BaseDropdown', {
    key: _vm.inputUpdater,
    attrs: {
      "icon-left": "calendar_20_20.svg",
      "id": 'expected-closing-date',
      "placeholder": _vm.$t('DateOfTransaction'),
      "show-dropdown-arrow": true,
      "type": "date",
      "required": "true",
      "error-message": _vm.errors && _vm.errors.dateOfTransaction ? _vm.$t('FormErrors.' + _vm.errors.dateOfTransaction) : '',
      "error": _vm.showErrors && !_vm.isFieldValid('dateOfTransaction')
    },
    model: {
      value: _vm.form.dateOfTransaction,
      callback: function ($$v) {
        _vm.$set(_vm.form, "dateOfTransaction", $$v);
      },
      expression: "form.dateOfTransaction"
    }
  })], 1) : _vm._e(), _vm.form.projectEndReason === 'Lost' ? _c('v-row', {
    staticStyle: {
      "padding-left": "32px"
    }
  }, [_c('v-radio-group', {
    staticStyle: {
      "padding-top": "12px"
    },
    attrs: {
      "row": ""
    },
    model: {
      value: _vm.form.backedOutPerson,
      callback: function ($$v) {
        _vm.$set(_vm.form, "backedOutPerson", $$v);
      },
      expression: "form.backedOutPerson"
    }
  }, [_c('v-radio', {
    staticClass: "radio-button content",
    attrs: {
      "color": "#FF5C01",
      "value": "broker",
      "label": _vm.$t('BrokerBackedOut')
    }
  }), _c('v-radio', {
    staticClass: "radio-button content",
    attrs: {
      "color": "#FF5C01",
      "value": "client",
      "label": _vm.$t('ClientBackedOut')
    }
  })], 1)], 1) : _vm._e(), _vm.form.projectEndReason === 'Cancelled' ? _c('v-row', {
    staticStyle: {
      "padding-left": "32px"
    }
  }, [_c('v-radio-group', {
    staticStyle: {
      "padding-top": "12px"
    },
    attrs: {
      "row": ""
    },
    model: {
      value: _vm.form.cancelledPerson,
      callback: function ($$v) {
        _vm.$set(_vm.form, "cancelledPerson", $$v);
      },
      expression: "form.cancelledPerson"
    }
  }, [_c('v-radio', {
    staticClass: "radio-button content",
    attrs: {
      "color": "#FF5C01",
      "value": "broker",
      "label": _vm.$t('BrokerCancelled')
    }
  }), _c('v-radio', {
    staticClass: "radio-button content",
    attrs: {
      "color": "#FF5C01",
      "value": "client",
      "label": _vm.$t('ClientCancelled')
    }
  })], 1)], 1) : _vm._e(), _vm.form.projectEndReason === 'Lost' ? _c('span', [_c('v-row', {
    staticStyle: {
      "padding-left": "32px",
      "margin-top": "24px"
    }
  }, [_c('BaseTextarea', {
    attrs: {
      "id": 'project-end-add-reason',
      "placeholder": _vm.$t('Reason')
    },
    model: {
      value: _vm.form.lostReason,
      callback: function ($$v) {
        _vm.$set(_vm.form, "lostReason", $$v);
      },
      expression: "form.lostReason"
    }
  })], 1)], 1) : _vm._e(), _vm.form.projectEndReason === 'Cancelled' ? _c('span', [_c('v-row', {
    staticStyle: {
      "padding-left": "32px",
      "margin-top": "24px"
    }
  }, [_c('BaseTextarea', {
    attrs: {
      "id": 'project-end-add-reason',
      "placeholder": _vm.$t('Reason')
    },
    model: {
      value: _vm.form.cancelledReason,
      callback: function ($$v) {
        _vm.$set(_vm.form, "cancelledReason", $$v);
      },
      expression: "form.cancelledReason"
    }
  })], 1)], 1) : _vm._e(), _vm.form.projectEndReason === 'Won' && _vm.isFeedbackEnabled ? _c('div', {
    staticStyle: {
      "padding-left": "32px",
      "margin-top": "24px"
    }
  }, [_c('div', [_vm._v(_vm._s(_vm.$t("SendOutFeedbackQuestionnaire") + ":"))]), _vm._l(this.contacts, function (contact) {
    return _c('div', {
      key: contact.email,
      staticStyle: {
        "display": "flex",
        "align-items": "center",
        "margin-top": "12px"
      }
    }, [_c('BaseToggle', {
      attrs: {
        "label": contact.name + ' (' + contact.email + ')'
      },
      model: {
        value: contact.sendFeedbackEmail,
        callback: function ($$v) {
          _vm.$set(contact, "sendFeedbackEmail", $$v);
        },
        expression: "contact.sendFeedbackEmail"
      }
    })], 1);
  })], 2) : _vm._e(), _c('div', {
    staticClass: "modal-footer"
  }, [_c('v-divider', {
    staticStyle: {
      "z-index": "111",
      "position": "fixed !important",
      "width": "560px"
    }
  }), _c('div', {
    staticClass: "buttons-row"
  }, [_c('div', {
    staticClass: "buttons-wrapper"
  }, [!_vm.isEdit ? _c('button', {
    staticClass: "cancel",
    on: {
      "click": function ($event) {
        return _vm.closeModal();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")]) : _vm._e(), _c('SubmitButton', {
    attrs: {
      "submitPlaceholder": true,
      "loading": _vm.isLoading
    },
    on: {
      "clicked": function ($event) {
        return _vm.saveEndState();
      }
    }
  })], 1)])], 1)], 1)], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }