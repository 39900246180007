<template>
  <div id="widget-overlay" class="widget-overlay">
    <div id="widget" v-click-outside="closeWidget">
      <span v-if="this.task.type !== 'open_house'">
        <v-row>
          <textarea
            :placeholder="$t('AddTitle')"
            class="entry-title"
            type="text"
            style="width: 100%"
            v-model="task.content"
          />
        </v-row>

        <v-row
          v-if="showErrors && !isFieldValid('content')"
          class="error-text"
          style="margin-top: 4px"
        >
          {{ $t("FormErrors." + errors.content) }}
        </v-row>

        <v-row style="margin-top: 4px">
          <div class="title-underline" />
        </v-row>

        <v-row class="type-selector" style="margin: 24px 0">
          <div
            @click="task.category = 'event'"
            v-bind:class="{
              'selected-type': task.category === 'event',
              'not-selected-type': task.category === 'task',
            }"
          >
            <p>{{ $t("Event") }}</p>
          </div>
          <div
            @click="task.category = 'task'"
            v-bind:class="{
              'selected-type': task.category === 'task',
              'not-selected-type': task.category === 'event',
            }"
            v-if="task.type !== 'resource'"
          >
            <p>{{ $t("Task") }}</p>
          </div>
        </v-row>
      </span>

      <v-row>
        <div class="field-icon-con">
          <date-pick @input="setDate">
            <template v-slot:default="{ toggle }">
              <img
                @click="toggle"
                src="../../assets/images/calendar.svg"
                class="field-icon"
              />
            </template>
          </date-pick>
        </div>

        <v-col class="col-4">
          <date-pick @input="setDate">
            <template v-slot:default="{ toggle }">
              <div
                @click="toggle"
                class="entry-field-label"
                style="cursor: pointer"
              >
                {{
                  task.category === "task" ? $t("Field.dueDate") : $t("Date")
                }}
              </div>
              <div @click="toggle">
                <span
                  v-if="task.dates.endDate"
                  style="cursor: pointer; font-size: 12px"
                >
                  {{ formatDate(task.dates.endDate) }}
                </span>
              </div>
            </template>
          </date-pick>
          <div class="d-inline-flex" style="margin-top: 8px">
            <span>
              <Checkbox
                @clicked="isFullDay = !isFullDay"
                :checked="isFullDay"
                :customStyle="'width: 16px; height: 16px; margin: 0'"
              />
            </span>

            <span class="entry-field-label" style="padding-left: 8px">
              {{ $t("FullDay") }}
            </span>
          </div>
        </v-col>
        <v-col v-if="!isFullDay" class="time-con">
          <span
            v-if="!task.dates.endTime"
            class="entry-field-label"
            style="cursor: pointer"
            @click="
              () => {
                task.dates.startTime = '09:00';
                task.dates.endTime = '10:00';
              }
            "
          >
            {{ $t("Add") + " " + $t("Time") }}
          </span>

          <span>
            <input
              v-show="task.dates.startTime && task.category !== 'task'"
              class="time-picker"
              type="time"
              step="600"
              id="start-time-dropdown"
              name="end-time-dropdown"
              style="min-width: 86px"
              v-model="task.dates.startTime"
            />
          </span>

          <span
            v-if="
              task.dates.startTime &&
              task.dates.endTime &&
              task.category !== 'task'
            "
          >
            <!--            —-->
            -
          </span>

          <span v-if="task.dates.endTime">
            <input
              style="min-width: 86px"
              class="time-picker"
              type="time"
              step="600"
              id="end-time-dropdown"
              name="end-time-dropdown"
              v-model="task.dates.endTime"
            />
          </span>
        </v-col>
      </v-row>

      <v-row
        @click="typeClick"
        v-click-outside="
          () => {
            showTypes = false;
          }
        "
        style="cursor: pointer"
      >
        <div class="field-icon-con">
          <div
            class="type-con"
            :style="
              'background: ' +
              eventTypes.find((e) => e.value === task.type).color
            "
          />
          <div
            :style="'z-index: 1; display: ' + (showTypes ? 'flex;' : 'none;')"
            class="dropdown-single scrollable"
          >
            <div
              v-for="type in eventTypes"
              :key="type.value"
              class="dropdown-item-wrapper"
              @click="selectType(type)"
            >
              <p :style="task.type === type.value ? 'font-weight: bold' : null">
                {{ type.name }}
              </p>
            </div>
          </div>
        </div>

        <v-col style="display: inline-grid">
          <span class="entry-field-label">
            {{ $t("Type") }}
          </span>
          <v-row class="entry-field-value">
            {{ eventTypes.find((e) => e.value === task.type).name }}
          </v-row>
        </v-col>
      </v-row>

      <TaskRowWithDropdown
        :items="projects"
        :display-key="'name'"
        :value-key="'id'"
        :label="'Project'"
        :icon="'projects.svg'"
        :selectedItem="task.linkedProject"
        :allow-delete="true"
        :fieldRequired="this.task.type === 'open_house'"
        :update="projectUpdater"
        @itemSelected="
          (item) => {
            task.linkedProject = item.id ? item : { id: null, name: null };
            projectUpdater++;
          }
        "
      />

      <v-row
        v-if="showErrors && !isFieldValid('project')"
        class="error-text"
        style="margin-top: 4px"
      >
        {{ $t("FormErrors." + errors.project) }}
      </v-row>

      <TaskRowWithDropdown
        :items="contacts"
        :display-key="'name'"
        :value-key="'id'"
        :label="'Contact'"
        :icon="'clients.svg'"
        :selectedItem="task.linkedContact"
        :allow-delete="true"
        :updater="contactUpdater"
        @itemSelected="
          (item) => {
            task.linkedContact = item.id ? item : { id: null, name: null };
            contactUpdater++;
          }
        "
      />

      <TaskRowWithDropdown
        :items="colleagues"
        :display-key="'name'"
        :value-key="'userId'"
        :label="'Owner'"
        :icon="'users.svg'"
        :selectedItem="task.assignedTo"
        @itemSelected="(item) => (task.assignedTo = item)"
      />

      <v-row class="justify-space-between" style="margin-top: 24px">
        <div class="flex-column">
          <div>
            {{ $t("Private") + " " + $t("Entry").toLowerCase() }}
          </div>
          <div>
            <img
              @click="task.isPrivate = !task.isPrivate"
              style="cursor: pointer"
              :src="
                task.isPrivate
                  ? require('../../assets/images/toggle-active.svg')
                  : require('../../assets/images/toggle-inactive.svg')
              "
              alt=""
            />
          </div>
        </div>
        <div>
          <v-row style="gap: 8px">
            <div>
              <button @click="closeWidget" class="cancel">
                {{ $t("Cancel") }}
              </button>
            </div>

            <div class="add-button" @click="createTask">
              <p>{{ $t("Save") }}</p>
            </div>
          </v-row>
        </div>
      </v-row>
    </div>
  </div>
</template>

<script>
import nanoid from "nanoid";
import DatePick from "vue-date-pick";
import "vue-date-pick/dist/vueDatePick.css";
import Checkbox from "@/components/common/Checkbox";
import TaskRowWithDropdown from "@/components/Tasks/TaskRowWithDropdown";

import { mapGetters } from "vuex";
import axios from "axios";
import moment from "moment";

//const graphConfig = require("../../configs/graphConfig");

export default {
  name: "CalendarWidget",
  components: {
    DatePick,
    Checkbox,
    TaskRowWithDropdown,
  },
  props: [
    "position",
    "clickedTask",
    "calendarData",
    "taskValue",
    "project",
    "property",
    "development",
    "contact",
    "calendarResource",
  ],
  data() {
    return {
      projectUpdater: 1,
      contactUpdater: 1,
      showErrors: false,
      showTime: false,
      contactDropdown: false,
      showTypes: false,
      isFullDay: false,
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
      validate: ["content", "project"],
      errors: this.getEmptyErrors(),
      eventTypes: [
        { name: this.$t("Generic"), value: "generic", color: "#939597" },
        { name: this.$t("Meeting"), value: "meeting", color: "#f81ca0" },
        {
          name: this.$t("NotaryMeeting"),
          value: "notary_meeting",
          color: "#4c8dd9",
        },
        {
          name: this.$t("ClientVisit"),
          value: "client_visit",
          color: "#ff5c01",
        },
        {
          name: this.$t("OpenHouse"),
          value: "open_house",
          color: "#7200E2",
        },
      ],
      projects: [{ name: this.$t("Select"), id: "" }],
      contacts: [{ name: this.$t("Select"), id: "" }],
      colleagues: [],
      task: {
        _id: nanoid(20),
        createdAt: Date.now(),
        content: "",
        assignedTo: {
          userId: null,
          name: null,
        },
        isCompleted: false,
        type: "generic",
        category: "event",
        dates: {
          startDate: null,
          startTime: null,
          endDate: null,
          endTime: null,
        },
        linkedProject: {
          id: null,
          name: null,
        },
        linkedContact: {
          id: null,
          name: null,
        },
        isPrivate: false,
      },
    };
  },
  async created() {
    await this.setup();
  },
  mounted() {
    this.setWidgetPosition();
  },
  beforeDestroy() {
    document.removeEventListener("click", this.closeWidget);
    window.removeEventListener("resize", this.onResize);
  },
  watch: {},
  computed: {
    ...mapGetters(["user", "users"]),
  },
  methods: {
    typeClick() {
      this.showTypes = !this.showTypes;
    },
    selectType(type) {
      this.task.type = type.value;
      if (this.task.type === "open_house") {
        this.task.category = "event";
      }
      this.showTypes = true;
    },
    async setup() {
      if (this.calendarResource) {
        this.task.type = "resource";
        this.eventTypes = [
          {
            name: this.$t("Resource"),
            value: "resource",
            color: "#16AFB9",
          },
        ];
      }

      if (this.clickedTask) {
        this.task = this.clickedTask;
        if (this.task.type === "resource") {
          this.eventTypes = [
            {
              name: this.$t("Resource"),
              value: "resource",
              color: "#16AFB9",
            },
          ];
        }
      } else {
        const dateNow = moment().format();
        this.task.dates.startDate = dateNow;
        this.task.dates.endDate = dateNow;
      }

      this.mapDates();

      if (this.taskValue === "open_house") {
        this.task.category = "event";
        this.task.type = "open_house";
      }

      if (this.project) {
        this.task.linkedProject = {
          id: this.project._id,
          name: this.project.name,
        };
      }

      if (this.property) {
        this.task.linkedProperty = {
          id: this.property._id,
          name: this.property.name,
        };
      }

      if (this.development) {
        this.task.linkedDevelopment = {
          id: this.development._id,
          name: this.development.name,
        };
      }

      if (this.contact) {
        this.task.linkedContact = this.contact;
      }

      this.setupColleagues();
      await Promise.all([this.setupProjects(), this.setupContacts()]);
    },
    async createTask() {
      if (!this.isFormValid()) {
        this.showErrors = true;
        return;
      }

      let task = this.task;

      if (this.calendarResource) {
        task.resourceId = this.calendarResource._id;
      }

      this.mapTask(task);

      if (this.user.microsoft) {
        const access = await this.getMsalAccessToken();
        if (access) {
          task.accessToken = access.accessToken;
        }
      }
      if (this.user.nylas) {
        const accessToken = await this.getNylasAccessToken();
        if (accessToken) {
          task.nylasAccessToken = accessToken;
        }
      }
      if (!this.clickedTask) {
        const response = await axios.post("/api/task", task);
        if (response.status !== 200) {
          this.toastError(this.$t("ErrorCreating"));
        } else {
          this.toastSuccess(
            this.$t("Task") + " " + this.$t("Created").toLowerCase()
          );
          this.$emit("addTask", response.data);
          this.$emit("update");
          this.closeWidget();
        }
      }
      if (this.clickedTask) {
        const updateResponse = await axios.patch(`/api/task/${task._id}`, task);
        if (updateResponse.data === 0) {
          this.toastError(this.$t("ErrorUpdating"));
        } else {
          if (task.content && task.content !== "") {
            this.toastSuccess(
              this.$t("Task") + " " + this.$t("Updated").toLowerCase()
            );
          } else {
            this.toastSuccess(
              this.$t("Task") + " " + this.$t("Deleted").toLowerCase()
            );
          }
          this.$emit("updateTask", task);
          this.$emit("update");
          this.closeWidget();
        }
      }
    },
    mapTask(task) {
      if (task.dates.startDate) {
        task.dates.startDate = moment(task.dates.startDate).format();
      }
      if (task.dates.endDate) {
        task.dates.endDate = moment(task.dates.endDate).format();
      }
      if (this.isFullDay) {
        task.dates.startTime = null;
        task.dates.endTime = null;
      }
      if (task.category === "task") {
        task.dates.startTime = null;
      }

      if (task.type === "open_house") {
        task.content = "OpenHouse";
        task.category = "event";
      }
    },
    mapDates() {
      if (this.clickedTask || !this.calendarData) return;

      this.task.dates.startDate = this.calendarData.date;
      this.task.dates.endDate = this.calendarData.date;
      if (this.calendarData.time) {
        let time = "";
        if (this.calendarData.hour.toString().length === 1) time += "0";
        time += this.calendarData.hour + ":00";
        this.task.dates.startTime = time;
        this.task.dates.endTime = this.addHour(this.task.dates.startTime, 1);
      } else if (moment().isSame(this.task.dates.endDate, "day")) {
        this.task.dates.startTime = moment().format("HH:mm");
        this.task.dates.endTime = this.addHour(this.task.dates.startTime, 1);
      }
    },
    isFormValid() {
      this.errors = this.getEmptyErrors();

      return this.validate
        .map((field) => {
          return this.isFieldValid(field);
        })
        .every((item) => item === true);
    },
    isFieldValid(fieldName) {
      switch (fieldName) {
        case "content":
          if (this.task.type !== "open_house" && !this.task.content) {
            this.errors.content = "FieldRequired";
            return false;
          }
          break;
        case "project":
          if (this.task.type === "open_house" && !this.task.linkedProject.id) {
            this.errors.project = "FieldRequired";
            return false;
          }
          break;
      }

      return true;
    },
    getEmptyErrors() {
      return {
        content: "",
        project: "",
      };
    },
    setDate(date) {
      this.task.dates.endDate = date;
      this.task.dates.startDate = date;
    },
    setupColleagues() {
      let myself = {
        userId: this.user._id,
        name: this.user.name,
      };

      this.colleagues.push(myself);
      this.users.forEach((user) => {
        if (user._id === this.user._id) {
          return;
        }
        let formattedUser = {
          userId: user._id,
          name: user.name,
        };
        this.colleagues.push(formattedUser);
      });
      this.colleagues.sort((a, b) => a.name.localeCompare(b.name));
      if (!this.clickedTask) {
        this.task.assignedTo.userId = this.user._id;
        this.task.assignedTo.name = this.user.name;
      }
      if (this.clickedTask) {
        this.task.assignedTo.userId = this.clickedTask.assignedTo.userId;
        this.task.assignedTo.name = this.clickedTask.assignedTo.name;
      }
    },
    async setupContacts() {
      const contactsResponse = await axios.get("/api/contacts/names");
      const contacts = contactsResponse.data;
      contacts.customers.forEach((contact) => {
        let formattedContact = {
          id: contact.customerId,
          name: contact.customerName,
        };
        this.contacts.push(formattedContact);
      });
      this.contacts.sort((a, b) => a.name.localeCompare(b.name));
    },
    async setupProjects() {
      const projectResponse = await axios.get("/api/projects/names");
      const projects = projectResponse.data;
      projects.deals.forEach((project) => {
        let formattedProject = {
          id: project._id,
          name: project.name,
          createdAt: project.createdAt,
        };
        this.projects.push(formattedProject);
      });
      this.projects.sort(function (a, b) {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
    },
    setWidgetPosition() {
      if (!this.position || this.position === {}) return;
      let top = this.position.top;
      let right = this.position.right;
      let realWidth = document.getElementById("widget-overlay").clientWidth;
      let realHeight = document.getElementById("widget-overlay").clientHeight;
      if (realWidth - right < 416) {
        right = right - (realWidth - right);
        right = right - (250 - (realWidth - right));
      }
      if (realHeight - top < 454) {
        top = top - (realHeight - top);
        top = top - (320 - (realHeight - top));
      }
      top = top - 160;

      document.getElementById("widget").style.setProperty("left", right + "px");
      document
        .getElementById("widget")
        .style.setProperty("position", "absolute");
      document.getElementById("widget").style.setProperty("top", top + "px");

      document
        .getElementById("widget-overlay")
        .addEventListener("click", (e) => {
          if (e.target === e.currentTarget) this.closeWidget();
        });

      window.addEventListener("resize", this.onResize);
      document
        .getElementById("start-time-dropdown")
        .addEventListener("input", this.startTimeChange);
    },
    /*  moveWidgetX(newWidth, oldWidth) {
      const difference = newWidth - oldWidth;
      const widget = document.getElementById("widget");
      let oldPosition = parseInt(widget.style.left, 10);
      const newPosition = oldPosition + difference;

      widget.style.setProperty("left", newPosition + "px");
    }, */
    /* moveWidgetY(newHeight, oldHeight) {

    },*/
    /*   setWidgetPosition() {
      let yPosition = this.position.top;
      let xPosition = this.position.right;
      let widget = document.getElementById("widget");
      let widgetWidth = widget.offsetWidth;

      widget.style.setProperty("top", yPosition + "px");
      widget.style.setProperty("left", xPosition + 50 + "px");

      if (this.windowWidth - xPosition < widgetWidth) {
        widget.style.setProperty("left", xPosition - widgetWidth - 50 + "px");
      }
    }, */
    closeWidget() {
      this.$emit("close");
    },
    onResize() {
      this.windowHeight = window.innerHeight;
      this.windowWidth = window.innerWidth;
    },
    addHour(time, hourToAdd) {
      return moment(time, "HH:mm").add(hourToAdd, "hours").format("HH:mm");
    },
    formatDate(date) {
      return moment(date).format("dddd, DD.MM");
    },
    startTimeChange() {
      this.task.dates.endTime = this.addHour(this.task.dates.startTime, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/global_variables";

.time-con {
  display: flex;
  align-items: center;
  margin-bottom: 14px;
  gap: 5px;
}

.time-picker {
  cursor: pointer;
  color: black;
}

.cancel {
  padding-left: 16px;
  padding-right: 16px;
  font-weight: normal;
}

.entry-title {
  font-size: 18px;
  outline: none;
  border: none;
  overflow: hidden;
  height: 24px;
  //line-height: 18px;
}

.dropdown-item-wrapper {
  cursor: pointer;
  padding: 12px;
  display: flex;
  flex-direction: row;
  gap: 8px;

  &:hover {
    background-color: #f4f5f7;
  }
}

.dropdown-single {
  position: absolute;
  flex-direction: column;
  background-color: white;
  overflow: auto;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  z-index: 4;

  p {
    color: black;
    text-decoration: none;
    display: block;
  }
}

.type-con {
  background: #939597;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  cursor: pointer;
}

.field-icon-con {
  width: 32px;
}

.field-icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.entry-field-label {
  color: $grey950 !important;
  font-size: 12px;
}

.birth-class {
  border: 1px solid #e6e8ec;
  border-radius: 8px;
  width: 100%;
  height: 56px;
  padding: 0 !important;

  &:focus {
    outline: none !important;
  }
}

.col {
  padding: 0;
}

.widget-overlay {
  z-index: 1;
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

#widget {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;

  position: fixed;

  padding: 24px;
  width: 416px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 1px 2px rgba(0, 0, 0, 0.03),
    0px 0px 1px rgba(0, 0, 0, 0.04);
}

.title-underline {
  height: 2px;
  width: 100%;
  background: #000;
}

.widget-title {
  outline: none;
  border: none;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #75787a;
  width: 100%;
  flex-grow: 1;
  margin-bottom: 16px;
}

.type-selector {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  //padding: 2px;
  width: 172px;
  height: 32px;
  margin-top: 24px;
  background: #fafafa;
  //border: 1px solid #e6e8ec;
  box-sizing: border-box;
  border-radius: 1000px;
}

.selected-type {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0;
  height: 100%;
  background: #ff5c01;
  border-radius: 1000px;
  flex-grow: 1;

  p {
    height: 16px;

    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #ffffff;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0 10px;
  }
}

.not-selected-type {
  display: flex;
  cursor: pointer;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0;
  height: 100%;
  border-radius: 1000px;
  flex-grow: 1;

  p {
    height: 16px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #000000;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0 10px;
  }
}

.dates-wrapper {
  display: flex;
  flex-direction: row;
  margin-top: 24px;
  gap: 16px;

  p {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;

    color: #000000;

    flex: none;
    order: 0;
    flex-grow: 0;
  }
}

.add-start-date {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  width: 176px;
  height: 32px;
  background: #fafafa;
  border: 2px dashed #e6e8ec;
  box-sizing: border-box;
  border-radius: 8px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 1;
  margin-top: 8px;
  cursor: pointer;
}

.end-date-wrapper {
  display: flex;
  flex-direction: row;
  margin-top: 8px;
  gap: 8px;
}

.date-button {
  cursor: pointer;
  /* button */

  /* Auto layout */

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0;
  height: 32px;
  border: 1px solid #e6e8ec;
  box-sizing: border-box;
  border-radius: 8px;
  width: 100%;
  min-width: 84px;

  p {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #75787a;
    flex: none;
    margin: 0 8px;
  }

  #end-time-dropdown {
  }
}

.all-day-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-top: 8px;
}

.dropdown-label {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #75787a;
  flex: none;
  margin: 0 8px;
}

.event-type-wrapper {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 70%;

  p {
    height: 16px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #000000;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0;
  }
}

.buttons-con {
  gap: 16px;
}

.add-button {
  cursor: pointer !important;
  padding-left: 12px;
  padding-right: 12px;
  max-width: fit-content;

  p {
    color: white;
  }
}
</style>
