<template>
  <div
    v-show="loaded"
    style="width: 720px; display: flex; flex-direction: column; gap: 16px"
  >
    <div
      v-for="(interest, index) in interests"
      :key="interest._id"
      class="interest"
    >
      <div class="interest-title-wrapper bottom-radius" :id="'title' + index">
        <div class="interest-title-row">
          <div class="interest-heading">
            <p
              class="interest-title-text"
              v-bind:class="{
                'interest-title-text': interest.statusCode === 1,
                'interest-title-text inactive-text': interest.statusCode !== 1,
              }"
            >
              {{ formatTransactionType(interest.transactionType) }}
            </p>

            <img
              v-bind:class="{
                'point-separator': interest.statusCode === 1,
                'point-separator inactive-svg': interest.statusCode !== 1,
              }"
              src="@/assets/images/•.svg"
              alt=""
            />
            <p
              v-bind:class="{
                'interest-title-text': interest.statusCode === 1,
                'interest-title-text inactive-text': interest.statusCode !== 1,
              }"
              v-if="interest.estateType"
            >
              {{ formatEstateType(interest.estateType) }}
            </p>
            <img
              v-if="interest.address.county"
              v-bind:class="{
                'point-separator': interest.statusCode === 1,
                'point-separator inactive-svg': interest.statusCode !== 1,
              }"
              src="@/assets/images/•.svg"
              alt=""
            />
            <p
              v-if="interest.address.county"
              v-bind:class="{
                'interest-address-text': interest.statusCode === 1,
                'interest-address-text inactive-text':
                  interest.statusCode !== 1,
              }"
            >
              {{ interestTitle(interest) }}
            </p>
          </div>
          <IconDropdown
            style="border-radius: 8px"
            :items="interestActions"
            :display-key="'name'"
            :value-key="'value'"
            :icon="'dot_menu.svg'"
            @itemSelected="selectInterestAction($event, interest)"
          />
        </div>
        <div class="interest-buttons-row">
          <div
            :key="countUpdater"
            @click="openExtras(interest._id, 'listings')"
            v-bind:class="{
              'interest-button': !openedExtras.listings.includes(interest._id),
              'interest-button button-active': openedExtras.listings.includes(
                interest._id
              ),
            }"
          >
            <img src="@/assets/images/objects.svg" alt="" />
            <p class="button-small">
              {{ listingsCount(interest) }}&nbsp;{{ $t("Listingst") }}
            </p>
          </div>
          <div
            @click="openExtras(interest._id, 'details')"
            v-bind:class="{
              'interest-button': !openedExtras.details.includes(interest._id),
              'interest-button button-active': openedExtras.details.includes(
                interest._id
              ),
            }"
          >
            <img src="@/assets/images/info.svg" alt="" />
            <p class="button-small">{{ $t("Details") }}</p>
          </div>
          <div
            @click="openExtras(interest._id, 'comments')"
            v-bind:class="{
              'interest-button': !openedExtras.comments.includes(interest._id),
              'interest-button button-active': openedExtras.comments.includes(
                interest._id
              ),
            }"
          >
            <img
              v-if="interest.comment"
              src="@/assets/images/chat-bubble.svg"
              alt=""
            />
            <img v-else src="@/assets/images/chat-no-bubble.svg" alt="" />

            <p class="button-small">{{ $t("Comment") }}</p>
          </div>
          <div
            @click="openExtras(interest._id, 'settings')"
            v-bind:class="{
              'interest-button': !openedExtras.settings.includes(interest._id),
              'interest-button button-active': openedExtras.settings.includes(
                interest._id
              ),
            }"
          >
            <img src="@/assets/images/admin.svg" alt="" />

            <p class="button-small">{{ $t("Settings") }}</p>
          </div>
        </div>
      </div>
      <InterestListings
        @openListingPreview="openListingPanel"
        @closeListingPreview="closeListingPanel"
        @funnelRemoved="$emit('funnelRemoved')"
        @refreshLeads="refresh"
        @autoSuggestionsCount="setAutoSuggestionsCount"
        :key="listingsUpdater"
        v-show="openedExtras.listings.includes(interest._id)"
        :project-id="projectId"
        :project-name="project.name"
        :interest="interest"
        :chosen-listing-preview-id="chosenListingPreviewId"
        :chosen-interest-id="chosenInterestId"
      />
      <InterestComment
        :comment="interest.comment"
        :project-id="projectId"
        :interest="interest"
        @commentUpdated="refresh"
        v-show="openedExtras.comments.includes(interest._id)"
      />
      <InterestDetails
        :interest="interest"
        v-show="openedExtras.details.includes(interest._id)"
        @editInterest="editFromDetails($event)"
      />
      <InterestSettings
        :project-id="projectId"
        :interest="interest"
        v-show="openedExtras.settings.includes(interest._id)"
        @changeInterestStatus="changeInterestStatus"
        @changeInterestWideSearch="changeInterestWideSearch"
        @toggled="toggleOutputs($event)"
      />
    </div>
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
import nanoid from "nanoid";
import IconDropdown from "@/components/common/IconDropdown";
import InterestListings from "@/components/Kliendiregister/InterestListings";
import InterestDetails from "@/components/Kliendiregister/InterestDetails";
import InterestComment from "@/components/Kliendiregister/InterestComment";
import InterestSettings from "@/components/Kliendiregister/InterestSettings";
import projectApi from "@/http/project";

export default {
  name: "ProjectInterests.vue",
  props: [
    "projectId",
    "listingPanelCloser",
    "interestsUpdater",
    "dealType",
    "listingKey",
  ],
  components: {
    IconDropdown,
    InterestListings,
    InterestComment,
    InterestDetails,
    InterestSettings,
  },
  data() {
    return {
      project: null,
      randomId: nanoid(10),
      loaded: false,
      interests: null,
      chosenListingId: null,
      listingPanelOpen: false,
      interestsOpen: false,
      listingsUpdater: 0,
      countUpdater: 0,
      chosenListingPreviewId: null,
      chosenInterestId: null,
      customer: null,
      autoSuggestionsCounts: {},
      interestActions: [
        {
          name: this.$t("Edit"),
          value: "edit",
          _id: nanoid(8),
        },
        {
          name: this.$t("Duplicate"),
          value: "duplicate",
          _id: nanoid(8),
        },
        {
          name: this.$t("Delete"),
          value: "delete",
          _id: nanoid(8),
        },
      ],
      openedExtras: {
        listings: [],
        details: [],
        comments: [],
        settings: [],
      },
    };
  },
  async created() {
    await this.setupInterests();
  },
  filters: {
    moment: function (date) {
      return moment(date).format("DD.MM.YYYY");
    },
  },
  watch: {
    listingPanelCloser() {
      this.closeListingPanel();
    },
    listingKey: function () {
      this.chosenListingPreviewId = null;
      this.chosenInterestId = null;
    },
    /*  async interestsUpdater() {
      await this.setupInterests();
    }, */
  },
  computed: {
    funnelSteps() {
      return [
        { name: this.$t("Offers"), value: "Offers" },
        { name: this.$t("Interested"), value: "Interested" },
        { name: this.$t("Viewed"), value: "Viewed" },
        {
          name: this.$t("Negotiation & Contract"),
          value: "Negotiation & Contract",
        },
        { name: this.$t("LostArchived"), value: "Lost / Archived" },
      ];
    },
  },
  methods: {
    async setupInterests() {
      this.project = (await projectApi.getProjectById(this.projectId));
      this.interests = this.project.interests;
      // await this.getInterestSuggestions(this.interests);
      this.$emit("gotInterests", this.interests);
      this.loaded = true;
    },
    async editFromDetails(interest) {
      await this.selectInterestAction({ value: "edit" }, interest);
    },
    async toggleOutputs(event) {
      let body = {
        interestId: event.interestId,
        marketplaces: event.marketplaces,
      };
      console.log(body);
      await projectApi.toggleInterestOutputs(this.projectId, body);
    },
    interestTitle(interest) {
      const county = interest.address.county.name;
      const cities = interest.address.cities;
      const cityParts = interest.address.cityParts;

      let title = county;
      if (cities) {
        cities.forEach((city) => {
          title = title + ", " + city.name;
        });
      }
      if (cityParts) {
        cityParts.forEach((citypart) => {
          title = title + ", " + citypart.name;
        });
      }
      return title;
    },
    async changeInterestStatus(status, interest) {
      let newStatus = status ? 1 : 0;
      await projectApi.changeInterestStatus(this.projectId, {
        interest,
        status: newStatus,
      });
      await this.refresh();
    },
    async changeInterestWideSearch(wideSearch, interest) {
      await projectApi.changeInterestWideSearch(this.projectId, {
        interest,
        wideSearch: wideSearch,
      });
      await this.refresh();
    },
    formatTransactionType(type) {
      switch (type) {
        case "rent":
          return this.$t("Rent");
        case "sale":
          return this.$t("Purchase");
        case "short_term_rent":
          return this.$t("ShortTermRent");
      }
    },
    formatEstateType(estateType) {
      if (estateType == "commercial") {
        return this.$t("Commercial");
      } else if (estateType == "apartment") {
        return this.$t("Apartment");
      } else if (estateType == "house") {
        return this.$t("House");
      } else if (estateType == "house_part") {
        return this.$t("HousePart");
      } else if (estateType == "land") {
        return this.$t("LandPlot");
      } else if (estateType == "cottage") {
        return this.$t("SummerHouse");
      } else if (estateType == "garage") {
        return this.$t("Garage");
      }
    },
    openListingPanel(listingId, interestId) {
      if (
        this.chosenListingPreviewId === listingId &&
        this.chosenInterestId === interestId
      ) {
        this.closeListingPanel(listingId, interestId);
      } else {
        this.closeListingPanel();
        this.chosenListingPreviewId = listingId;
        this.chosenInterestId = interestId;
        this.$emit(
          "openListingPanel",
          this.chosenListingPreviewId,
          this.chosenInterestId
        );
      }
    },
    closeListingPanel(listingId, interestId) {
      if (!listingId && !interestId) {
        listingId = this.chosenListingPreviewId;
        interestId = this.chosenInterestId;
      }
      if (listingId && interestId) {
        this.chosenListingPreviewId = null;
      }
      this.$emit("closeListingPanel", listingId, interestId);
    },
    async selectInterestAction(action, interest) {
      try {
        if (action.value === "edit") {
          this.$emit("editInterest", interest);
        }
        if (action.value === "duplicate") {
          let interestToDuplicate = interest;
          interestToDuplicate._id = null;
          await axios.post(
            `/api/project/interest/${this.projectId}`,
            interestToDuplicate
          );
          await this.refresh();
        }
        if (action.value === "delete") {
          await projectApi.deleteInterest(this.projectId, { interest });
          await this.refresh();
        }
      } catch (e) {
        this.toastError(this.$t("ErrorAdding"));
      }
    },
    async refresh() {
      await this.setupInterests();
      this.$emit("refreshLeads");
      this.listingsUpdater++;
    },
    listingsCount(interest) {
      if (!this.autoSuggestionsCounts[interest._id]) {
        return `${interest.funnels.length}/${interest.funnels.length}`;
      }
      return `${interest.funnels.length}/${
        this.autoSuggestionsCounts[interest._id] + interest.funnels.length
      }`;
    },
    setAutoSuggestionsCount(interest, count) {
      this.autoSuggestionsCounts[interest] = count;
      this.countUpdater++;
    },
    openExtras(interestId, extraType) {
      if (this.openedExtras[extraType].includes(interestId)) {
        this.openedExtras[extraType] = this.openedExtras[extraType].filter(
          (e) => e !== interestId
        );
      } else {
        Object.keys(this.openedExtras).forEach((extra) => {
          this.openedExtras[extra] = this.openedExtras[extra].filter(
            (e) => e !== interestId
          );
        });
        this.openedExtras[extraType].push(interestId);
      }
    },
    async findMatches(interests) {
      for (let interest of interests) {
        let response = await axios.get(
          `/api/interest/auto-suggest/${interest._id}`
        );
        let matches = response.data;
        interest.autoSuggestions = matches;
      }
    },
    moment: function () {
      return moment();
    },
  },
};
</script>

<style lang="scss" scoped>
.customer-interests {
  margin-bottom: 12px;
}
.interests-wrapper {
  padding-left: 0px;
  padding-right: 24px;
}
.interest-title-wrapper {
  width: 100%;
  padding: 16px;
}

.bottom-radius {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.no-bottom-radius {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.interest-title-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
}
.interests-row {
  /*  border-left: 1px solid #e6e8ec;
  border-right: 1px solid #e6e8ec; */
  /* border-bottom: 1px solid #e6e8ec; */
  padding-left: 12px;
  padding-right: 12px;
}
.all-funnels-wrapper {
  display: flex;
  flex-direction: column;
}
.suggestions-wrapper {
  background: #f4f5f7;
  width: 100%;
  padding: 12px 12px 0px 12px;
  border-left: 1px solid #e6e8ec;
  border-right: 1px solid #e6e8ec;
  border-bottom: 1px solid #e6e8ec;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.name-wrapper {
  min-width: 45%;
  max-width: 45%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.step-wrapper {
  text-align: right;
  width: 35%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 0px;
  img {
    cursor: pointer;
  }
}
.steps-menu-row {
  padding: 8px;
  cursor: pointer;
  &:hover {
    background-color: #e6e8ec;
  }
}
.date-wrapper {
  max-width: fit-content;
  margin-left: 24px;
  text-align: right;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  display: flex;
  flex: 1;
  gap: 12px;
  img {
    height: 100%;
    width: auto;
    cursor: pointer;
  }
}
.interest {
  border: 1px solid #e6e8ec;
  border-radius: 8px;
}
.fit {
  min-width: 100%;
  overflow: hidden;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.pointer {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
.point-separator {
  margin-left: 8px;
  margin-right: 8px;
}
.step-and-date-wrapper {
  display: flex;
  flex-direction: row;
  width: 30%;
  justify-content: end;
}
.interest-heading {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 50%;
  flex-wrap: nowrap;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.interest-address-text {
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.interest-title-text {
  white-space: nowrap;
  &:first-letter {
    text-transform: capitalize;
  }
}
.inactive-text {
  color: #75787a;
}
.interest-buttons-row {
  display: flex;
  flex-direction: row;
  margin-top: 16px;
  gap: 12px;
  flex-wrap: nowrap;
}
.interest-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  gap: 8px;
  width: 100%;
  height: 32px;
  background: #f4f5f7;
  border-radius: 8px;
  cursor: pointer;
  img {
    width: 16px;
    height: 16px;
  }
}
.button-active {
  p {
    color: white;
  }
  background: #ff5c01;
  img {
    filter: invert(100%) sepia(0%) saturate(7493%) hue-rotate(186deg)
      brightness(108%) contrast(95%);
  }
}
</style>
