var render = function render(){
  var _vm$contract, _vm$contract$client, _vm$contract$client2, _vm$contract$client3, _vm$contract$client4;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    style: _vm.OverlayStyle,
    attrs: {
      "id": "contract-overlay"
    }
  }, [_vm.sidewaysHeader ? _c('div', {
    staticClass: "contract-panel-header-sideways",
    style: _vm.sidewaysHeaderStyle,
    on: {
      "click": function ($event) {
        return _vm.closeSidepanels();
      }
    }
  }, [_c('h4', [_vm._v(_vm._s(!_vm.contract ? _vm.$t('AddNewContract') : _vm.$t('EditContract')))])]) : _c('div', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: function ($event) {
        return _vm.closeModal($event);
      },
      expression: "($event) => closeModal($event)"
    }],
    staticClass: "contract-panel"
  }, [_vm.contract && !_vm.editContract ? _c('div', {
    staticClass: "contract-panel-header"
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "column",
      "gap": "8px",
      "width": "100%"
    }
  }, [_c('h4', [_vm._v(" " + _vm._s((_vm$contract = _vm.contract) === null || _vm$contract === void 0 ? void 0 : _vm$contract.number))])]), _c('BaseButton', {
    attrs: {
      "icon-left": 'edit_16_16.svg',
      "label": _vm.$t('Edit'),
      "size": "small"
    },
    on: {
      "click": _vm.setupEditFields
    }
  })], 1) : _c('div', {
    staticClass: "contract-panel-header-add"
  }, [_c('h4', [_vm._v(_vm._s(!_vm.contract ? _vm.$t('AddNewContract') : _vm.$t('EditContract')))])]), _vm.contract && !_vm.editContract ? _c('div', {
    staticClass: "contract-panel-content"
  }, [_c('div', {
    staticClass: "contract-panel-content-info"
  }, [_c('h5', [_vm._v(_vm._s(_vm.$t("MainInfo")))]), _c('div', {
    staticClass: "contract-panel-content-info-details"
  }, [_c('div', {
    staticClass: "contract-panel-content-info-row"
  }, [_c('div', {
    staticClass: "content-normal",
    staticStyle: {
      "width": "50%",
      "color": "#75787A"
    }
  }, [_vm._v(_vm._s(_vm.$t('ContractType')))]), _c('div', {
    staticClass: "content-normal",
    staticStyle: {
      "width": "50%"
    }
  }, [_vm._v(" " + _vm._s(_vm.formatContractType(_vm.contract.type)) + " ")])]), _c('div', {
    staticClass: "contract-panel-content-info-row"
  }, [_c('div', {
    staticClass: "content-normal",
    staticStyle: {
      "width": "50%",
      "color": "#75787A"
    }
  }, [_vm._v(_vm._s(_vm.$t('ContractNumber')))]), _c('div', {
    staticClass: "content-normal",
    staticStyle: {
      "width": "50%"
    }
  }, [_vm._v(_vm._s(_vm.contract.number))])]), _c('div', {
    staticClass: "contract-panel-content-info-row"
  }, [_c('div', {
    staticClass: "content-normal",
    staticStyle: {
      "width": "50%",
      "color": "#75787A"
    }
  }, [_vm._v(_vm._s(_vm.$t('StartDate')))]), _c('div', {
    staticClass: "content-normal",
    staticStyle: {
      "width": "50%"
    }
  }, [_vm._v(_vm._s(_vm.formatDate(_vm.contract.startDate)))])]), _c('div', {
    staticClass: "contract-panel-content-info-row"
  }, [_c('div', {
    staticClass: "content-normal",
    staticStyle: {
      "width": "50%",
      "color": "#75787A"
    }
  }, [_vm._v(_vm._s(_vm.$t('EndDate')))]), _c('div', {
    staticClass: "content-normal",
    staticStyle: {
      "width": "50%"
    }
  }, [_vm._v(_vm._s(_vm.formatDate(_vm.contract.endDate)))])]), _c('div', {
    staticClass: "contract-panel-content-info-row"
  }, [_c('div', {
    staticClass: "content-normal",
    staticStyle: {
      "width": "50%",
      "color": "#75787A"
    }
  }, [_vm._v(_vm._s(_vm.$t('Client')))]), _c('div', {
    staticClass: "content-normal",
    staticStyle: {
      "width": "50%",
      "display": "flex",
      "flex-direction": "column"
    }
  }, [_c('div', [_vm._v(" " + _vm._s((_vm$contract$client = _vm.contract.client) === null || _vm$contract$client === void 0 ? void 0 : _vm$contract$client.name) + " ")]), _c('div', [_vm._v(" " + _vm._s((_vm$contract$client2 = _vm.contract.client) === null || _vm$contract$client2 === void 0 ? void 0 : _vm$contract$client2.registryCode) + " ")]), _c('div', [_vm._v(" " + _vm._s((_vm$contract$client3 = _vm.contract.client) === null || _vm$contract$client3 === void 0 ? void 0 : _vm$contract$client3.personalCode) + " ")]), _c('div', [_vm._v(" " + _vm._s((_vm$contract$client4 = _vm.contract.client) === null || _vm$contract$client4 === void 0 ? void 0 : _vm$contract$client4.address) + " ")])])]), _c('div', {
    staticClass: "contract-panel-content-info-row"
  }, [_c('div', {
    staticClass: "content-normal",
    staticStyle: {
      "width": "50%",
      "color": "#75787A"
    }
  }, [_vm._v(_vm._s(_vm.$t('Representative')))]), _c('div', {
    staticClass: "content-normal",
    staticStyle: {
      "width": "50%"
    }
  }, [_vm._v(_vm._s(this.$t(_vm.contract.representationBasis.charAt(0).toUpperCase() + _vm.contract.representationBasis.slice(1))))])]), _c('div', {
    staticClass: "contract-panel-content-info-row"
  }, [_c('div', {
    staticClass: "content-normal",
    staticStyle: {
      "width": "50%",
      "color": "#75787A"
    }
  }, [_vm._v(_vm._s(_vm.$t('ServiceFee')))]), _c('div', {
    staticClass: "content-normal",
    staticStyle: {
      "width": "50%"
    }
  }, [_vm._v(_vm._s(_vm.$nMapper.price(_vm.contract.brokerageFee, "€")))])]), _vm.contract.comment ? _c('div', {
    staticClass: "contract-panel-content-info-row"
  }, [_c('div', {
    staticClass: "content-normal",
    staticStyle: {
      "width": "50%",
      "color": "#75787A"
    }
  }, [_vm._v(_vm._s(_vm.$t('Comment')))]), _c('div', {
    staticClass: "content-normal",
    staticStyle: {
      "width": "50%"
    }
  }, [_vm._v(_vm._s(_vm.contract.comment))])]) : _vm._e()])])]) : _c('div', {
    staticClass: "contract-panel-content-add"
  }, [_c('div', {
    staticClass: "contract-panel-content-add-project"
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "column",
      "gap": "12px"
    }
  }, [_c('BaseDropdown', {
    attrs: {
      "placeholder": _vm.$t('ContractType'),
      "dropdown-offset-y": 60,
      "show-dropdown-arrow": true,
      "type": "dropdown",
      "items": _vm.contractTypes,
      "required": true,
      "error-message": _vm.ContractType.errorMessage,
      "error": _vm.ContractType.showError
    },
    model: {
      value: _vm.contractType,
      callback: function ($$v) {
        _vm.contractType = $$v;
      },
      expression: "contractType"
    }
  }), _c('BaseInput', {
    attrs: {
      "placeholder": _vm.$t('ContractNumber'),
      "type": "text",
      "required": true,
      "error-message": _vm.ContractNumber.errorMessage,
      "error": _vm.ContractNumber.showError
    },
    model: {
      value: _vm.contractNumber,
      callback: function ($$v) {
        _vm.contractNumber = $$v;
      },
      expression: "contractNumber"
    }
  }), _c('div', [_c('v-row', {
    staticClass: "edit-start-date-row"
  }, [_c('BaseDropdown', {
    attrs: {
      "icon-left": "calendar_20_20.svg",
      "id": 'lease-start-date',
      "placeholder": _vm.$t('StartDate'),
      "show-dropdown-arrow": true,
      "type": "date"
    },
    model: {
      value: _vm.contractStartDate,
      callback: function ($$v) {
        _vm.contractStartDate = $$v;
      },
      expression: "contractStartDate"
    }
  }), _c('BaseDropdown', {
    attrs: {
      "icon-left": "calendar_20_20.svg",
      "id": 'lease-end-date',
      "placeholder": _vm.$t('EndDate'),
      "show-dropdown-arrow": true,
      "type": "date"
    },
    model: {
      value: _vm.contractEndDate,
      callback: function ($$v) {
        _vm.contractEndDate = $$v;
      },
      expression: "contractEndDate"
    }
  })], 1)], 1)], 1)]), _c('div', {
    key: 'client-section-' + _vm.clientUpdater,
    staticClass: "contract-panel-content-add-client"
  }, [_c('h5', [_vm._v(_vm._s(_vm.$t('PartyToContract')))]), _c('div', [_c('v-radio-group', {
    attrs: {
      "row": ""
    },
    model: {
      value: _vm.contractPartyType,
      callback: function ($$v) {
        _vm.contractPartyType = $$v;
      },
      expression: "contractPartyType"
    }
  }, _vm._l(_vm.clientTypes, function (item) {
    return _c('v-radio', {
      key: 'radio' + item._id,
      staticClass: "content",
      attrs: {
        "label": item.title,
        "color": "orange darken-3",
        "value": item.value,
        "row": ""
      }
    });
  }), 1), _vm.contractPartyType === 'private' ? _c('div', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "column",
      "gap": "12px"
    }
  }, [_c('ClientListSearch', {
    attrs: {
      "update-component": _vm.clientSearchUpdater,
      "icon-left": 'search20.svg',
      "required": true,
      "error-message": _vm.ClientError.errorMessage,
      "error": _vm.ClientError.showError,
      "placeholder": _vm.$t('FullName')
    },
    on: {
      "itemChosen": _vm.selectClient,
      "addNew": _vm.openClientAddSidepanel
    },
    model: {
      value: _vm.contractClientSearch,
      callback: function ($$v) {
        _vm.contractClientSearch = $$v;
      },
      expression: "contractClientSearch"
    }
  }), _c('BaseInput', {
    attrs: {
      "placeholder": _vm.$t('Address'),
      "type": "text"
    },
    model: {
      value: _vm.contractClient.private.address,
      callback: function ($$v) {
        _vm.$set(_vm.contractClient.private, "address", $$v);
      },
      expression: "contractClient.private.address"
    }
  })], 1) : _c('div', {
    key: _vm.clientUpdater,
    staticStyle: {
      "display": "flex",
      "flex-direction": "column",
      "gap": "12px"
    }
  }, [_c('BaseInput', {
    attrs: {
      "placeholder": _vm.$t('FullName'),
      "required": true,
      "error-message": _vm.ClientError.errorMessage,
      "error": _vm.ClientError.showError,
      "type": "text"
    },
    model: {
      value: _vm.contractClient.company.name,
      callback: function ($$v) {
        _vm.$set(_vm.contractClient.company, "name", $$v);
      },
      expression: "contractClient.company.name"
    }
  }), _c('BaseInput', {
    attrs: {
      "placeholder": _vm.$t('RegistrationNumber'),
      "type": "text"
    },
    model: {
      value: _vm.contractClient.company.registryCode,
      callback: function ($$v) {
        _vm.$set(_vm.contractClient.company, "registryCode", $$v);
      },
      expression: "contractClient.company.registryCode"
    }
  }), _c('BaseInput', {
    attrs: {
      "placeholder": _vm.$t('Address'),
      "type": "text"
    },
    model: {
      value: _vm.contractClient.company.address,
      callback: function ($$v) {
        _vm.$set(_vm.contractClient.company, "address", $$v);
      },
      expression: "contractClient.company.address"
    }
  })], 1)], 1), _c('h5', [_vm._v(_vm._s(_vm.$t('RepresentsWhichBasis')))]), _c('v-radio-group', {
    attrs: {
      "row": ""
    },
    model: {
      value: _vm.contractRepresentationBasis,
      callback: function ($$v) {
        _vm.contractRepresentationBasis = $$v;
      },
      expression: "contractRepresentationBasis"
    }
  }, _vm._l(_vm.contractRepresentations, function (item) {
    return _c('v-radio', {
      key: 'radio' + item._id,
      staticClass: "content",
      attrs: {
        "label": item.title,
        "color": "orange darken-3",
        "value": item.value,
        "row": ""
      }
    });
  }), 1)], 1), _c('div', {
    staticClass: "contract-panel-content-add-project"
  }, [_c('h5', [_vm._v(_vm._s(_vm.$t("BrokerageFee")))]), _c('div', {
    staticClass: "contract-brokerage"
  }, [_c('v-radio-group', {
    attrs: {
      "row": ""
    },
    model: {
      value: _vm.contractBrokerageFeeType,
      callback: function ($$v) {
        _vm.contractBrokerageFeeType = $$v;
      },
      expression: "contractBrokerageFeeType"
    }
  }, _vm._l(_vm.brokerageTypes, function (item) {
    return _c('v-radio', {
      key: 'radio' + item._id,
      staticClass: "content",
      attrs: {
        "label": item.title,
        "color": "orange darken-3",
        "value": item.value,
        "row": ""
      }
    });
  }), 1), _vm.contractBrokerageFeeType === 'agreedPrice' ? _c('div', {
    staticClass: "edit-brokerage-fee-row",
    staticStyle: {
      "gap": "12px",
      "display": "flex",
      "flex-direction": "column"
    }
  }, [_c('v-row', {
    staticStyle: {
      "display": "flex",
      "width": "100%",
      "margin-bottom": "12px"
    }
  }, [_c('BaseInput', {
    attrs: {
      "id": 'brokerage-fee-field',
      "placeholder": _vm.$t('BrokerageFee'),
      "icon-right": "euroSign.svg",
      "type": "number",
      "format": 'price'
    },
    model: {
      value: _vm.contractBrokerageFee,
      callback: function ($$v) {
        _vm.contractBrokerageFee = $$v;
      },
      expression: "contractBrokerageFee"
    }
  })], 1)], 1) : _vm.contractBrokerageFeeType === 'percentOfPrice' ? _c('div', {
    staticStyle: {
      "gap": "12px",
      "display": "flex",
      "margin-bottom": "12px"
    }
  }, [_c('BaseInput', {
    attrs: {
      "type": "number",
      "format": 'price',
      "id": 'deal-amount-percent',
      "icon-right": "percentage.svg",
      "placeholder": _vm.$t('Percentage')
    },
    model: {
      value: _vm.contractBrokerFeePercentage,
      callback: function ($$v) {
        _vm.contractBrokerFeePercentage = $$v;
      },
      expression: "contractBrokerFeePercentage"
    }
  }), _c('BaseInput', {
    attrs: {
      "type": "number",
      "format": 'price',
      "id": 'deal-amount-min-fee',
      "placeholder": _vm.$t('MinimumFee'),
      "icon-right": "euroSign.svg"
    },
    model: {
      value: _vm.contractMinimumFee,
      callback: function ($$v) {
        _vm.contractMinimumFee = $$v;
      },
      expression: "contractMinimumFee"
    }
  })], 1) : _vm._e(), _c('div', {
    staticStyle: {
      "margin-bottom": "12px"
    }
  }, [_c('BaseInput', {
    attrs: {
      "type": "number",
      "format": 'price',
      "id": 'deal-amount-deal-value',
      "placeholder": _vm.$t('DealValue'),
      "icon-right": "euroSign.svg"
    },
    model: {
      value: _vm.contractDealValue,
      callback: function ($$v) {
        _vm.contractDealValue = $$v;
      },
      expression: "contractDealValue"
    }
  })], 1), _c('BaseDropdown', {
    attrs: {
      "icon-left": "calendar_20_20.svg",
      "id": 'transaction-date',
      "placeholder": _vm.$t('TransactionDate'),
      "show-dropdown-arrow": true,
      "type": "date"
    },
    model: {
      value: _vm.contractTransactionDate,
      callback: function ($$v) {
        _vm.contractTransactionDate = $$v;
      },
      expression: "contractTransactionDate"
    }
  })], 1)]), _c('div', {
    staticClass: "contract-panel-content-add-client"
  }, [_c('h5', [_vm._v(_vm._s(_vm.$t('Comment')))]), _c('div', {
    staticClass: "comment-text-wrapper"
  }, [_c('BaseTextarea', {
    attrs: {
      "id": 'contract-comment'
    },
    model: {
      value: _vm.contractComment,
      callback: function ($$v) {
        _vm.contractComment = $$v;
      },
      expression: "contractComment"
    }
  })], 1)])]), !_vm.contract || _vm.contract && _vm.editContract ? _c('div', {
    staticClass: "contract-panel-add-footer",
    style: _vm.contract && _vm.editContract ? 'justify-content: space-between;' : 'justify-content: flex-end;'
  }, [_vm.contract && _vm.editContract ? _c('div', {
    staticStyle: {
      "justify-self": "flex-start"
    }
  }, [_c('BaseButton', {
    attrs: {
      "size": "large",
      "label": _vm.$t('Delete'),
      "icons-style": 'width: 16px; height: 16px;',
      "label-color": '#ff1e24',
      "border-color": '#ff1e24',
      "icon-left": "red_trash_16_16.svg"
    },
    on: {
      "click": _vm.deleteContract
    }
  })], 1) : _vm._e(), _c('div', {
    staticStyle: {
      "display": "flex",
      "gap": "16px"
    }
  }, [_c('BaseButton', {
    attrs: {
      "size": "large",
      "label": _vm.$t('Cancel')
    },
    on: {
      "click": _vm.closeModal
    }
  }), _c('BaseButton', {
    attrs: {
      "icon-left": 'checkmark_white_16_16.svg',
      "size": "large",
      "primary": "true",
      "label": _vm.$t('Save')
    },
    on: {
      "click": _vm.saveButtonClicked
    }
  })], 1)]) : _vm._e()]), _vm.contractDeleteModalOpen ? _c('DeleteModal', {
    on: {
      "approved": _vm.deleteContractConfirmed,
      "canceled": function ($event) {
        _vm.contractDeleteModalOpen = false;
      }
    }
  }) : _vm._e(), _vm.clientAddSidepanelOpen ? _c('AddModal', {
    attrs: {
      "not-contact-view-route": true
    },
    on: {
      "newAdded": _vm.newClientAdded,
      "closed": _vm.closeClientAddSidepanel
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }