<template>
  <div id="aml-version-panel">
    <div class="header-row">
      <h4>{{ $t("VersionHistory") }}</h4>
      <img @click="closePanel" src="../../assets/images/close.svg" alt="" />
    </div>
    <div>
      <v-row class="version-title-row">
        <v-col
          ><p class="label12 gray-text">{{ $t("Date") }}</p></v-col
        >
        <v-col
          ><p class="label12 gray-text">{{ $t("User") }}</p></v-col
        >
      </v-row>
      <div class="versions-wrapper">
        <v-row
          @click="chooseVersion(index)"
          v-for="(aml, index) in aml"
          :key="index"
          class="version-row"
          v-bind:class="{
            'version-row': index !== chosenIndex,
            'version-row gray': index === chosenIndex,
          }"
        >
          <v-col>
            <p v-if="index === lastIndex" class="label10 green-text">
              {{ $t("CurrentVersion") }}
            </p>
            <p>{{ formatAmlEditDate(aml.createdAt) }}</p></v-col
          >
          <v-col style="align-self: flex-end">
            <p v-if="aml.creator">{{ aml.creator.name }}</p></v-col
          >
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "AmlVersionsPanel",
  props: ["aml"],
  data: () => ({
    chosenIndex: null,
    lastIndex: null,
  }),
  created() {
    this.chosenIndex = this.aml.length - 1;
    this.lastIndex = this.aml.length - 1;
    console.log("index!: ", this.chosenIndex);
  },
  methods: {
    formatAmlEditDate(e) {
      return moment(e).format("DD.MM.YYYY");
    },
    closePanel() {
      this.$emit("closed");
    },
    chooseVersion(index) {
      this.chosenIndex = index;
      this.$emit("versionChosen", index);
    },
  },
};
</script>

<style scoped lang="scss">
#aml-version-panel {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  background: white;
  width: 340px;
  max-width: 340px;
  min-width: 340px;
  padding: 24px;
}
.col {
  padding: 0;
}
.version-title-row {
  margin-top: 32px;
}
.header-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  img {
    cursor: pointer;
    width: 24px;
    height: 24px;
  }
}
.version-row {
  cursor: pointer;
  padding: 8px;
  margin: 0 !important;
  border-radius: 4px;
}
.versions-wrapper {
  display: flex;
  flex-direction: column-reverse;
  margin-top: 12px;
}
.green-text {
  color: #0aaf60;
}
</style>
