<template>
  <div class="content" id="overlay-project" v-if="deal" :key="dealUpdater">
    <div id="panel-wrapper-deal" class="panel-wrapper">
      <div v-if="!deal" class="overlay">
        <div class="overlay__inner">
          <div class="overlay__content"><span class="spinner"></span></div>
        </div>
      </div>
      <div>
        <v-row class="inputFields" style="margin-top: 0px; padding-top: 0px">
          <div class="stageSelector" style="padding: 16px; padding-left: 32px">
            <div style="display: flex; gap: 24px; width: 100%">
              <h5
                style="white-space: nowrap"
                v-if="deal.pipelineStep !== 'archived'"
              >
                {{ $t("ProjectStage") }}
              </h5>
              <div style="display: flex; width: 100%">
                <template v-if="deal.pipelineStep !== 'archived'">
                  <div
                    style="display: flex; flex-direction: row; cursor: pointer"
                    v-for="(pipelineStep, index) in pipelineSteps"
                    :key="pipelineStep.name"
                    @mouseover="
                      hoverOpen(
                        $event,
                        pipelineStep.name,
                        pipelineStep.isChosen,
                        index,
                        pipelineStep.finalSelection
                      )
                    "
                    @mouseleave="
                      hoverClose(
                        $event,
                        pipelineStep.name,
                        pipelineStep.isChosen,
                        index,
                        pipelineStep.finalSelection
                      )
                    "
                    @click="changePipelineStep(pipelineStep)"
                  >
                    <div style="position: relative">
                      <div
                        :id="index + 'd'"
                        v-bind:class="{
                          'pipeline-middle': !pipelineStep.isChosen,
                          'pipeline-middle chosen-step-container':
                            pipelineStep.isChosen,
                          'pipeline-left-black':
                            index <
                              pipelineSteps.indexOf(
                                pipelineSteps.find((step) => step.isChosen)
                              ) || deal.pipelineStep === 'archived',
                        }"
                        alt=""
                      >
                        <img
                          class="pipeline-left"
                          src="../../../assets/images/pipeline-left.svg"
                          alt=""
                        />
                        <p
                          v-bind:class="{
                            '': !pipelineStep.isChosen,
                            'chosen-step-nr': pipelineStep.isChosen,
                            'step-nr-white':
                              index <
                                pipelineSteps.indexOf(
                                  pipelineSteps.find((step) => step.isChosen)
                                ) || deal.pipelineStep === 'archived',
                          }"
                          :id="pipelineStep.name + 'nr'"
                          style="margin-left: 24px"
                        >
                          {{ index + 1 }}
                        </p>
                        <p
                          v-bind:class="{
                            'pipeline-name': !pipelineStep.isChosen,
                            'pipeline-name chosen-step-name':
                              pipelineStep.isChosen,
                            'step-nr-white':
                              index <
                              pipelineSteps.indexOf(
                                pipelineSteps.find((step) => step.isChosen)
                              ),
                          }"
                          :id="pipelineStep.name + 'p'"
                          class="pipeline-name"
                        >
                          {{ pipelineStep.name }}
                        </p>
                        <img
                          class="pipeline-right"
                          src="../../../assets/images/pipeline-right.svg"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </template>
                <div
                  v-if="
                    deal.pipelineStep === 'Done' &&
                    deal.pipelineStep !== 'archived'
                  "
                  style="padding-left: 24px; display: flex; align-items: center"
                >
                  <div
                    @click="openArchiveConfirmModal"
                    style="font-weight: bold"
                    class="link"
                  >
                    {{ $t("ArchiveVerb") }}
                  </div>
                </div>
                <div
                  v-if="deal.pipelineStep === 'archived'"
                  style="
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    align-items: center;
                  "
                >
                  <div style="display: flex; gap: 16px; align-items: center">
                    <div class="archive-label">{{ $t("Archived") }}</div>
                    <div
                      v-if="deal.doneDate"
                      style="display: flex; align-items: center"
                      :style="{
                        paddingLeft:
                          deal.pipelineStep === 'archived' ? '0' : '24px',
                      }"
                    >
                      {{ $t("ProjectCompleted") + " " + deal.doneDate }}
                    </div>
                  </div>

                  <div style="display: flex; gap: 8px; align-items: center">
                    <div
                      v-if="creatingDuplicateProject"
                      style="height: 32px; width: 32px; margin-left: 52px"
                    >
                      <div class="loader" style="height: 100%; width: 100%" />
                    </div>
                    <template v-else>
                      <BaseButton
                        :label="$t('DuplicateProjectAsNew')"
                        :icon-left="'duplicate_black_16_16.svg'"
                        @click="
                          createDuplicateProjectConfirmationModalOpen = true
                        "
                      />
                    </template>
                    <BaseButton
                      v-if="
                        user.access.accessLevel === 'company_admin' ||
                        user.access.accessLevel === 'group_admin'
                      "
                      :label="$t('Restore')"
                      :icon-left="'undo_black_16_16.svg'"
                      :icon-right="'chevron_24_24.svg'"
                      :items="pipelineSteps"
                      :display-key="'name'"
                      :display-value="'value'"
                      @onConfirm="
                        (step) =>
                          selectPipeline(
                            null,
                            step.name,
                            step.isChosen,
                            step.value,
                            false,
                            true
                          )
                      "
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="deal.pipelineStep !== 'archived'"
              style="min-width: fit-content; margin-right: 8px"
            >
              <div
                v-if="creatingDuplicateProject"
                style="height: 32px; width: 32px; margin-left: 52px"
              >
                <div class="loader" style="height: 100%; width: 100%" />
              </div>
              <template v-else>
                <BaseButton
                  :label="$t('DuplicateProjectAsNew')"
                  :icon-left="'duplicate_black_16_16.svg'"
                  @click="createDuplicateProjectConfirmationModalOpen = true"
                />
              </template>
            </div>
            <div
              v-if="
                deal.pipelineStep !== 'Done' && deal.pipelineStep !== 'archived'
              "
              style="height: 32px; white-space: nowrap"
              class="end-project-button"
              @click="openProjectEndPanel()"
            >
              <img
                src="../../../assets/images/projectIcon16x16_white.svg"
                alt=""
              />
              <p>{{ $t("CompleteProject") }}</p>
            </div>
            <v-menu
              :key="updateEndState"
              :close-on-content-click="true"
              :nudge-width="124"
              bottom
            >
              <!--              <template v-slot:activator="{ on, attrs }">-->
              <!--                <div-->
              <!--                    v-bind="attrs"-->
              <!--                    v-on="on"-->
              <!--                    v-if="(deal.pipelineStep === 'Done' || deal.pipelineStep === 'archived') && deal.endState"-->
              <!--                    style="height: 32px"-->
              <!--                    :class="{-->
              <!--                    'red-background':-->
              <!--                      deal.endState &&-->
              <!--                      (deal.endState[deal.endState.length - 1].projectEndReason === 'Lost' || deal.endState[deal.endState.length - 1].projectEndReason === 'Cancelled'),-->
              <!--                    'green-background':-->
              <!--                      deal.endState &&-->
              <!--                      deal.endState[deal.endState.length - 1].projectEndReason === 'Won'-->
              <!--                  }"-->
              <!--                    class="project-end-status"-->
              <!--                >-->
              <!--                  <p>{{ getEndStateStatus(deal.endState) }}</p>-->
              <!--                </div>-->
              <!--              </template>-->
              <div
                v-if="deal.endState"
                class="outputs-modal"
                style="
                  background-color: white;
                  border-radius: 8px;
                  padding: 16px;
                "
              >
                <div
                  v-if="
                    deal.endState &&
                    deal.endState[deal.endState.length - 1].projectEndReason ===
                      'Lost'
                  "
                >
                  <v-row
                    ><p class="content-medium">
                      {{
                        formatBackedOut(
                          deal.endState[deal.endState.length - 1]
                            .backedOutPerson
                        )
                      }}
                    </p></v-row
                  >
                  <v-row style="margin-top: 8px"
                    ><p class="content-normal">
                      {{ deal.endState[deal.endState.length - 1].lostReason }}
                    </p></v-row
                  >
                </div>
                <div
                  v-if="
                    deal.endState &&
                    deal.endState[deal.endState.length - 1].projectEndReason ===
                      'Cancelled'
                  "
                >
                  <v-row
                    ><p class="content-medium">
                      {{
                        formatBackedOut(
                          deal.endState[deal.endState.length - 1]
                            .cancelledPerson
                        )
                      }}
                    </p></v-row
                  >
                  <v-row style="margin-top: 8px"
                    ><p class="content-normal">
                      {{
                        deal.endState[deal.endState.length - 1].cancelledReason
                      }}
                    </p></v-row
                  >
                </div>
                <div
                  v-if="
                    deal.endState &&
                    deal.endState[deal.endState.length - 1].projectEndReason ===
                      'Won'
                  "
                >
                  <v-row
                    ><p class="content-normal">
                      {{
                        $t("DealValue") +
                        ": " +
                        deal.endState[deal.endState.length - 1].amount +
                        " " +
                        deal.endState[deal.endState.length - 1].currency
                      }}
                    </p></v-row
                  >
                  <v-row
                    v-if="
                      deal.endState[deal.endState.length - 1].dateOfTransaction
                    "
                    ><p class="content-normal">
                      {{
                        $t("DateOfTransaction") +
                        ": " +
                        formatDate(
                          deal.endState[deal.endState.length - 1]
                            .dateOfTransaction
                        )
                      }}
                    </p></v-row
                  >
                </div>
              </div>
            </v-menu>
          </div>
        </v-row>
        <v-row style="gap: 24px; margin-top: 0px; padding-bottom: 48px">
          <BaseDetailviewContainer :width="'440px'">
            <BaseAccordion
              @buttonPressed="openSidepanelAdd(deal)"
              id="'generalData'"
              :identifier="'generalData'"
              :parent-name="'project'"
              :show-bot-divider="true"
              :title="$t('ProjectInfo')"
              :button-name="$t('Edit')"
              :padding-top="'0px'"
              :button-icon="'edit_16_16.svg'"
              :show-button="deal.pipelineStep !== 'archived'"
            >
              <v-row
                v-if="
                  deal.dealType === 'Sale' &&
                  deal.aml[deal.aml.length - 1] &&
                  (deal.aml[deal.aml.length - 1].subjectOfSanctions == 1 ||
                    deal.aml[deal.aml.length - 1].politicallyExposedPerson == 1)
                "
                style="
                  background: #fff0f0;
                  border-radius: 4px;
                  display: flex;
                  justify-content: center;
                "
              >
                <p class="content-bold" style="color: #ff1e24">
                  {{ $t("WarningHighRiskClient") }}
                </p></v-row
              >
              <v-row
                v-if="deal.dealType"
                class="customer-field-row content-bold"
                ><p class="deal-item" style="width: 176px">
                  {{ $t("DealType1") }}
                </p>
                <p
                  id="deal-type"
                  v-bind:class="{
                    'content-bold content-small-green green-font':
                      deal.dealType == 'Sale',
                    'content-bold content-small-blue blue-font':
                      deal.dealType == 'Purchase',
                    'content-bold content-small-pink pink-font':
                      deal.dealType.replace(/ /g, '').toLowerCase() ==
                      'leaseout',
                    'content-bold content-small-orange orange-font':
                      deal.dealType.replace(/ /g, '').toLowerCase() ==
                      'aquirelease',
                    'content-bold content-small-yellow yellow-font':
                      deal.dealType == 'Referral',
                  }"
                >
                  {{ formatDealType(deal.dealType) }}
                </p></v-row
              >
              <v-row v-if="deal.probability" class="customer-field-row top24">
                <p class="deal-item" style="width: 176px">
                  {{ $t("Probability") }}
                </p>
                <p id="deal-probability" class="deal-item-name">
                  {{ deal.probability }} %
                </p>
              </v-row>

              <v-row
                v-if="!deal.aml.length && deal.dealType == 'Sale'"
                class="customer-field-row"
                ><p class="deal-item" style="width: 176px">
                  {{ $t("KnowYourCustomer") }}
                </p>
                <p
                  class="deal-item-name"
                  style="
                    text-decoration: underline;
                    font-weight: 600;
                    color: #ff1e24;
                    cursor: pointer;
                  "
                  @click="openAml()"
                >
                  {{ $t("NotFilled") }}
                </p></v-row
              >
              <v-row
                v-if="
                  deal.aml.length &&
                  deal.aml[deal.aml.length - 1].activeBusiness &&
                  deal.dealType == 'Sale'
                "
                class="customer-field-row"
                ><p class="deal-item" style="width: 176px">
                  {{ $t("KnowYourCustomer") }}
                </p>
                <p
                  class="deal-item-name"
                  style="
                    text-decoration: underline;
                    font-weight: 600;
                    color: #0aaf60;
                    cursor: pointer;
                  "
                  @click="openAmlDetailPanel()"
                >
                  {{ $t("Done") }}
                </p></v-row
              >
              <v-row v-if="deal.service" class="customer-field-row"
                ><p class="deal-item" style="width: 176px">
                  {{ $t("Service") }}
                </p>
                <p class="deal-item-name">{{ deal.service }}</p></v-row
              >
              <v-row
                v-if="deal.contactPerson.contactName != null"
                class="customer-field-row"
                style="flex-wrap: nowrap; gap: 24px"
                ><p class="deal-item" style="min-width: 176px">
                  {{ $t("Contact") }}
                </p>
                <p
                  v-if="deletedUser"
                  class="deal-item-name link"
                  style="text-align: initial; word-break: break-word"
                  @click="openCustomerDetailpanel(deal.contactPerson)"
                >
                  {{ deal.contactPerson.contactName }} {{ deletedUser }}
                </p>
                <p
                  v-if="!deletedUser"
                  class="deal-item-name link"
                  style="text-align: initial; word-break: break-word"
                  @click="openCustomerDetailpanel(deal.contactPerson)"
                >
                  {{ deal.contactPerson.contactName }}
                </p></v-row
              >

              <v-row v-if="deal.client" style="flex-wrap: nowrap; gap: 24px"
                ><p class="deal-item" style="min-width: 176px">
                  {{ $t("Client") }}
                </p>
                <p
                  v-if="deal.client.company_name"
                  class="deal-item-name link"
                  @click="openOrganization(deal.client)"
                  style="text-align: initial; word-break: break-word"
                >
                  {{ deal.client.company_name }}
                </p>
                <p
                  v-if="!deal.client.company_name && deletedClient"
                  class="deal-item-name link"
                  style="text-align: initial; word-break: break-word"
                  @click="openCustomerDetailpanel(deal.client)"
                >
                  {{ deal.client.contactName }} {{ deletedClient }}
                </p>
                <p
                  v-if="!deal.client.company_name && !deletedClient"
                  class="deal-item-name link"
                  style="text-align: initial; word-break: break-word"
                  @click="openCustomerDetailpanel(deal.client)"
                >
                  {{ deal.client.contactName }}
                </p>
              </v-row>
              <v-row v-if="deal.estimatedCloseDate" class="customer-field-row"
                ><p class="deal-item" style="width: 176px">
                  {{ $t("ExpectedClosingDate") }}
                </p>
                <p id="deal-closing-date" class="deal-item-name">
                  {{ formatDate(deal.estimatedCloseDate) }}
                </p></v-row
              >
              <v-row
                v-if="deal.amount && deal.priceHistoryLog"
                class="customer-field-row price-info-dropdown"
                ><p id="deal-value" class="deal-item" style="width: 176px">
                  {{ $t("ProjectValue") }}
                </p>
                <p
                  id="deal-value-eur"
                  class="deal-item-name"
                  @click="openPriceLogDropdown"
                  v-bind:class="{
                    'price-history-log':
                      deal.priceHistoryLog && deal.priceHistoryLog.length,
                  }"
                >
                  {{ $nMapper.price(deal.amount, "€") }}
                </p>
                <div
                  v-if="
                    deal.priceHistoryLog &&
                    deal.priceHistoryLog.length &&
                    dropdownIsOpen
                  "
                  class="price-info-container customer-field-row price-info-container-hover"
                >
                  <div
                    v-if="
                    deal.priceHistoryLog &&
                    deal.priceHistoryLog.length &&
                    dropdownIsOpen
                  "
                    v-click-outside="closePriceLogDropdown"
                    class="price-history-info-dropdown price-info-container customer-field-row price-info-container-hover"
                  >
                    <div
                      class="price-info"
                      v-for="(info, index) in deal.priceHistoryLog.slice(-20)"
                      :key="'price-info-' + index"
                    >
                      <div class="content-normal">
                        {{ formatDate(info.date) }}
                      </div>
                      <div
                        style="width: 100%; text-align: right"
                        class="content-semibold"
                      >
                        {{ $nMapper.price(info.to, "€") }}
                      </div>
                    </div>
                  </div>
                </div>
              </v-row>

              <!--  <v-row v-if="deal.amount" class="customer-field-row"
                ><p id="deal-value" class="deal-item" style="width: 176px">
                  {{ $t("ProjectValue") }}
                </p>
                <p
                  v-if="deal.currency == 'EUR'"
                  id="deal-value-eur"
                  class="deal-item-name"
                >
                  {{ deal.amount }} €
                </p>
                <p
                  v-if="deal.currency == 'USD'"
                  id="deal-value-usd"
                  class="deal-item-name"
                >
                  {{ deal.amount }} $
                </p>
              </v-row> -->
              <v-row
                v-if="
                  deal.brokerFeePercentage ||
                  deal.brokerageFee ||
                  deal.minimumFee
                "
                class="customer-field-row"
                ><p class="deal-item" style="width: 176px">
                  {{ $t("BrokerageFee") }}
                </p>
                <p class="deal-item-name">
                  {{ brokerFee() }}
                </p>
              </v-row>
              <div
                v-if="deal.description"
                class="separator"
                style="margin-top: 24px"
              ></div>
              <v-row v-if="deal.description">
                <div class="description" style="margin-top: 0px">
                  <v-row
                    class="description-item"
                    style="margin-top: 0px; padding-top: 0px"
                  >
                    <textarea
                      style="auto-scale-text; padding-top:24px"
                      outlined
                      rows="3"
                      id="text-area"
                      v-if="!showFullText"
                      :disabled="!editMode"
                      class="description-item1"
                      v-model="deal.description"
                    >
                    </textarea>
                  </v-row>
                  <button
                    :hidden="showFullText"
                    style="
                      padding-bottom: 12px;
                      text-decoration-line: underline;
                    "
                    for="expanded"
                    @click="showText()"
                    id="textButton"
                  >
                    <p
                      v-if="
                        deal.description &&
                        (deal.description.length > 274 || this.rowCount > 3)
                      "
                      style="font-size: 12px; line-height: 16px"
                    >
                      {{ $t("ShowMore") }}
                    </p>
                  </button>
                  <v-row
                    style="margin-top: 0px; padding-top: 12px; width: 410px"
                  >
                    <v-textarea
                      hide-details
                      v-if="showFullText"
                      name="input-7-1"
                      auto-grow
                      disabled
                      class="description-textarea"
                      v-model="deal.description"
                    ></v-textarea>
                  </v-row>
                  <button
                    :hidden="!showFullText"
                    style="
                      padding-left: 0px;
                      padding-bottom: 24px;
                      text-decoration-line: underline;
                    "
                    for="expanded"
                    @click="showTextLess()"
                    id="textButton"
                  >
                    <p style="font-size: 12px; line-height: 16px">
                      {{ $t("ShowLess") }}
                    </p>
                  </button>
                </div>
              </v-row>
            </BaseAccordion>
            <BaseAccordion
              @buttonPressed="openTaskAddWidgetCounter++"
              :button-opens-accordion="true"
              :title="$t('TasksAndEvents')"
              :identifier="'tasks'"
              :counter-bg-color-red="true"
              :parent-name="'project'"
              :show-bot-divider="true"
              :counter="tasksCounter"
              :button-icon="'plus.svg'"
              :button-name="$t('Add')"
            >
              <BaseTasksAndEvents
                @tasks="tasksUpdated"
                :open-new-task-add-widget="openTaskAddWidgetCounter"
                :labels-and-order="{
                  overDueTasks: {
                    label: false,
                    order: 2,
                  },
                  noDueDateTasks: {
                    label: false,
                    order: 1,
                  },
                  todayTasks: {
                    label: false,
                    order: 3,
                  },
                  upcomingTasks: {
                    label: false,
                    order: 4,
                  },
                }"
                :view="{
                  name: 'project',
                  itemId: $route.params.id,
                  itemName: deal.name,
                }"
                :show-toggle-button="true"
              />
            </BaseAccordion>
            <BaseAccordion
              :title="$t('Notes')"
              :is-add-button="true"
              :separator-line="true"
              :identifier="'notes'"
              :parent-name="'project'"
              :button-name="$t('Add')"
              :button-icon="'plus.svg'"
              :show-bot-divider="true"
              :button-opens-accordion="true"
              :counter="totalNotesCounter"
              @buttonPressed="addNewNoteCounter++"
            >
              <BaseNotes
                @notesCounter="
                  (value) => {
                    this.totalNotesCounter = value;
                  }
                "
                :add-new-note-counter="addNewNoteCounter"
                :view="{
                  name: 'project',
                  itemId: $route.params.id,
                  itemName: deal.name,
                }"
              />
            </BaseAccordion>
            <BaseAccordion
              :show-bot-divider="true"
              :parent-name="'project'"
              :identifier="'relatedPersons'"
              :button-name="$t('Add')"
              :button-icon="'plus.svg'"
              :counter="countRelatedPersons()"
              @buttonPressed="openInlineAdd()"
              :title="$t('Subblock.RelatedPersons.COMP')"
              :button-opens-accordion="true"
            >
              <v-col style="max-width: 456px; padding: 0">
                <v-row v-if="addNewRelatedPerson">
                  <ClientListSearch
                    :placeholder="$t('UserSearch')"
                    :icon-left="'clients.svg'"
                    @addNew="addNewContact"
                    @itemChosen="addContactToRelatedPeople"
                  >
                  </ClientListSearch>
                </v-row>
                <div
                  v-for="customer in dealRelatedCustomers"
                  :key="customer.customerId"
                >
                  <v-row class="related-person-row">
                    <div
                      class="relationIcon"
                      @click="openCustomerDetailpanel(customer)"
                    >
                      <span class="font-20-medium">
                        {{ customer.customerName[0].toUpperCase() }}
                      </span>
                    </div>
                    <v-row style="justify-content: space-between">
                      <div class="related-person-name">
                        <span
                          class="content-medium related-person-customer-name"
                          @click="openCustomerDetailpanel(customer)"
                        >
                          {{ customer.customerName }}
                        </span>
                        <span v-if="customer.organization"> – </span>
                        <span v-if="customer.organization">
                          {{ customer.organization }}
                        </span>
                        <span v-if="customer.isPartner">( Partner )</span>
                      </div>
                      <div style="display: flex">
                        <p
                          id="delete-modal-button"
                          class="content-medium"
                          style="
                            display: flex;
                            align-items: center;
                            cursor: pointer;
                          "
                          @click="openDeleteModal(customer.customerId)"
                        >
                          <img src="@/assets/images/close_grey.svg" alt="" />
                        </p>
                      </div>
                    </v-row>
                  </v-row>
                </div>
                <v-row
                  class="content"
                  style="
                    margin-top: 0px;
                    background-color: white;
                    padding-left: 0px;
                    padding-top: 0px;
                    padding-bottom: 0px;
                    padding-right: 0px;
                  "
                  v-if="
                    dealRelatedCustomers &&
                    dealRelatedCustomers.length === 0 &&
                    !addNewRelatedPerson
                  "
                >
                  <p style="color: #75787a">
                    {{ $t("NoRelatedPersonsAdded") }}
                  </p></v-row
                >
              </v-col>
            </BaseAccordion>
            <BaseAccordion
              @buttonPressed="openContractSidepanel"
              :button-opens-accordion="true"
              :title="$t('Contracts')"
              :identifier="'contracts'"
              :parent-name="'project'"
              :show-bot-divider="true"
              :counter="contractsCounter"
              :button-icon="'plus.svg'"
              :button-name="$t('Add')"
            >
              <ContractsList
                @contractsCounter="setContractsCounter"
                :project-id="deal._id"
                :deal-contracts="deal.contracts"
                :updater="contractListUpdater"
                @openContract="openSelectedContract"
              />
            </BaseAccordion>
            <BaseAccordion
              :title="$t('Invoices')"
              :button-name="$t('Add')"
              :button-icon="'plus.svg'"
              :show-bot-divider="true"
              :parent-name="'project'"
              :identifier="'invoices'"
              :counter="invoicesCounter"
              @buttonPressed="openInvoiceAddPanel(deal)"
              :button-opens-accordion="true"
            >
              <InvoiceWidget
                :project="deal"
                @reloadInvoices="getInvoices"
                :invoices="invoices"
                :loading="loadingInvoices"
              />
            </BaseAccordion>
            <BaseAccordion
              :title="$t('MarketingExpenses')"
              :button-name="$t('Add')"
              :button-icon="'plus.svg'"
              :show-bot-divider="true"
              :parent-name="'project'"
              :identifier="'marketingExpenses'"
              :counter="marketingExpensesCounter"
              :button-dropdown-items="marketingExpenseOptions"
              button-display-key="name"
              button-value-key="value"
              @dropdownItemSelected="openMarketingExpenseAdd"
              :button-opens-accordion="true"
            >
              <MarketingExpenses
                @marketingExpenseCounter="setMarketingExpenseCounter"
                @resetType="addMarketingExpense = null"
                :add-button-outside="addMarketingExpense"
                :project-id="deal._id"
              />
              <v-row
                class="content"
                style="
                  margin-top: 0px;
                  background-color: white;
                  padding-left: 0px;
                  padding-top: 0px;
                  padding-right: 0px;
                "
                v-if="marketingExpensesCounter === 0"
              >
                <p style="color: #75787a">
                  {{ $t("NoMarketingExpenseAdded") }}
                </p></v-row
              >
            </BaseAccordion>
            <BaseAccordion
              @buttonPressed="fileUpload()"
              :identifier="'files'"
              :parent-name="'project'"
              :title="$t('Files')"
              :counter="deal.files.length"
              :button-name="$t('Add')"
              :button-icon="'plus.svg'"
              :button-opens-accordion="true"
            >
              <FileUpload
                @success="getDeal"
                :files.sync="deal.files"
                :targetId="deal._id"
                :target-object="'project'"
                :showButtons="true"
                :id="'project-general-upload'"
                :aml-panel="1"
              />
              <v-row
                class="content"
                style="
                  margin-top: 0px;
                  background-color: white;
                  padding-left: 0px;
                  padding-top: 0px;
                  padding-right: 0px;
                "
                v-if="deal.files.length === 0"
              >
                <p style="color: #75787a">
                  {{ $t("NoFilesAdded") }}
                </p></v-row
              >
            </BaseAccordion>
          </BaseDetailviewContainer>
          <v-col
            v-if="
              deal.dealType == 'Sale' ||
              deal.dealType.replace(/ /g, '').toLowerCase() == 'leaseout' ||
              deal.dealType == 'Referral' ||
              deal.dealType == 'AquireLease' ||
              deal.dealType == 'Purchase'
            "
            class="right-col"
            style="height: fit-content"
          >
            <div class="project-second-header">
              <div class="project-actions-wrapper">
                <template v-if="deal.pipelineStep !== 'archived'">
                  <ButtonDropdown
                    :items="documentTemplates"
                    :display-key="'name'"
                    :value-key="'value'"
                    :icon="'document.svg'"
                    :button-label="$t('GenerateDocument')"
                    :color-reverse="true"
                    custom-style-button="padding: 8px 12px"
                    @itemSelected="openDocument"
                  />
                </template>
                <div
                  v-if="
                    deal.dealType == 'Sale' ||
                    deal.dealType.replace(/ /g, '').toLowerCase() == 'leaseout'
                  "
                  class="client-report-preview-button"
                  @click="openClientReport"
                >
                  <img src="../../../assets/images/report.svg" alt="" />
                  <p>{{ $t("ClientReport") }}</p>
                </div>
                <div
                  class="client-report-preview-button"
                  @click="openTransactionDetails"
                >
                  <img
                    src="../../../assets/images/transactions.svg"
                    class="transactions-icon img16"
                    style="filter: invert(1)"
                    alt=""
                  />
                  <p>{{ $t("TransactionDetails") }}</p>
                </div>
              </div>
            </div>
            <div>
              <div
                class="separator"
                style="border-top: 8px solid #f4f5f7"
              ></div>
              <v-col
                v-if="
                  deal.dealType == 'Sale' ||
                  deal.dealType.replace(/ /g, '').toLowerCase() == 'leaseout'
                "
                style="padding: 0"
                class="objects-wrapper"
                v-bind:class="{
                  'align-center': deal.objects.length < 1,
                  'align-start': deal.objects.length,
                }"
              >
                <template
                  v-if="
                    (deal.pipelineStep === 'Done' ||
                      deal.pipelineStep === 'archived') &&
                    deal.endState &&
                    deal.endState.length > 0
                  "
                >
                  <CompletedContainer
                    :key="'complete-container-key-' + objectRefresher"
                    @updateDealEndState="getDeal"
                    :deal="deal"
                  />
                  <div
                    class="separator"
                    style="border-top: 8px solid #f4f5f7"
                  ></div>
                </template>
                <template>
                  <div style="width: 100%" v-show="!noListings">
                    <ProjectObject
                      v-for="object in deal.objects"
                      :key="object._id"
                      :object="object"
                      :deal-id="deal._id"
                      :project-type="deal.dealType"
                      :refresh-key="objectRefresher"
                      :listing-refresh-key="listingNameRefresher"
                      :openHouse="openHouse"
                      :is-project-inactive="isProjectInactive"
                      :deal-pipeline-step="deal.pipelineStep"
                      @refresh="getDeal"
                      @noListings="showActiveListingsStatus(true)"
                      @listingsExist="showActiveListingsStatus(false)"
                      @noArchivedListings="showArchivedListingExist(true)"
                      @archivedListingsExist="showArchivedListingExist(false)"
                    />
                  </div>

                  <v-col
                    v-show="noListings && !archivedListingsEmpty"
                    style="
                      background-color: white;
                      width: 100%;
                      display: flex;
                      align-content: flex-end;
                      align-items: center;
                      justify-content: center;
                      border-radius: 8px;
                    "
                  >
                    <div
                      class="add-object-to-start"
                      style="
                        display: flex;
                        justify-content: flex-end;
                        flex-direction: column;
                      "
                    >
                      <v-row>{{ $t("NoListingsInProject") }}</v-row>
                      <v-row
                        v-if="deal.statusCode !== 0"
                        style="display: flex; justify-content: center"
                      >
                        <button
                          name="add-contact-button"
                          @click="addListing()"
                          class="outlined-small-button"
                          style="
                            background-color: black !important;
                            margin-top: 16px;
                            margin-left: 24px;
                            height: 32px;
                            align-items: center;
                            border: 0;
                          "
                        >
                          <img
                            style="
                              filter: invert(100%) sepia(0%) saturate(0%)
                                hue-rotate(317deg) brightness(103%)
                                contrast(102%);
                            "
                            src="@/assets/images/plusSign.svg"
                            alt=""
                          />
                          <p
                            style="
                              filter: invert(100%) sepia(0%) saturate(0%)
                                hue-rotate(317deg) brightness(103%)
                                contrast(102%);
                            "
                          >
                            {{ $t("AddListing") }}
                          </p>
                        </button>
                      </v-row>
                    </div>
                  </v-col>
                  <v-col
                    v-show="noListings && archivedListingsEmpty"
                    style="
                      background-color: white;
                      width: 100%;
                      display: flex;
                      align-content: flex-end;
                      align-items: center;
                      justify-content: center;
                      border-radius: 8px;
                      margin-bottom: 0px;
                    "
                  >
                    <div
                      class="add-object-to-start"
                      style="
                        display: flex;
                        justify-content: flex-end;
                        flex-direction: column;
                      "
                    >
                      <v-row>{{ $t("NoListingsArchived") }}</v-row>
                    </div>
                  </v-col>
                </template>
              </v-col>
              <v-col v-else>
                <template>
                  <v-row
                    style="
                      display: flex;
                      justify-content: space-between;
                      padding: 32px 0px;
                      align-items: center;
                    "
                  >
                    <div style="padding-left: 32px">
                      <h5>{{ $t("Interests") }}</h5>
                    </div>
                    <div>
                      <div
                        style="
                          display: flex;
                          align-items: center;
                          padding-right: 32px;
                        "
                      >
                        <button
                          @click="openInterests()"
                          class="outlined-small-button"
                        >
                          <img
                            src="../../../assets/images/plusSign.svg"
                            alt=""
                          />
                          <p>
                            {{ $t("AddInterest") }}
                          </p>
                        </button>
                      </div>
                    </div>
                  </v-row>
                  <v-col
                    class="objects-wrapper"
                    style="
                      background-color: white;
                      padding-left: 24px;
                      padding-right: 32px;
                    "
                    v-bind:class="{
                      'align-center': interests.length < 1,
                      'align-start': interests.length,
                    }"
                  >
                    <div
                      v-if="interestsLoaded && interests.length < 1"
                      style="padding: 50px"
                      id="add-object-start-text2"
                      class="padding-wrapper"
                    >
                      <p class="add-object-to-start">
                        {{ $t("AddAnInterestToStart") }}
                      </p>
                    </div>
                    <ProjectInterests
                      @gotInterests="setInterests"
                      :key="interestsUpdater"
                      :project-id="deal._id"
                      @openListingPanel="openListingPanel"
                      @funnelRemoved="$emit('funnelRemoved')"
                      @closeListingPanel="closeListingPanel"
                      @refreshLeads="$emit('refreshLeads')"
                      :deal-type="deal.dealType"
                      @editInterest="openInterests"
                      :listing-key="listingKey"
                    />
                  </v-col>
                </template>
              </v-col>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
    <InterestPanel
      :existing-interest="interestToEdit"
      :project-id="deal._id"
      v-if="interestsOpen"
      :deal-type="deal.dealType"
      :project-interests="true"
      @closed="closeInterests()"
    />
    <SidepanelAdd
      v-if="editModalOpen"
      :dealData="deal"
      :contact-person="deal.contactPerson"
      :isEdit="true"
      @closed="closeSidepanelAdd()"
    />
    <NotarySidepanel
      :fromAnotherPanel="false"
      :deal="this.deal"
      v-if="notarySidepanelOpen"
      @panelClosed="
        closeNotarySidepanel();
        getDeal();
      "
    />
    <NotaryBaseDocSidepanel
      :fromAnotherPanel="false"
      :deal="this.deal"
      v-if="notaryBaseDocSidepanelOpen"
      @panelClosed="
        closeNotaryBaseDocSidepanel();
        getDeal();
      "
    />
    <ClientSidepanel
      v-if="detailPanelOpen"
      @panelClosed="closeCustomerDetailPanel()"
      :customerData="sidePanelData"
      :from-project-general="true"
    />
    <Modal
      v-if="relatedCustomersModalOpen"
      @modalClosed="closeRelatedCustomersModal()"
      :customerData="customer"
      @addRelatedPerson="addRelatedPerson"
      :from-project-gen="true"
    />
    <DeleteModal
      :removing="true"
      @canceled="isDeleteModalOpen = false"
      @approved="deleteRelatedCustomer()"
      v-if="isDeleteModalOpen"
    />

    <StageChangeWarningModal
      v-if="selectedPipelineStep"
      @canceled="selectedPipelineStep = null"
      @approved="
        selectPipeline(
          null,
          selectedPipelineStep.name,
          selectedPipelineStep.isChosen,
          selectedPipelineStep.value,
          selectedPipelineStep.index
        )
      "
      @closePanel="selectedPipelineStep = null"
    />
    <AmlSidepanelAdd
      v-if="openAmlPanel"
      :deal="deal"
      @refresher="getDeal()"
      @closed="closeAml()"
      @saveResponse="getDeal()"
    />
    <AmlDetailSidepanel
      v-if="openAmlDetail"
      @refresh="getDeal()"
      @closed="closeAmlDetailPanel"
      @amlDeleted="amlDeleted()"
      :deal="deal"
    />
    <ContractSidepanelAdd
      v-if="contractSidepanelAddOpen"
      :project="deal"
      :contract="selectedContract"
      @closePanel="contractSidepanelAddOpen = false"
      @newContractAdded="contractListUpdater++"
      @contractDeleted="contractListUpdater++"
      @contractUpdated="updateSelectedContract"
    />
    <ListingSidepanel
      :listing-preview-id="chosenListingPreviewId"
      v-if="listingPanelOpen"
      :project-view="true"
      @closeListingPanel="closePanel($event)"
    />
    <OrganizationSidepanel
      :selected-organization="clickedOrg"
      v-if="orgDetailOpen"
      :project-view="true"
      @panelClosed="orgDetailClose()"
    />
    <AddObjectPopover
      v-if="addObjectOpen"
      :deal="deal"
      :position="position"
      @closed="addObjectOpen = false"
      @refreshDeal="getDeal"
    />
    <CalendarWidget
      @update="taskUpdater++"
      v-if="widgetOpen"
      @close="
        getTasks();
        taskValue = null;
        widgetOpen = false;
      "
      :taskValue="taskValue"
      :project="deal"
      :contact="{
        id: deal.client.contactId,
        name: deal.client.contactName,
      }"
    />
    <EditPanel
      v-if="taskWidgetOpen"
      :clicked-task="taskWidgetTask"
      @deleteTask="deleteTask"
      @updateKey="getTasks"
      @closePanel="taskWidgetOpen = false"
    />
    <AddModal
      v-if="clientAddOpen"
      style="z-index: 1200"
      @closed="clientAddOpen = false"
      :not-contact-view-route="true"
      @newAddedContact="addContactToRelatedPeople"
    />
    <DocumentSidepanel
      v-if="documentSidepanelOpen"
      :template-id="templateId"
      :project-id="chosenObject"
      :customers="customers"
      @closePanel="documentSidepanelOpen = false"
    />
    <ProjectEndModal
      :deal="deal"
      v-if="projectEndModal"
      @closed="closeProjectEndPanel()"
      @projectWon="playAnimation"
      @saveResponse="() => getDeal()"
    />
    <TransactionsDetail
      v-if="transactionPanelOpened"
      @updateTransaction="updateTransaction"
      @openStatusDropdown="openStatusDropDown"
      @closePanel="closeDetailPanel"
      :transactionProjectId="deal._id"
      :update-detail-panel="detailPanelUpdater"
    />
    <PaymentStatusDropdown
      @closed="statusOpen = false"
      @statusChanged="changeTransactionStatus"
      v-if="statusOpen"
      :position="position"
      :transaction="selectedTransaction"
    >
    </PaymentStatusDropdown>
    <ConfirmationModal
      @onConfirm="approveConfirmationModal"
      @onCancel="closeConfirmationModal"
      :text-start-icon="'info.svg'"
      v-if="
        archiveProjectConfirmModalOpen ||
        createDuplicateProjectConfirmationModalOpen
      "
      ><p style="padding: 2px 0" class="content-normal">
        {{
          archiveProjectConfirmModalOpen
            ? $t("ConfirmProjectArchive")
            : $t("ConfirmProjectDuplicate")
        }}
      </p>
    </ConfirmationModal>
    <div v-if="showAnimation" class="deal-won-animation">
      <Lottie v-on:animCreated="handleAnimation" :options="animationOptions" />
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import SidepanelAdd from "../../../components/Deals/SidepanelAdd.vue";
import NotarySidepanel from "../../../components/Deals/NotarySidepanel.vue";
import NotaryBaseDocSidepanel from "../../../components/Deals/NotaryBaseDocSidepanel.vue";
import FileUpload from "../../common/FileUpload.vue";
import nanoid from "nanoid";
import DeleteModal from "@/components/common/DeleteModal";
import ListingSidepanel from "@/components/Listings/ListingSidepanel";
import StageChangeWarningModal from "@/components/common/StageChangeWarningModal";
import ClientSidepanel from "../../../components/Kliendiregister/ClientSidepanel.vue";
import Modal from "@/components/Kliendiregister/CustomerDetails/Modal.vue";
import AmlSidepanelAdd from "@/components/Deals/AmlSidepanelAdd.vue";
import AmlDetailSidepanel from "@/components/Deals/AmlDetailSidepanel.vue";
import OrganizationSidepanel from "@/components/Organization/OrganizationSidepanel";
import AddObjectPopover from "@/components/Deals/AddObjectPopover";
import ProjectObject from "@/components/Deals/Detail/ProjectObject";
import InterestPanel from "@/components/Kliendiregister/InterestPanel";
import ClientListSearch from "../../common/ClientListSearch";
//eslint-disable-next-line
import arrowCreate, { DIRECTION, HEAD } from "arrows-svg";
import ProjectInterests from "@/components/Deals/Detail/ProjectInterests";
import MarketingExpenses from "@/components/common/MarketingExpenses";
import CalendarWidget from "@/components/Tasks/CalendarWidget";
import AddModal from "@/components/Kliendiregister/AddModal";
import EditPanel from "@/components/Calendar/EditPanel";
import ButtonDropdown from "@/components/common/ButtonDropdown";
import DocumentSidepanel from "@/components/Document/DocumentSidepanel";
import ProjectEndModal from "../../../components/Deals/ProjectEndModal.vue";
import moment from "moment";
import BaseTasksAndEvents from "@/components/common/BaseTasksAndEvents";
import BaseDetailviewContainer from "@/components/common/BaseDetailviewContainer";
import BaseAccordion from "@/components/common/BaseAccordion";
import BaseNotes from "@/components/common/BaseNotes";
import TransactionsDetail from "@/components/Transactions/TransactionsDetail";
import PaymentStatusDropdown from "../../Transactions/PaymentStatusDropdown";
import CompletedContainer from "@/components/Deals/Detail/CompletedContainer";
import ConfirmationModal from "@/components/common/ConfirmationModal";
import Lottie from "vue-lottie/src/lottie";
import animationData from "@/assets/lottie/confetti.json";
import BaseButton from "@/components/common/BaseButton";
import InvoiceWidget from "@/components/Invoices/InvoiceWidget";
import ContractSidepanelAdd from "../../../components/Deals/ContractSidepanelAdd.vue";
import ContractsList from "../../../components/Deals/ContractsList.vue";

export default {
  name: "SidepanelDetail",
  components: {
    InvoiceWidget,
    BaseButton,
    ConfirmationModal,
    CompletedContainer,
    BaseAccordion,
    BaseDetailviewContainer,
    BaseTasksAndEvents,
    BaseNotes,
    DocumentSidepanel,
    ButtonDropdown,
    EditPanel,
    MarketingExpenses,
    ClientListSearch,
    FileUpload,
    SidepanelAdd,
    NotarySidepanel,
    NotaryBaseDocSidepanel,
    ClientSidepanel,
    Modal,
    DeleteModal,
    AmlSidepanelAdd,
    AmlDetailSidepanel,
    OrganizationSidepanel,
    StageChangeWarningModal,
    AddObjectPopover,
    ProjectObject,
    ProjectInterests,
    InterestPanel,
    CalendarWidget,
    AddModal,
    ProjectEndModal,
    TransactionsDetail,
    PaymentStatusDropdown,
    Lottie,
    ListingSidepanel,
    ContractSidepanelAdd,
    ContractsList,
  },
  data: () => ({
    invoices: [],
    invoicesCounter: 0,
    loadingInvoices: true,
    isProjectInactive: false,
    creatingDuplicateProject: false,
    listingNameRefresher: 0,
    contractListUpdater: 0,
    showAnimation: false,
    anim: null,
    animationOptions: {
      animationData: animationData,
      autoplay: false,
      loop: false,
      rendererSettings: {
        preserveAspectRatio: "xMidYMax slice",
      },
    },
    archiveProjectConfirmModalOpen: false,
    createDuplicateProjectConfirmationModalOpen: false,
    dealUpdater: 0,
    openTaskAddWidgetCounter: 0,
    addNewNoteCounter: 0,
    templateId: "",
    notesLoading: false,
    documentSidepanelOpen: false,
    updateEndState: 0,
    documentTemplates: [],
    selectedContract: null,
    showCompletedTasks: false, // to state
    tasksCounter: 0,
    taskWidgetOpen: false,
    taskWidgetTask: "",
    selectedPipelineStep: null,
    stageChange: false,
    sidePanelData: null,
    clientAddOpen: false,
    transactionPanelOpened: false,
    taskUpdater: 0,
    taskValue: null,
    chosenListingPreviewId: null,
    widgetOpen: false,
    dealId: 0,
    detailPanelUpdater: 0,
    interests: [],
    interestsLoaded: false,
    interestsOpen: false,
    listingPanelCloser: 0,
    listingPanelOpen: false,
    interestsUpdater: 0,
    dealLoaded: false,
    objectRefresher: 0,
    addObjectOpen: false,
    position: {},
    customer: null,
    allListings: [],
    selectedRelation: null,
    notesWithContent: [],
    noListings: false,
    archivedListingsEmpty: false,
    //listing: null,
    projectEndModal: false,
    contractSidepanelAddOpen: false,
    showFullText: false,
    marketingExpenseOptions: [],
    marketingExpensesCounter: 0,
    unfinishedTasksCounter: 0,
    contractsCounter: 0,
    allTasksCounter: 0,
    totalNotesCounter: 0,
    rowCount: 0,
    orgDetailOpen: false,
    clickedOrg: null,
    listingKey: 0,
    fieldReset: 0,
    notes: null,
    showArchived: false,
    isDeleteModalOpen: false,
    deletable: null,
    dropdownIsOpen: false,
    editModalOpen: false,
    relatedCustomersModalOpen: false,
    interestToEdit: null,
    searchLoaded: false,
    detailPanelOpen: false,
    notarySidepanelOpen: false,
    notaryBaseDocSidepanelOpen: false,
    openAmlPanel: false,
    openAmlDetail: false,
    newNote: {
      id: null,
      content: "",
      linkedTo: null,
      parentName: null,
      createdAt: null,
    },
    addNewRelatedPerson: false,
    newRelatedPerson: {
      customer: null,
    },
    newNoteOpen: false,
    newTaskOpen: false,
    tasks: null,
    newTask: {
      id: nanoid(20),
      content: "",
      linkedTo: null,
      createdAt: null,
      isCompleted: false,
      parentName: null,
    },
    task: null,
    deal: null,
    dealTypes: ["Müügitehing", "Üüritehing"],
    currencies: ["EUR", "USD"],
    services: ["Maaklerteenus"],
    menu: false,
    deletedUser: null,
    deletedClient: null,
    editMode: false,
    dealRelatedCustomers: null,
    form: {
      name: null,
      dealType: null,
      description: null,
      amount: null,
      currency: "EUR",
      service: null,
      probability: null,
      doneDate: null,
      estimatedCloseDate: null,
      pipelineStep: null,
      contactPerson: null,
      organization: null,
      objects: [],
      objectName: null,
    },
    arrow: null,
    addMarketingExpense: null,
    chosenObject: null,
    openHouse: null,
    statusOpen: false,
    selectedTransaction: null,
  }),
  sockets: {
    connect: function () {
      console.log(1234);
    },
  },
  async created() {
    this.task = this.getEmptyTask();
    this.showCompletedTasks = this.showCompletedProjectTasks;
    this.chosenObject = this.$route.params.id;
    await Promise.all([
      this.getTasks(),
      this.getDeal(),
      this.getFirstOpenHouse(),
    ]);
    if (
      this.deal.pipelineStep === "Done" ||
      this.deal.pipelineStep === "archived"
    ) {
      this.isProjectInactive = true;
      this.filterArchivedListings();
    }
    await this.getDocumentTemplates();
    //this.getObjectListings();
    this.marketingExpenseOptions = [
      {
        name: this.$t("Advertisement"),
        value: "advertisement",
        _id: nanoid(8),
      },
      {
        name: this.$t("LevelService"),
        value: "levelService",
        _id: nanoid(8),
      },
    ];
    this.connectSockets();
    if (this.$route.params) {
      if (this.$route.params.openAmlPanel) {
        this.openAmlPanel = this.$route.params.openAmlPanel;
      } else if (this.$route.params.openProjectEndPanel) {
        this.projectEndModal = this.$route.params.openProjectEndPanel;
      }
    }
    await this.getInvoices();
  },
  beforeDestroy() {
    if (this.arrow) {
      this.arrow.clear();
    }
  },
  mounted() {
    window.onclick = function (event) {
      if (!event.target.matches(".dropbtn")) {
        var dropdowns = document.getElementsByClassName("dropdown-content");
        var i;
        for (i = 0; i < dropdowns.length; i++) {
          var openDropdown = dropdowns[i];
          if (openDropdown.classList.contains("show")) {
            openDropdown.classList.remove("show");
          }
        }
      }
    };
    const ps = document.querySelectorAll("p");
    const observer = new ResizeObserver((entries) => {
      for (let entry of entries) {
        entry.target.classList[
          entry.target.scrollHeight > entry.contentRect.height
            ? "add"
            : "remove"
        ]("truncated");
      }
    });

    ps.forEach((p) => {
      observer.observe(p);
    });
  },
  computed: {
    ...mapGetters([
      "customers",
      "user",
      "clientSidepanelStatus",
      "projectAccordionsStates",
      "showCompletedProjectTasks",
      "invoiceUpdater",
    ]),
    pipelineSteps() {
      return [
        {
          name: this.$t("NewLeads"),
          isChosen: false,
          value: "qualified",
          finalSelection: false,
          statusConfirmationModal: true,
          statusConfirmationModalText: this.$t("PleaseConfirmProjectRestore"),
        },
        {
          name: this.$t("NegotiatingRepresentation"),
          isChosen: false,
          value: "contactMade",
          finalSelection: false,
          statusConfirmationModal: true,
          statusConfirmationModalText: this.$t("PleaseConfirmProjectRestore"),
        },
        {
          name: this.$t("Preparation"),
          isChosen: false,
          value: "preparation",
          finalSelection: false,
          statusConfirmationModal: true,
          statusConfirmationModalText: this.$t("PleaseConfirmProjectRestore"),
        },
        {
          name: this.$t("ShowingAndAdvertising"),
          isChosen: false,
          value: "review",
          finalSelection: false,
          statusConfirmationModal: true,
          statusConfirmationModalText: this.$t("PleaseConfirmProjectRestore"),
        },
        {
          name: this.$t("FinalizingDeal"),
          isChosen: false,
          value: "Finished",
          finalSelection: false,
          statusConfirmationModal: true,
          statusConfirmationModalText: this.$t("PleaseConfirmProjectRestore"),
        },
        {
          name: this.$t("DoneDone"),
          isChosen: false,
          value: "Done",
          finalSelection: false,
          statusConfirmationModal: true,
          statusConfirmationModalText: this.$t("PleaseConfirmProjectRestore"),
        },
      ];
    },
  },
  watch: {
    invoiceUpdater() {
      console.log("nope..");
      this.getInvoices();
    },
    "$route.params.id": async function () {
      this.task = this.getEmptyTask();
      this.showCompletedTasks = this.showCompletedProjectTasks;
      this.chosenObject = this.$route.params.id;
      await Promise.all([
        this.getTasks(),
        this.getDeal(),
        this.getFirstOpenHouse(),
      ]);
      await this.getDocumentTemplates();
      //this.getObjectListings();
      this.marketingExpenseOptions = [
        {
          name: this.$t("Advertisement"),
          value: "advertisement",
          _id: nanoid(8),
        },
        {
          name: this.$t("LevelService"),
          value: "levelService",
          _id: nanoid(8),
        },
      ];
      this.connectSockets();
      if (this.$route.params && this.$route.params.openAmlPanel) {
        this.openAmlPanel = this.$route.params.openAmlPanel;
      }
      this.dealUpdater++;
    },
    "deal.notes": function () {
      this.countNotes();
    },

    dealLoaded() {
      //this.addArrow();
    },
    beforeDestroy() {
      this.aadressSearch = undefined;
      document.documentElement.style.overflow = "visible";
    },
  },

  methods: {
    ...mapActions([
      "changeSidepanelStateDeal",
      "openInvoiceAddPanel",
      "addNotesDeal",
      "setShowCompletedProjectTasks",
      "setClientSidepanel",
      "setNotarySidepanel",
      "setNotaryBaseDocSidepanel",
      "setAccordionState",
      "previewSelfServiceUser",
    ]),
    async getInvoices() {
      const invoicesResponse = await axios.get(
        `/api/invoices/${this.deal._id}`
      );
      if (invoicesResponse && invoicesResponse.status === 200) {
        this.invoices = invoicesResponse.data;
        this.invoicesCounter = invoicesResponse.data.length;
      }
      console.log(invoicesResponse);
      this.loadingInvoices = false;
    },
    brokerFee() {
      const currencyMark = this.deal.currency === "EUR" ? "€" : "$";
      switch (this.deal.brokerageFeeType) {
        case "percentOfPrice":
          if (this.deal.brokerageFee) {
            return `${this.deal.brokerageFee} ${currencyMark}`;
          } else if (this.deal.brokerFeePercentage) {
            return `${this.deal.brokerFeePercentage}%`;
          } else if (this.deal.minimumFee) {
            return `${this.deal.minimumFee} ${currencyMark}`;
          } else {
            return "-";
          }
        case "agreedPrice":
          if (this.deal.brokerageFee) {
            return `${this.deal.brokerageFee} ${currencyMark}`;
          } else {
            return "-";
          }
      }
    },
    changePipelineStep(pipelineStep) {
      if (pipelineStep.value === "Done") {
        this.openProjectEndPanel();
      } else {
        this.selectedPipelineStep = pipelineStep;
      }
    },
    openListingPanel(listingId, interestId) {
      if (
        this.chosenListingPreviewId === listingId &&
        this.chosenInterestId === interestId
      ) {
        this.closeListingPanel(listingId, interestId);
      } else {
        this.closeListingPanel();
        this.chosenListingPreviewId = listingId;
        this.chosenInterestId = interestId;
        this.listingPanelOpen = true;
      }
    },
    closeListingPanel(listingId, interestId) {
      if (!listingId && !interestId) {
        listingId = this.chosenListingPreviewId;
        interestId = this.chosenInterestId;
      }
      if (listingId && interestId) {
        this.chosenListingPreviewId = null;
        this.listingPanelOpen = false;
      }
    },
    handleAnimation(anim) {
      this.anim = anim;
    },
    animationFinished() {
      this.showAnimation = false;
      this.getDeal();
    },
    playAnimation() {
      this.showAnimation = true;
      this.$nextTick(() => {
        this.anim.addEventListener("complete", () => this.animationFinished());
        this.anim.play();
      });
    },
    closeConfirmationModal() {
      this.archiveProjectConfirmModalOpen = false;
      this.createDuplicateProjectConfirmationModalOpen = false;
    },
    async approveConfirmationModal() {
      if (this.archiveProjectConfirmModalOpen) {
        await this.archiveProject();
      } else {
        await this.createDuplicateProject();
      }
    },
    openArchiveConfirmModal() {
      this.archiveProjectConfirmModalOpen = true;
    },
    async archiveProject() {
      this.deal.pipelineStep = "archived";
      this.archiveProjectConfirmModalOpen = false;
      await axios.patch(`/api/project`, this.deal);
    },
    connectSockets() {
      this.sockets.subscribe(this.deal._id, () => {
        this.getDeal();
      });
    },
    /*  calculateBrokerageFee(totalValue, percentage, minimumFee) {
      let percent = percentage / 100;
      let answer = totalValue * percent;
      if (minimumFee) {
        if (answer < minimumFee) {
          answer = minimumFee;
        }
      }
      return answer;
    }, */
    async openClientReport() {
      let respo = await this.previewSelfServiceUser(
        this.deal.contactPerson.contactId
      );
      if (respo.status !== 200) {
        this.responseMessage = "Login failed";
        setTimeout(() => {
          this.responseMessage = null;
        }, 3000);
      } else {
        await this.$router.push({
          name: "SelfServiceBoard",
          query: {
            dealId: this.deal._id,
          },
        });
      }
    },
    openContractSidepanel() {
      this.contractSidepanelAddOpen = true;
      this.selectedContract = null;
    },
    amlDeleted() {
      this.deal.aml[this.deal.aml.length - 1].activeBusiness = null;
    },
    getEndStateStatus(endState) {
      if (endState[this.deal.endState.length - 1].projectEndReason === "Lost") {
        return this.$t("Lost");
      } else if (
        endState[this.deal.endState.length - 1].projectEndReason === "Won"
      ) {
        return this.$t("Won");
      } else if (
        endState[this.deal.endState.length - 1].projectEndReason === "Cancelled"
      ) {
        return this.$t("Cancelled");
      }
    },
    formatBackedOut(backedOutItem) {
      if (backedOutItem === "broker") {
        return this.$t("BrokerBackedOut");
      } else if (backedOutItem === "client") {
        return this.$t("ClientBackedOut");
      }
    },
    openTransactionDetails() {
      this.transactionPanelOpened = true;
    },
    closeDetailPanel() {
      this.transactionPanelOpened = false;
    },
    addEvent() {
      this.taskValue = null;
      this.widgetOpen = true;
    },
    openInlineAdd() {
      this.addNewRelatedPerson = true;
    },
    closeRelatedPersonField() {
      //this.saveRelatedPerson();
      this.addNewRelatedPerson = false;
    },
    addOpenHouse() {
      this.taskValue = "open_house";
      this.widgetOpen = true;
    },
    openMarketingExpenseAdd(value) {
      this.addMarketingExpense = value;
    },
    showActiveListingsStatus(status) {
      this.noListings = status;
      this.archivedListingsEmpty = false;
    },
    showArchivedListingExist(status) {
      this.archivedListingsEmpty = status;
    },
    async getDocumentTemplates() {
      const response = await axios.get(
        `/api/document/templates/names/byProjectType/${this.deal.dealType}`
      );
      this.mapDocumentTemplates(response.data);
    },
    mapDocumentTemplates(templates) {
      let mappedDocumentTemplates = [];

      // TODO if user switch language then switch there also ...
      for (let i = 0; i < templates.length; i++) {
        mappedDocumentTemplates.push({
          name: templates[i].title.et,
          value: templates[i]._id,
          _id: templates[i]._id,
        });
      }

      this.documentTemplates = mappedDocumentTemplates;
      console.log("DOCUMENT TEMPLATES", this.documentTemplates);
    },
    openDocument(item) {
      this.templateId = item.value;
      this.documentSidepanelOpen = true;
    },
    async getFirstOpenHouse() {
      const response = await axios.get(
        `/api/task/project/open-house/${this.chosenObject}`
      );
      this.openHouse = response.data;
    },
    filterArchivedListings() {
      this.showArchived = !this.showArchived;
    },
    openProjectEndPanel() {
      this.projectEndModal = true;
    },
    closeProjectEndPanel() {
      this.projectEndModal = false;
    },
    openInterests(interest) {
      this.interestToEdit = null;
      if (interest) {
        this.interestToEdit = interest;
      }
      this.closeListingPanel();

      this.interestsOpen = true;
    },
    async closeInterests() {
      this.interestsOpen = false;
      this.interestsUpdater++;
    },
    setInterests(interests) {
      this.interests = interests;
      this.interestsLoaded = true;
    },
    updateSelectedContract(contract) {
      this.selectedContract = contract;
      this.contractListUpdater++;
    },
    openSelectedContract(contract) {
      this.contractSidepanelAddOpen = true;
      this.selectedContract = contract;
    },
    addArrow() {
      if (this.arrow) {
        this.arrow.clear();
      }
      const checkIfButtonLoaded = setInterval(() => {
        let dealWithoutObjects = false;
        if (this.dealLoaded) {
          if (this.deal.objects.length < 1) {
            dealWithoutObjects = true;
          }
        }
        let text = document.getElementById("add-object-start-text");
        let button = document.getElementById("arrow-destination");
        if (dealWithoutObjects && text && button) {
          this.arrow = arrowCreate({
            className: "arrow2",
            from: {
              direction: DIRECTION.RIGHT,
              translation: [0, 0],
              node: text,
            },
            to: {
              direction: DIRECTION.BOTTOM,
              translation: [0, 1],
              node: button,
            },
            head: {
              func: HEAD.THIN,
              size: 5,
            },
          });
          document.body.appendChild(this.arrow.node);
          let arrowHead = document.querySelector(".arrow2__head");
          let arrowBody = document.querySelector(".arrow2__path");
          arrowHead.style.stroke = "#0AAF60";
          arrowBody.style.stroke = "#0AAF60";
          arrowBody.style.fill = "transparent";
          arrowBody.style.strokeWidth = "1";
          clearInterval(checkIfButtonLoaded);
        }
      }, 100);
    },
    openAddObject() {
      let button = document.getElementById("openAddObjectButton");
      const rect = button.getBoundingClientRect();
      this.position.right = window.innerWidth - rect.right;
      this.position.top = rect.top + window.scrollY - 50;

      this.addObjectOpen = true;
    },
    showText() {
      this.showFullText = true;
    },
    showTextLess() {
      this.showFullText = false;
    },
    openAml() {
      if (this.deal.dealType == "Sale" || this.deal.dealType == "Müük") {
        this.openAmlPanel = true;
      }
    },
    closeAml() {
      this.openAmlPanel = false;
    },
    openAmlDetailPanel() {
      if (this.deal.dealType == "Sale" || this.deal.dealType == "Müük") {
        this.openAmlDetail = true;
      }
    },
    addListing() {
      this.$router.push({
        name: "ListingsAdd",
        params: { project: this.deal },
      });
    },
    closeAmlDetailPanel(aml) {
      this.openAmlDetail = false;
      this.deal.aml = aml;
    },
    async addContactToRelatedPeople(contact, saveResponse) {
      if (!contact) return;
      this.addNewRelatedPerson = false;
      let relatedCustomer;
      if (contact.contactName) {
        relatedCustomer = {
          customerName: contact.contactName,
          organization: contact.company_name,
          customerId: contact.customerId,
          isPartner: contact.contactIsPartner,
        };
      } else if (contact.customerName) {
        relatedCustomer = {
          customerName: contact.customerName,
          organization: contact.organization,
          customerId: contact.customerId ? contact.customerId : saveResponse,
          isPartner: contact.contactIsPartner,
        };
      } else {
        return;
      }

      if (this.dealRelatedCustomers) {
        let found = this.dealRelatedCustomers.find(
          (item) => item.customerId === relatedCustomer.customerId
        );

        if (!found) {
          this.dealRelatedCustomers.unshift(relatedCustomer);
        } else {
          return;
        }
      } else {
        this.dealRelatedCustomers = [relatedCustomer];
      }

      await axios.post(`/api/project/relation/add/${this.deal._id}`, {
        relatedCustomer,
      });
    },
    async getRelations() {
      /*  const contact = {
        customerName: this.deal.contactPerson.contactName,
        customerId: this.deal.contactPerson.contactId,
      } */
      let relatedCustomers = this.deal.relatedCustomers;
      //relatedCustomers.push(contact);
      this.dealRelatedCustomers = relatedCustomers ? relatedCustomers : [];

      //dont show project contact person in related list
      /*      const result = this.dealRelatedCustomers.filter(
        (x) => x.customerId !== this.deal.contactPerson.contactId
      );
      this.dealRelatedCustomers = result;*/
    },
    addNewContact() {
      this.clientAddOpen = true;
    },
    openDeleteModal(customerId) {
      this.isDeleteModalOpen = true;
      this.deletable = customerId;
    },
    closeDeleteModal() {
      this.isDeleteModalOpen = false;
    },
    countRelatedPersons() {
      let relatedPersons = 0;
      if (this.dealRelatedCustomers) {
        relatedPersons = this.dealRelatedCustomers.length;
      }
      return relatedPersons;
    },
    countNotes() {
      let notesCounter = 0;
      this.deal.notes.forEach((note) => {
        if (note.statusCode !== 0) {
          notesCounter++;
        }
      });
      this.totalNotesCounter = notesCounter;
      return this.totalNotesCounter;
    },
    formatDealType() {
      if (this.deal.dealType == "Sale") {
        return this.$t("Sale");
      } else if (this.deal.dealType == "Purchase") {
        return this.$t("Purchase");
      } else if (
        this.deal.dealType.replace(/ /g, "").toLowerCase() == "leaseout"
      ) {
        return this.$t("LeaseOut");
      } else if (
        this.deal.dealType.replace(/ /g, "").toLowerCase() == "aquirelease"
      ) {
        return this.$t("AquireLease");
      } else if (this.deal.dealType == "Referral") {
        return this.$t("Referral");
      }
    },
    checkRows() {
      if (this.deal.description) {
        var el = this.deal.description;
        var lineCount = 0;
        var lines = el.split("\n");
        for (var i = 0; i < lines.length; i++) {
          if (lines[i].length > 0) {
            lineCount++;
          }
        }
        this.rowCount = lineCount;
      }
    },
    closeRelatedCustomersModal() {
      this.relatedCustomersModalOpen = false;
    },
    openRelatedCustomerModal() {
      this.relatedCustomersModalOpen = true;
    },
    openPriceLogDropdown() {
      if (!this.dropdownOpen) {
        this.dropdownIsOpen = true;
      }
    },
    closePriceLogDropdown() {
      this.dropdownIsOpen = false;
    },
    async deleteRelatedCustomer() {
      let dataObject = {
        projectId: this.deal._id,
        relatedPersonId: this.deletable,
      };
      await axios.post(`/api/project/relation/delete`, dataObject);
      this.isDeleteModalOpen = false;
      this.$router.go();
    },

    formatDate(item) {
      if (item) {
        return moment(item).format("DD.MM.YYYY");
      } else {
        return "-";
      }
    },

    formatType(e) {
      if (e == "CU") {
        return "Katastriüksus";
      } else if (e == "ME") {
        return "Mitteelukondlik hoone";
      } else if (e == "EE") {
        return "Elukondlik hoone";
      } else if (e == "AY") {
        return "Asustusüksus";
      } else if (e == "ER") {
        return "Eluruum";
      } else if (e == "LO") {
        return "Linnaosa";
      } else if (e == "LP") {
        return "Liikluspind";
      } else if (e == "MR") {
        return "Mitteeluruum";
      } else if (e == "OV") {
        return "Omavalitsus";
      }
    },
    openCustomerDetailpanel(client) {
      this.sidePanelData = client;
      this.detailPanelOpen = true;
    },
    async closeCustomerDetailPanel() {
      this.detailPanelOpen = false;
      await this.getProjectData();
    },
    async openNotarySidepanel() {
      const response = await axios.get(
        `/api/contact/${this.deal.contactPerson.contactId}`
      );
      if (response.data.statusCode === 0) {
        this.notarySidepanelOpen = false;
        this.toastError(this.$t("ContactIsDeleted"));
      } else {
        this.notarySidepanelOpen = true;
      }
    },
    closeNotarySidepanel() {
      this.notarySidepanelOpen = false;
    },
    async openNotaryBaseDocSidepanel() {
      const response = await axios.get(
        `/api/contact/${this.deal.contactPerson.contactId}`
      );
      if (response.data.statusCode === 0) {
        this.notaryBaseDocSidepanelOpen = false;
        this.toastError(this.$t("ContactIsDeleted"));
      } else {
        this.notaryBaseDocSidepanelOpen = true;
      }
    },

    closeNotaryBaseDocSidepanel() {
      this.notaryBaseDocSidepanelOpen = false;
    },
    openOrganization(orgRegCode) {
      /*  this.clickedOrg = orgRegCode;
      this.orgDetailOpen = true;
      document.documentElement.style.overflow = "hidden"; */
      if (/^\d+$/.test(orgRegCode.reg_code)) {
        this.clickedOrg = orgRegCode;
        this.clickedOrg.regCode = orgRegCode.reg_code
        this.orgDetailOpen = true;
        document.documentElement.style.overflow = "hidden";
      } else {
        this.clickedOrg = orgRegCode.companyId ? orgRegCode.companyId: orgRegCode._id;
        this.orgDetailOpen = true;
        document.documentElement.style.overflow = "hidden";
      }
    },
    orgDetailClose() {
      this.orgDetailOpen = false;
      document.documentElement.style.overflow = "visible";
    },
    openSidepanelAdd() {
      document.documentElement.style.overflow = "hidden";
      this.editModalOpen = true;
    },
    closeSidepanelAdd() {
      this.editModalOpen = false;
      document.documentElement.style.overflow = "visible";
      document.getElementById("panel-wrapper-deal").style.display = "initial";
      this.getDeal(this.$route.params.id);
      this.$emit("projectChangedFromGeneral");
      this.listingNameRefresher++;
    },
    isListingPanel(el) {
      let listingInterestPanelId = this.chosenInterestId + "inside-column";
      while (el.parentNode) {
        el = el.parentNode;
        if (el.id === "listing-overlay" || el.id === listingInterestPanelId)
          return true;
      }
      return false;
    },
    closePanel(e) {
      let isListingPanel = this.isListingPanel(e.target);
      if (this.listingPanelOpen && !isListingPanel) {
        /*     var els = document.querySelectorAll(
            ".funnel-row.listing-preview-opened"
          );
         var els2 = document.getElementById("close-white-cross-image");
          console.log("ELEMENT NR 2", els2)
          for (var i = 0; i < els.length; i++) {
            els[i].classList.remove("listing-preview-opened");
          }
          els2.style.display = "none";
         */
        this.listingKey++;
        this.listingPanelOpen = false;
      }
    },
    openField(note) {
      this.$set(note, "isDisabled", false);
      this.$forceUpdate();
    },
    closeFields() {
      this.notes.forEach((e) => {
        e.isDisabled = true;
      });
      this.$forceUpdate();
    },
    async selectPipeline(
      e,
      pipelineName,
      isActive,
      pipelineValue,
      updateElements = true,
      restored = false
    ) {
      if (
        (!this.deal.endState ||
          (this.deal.endState && this.deal.endState.length < 1)) &&
        pipelineValue === "Done"
      ) {
        this.projectEndModal = true;
        return;
      }
      let selectedIndex = null;
      this.pipelineSteps.forEach((pipeline, index) => {
        if (pipeline.name === pipelineName) {
          pipeline.isChosen = true;
          pipeline.finalSelection = true;
          selectedIndex = index;
          this.form.pipelineStep = pipeline.value;
          if (pipeline.value === "Done" || pipeline.value === "Lõpetatud") {
            let today = new Date();
            let dd = String(today.getDate()).padStart(2, "0");
            let mm = String(today.getMonth() + 1).padStart(2, "0");
            let yyyy = today.getFullYear();
            if (!this.deal.doneDate) {
              this.form.doneDate = dd + "." + mm + "." + yyyy;
              this.deal.doneDate = this.form.doneDate;
            } else if (this.deal.doneDate) {
              return;
            }
          } else {
            this.deal.doneDate = null;
            this.form.doneDate = null;
          }
          if (updateElements) {
            let middle = document.getElementById(index + "d");
            document
              .getElementById(pipelineName + "p")
              .classList.add("chosen-step-name");
            document
              .getElementById(pipelineName + "nr")
              .classList.add("chosen-step-nr");
            middle.classList.add("chosen-step-container");
            middle.classList.remove("hover-step-container");
          }
        } else {
          pipeline.finalSelection = false;
          pipeline.isChosen = false;
          if (updateElements) {
            let middle = document.getElementById(index + "d");
            //here
            document
              .getElementById(pipeline.name + "p")
              .classList.remove("chosen-step-name");
            document.getElementById(pipeline.name + "p").style.display = "none";

            document
              .getElementById(pipeline.name + "nr")
              .classList.remove("chosen-step-nr");

            middle.classList.remove("chosen-step-container");
            middle.classList.remove("hover-step-container");
          }
        }
      });
      this.pipelineSteps.forEach((step, index) => {
        if (index <= selectedIndex) {
          step.isChosen = true;
          if (updateElements) {
            document
              .getElementById(index + "d")
              .classList.add("pipeline-left-black");
            document
              .getElementById(step.name + "nr")
              .classList.add("chosen-step-nr");
            document
              .getElementById(step.name + "p")
              .classList.add("pipeline-name-white");
            if (index === selectedIndex) {
              document.getElementById(step.name + "p").style.display = "block";
            }
          }
        } else {
          if (updateElements) {
            document
              .getElementById(index + "d")
              .classList.remove("pipeline-left-black");
          }
        }
      });
      if (this.deal.pipelineStep !== this.form.pipelineStep) {
        this.deal.pipelineStep = this.form.pipelineStep;
        await axios.patch(`/api/project`, this.deal);
        if (restored) {
          this.toastSuccess(this.$t("Project") + " " + this.$t("restored"));
          this.isProjectInactive = false;
          await this.getDeal();
        }
      }
    },
    hoverOpen(e, pipelineStep, isActive, index, finalSelection) {
      if (!finalSelection) {
        let middle = document.getElementById(index + "d");
        document.getElementById(pipelineStep + "p").style.display = "initial";
        middle.classList.add("hover-step-container");
      }
    },
    hoverClose(e, pipelineStep, isActive, index, finalSelection) {
      if (!finalSelection) {
        let middle = document.getElementById(index + "d");
        document.getElementById(pipelineStep + "p").style.display = "none";
        middle.classList.remove("hover-step-container");
      }
    },
    addNewNote(newNote) {
      if (newNote.content != "") {
        let today = new Date();
        let dd = String(today.getDate()).padStart(2, "0");
        let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        let yyyy = today.getFullYear();

        today = dd + "/" + mm + "/" + yyyy;
        newNote.createdAt = today;
        this.notes.push(newNote);
      } else {
        return;
      }
    },
    async saveNotes(notes) {
      this.notesLoading = true;
      this.notes = notes;
      try {
        this.notes.forEach((el) => {
          if (el.content == "") {
            el.statusCode = 0;
          }
        });
        this.deal.notes = this.notes;

        await axios.patch(
          `/api/project/notes/${this.$route.params.id}`,
          this.notes
        );

        this.newNote.content = "";
      } catch (err) {
        console.log(err);
      }
    },

    fileUpload() {
      const fileContentLoaded = setInterval(() => {
        if (document.getElementById("project-general-upload")) {
          document.getElementById("project-general-upload").click();
          clearInterval(fileContentLoaded);
        }
      }, 100);
    },
    async saveTask(task) {
      let res = await axios.patch(`/api/task/${task._id}`, task);

      if (res.status !== 200) {
        this.toastError(this.$t("Error") + " " + res.status);
      }
      this.countTasks();
    },
    tasksUpdated(emittedTasks) {
      this.tasks = emittedTasks;
      this.countTasks();
    },
    isDateOver(obsDates, dateNow) {
      const date = moment(obsDates.endDate);
      if (obsDates.endTime) {
        const endTime = obsDates.endTime.split(":");
        date.set({ h: endTime[0], m: endTime[1] });
      } else {
        date.set({ h: 0, m: 0 });
      }
      return date.isBefore(dateNow);
    },
    async getTasks() {
      let response;
      if (this.showCompletedTasks) {
        response = await axios.get(
          `/api/tasks/project/${this.$route.params.id}`
        );
      } else {
        response = await axios.get(
          `/api/tasks/project/unfinished/${this.$route.params.id}`
        );
      }
      this.tasks = response.data;
      this.groupTasks();
      this.countTasks();
    },
    groupTasks() {
      const dateNow = moment().add(-1, "d");
      this.tasks.forEach((task) => {
        if (task.isCompleted && task.category === "task") {
          return;
        }
        if (!task.dates.endDate || task.type === "open_house") {
          task.dueDate = "none";
        } else if (this.isDateOver(task.dates, dateNow)) {
          task.dueDate = "over";
        } else {
          task.dueDate = "upcoming";
        }
      });
    },
    countTasks() {
      let taskCounter = 0;
      let currentDate = new Date(Date.now());
      const currentTime =
        currentDate.getHours() + ":" + currentDate.getMinutes();
      currentDate = currentDate.toISOString().replace("Z", "+03:00");
      this.tasks.forEach((task) => {
        if (task.category === "open_house") {
         /*  if (!task.numberOfParticipants) { */
            taskCounter++;
          /* } */
        }
        if (task.category === "task") {
          if (!task.isCompleted) {
            taskCounter++;
          }
        }
        if (task.category === "event") {
          if (
            (task.dates.endDate >= currentDate &&
              task.dates.endTime > currentTime) ||
            (task.dates.endDate >= currentDate && !task.dates.endTime) ||
            (task.dates.endDate > currentDate && task.dates.endTime)
          ) {
            taskCounter++;
          }
        }
      });
      this.tasksCounter = taskCounter;
    },
    async deleteTask(taskId) {
      let msToken = null;
      let nylasAccessToken = null;
      if (this.user.microsoft) {
        const access = await this.getMsalAccessToken();
        if (access) {
          msToken = access.accessToken;
        }
      }
      if (this.user.nylas) {
        const accessToken = await this.getNylasAccessToken();
        if (accessToken) {
          nylasAccessToken = accessToken;
        }
      }
      const res = await axios.post(`/api/task/delete/${taskId}`, {
        accessToken: msToken,
        nylasAccessToken
      });

      if (res.status === 200) {
        this.tasks = this.tasks.filter((task) => task._id !== taskId);
        this.taskUpdater++;
        this.toastSuccess(this.$t("Deleted"));
        this.countTasks();
      } else {
        this.toastError(this.$t("ErrorDeleting"));
      }
    },
    getEmptyTask() {
      return {
        _id: null,
        createdAt: null,
        content: "",
        assignedTo: {
          userId: null,
          name: null,
        },
        isCompleted: false,
        type: "generic",
        category: "task",
        dates: {
          startDate: null,
          startTime: null,
          endDate: null,
          endTime: null,
        },
        linkedProject: {
          id: null,
          name: null,
        },
        linkedContact: {
          id: null,
          name: null,
        },
        isPrivate: false,
      };
    },
    /*  setUnfinishedTasksCounter(count) {
       this.unfinishedTasksCounter = count;
     }, */
    /*  setAllTasksCounter(count) {
       this.allTasksCounter = count;
     }, */
    setMarketingExpenseCounter(count) {
      this.marketingExpensesCounter = count;
    },
    setContractsCounter(count) {
      this.contractsCounter = count;
    },
    async getProjectData() {
      const response = await axios.get(`/api/project/${this.chosenObject}`);
      this.deal = response.data;
    },
    async getDeal() {
      const response = await axios.get(`/api/project/${this.chosenObject}`);
      this.deal = response.data;

     //let amlItem = this.deal.aml.find(aml => aml.statusCode === 1);
      let amlLastObject = this.deal.aml ? this.deal.aml[this.deal.aml.length - 1] : null
      let amlFiles = amlLastObject ? amlLastObject.files.map((file) => {
          return {
              fileName: file.name,
              uploaderName: file.creator.name,
              createdAt: file.createdAt,
              amlId: file.amlId,
              _id: file._id,
          };
      }) : [];
      amlFiles = amlFiles.filter(
        (v, i, a) => a.findIndex((v2) => v2._id === v._id) === i
      );
      this.deal.files.push(...amlFiles);
      if (this.deal.marketingExpenses) {
        this.marketingExpensesCounter = this.deal.marketingExpenses.length;
      }
      if (this.deal.contracts) {
        this.contractsCounter = this.deal.contracts.length;
      }
      let tasks = await axios.get(`/api/tasks/project/${this.deal._id}`);
      tasks = tasks.data;
      if (tasks) {
        let unfinishedCounter = 0;
        tasks.forEach((task) => {
          if (!task.isCompleted && task.content !== "") {
            unfinishedCounter++;
          }
        });
        this.unfinishedTasksCounter = unfinishedCounter;
      }

      const res = await axios.get(
        `/api/contact/${this.deal.contactPerson.contactId}`
      );
      if (res.data.statusCode === 1) {
        this.deletedUser = null;
      } else {
        this.deletedUser = "(" + this.$t("DeletedContact") + ")";
      }
      if (
        this.deal.client &&
        this.deal.client.contactId &&
        this.deal.client.contactId !== this.deal.contactPerson.contactId
      ) {
        const resForClient = await axios.get(
          `/api/contact/${this.deal.client.contactId}`
        );
        if (resForClient.data.statusCode === 1) {
          this.deletedClient = null;
        } else {
          this.deletedClient = "(" + this.$t("DeletedContact") + ")";
        }
      } else {
        this.deletedClient = this.deletedUser;
      }

      this.dealLoaded = true;
      this.newNote.linkedTo = this.deal._id;
      this.newTask.linkedTo = this.deal._id;
      this.newNote.parentName = this.deal.name;
      this.newTask.parentName = this.deal.name;

      await this.getRelations();
      this.checkRows();
      this.dealUpdater++;
      this.$nextTick(() => this.objectRefresher++);
      this.$nextTick(() => this.updateEndState++);

      this.$nextTick(() => {
        this.pipelineSteps.forEach((step) => {
          if (step.value === this.deal.pipelineStep) {
            step.isChosen = true;
            this.selectPipeline(null, step.name, step.isChosen, step.value);
          }
        });
      });
    },
    async saveDeal(deal) {
      //deal.contactPersonName = deal.contactPerson.customerName;
      await axios.patch("/api/project", deal);
      await this.changeSidepanelStateDeals(0);
    },
    async deleteDeal() {
      let listings = this.deal.objects[0].listings;
      await axios.post(`/api/project/delete/${this.deal._id}`, listings);
      await this.changeSidepanelStateDeal(0);
      await this.$router.push("/projects");
      await this.$router.go();
    },
    closeDeal() {
      this.changeSidepanelStateDeal(0);
      window.history.replaceState({}, document.title, "/projects");
    },
    async createDuplicateProject() {
      if (this.creatingDuplicateProject === true) return;
      this.createDuplicateProjectConfirmationModalOpen = false;
      this.creatingDuplicateProject = true;
      const newDeal = JSON.parse(JSON.stringify(this.deal));
      newDeal.pipelineStep = this.pipelineSteps[0].value;
      newDeal.endState = [];
      const response = await axios.post(
        `/api/project/duplicate/${this.deal._id}`,
        newDeal
      );
      if (response && response.status === 200) {
        await this.$router.push(`/projects/${response.data._id}`);
        this.toastSuccess(
          this.$t("Project") + " " + this.$t("duplicated").toLowerCase()
        );
        this.isProjectInactive = false;
      } else {
        this.toastError(this.$t("ErrorCreating"));
      }
      this.creatingDuplicateProject = false;
    },
    async updateTransaction(updatedTransaction) {
      this.transactionId = updatedTransaction._id;
      let response = await axios.patch(
        `/api/transaction-update/${this.transactionId}`,
        updatedTransaction
      );

      if (response.status > 499) {
        this.toastError(this.$t("ErrorUpdating"));
      } else if (response.status === 200) {
        this.toastSuccess(this.$t("TransactionUpdated"));
      }
      this.detailPanelUpdater++;
      this.getDeal();
    },
    openStatusDropDown(transaction, position) {
      this.statusOpen = true;
      this.selectedTransaction = transaction;
      this.position = position;
    },
    async changeTransactionStatus(value) {
      if (this.selectedTransaction.status !== value) {
        this.selectedTransaction.status = value;
        const updatedTransaction = this.selectedTransaction;

        await this.updateTransaction(updatedTransaction);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "src/global_variables";

.client-report-preview-button {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  border-radius: 8px;
  gap: 8px;
  background: #2b7b6d;
  cursor: pointer;

  p {
    color: white;
    font-size: 12px;
    line-height: 16px;
  }
}

.end-project-button {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  border-radius: 8px;
  gap: 8px;
  background: #000000;
  cursor: pointer;

  p {
    color: white;
    font-size: 12px;
    line-height: 16px;
  }
}

.project-end-status {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  border-radius: 8px;
  height: 36px;
  gap: 8px;
  cursor: pointer;

  p {
    color: black;
    font-size: 12px;
    line-height: 16px;
  }
}

.red-background {
  background: #ffb4b6 !important;
  font-weight: bold !important;
}

.blue-background {
  background: #b8d8ff !important;
  font-weight: bold !important;
}

.green-background {
  background: #b5e7cf !important;
  font-weight: bold !important;
}

.project-second-header {
  height: 64px;
  display: flex;
  padding: 16px 24px;
  justify-content: space-between;
}

.project-actions-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.related-person-row {
  margin-top: 12px;
  gap: 12px !important;
  padding: 0 24px 0 0;
}

.relationIcon {
  border-radius: 50%;
  padding: 0 4px;
  background: $blue;
  height: 32px;
  min-width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.related-person-name {
  display: flex;
  align-items: center;
  gap: 8px;
}

.related-person-customer-name {
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

#arrow-destination {
  width: 144px;
  height: 27px;
  position: absolute;
  right: 0;
  top: 0;
}

.dropdown-content {
  display: none;
  margin-top: 10px;
  margin-left: 22px;
  position: absolute;
  background-color: white;
  width: 160px;
  max-width: 160px;
  overflow: auto;
  z-index: 3;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 1px 2px rgba(0, 0, 0, 0.03),
    0px 0px 1px rgba(0, 0, 0, 0.04);

  p {
    padding: 12px 12px 12px 24px;
    font-size: 14px;
    line-height: 16px;

    &:hover {
      background-color: #f4f5f7;
      cursor: pointer;
    }
  }
}

.show {
  display: block;
}

.content {
  display: block;
  padding-left: 104px;
  padding-right: 50px;
  padding-top: 24px;
  width: 100%;
  max-width: 2106px;
  background-color: #f4f5f7;
  z-index: 101;
}

#autoresizing {
  display: block;
  overflow: hidden;
  resize: none;
}

.note-row {
  flex-flow: row;
  margin-top: 16px !important;
  cursor: pointer;
  max-width: 100%;
}

.note-text {
  white-space: pre-line;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  width: 100%;
  padding-left: 4px;
  box-sizing: border-box;
}

.note-text:hover {
  cursor: pointer;
  /*
  box-shadow: inset 0px 0px 0px 1px rgb(230, 232, 236);
*/
  border-radius: 8px;
}

.createdAt {
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  display: flex;
  align-items: center;
  margin-bottom: 0 !important;

  /* grey/grey900 */

  color: #939597;
}

.add-field {
  margin-bottom: 0 !important;
  width: 100% !important;
}

/* .notes {
  padding-left: 12px;
  padding-right: 28px;
  padding-bottom: 12px;
} */
.related-content {
  padding-left: 24px;
  padding-right: 56px;
  padding-bottom: 24px;
}

.add-notes {
  height: 50px;
  flex-direction: row;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-family: Inter;
  border-radius: 8px;
  height: 40px;
  padding: 0;
  justify-content: space-between;
}

.add-note-inside {
  width: 73px;
  height: 16px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
}

.text-area {
  height: fit-content !important;
}

.delete {
  background-color: #ff1e24;
  color: white;
}

.theme--light.v-input--is-disabled,
.theme--light.v-input--is-disabled input,
.theme--light.v-input--is-disabled textarea {
  color: black !important;
}

.v-input__slot {
}

.buttons-row {
  justify-content: flex-end;
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: 12px 12px 12px;
  background-color: white;
  border-radius: 8px;

  .cancel {
    background-color: transparent !important;
    box-shadow: 0px 0 2px rgba(28, 14, 3, 0.3);
    text-transform: none;
    padding: 12px;
    display: flex;
    align-items: center;
    height: 40px;
  }
}

.pipeline-header {
  margin-top: 48px;
  width: 30%;

  p {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
  }
}

/* .inputFields {
  font-size: 14px;
  font-family: Inter;
  line-height: 1.2;
  gap: 8px 24px;
  display: flex;
  flex-flow: row wrap;
} */
.stageSelector {
  font-size: 14px;
  font-family: Inter;
  line-height: 1.2;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  border-radius: 8px;
}

.stageSelector > input {
  margin: 0;
  padding: 0;
  -moz-appearance: none;
  -webkit-appearance: none;
}

.stageSelector > * {
  margin-right: 0px;
}

.arrowLabel {
  width: 173px;
  cursor: pointer;
  height: 40px;
  display: flex;
  position: relative;
  font-size: 12px;
  background: #e6e8ec;
  align-items: center;
  justify-content: center;
}

.stageSelector > input:checked + label:before {
  border-left-color: #75787a;
}

.arrowLabel:before {
  right: -10px;
  z-index: 2;
}

.arrowLabel:before {
  width: 0;
  bottom: 0;
  height: 0;
  content: "";
  position: absolute;
  border-top: 20px solid transparent;
  border-left: 10px solid #e6e8ec;
  border-bottom: 20px solid transparent;
}

.stageSelector > input:checked + label {
  color: #ffffff;
  background: #75787a;
}

/* .row {
  gap: 24px;
} */
.notes-info-row {
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 16px;
  background-color: #ffffff;
}

.files-info-row {
  padding: 0;
  border-radius: 8px;
  margin-bottom: 8px;
  background-color: #ffffff;
}

.notes-col {
  padding: 0;
  padding-top: 12px;
}

.top-row {
  width: 100%;
  justify-content: flex-end;
  padding: 12px;
}

.close-button {
  background: #f4f5f7;
  border-radius: 8px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.divider {
  width: 100%;

  border: 1px solid #e6e8ec;
  margin-bottom: 24px;
  margin-top: 16px;
  left: 0;
  right: 0;
}

.description {
  /* padding-left: 24px;
  padding-top: 0px;
  padding-bottom: 24px;
  padding-right: 56px; */
  border: none !important;
  outline: none !important;
  width: 100%;
  resize: none;
}

.main-wrapper {
  margin: 12px;
  border: 1px solid #e6e8ec;
  border-radius: 8px;
}

.sub-block {
  padding: 0px;
  border-bottom: 1px solid #e6e8ec;
}

.sub-block-wrapper {
  padding: 12px;
}

.no-active {
  height: 40px;
  max-width: 65%;
}

.inputFields {
  font-size: 14px;
  font-family: Inter;
  line-height: 1.2;
  gap: 8px 24px;
  display: flex;
  flex-flow: row wrap;
  padding-top: 16px;
  padding-bottom: 24px;
}

.no-active.v-input.v-input__slot {
  border-radius: 20px;
  border: 1px solid #e6e8ec !important;
}

.related-persons {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #000000;
  margin-bottom: 0 !important;
}

.contact-type {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #75787a;
}

.related-person-info {
  text-align: right;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-align: right;
  color: #000000;
}

.stageSelector {
  font-size: 14px;
  font-family: Inter;
  line-height: 1.2;
  width: 100%;
  display: flex;
}

.stageSelector > input {
  margin: 0;
  padding: 0;
  -moz-appearance: none;
  -webkit-appearance: none;
}

.pipeline-name {
  display: none;
  white-space: nowrap;
  margin-left: 5px;
}

.pipeline-name-white {
  display: none;
  color: white !important;
  white-space: nowrap;
  margin-left: 5px;
}

.pipeline-middle {
  display: flex;
  flex-direction: row;
  flex: 0;
  align-items: center;
  width: 52.48px;
  height: 40px;
  padding-right: 0px;
  background-color: #e6e8ec;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.deal-item-name {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  text-align: right;

  /* black */

  color: #000000;
}

.client-name {
  &:hover {
    text-decoration: underline;
  }

  cursor: pointer;
}

.pipeline-image-front {
  width: 12px;

  path {
    fill: #e6e8ec !important;
  }
}

.stageSelector > input:checked + label {
  color: #ffffff;
  background: #75787a;
}

.stageSelector > input:checked + label:before {
  border-left-color: #75787a;
}

.pipeline-left {
  position: absolute;
  left: 0px;
}

.pipeline-left-black {
  background-color: #000000 !important;
}

.pipeline-right-black {
  background-color: #000000 !important;
}

.pipeline-right {
  position: absolute;
  right: -1.2px;
}

.chosen-step-nr {
  color: white !important;
  width: 8.4px;
}

.step-nr-white {
  color: white !important;
}

.chosen-step-name {
  display: initial;
  color: white !important;
}

.chosen-step-container {
  background-color: black !important;
  width: fit-content;
  padding-right: 20px;
}

.hover-step-container {
  background-color: #fff0d4;
  width: fit-content;
  padding-right: 20px;
}

.probability {
  padding-right: 20%;
}

.customer-row {
  border-radius: 8px;
  margin-bottom: 8px;
  background-color: #ffffff;
}

.main-info-row {
  padding: 24px;
  border-radius: 8px;
  margin-bottom: 16px;
  background-color: #ffffff;
}

.link {
  text-decoration: underline;
  cursor: pointer;
}

.files {
  padding: 0 !important;
}

.add-persons {
  flex-direction: row;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-family: Inter;
  font-size: 12px;
  line-height: 16px;
  border-radius: 8px;
  height: 28px;
  gap: 8px;
  padding: 0;
  justify-content: space-between;
  border: 1px solid #e6e8ec;
  padding: 8px;
}

.sub-heading {
  padding: 24px;
  padding-right: 56px;
}

.deal-item {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #75787a !important;
}

.register-code {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.edit-button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  height: 32px;
  gap: 8px;
  /* grey/grey500 */

  border: 1px solid #e6e8ec;
  box-sizing: border-box;
  border-radius: 8px;

  &:hover {
    background-color: #e7e7e7;
    cursor: pointer;
  }
}

.edit-button-text {
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 0 !important;
}

.vertical-line {
  background-color: #e6e8ec;
  width: 1px;
  height: 16px;
}

.description-item {
  text-overflow: ellipsis;
}

.description-item1 {
  border: none !important;
  outline: none !important;
  width: 376px;
  height: 52px;
  font-size: 14px;
  resize: none;
  padding-right: 16px;
  padding-bottom: 58px;
  padding-top: 0px;
  white-space: pre-line;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

::v-deep .v-text-field__slot textarea {
  padding-right: 40px !important;
}

.autoscale-text {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}

.description-item2 {
  border: none !important;
  outline: none !important;
  width: 592px;
  height: 300px;
  font-size: 14px;
  resize: none;
  padding: 12px;
  overflow: hidden;
}

::v-deep .description-textarea > .v-text-field--outlined fieldset {
  border: 0px solid red !important;
}

::v-deep .v-text-field > .v-input__control > .v-input__slot:before {
  width: 0%;
}

.activities {
  padding-left: 24px;
  padding-right: 56px;
  padding-bottom: 24px;
}

.modal-open {
  overflow-x: hidden;
  overflow-y: hidden;
}

.modal-close {
  overflow-x: auto;
  overflow-y: auto;
}

.object-link:hover {
  color: black;
  text-decoration: underline;
  cursor: pointer;
}

::v-deep
  .ui-popup-container
  ::v-deep.ui-popup-hidden
  ::v-deep.ui-popup-truncate {
  display: none !important;
}

.amount {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  height: 24px;
  line-height: 24px;
  /* identical to box height, or 133% */

  display: flex;
  align-items: center;

  /* black */

  color: #000000;
}

.hover-open-delete {
  background-image: url("../../../assets/images/trash-red.svg");
}

.save {
  background-color: #0aaf60;
  color: white;
  border-color: #e6e8ec;
}

.contactperson {
  font-size: 14px !important;
  line-height: 16px;

  &:hover {
    text-decoration: underline;
  }
}

.separator {
  border-top: 1px solid #e6e8ec;
  width: 100%;
  height: 1px;
}

.initials-text {
  display: initial;
}

.content-small-green {
  color: #0aaf60;
  max-width: 90% !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.self-service-button {
  color: #ffffff;
  background: #000000;
  border: none;
  cursor: pointer;
  //padding: 12px 12px;
  font-size: 14px;
  width: 100px;
  height: 32px;
  font-family: Inter;
  text-transform: none;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.content-small-orange {
  color: #ff8a00;
  max-width: 90% !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.content-small-yellow {
  color: #b54616;
  max-width: 90% !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.content-small-blue {
  color: #0151af;
  max-width: 90% !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.content-small-pink {
  color: #f81ca0;
  max-width: 90% !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.green-font {
  background: #f2ffe0;
  border-radius: 4px;
  padding: 0px 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.blue-font {
  background: #dbe8f7;
  border-radius: 4px;
  padding: 0px 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.pink-font {
  background: #fff2f7;
  border-radius: 4px;
  padding: 0px 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.orange-font {
  background: #fff0d4;
  border-radius: 4px;
  padding: 0px 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.yellow-font {
  background: #fff0d4;
  border-radius: 4px;
  padding: 0px 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.gray-font {
  background: #f4f5f7;
  border-radius: 4px;
  padding: 0px 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.customer-field-row {
  gap: 24px;
}

.top16 {
  margin-top: 16px;
}

.top24 {
  margin-top: 24px;
}

.add-object-to-start {
  font-family: "Geomanist-Light", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 32px;
  line-height: 40px;
  /* identical to box height, or 125% */

  display: flex;
  align-items: center;
  text-align: center;

  /* black */

  color: #000000;
}

.right-col {
  gap: 16px;
  max-width: calc(100% - 464px);
  background: #fff;
  border-radius: 8px;
  padding: 0;
  margin-bottom: 48px;
}

.right-col-header {
  display: flex;
  justify-content: space-between;
  padding: 32px 0;
  align-items: center;
}

.objects-wrapper {
  display: flex;
  position: relative;
  // min-width: 560px;
  //margin-top: 12px;
  padding-left: 32px;
  flex-direction: column;
  width: 100%;
  min-height: 502px;
  background-color: #f4f5f7;
}
.price-history-log {
  text-decoration: underline;
  cursor: pointer;
  font-weight: bold;
}
.align-start {
  justify-content: flex-start;
  align-items: flex-start;
}

.align-center {
  justify-content: center;
  align-items: center;
}

.generate-doc-button {
  color: #ffffff;
  background: #000000;
  border: none;
  cursor: pointer;
  //padding: 12px 12px;
  font-size: 14px;
  width: 200px;
  height: 40px;
  font-family: Inter;
  text-transform: none;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.archive-label {
  padding: 7px 11px;
  height: 32px;
  color: #75787a;
  border-radius: 8px;
  border: 1px solid #75787a;
  font-size: 12px;
  line-height: 16px;
}

.deal-won-animation {
  height: 110%;
  width: 100%;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -55%);
}
.price-info-container {
  position: relative;
  display: flex;
  border-radius: 8px;
}
.price-info-dropdown {
  display: flex;
  align-items: center;
}

.price-info-container-hover:hover img {
  filter: invert(100%) sepia(0) saturate(0) hue-rotate(317deg) brightness(103%)
    contrast(102%);
}
.price-history-info-dropdown {
  box-shadow: rgba(0, 0, 0, 0.11) 0px 2px 8px, rgba(0, 0, 0, 0.11) 2px 1px 9px, rgba(0, 0, 0, 0.1) 0px 0px 0px;
  position: absolute;
  z-index: 8;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 8px;
  gap: 12px;
  padding: 16px;
  left: -20px;
}

.price-info {
  border-radius: 8px 8px 0 0;
  display: flex;
  gap: 8px;
  flex-wrap: nowrap;
  white-space: nowrap;
}
</style>
