var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    key: _vm.refresher,
    staticClass: "object-wrapper"
  }, [_vm.isOpen && _vm.view === 'listings' ? _c('Listings', {
    attrs: {
      "object-listings": _vm.object.listings,
      "listing-refresh-key": _vm.listingRefreshKey,
      "deal-identificator": _vm.dealId,
      "openHouse": _vm.openHouse,
      "is-project-inactive": _vm.isProjectInactive,
      "deal-pipeline-step": _vm.dealPipelineStep
    },
    on: {
      "noListings": function ($event) {
        return _vm.$emit('noListings');
      },
      "listingsExist": function ($event) {
        return _vm.$emit('listingsExist');
      },
      "noArchivedListings": function ($event) {
        return _vm.$emit('noArchivedListings');
      },
      "archivedListingsExist": function ($event) {
        return _vm.$emit('archivedListingsExist');
      }
    }
  }) : _vm._e(), _vm.isOpen && _vm.view === 'units' ? _c('Units', {
    attrs: {
      "project-type": _vm.projectType,
      "object": _vm.object,
      "refresh-key": _vm.refreshKey
    },
    on: {
      "refresh": function ($event) {
        _vm.$emit('refresh');
        _vm.unitsRefresher++;
      }
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }