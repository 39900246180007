<template>
  <div class="accordion-container" :style="accordionStyle">
    <div :style="headerStyle">
      <div class="accordion-header" @click="changeAccordionState()">
        <img
          :class="{
            'accordion-image-closed': !accordionStateActive(),
            'accordion-image-open': accordionStateActive(),
          }"
          alt=""
          src="../../assets/images/chevron_24_24.svg"
        />
        <div class="accordion-title-and-counter">
          <h5 v-if="!headingSmall">{{ title }}</h5>
          <h6 v-if="headingSmall">{{ title }}</h6>
          <div
            v-if="counter !== undefined"
            :class="{
              'accordion-counter-with-items':
                accordionCounterBackgroundRed && counter > 0,
            }"
            class="accordion-counter"
          >
            <p class="content-small">{{ counter }}</p>
          </div>
          <div v-if="toggleButton" id="toggle-div">
            <div class="toggle-wrapper">
              <img
                @click.stop="clickOnToggle"
                style="cursor: pointer"
                :src="
                  toggleStatus
                    ? require('../../assets/images/toggle-active.svg')
                    : require('../../assets/images/toggle-inactive.svg')
                "
                alt=""
              />
              <p @click.stop="clickOnToggle">{{ toggleText }}</p>
            </div>
          </div>
        </div>
      </div>
      <template v-if="showAccordionButton">
        <BaseButton
          @click="buttonPressed"
          :items="buttonDropdownItems"
          :display-key="buttonDisplayKey"
          :display-value="buttonValueKey"
          @itemSelected="itemSelected"
          v-if="buttonName"
          :size="'small'"
          :icon-left="buttonIcon"
          :label="buttonName"
        />
      </template>
    </div>
    <div v-if="accordionStateActive()">
      <slot />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import BaseButton from "@/components/common/BaseButton";

export default {
  name: "BaseAccordion",
  components: { BaseButton },
  props: [
    "title",
    "showBotDivider",
    "paddingTop",
    "paddingBottom",
    "parentName",
    "identifier",
    "counter",
    "counterBgColorRed",
    "buttonName",
    "buttonIcon",
    "buttonDropdownItems",
    "buttonDisplayKey",
    "buttonValueKey",
    "buttonOpensAccordion",
    "toggleButton",
    "toggleText",
    "toggleStatus",
    "showButton",
    "headingSmall",
  ],
  data() {
    return {
      open: false,
      headerStyle: {
        alignItems: "center",
        paddingTop: "32px",
        paddingBottom: "32px",
        display: "flex",
        flexWrap: "nowrap",
        width: "100%",
        gap: "4px",
      },
      accordionParent: "",
      accordionStyle: {},
      accordionCounterBackgroundRed: false,
      showAccordionButton: true,
    };
  },
  created() {
    this.initialize();
  },
  mounted() {},
  computed: {
    ...mapGetters([
      "projectAccordionsStates",
      "propertyAccordionsStates",
      "developmentAccordionsStates",
      "adminSettingsAccordionsStates",
      "brokerSettingsAccordionsStates",
    ]),
  },
  methods: {
    ...mapActions([
      "setAccordionState",
      "setPropertyAccordionState",
      "setDevelopmentAccordionState",
      "setAdminSettingsAccordionState",
      "setBrokerSettingsAccordionState",
    ]),
    initialize() {
      if (this.showBotDivider) {
        this.accordionStyle.borderBottom = "1px solid #E6E8EC";
      }
      if (this.paddingTop) {
        this.headerStyle.paddingTop = this.paddingTop;
      }
      if (this.paddingBottom) {
        this.headerStyle.paddingBottom = this.paddingBottom;
      }
      if (this.parentName) {
        this.accordionParent = this.parentName.toLowerCase();
      }
      if (this.counterBgColorRed) {
        this.accordionCounterBackgroundRed = true;
      }
      if (this.showButton === false) {
        this.showAccordionButton = false;
      }
    },
    clickOnToggle() {
      this.$emit("toggleAction");
    },
    changeAccordionState() {
      if (this.accordionParent === "project") {
        this.setAccordionState(this.identifier);
        return;
      }
      if (this.accordionParent === "property") {
        this.setPropertyAccordionState(this.identifier);
        return;
      }
      if (this.accordionParent === "developer") {
        this.setDevelopmentAccordionState(this.identifier);
      }
      if (this.accordionParent === "settings") {
        this.setAdminSettingsAccordionState(this.identifier);
      }
      if (this.accordionParent === "brokersettings") {
        this.setBrokerSettingsAccordionState(this.identifier);
      }
    },
    accordionStateActive() {
      if (
        this.accordionParent === "project" &&
        this.projectAccordionsStates[this.identifier]
      ) {
        this.headerStyle.paddingBottom = "24px";
        this.accordionStyle.paddingBottom = "32px";
        return true;
      }
      if (
        this.accordionParent === "property" &&
        this.propertyAccordionsStates[this.identifier]
      ) {
        this.headerStyle.paddingBottom = "24px";
        this.accordionStyle.paddingBottom = "32px";
        return true;
      }
      if (
        this.accordionParent === "developer" &&
        this.developmentAccordionsStates[this.identifier]
      ) {
        this.headerStyle.paddingBottom = "24px";
        this.accordionStyle.paddingBottom = "32px";
        return true;
      }
      if (
        this.accordionParent === "settings" &&
        this.adminSettingsAccordionsStates[this.identifier]
      ) {
        this.headerStyle.paddingBottom = "24px";
        this.accordionStyle.paddingBottom = "32px";
        return true;
      }
      if (
        this.accordionParent === "brokersettings" &&
        this.brokerSettingsAccordionsStates[this.identifier]
      ) {
        if (!this.paddingBottom) {
          this.accordionStyle.paddingBottom = "32px";
        }
        this.headerStyle.paddingBottom = "24px";
        return true;
      }
      if (!this.paddingBottom) {
        this.headerStyle.paddingBottom = "32px";
      } else {
        this.headerStyle.paddingBottom = this.paddingBottom;
      }
      this.accordionStyle.paddingBottom = "0px";
      return false;
    },
    buttonPressed() {
      if (this.buttonOpensAccordion && !this.accordionStateActive()) {
        this.changeAccordionState();
      }
      this.$nextTick(() => this.$emit("buttonPressed"));
    },
    itemSelected(item) {
      if (this.buttonOpensAccordion && !this.accordionStateActive()) {
        this.changeAccordionState();
      }
      this.$nextTick(() => this.$emit("dropdownItemSelected", item));
    },
  },
};
</script>

<style scoped>
.accordion-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.accordion-header {
  display: flex;
  flex-wrap: nowrap;
  height: 24px;
  gap: 4px;
  cursor: pointer;
  width: 100%;
}

.accordion-image-open {
  transform: rotate(180deg);
}

.accordion-image-closed {
  transform: rotate(0deg);
}

.accordion-title-and-counter {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  gap: 12px;
  align-items: center;
}

.accordion-title-and-counter h5 {
  line-height: unset;
}

.accordion-counter {
  height: 16px;
  min-width: 16px;
  border-radius: 6px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #75787a;
}

.accordion-counter p {
  color: #fff;
  font-weight: 600;
}

.accordion-counter-with-items {
  background: #ff1e24;
}
.toggle-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: 12px;
  cursor: pointer;
}
</style>
