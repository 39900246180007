var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "position": "relative"
    }
  }, [_c('div', {
    staticClass: "status-label content-bold",
    class: _vm.status + '-label',
    on: {
      "click": _vm.openDropdown
    }
  }, [_vm._v(" " + _vm._s(_vm.$lfMapper.status(_vm.status)) + " ")]), _vm.dropdownOpen ? _c('StatusDropdown', {
    attrs: {
      "listing": _vm.listing
    },
    on: {
      "closed": function ($event) {
        _vm.dropdownOpen = false;
      },
      "statusChanged": _vm.labelUpdated,
      "open-booking-modal": _vm.emitOpenBookingDetailsModal
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }