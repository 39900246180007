<template>
  <div style="position: relative">
    <div
        @click="openDropdown"
        class="status-label content-bold"
        :class="status + '-label'"
    >
      {{ $lfMapper.status(status) }}
    </div>
    <StatusDropdown
        v-if="dropdownOpen"
        @closed="dropdownOpen = false"
        @statusChanged="labelUpdated"
        @open-booking-modal="emitOpenBookingDetailsModal"
        :listing="listing" />
  </div>
</template>

<script>
import StatusDropdown from "@/components/Listings/StatusDropdown";
export default {
  name: "StatusLabel",
  components: {StatusDropdown},
  props: ["status", "listing", "disabled"],
  data() {
    return {
      dropdownOpen: false,
    }
  },
  methods: {
    openDropdown() {
      if (this.disabled) return;
      // this.$emit('onPress', event)
      this.dropdownOpen = true;
    },
    labelUpdated(data) {
      this.dropdownOpen = false;
      console.log(data)
      this.$emit('statusChanged', data)
    },
    emitOpenBookingDetailsModal(listing) {
      this.$emit('open-booking-modal', listing)
    }
  }
}
</script>

<style lang="scss" scoped>

.status-label {
  display: flex;
  padding: 2px 6px;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}

.status-label.booked-label {
  border-radius: 4px 0 0 4px !important;
}

</style>