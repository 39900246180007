<template>
  <div id="contract-overlay" :style="OverlayStyle">
    <div
        @click="closeSidepanels()" :style="sidewaysHeaderStyle" v-if="sidewaysHeader"
        class="contract-panel-header-sideways">
      <h4>{{ !contract ? $t('AddNewContract') : $t('EditContract') }}</h4>
    </div>
    <div v-else class="contract-panel" v-click-outside="($event) => closeModal($event)">
      <div v-if="contract && !editContract" class="contract-panel-header">
        <div style="display: flex; flex-direction: column; gap: 8px; width: 100%;">
          <h4> {{ contract?.number }}</h4>
        </div>
        <BaseButton @click="setupEditFields" :icon-left="'edit_16_16.svg'" :label="$t('Edit')" size="small"/>
      </div>
      <div v-else class="contract-panel-header-add">
        <h4>{{
            !contract ? $t('AddNewContract') : $t('EditContract')
          }}</h4>
      </div>
      <div v-if="contract && !editContract" class="contract-panel-content">
        <div class="contract-panel-content-info">
          <h5>{{ $t("MainInfo") }}</h5>
          <div class="contract-panel-content-info-details">
            <div class="contract-panel-content-info-row">
              <div class="content-normal" style="width: 50%; color: #75787A;">{{ $t('ContractType') }}</div>
              <div class="content-normal" style="width: 50%;">
                {{ formatContractType(contract.type) }}
              </div>
            </div>
             <div class="contract-panel-content-info-row">
              <div class="content-normal" style="width: 50%; color: #75787A;">{{ $t('ContractNumber') }}</div>
              <div class="content-normal" style="width: 50%;">{{ contract.number }}</div>
            </div>
             <div class="contract-panel-content-info-row">
              <div class="content-normal" style="width: 50%; color: #75787A;">{{ $t('StartDate') }}</div>
              <div class="content-normal" style="width: 50%;">{{ formatDate(contract.startDate) }}</div>
            </div>
             <div class="contract-panel-content-info-row">
              <div class="content-normal" style="width: 50%; color: #75787A;">{{ $t('EndDate') }}</div>
              <div class="content-normal" style="width: 50%;">{{ formatDate(contract.endDate) }}</div>
            </div>
            <div class="contract-panel-content-info-row">
              <div class="content-normal" style="width: 50%; color: #75787A;">{{ $t('Client') }}</div>
              <div class="content-normal" style="width: 50%; display: flex; flex-direction: column">
                <div>
                  {{ contract.client?.name }}
                </div>
                <div>
                  {{ contract.client?.registryCode }}
                </div>
                <div>
                  {{ contract.client?.personalCode }}
                </div>
                <div>
                  {{ contract.client?.address }}
                </div>
              </div>
            </div>
            <div class="contract-panel-content-info-row">
              <div class="content-normal" style="width: 50%; color: #75787A;">{{ $t('Representative') }}</div>
              <div class="content-normal" style="width: 50%;">{{ this.$t(contract.representationBasis.charAt(0).toUpperCase() + contract.representationBasis.slice(1)) }}</div>
            </div>

            <div class="contract-panel-content-info-row">
              <div class="content-normal" style="width: 50%; color: #75787A;">{{ $t('ServiceFee') }}</div>
              <div class="content-normal" style="width: 50%;">{{ $nMapper.price(contract.brokerageFee, "€") }}</div>
            </div>

            <div v-if="contract.comment" class="contract-panel-content-info-row">
              <div class="content-normal" style="width: 50%; color: #75787A;">{{ $t('Comment') }}</div>
              <div class="content-normal" style="width: 50%;">{{ contract.comment }}</div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="contract-panel-content-add">
        <div class="contract-panel-content-add-project">
          <div style="display: flex; flex-direction: column; gap: 12px">
              <BaseDropdown
                :placeholder="$t('ContractType')"
                v-model="contractType"
                :dropdown-offset-y="60"
                :show-dropdown-arrow="true"
                type="dropdown"
                :items="contractTypes"
                :required="true"
                :error-message="ContractType.errorMessage"
                :error="ContractType.showError"
            />
            <BaseInput
                :placeholder="$t('ContractNumber')"
                v-model="contractNumber"
                type="text"
                :required="true"
                :error-message="ContractNumber.errorMessage"
                :error="ContractNumber.showError"
            />
        <div>
          <v-row class="edit-start-date-row">
            <BaseDropdown
              icon-left="calendar_20_20.svg"
              :id="'lease-start-date'"
              :placeholder="$t('StartDate')"
              v-model="contractStartDate"
              :show-dropdown-arrow="true"
              type="date"
            >
            </BaseDropdown>
           <BaseDropdown
              icon-left="calendar_20_20.svg"
              :id="'lease-end-date'"
              :placeholder="$t('EndDate')"
              v-model="contractEndDate"
              :show-dropdown-arrow="true"
              type="date"
            >
            </BaseDropdown>
          </v-row>
        </div>
          </div>
        </div>
        <div :key="'client-section-' + clientUpdater" class="contract-panel-content-add-client">
          <h5>{{ $t('PartyToContract') }}</h5>
          <div>
            <v-radio-group row v-model="contractPartyType">
              <v-radio
                  v-for="item in clientTypes"
                  :key="'radio' + item._id"
                  :label="item.title"
                  color="orange darken-3"
                  class="content"
                  :value="item.value"
                  row
              >
              </v-radio>
            </v-radio-group>
            <div style="display: flex; flex-direction: column; gap: 12px;" v-if="contractPartyType === 'private'">
              <ClientListSearch
                  :update-component="clientSearchUpdater"
                  @itemChosen="selectClient"
                  @addNew="openClientAddSidepanel"
                  :icon-left="'search20.svg'"
                  :required="true"
                  v-model="contractClientSearch"
                  :error-message="ClientError.errorMessage"
                  :error="ClientError.showError"
                  :placeholder="$t('FullName')"
              />
              <BaseInput
                  :placeholder="$t('Address')"
                  v-model="contractClient.private.address"
                  type="text"
              />
            </div>
            <div :key="clientUpdater" style="display: flex; flex-direction: column; gap: 12px;" v-else>
              <BaseInput
                  :placeholder="$t('FullName')"
                  v-model="contractClient.company.name"
                  :required="true"
                  :error-message="ClientError.errorMessage"
                  :error="ClientError.showError"
                  type="text"
              />
              <BaseInput
                  :placeholder="$t('RegistrationNumber')"
                  v-model="contractClient.company.registryCode"
                  type="text"
              />
              <BaseInput
                  :placeholder="$t('Address')"
                  v-model="contractClient.company.address"
                  type="text"
              />
            </div>
          </div>
          <h5>{{ $t('RepresentsWhichBasis') }}</h5>
            <v-radio-group row v-model="contractRepresentationBasis">
              <v-radio
                  v-for="item in contractRepresentations"
                  :key="'radio' + item._id"
                  :label="item.title"
                  color="orange darken-3"
                  class="content"
                  :value="item.value"
                  row
              >
              </v-radio>
            </v-radio-group>
        </div>
        <div class="contract-panel-content-add-project">
          <h5>{{ $t("BrokerageFee") }}</h5>
          <div class="contract-brokerage">
           <v-radio-group row v-model="contractBrokerageFeeType">
              <v-radio
                  v-for="item in brokerageTypes"
                  :key="'radio' + item._id"
                  :label="item.title"
                  color="orange darken-3"
                  class="content"
                  :value="item.value"
                  row
              >
              </v-radio>
            </v-radio-group>
            <div
                v-if="contractBrokerageFeeType === 'agreedPrice'"
                style="gap: 12px; display: flex; flex-direction: column;"
                class="edit-brokerage-fee-row"
            >
              <v-row style="display: flex; width: 100%; margin-bottom:12px">
                <BaseInput
                    :id="'brokerage-fee-field'"
                    :placeholder="$t('BrokerageFee')"
                    icon-right="euroSign.svg"
                    type="number"
                    :format="'price'"
                    v-model="contractBrokerageFee"
                />
              </v-row>
            </div>
            <div style="gap:12px; display: flex; margin-bottom:12px"
                v-else-if="contractBrokerageFeeType === 'percentOfPrice'"
            >
              <BaseInput
                  type="number"
                  :format="'price'"
                  :id="'deal-amount-percent'"
                  icon-right="percentage.svg"
                  :placeholder="$t('Percentage')"
                  v-model="contractBrokerFeePercentage"
              />
              <BaseInput
                  type="number"
                  :format="'price'"
                  :id="'deal-amount-min-fee'"
                  :placeholder="$t('MinimumFee')"
                  icon-right="euroSign.svg"
                  v-model="contractMinimumFee"
              />
            </div>

             <div style="margin-bottom:12px"> 
                <BaseInput
                  type="number"
                  :format="'price'"
                  :id="'deal-amount-deal-value'"
                  :placeholder="$t('DealValue')"
                  icon-right="euroSign.svg"
                  v-model="contractDealValue"
              />
             </div>
              <BaseDropdown
                  icon-left="calendar_20_20.svg"
                  :id="'transaction-date'"
                  :placeholder="$t('TransactionDate')"
                  v-model="contractTransactionDate"
                  :show-dropdown-arrow="true"
                  type="date"
              >
              </BaseDropdown>
          </div>
        </div>
        <div class="contract-panel-content-add-client">
          <h5>{{ $t('Comment') }}</h5>
            <div class="comment-text-wrapper">
            <BaseTextarea
                :id="'contract-comment'"
                v-model="contractComment"
            />
            </div>
        </div>
       
      </div>
      <div v-if="!contract || (contract && editContract)"
           :style="contract && editContract ? 'justify-content: space-between;' : 'justify-content: flex-end;'"
           class="contract-panel-add-footer">
        <div style="justify-self: flex-start" v-if="contract && editContract">
          <BaseButton
              size="large"
              :label="$t('Delete')"
              :icons-style="'width: 16px; height: 16px;'"
              :label-color="'#ff1e24'"
              :border-color="'#ff1e24'"
              @click="deleteContract"
              icon-left="red_trash_16_16.svg"
          />
        </div>
        <div style="display: flex; gap: 16px;">
          <BaseButton
              size="large"
              :label="$t('Cancel')"
              @click="closeModal"
          >
          </BaseButton>
          <BaseButton
              :icon-left="'checkmark_white_16_16.svg'"
              size="large"
              primary="true"
              :label="$t('Save')"
              @click="saveButtonClicked"
          >
          </BaseButton>
        </div>
      </div>
    </div>
    <DeleteModal
        v-if="contractDeleteModalOpen"
        @approved="deleteContractConfirmed"
        @canceled="contractDeleteModalOpen = false"
    />
    <AddModal
        @newAdded="newClientAdded"
        @closed="closeClientAddSidepanel"
        :not-contact-view-route="true"
        v-if="clientAddSidepanelOpen"
    />
  </div>
</template>

<script>
import BaseButton from "@/components/common/BaseButton";
import BaseDropdown from "@/components/common/BaseDropdown";
import moment from "moment";
import BaseInput from "@/components/common/BaseInput";
import nanoid from "nanoid";
import ClientListSearch from "@/components/common/ClientListSearch";
import {mapActions, mapGetters} from "vuex";
import DeleteModal from "@/components/common/DeleteModal";
import AddModal from "@/components/Kliendiregister/AddModal";
import BaseTextarea from "@/components/common/BaseTextarea.vue";
import axios from "axios";


export default {
  name: "ContractSidepanelAdd",
  components: {
    AddModal,
    DeleteModal,
    ClientListSearch, BaseInput, BaseButton, BaseDropdown, BaseTextarea
  },
  props: ["contract", "project", "developmentView"],
  data() {
    return {
      OverlayStyle: {
        background: "#0000001a",
      },
      editContract: false,
      contractClient: {
        private: {
          name: "",
          _id: "",
          contactId: "",
          registryCode: "",
          personalCode: "",
          address: "",
        },
        company: {
          name: "",
          registryCode: "",
          personalCode: "",
          address: "",
        },
      },
        contractPartyType: "private",
        contractClientSearch: null,
        contractDate: null,
        contractBrokerId: null,
        contractBrokerageFeeType: "percentOfPrice",
        contractBrokerageFee: null,
        contractBrokerFeePercentage: null,
        contractMinimumFee: null,
        contractDealValue: null,
        contractTransactionDate: null,
        contractComment: "",
        contractType: null,
        contractNumber: null,
        contractStartDate: null,
        contractEndDate: null,
        contractRepresentationBasis: "authorization",
        contractCreatedAt: null,
      contractTypes: [
        {name: this.$t("OralContractExclusiveRepresentation"), value: "oral_exclusive_representation"},
        {name: this.$t("WrittenContractExclusiveRepresentation"), value: "written_exclusive_representation"},
        {name: this.$t("OralContractRepresentation"), value: "oral_contract_representation"},
        {name: this.$t("WrittenContractRepresentation"), value: "written_contract_representation"},
        {name: this.$t("RentalRepresentationContract"), value: "rental_representation_contract"},
        {name: this.$t("PurchasingRepresentationContract"), value: "purchasing_representation_contract"},
        {name: this.$t("DevelopmentProjectContract"), value: "development_project_contract"},
      ],
      clientTypes: [
        {
          _id: nanoid(8),
          value: "private",
          title: this.$t("PrivatePerson.Label"),
        },
        {
          _id: nanoid(8),
          value: "company",
          title: this.$t("Company"),
        },
      ],
      brokerageTypes: [
        {
          _id: nanoid(8),
          value: "percentOfPrice",
          title: this.$t("PercentageOfFinalPrice"),

        },
        {
          _id: nanoid(8),
          value: "agreedPrice",
          title: this.$t("AgreedAmount"),
        },
      ],
      contractRepresentations: [ {
          _id: nanoid(8),
          value: "authorization",
          title: this.$t("Authorization"),
        },
        {
          _id: nanoid(8),
          value: "Law",
          title: this.$t("Law"),
        },],
      colleagues: [],
      clientUpdater: 0,
      clientSearchUpdater: 0,
      contractDeleteModalOpen: false,
      clientAddSidepanelOpen: false,
      saveClicked: false,
      ClientError: {
        showError: false,
        errorMessage: ""
      },
      ContractNumber: {
        showError: false,
        errorMessage: "",
      },
      ContractType: {
        showError: false,
        errorMessage: "",
      },
      sidewaysHeader: false,
      sidewaysHeaderStyle: {},
      initialStartDate: ''
    }
  },
  created() {
     if (!this.contract) {
      this.contractCreatedAt = moment().format();
      this.clientUpdater++;
    } else if (this.contract.client._id) {
      this.contractPartyType = "private";
    } else {
      this.contractPartyType = "company";
    }
    if (this.contract && this.contract.startDate) {
      this.initialStartDate = this.contract.startDate
    }

    if (!this.editContract && this.project) {
      if (!this.project.brokerageFeeType) {
        this.contractBrokerageFeeType = 'percentOfPrice';
      }
      this.contractBrokerageFee = this.project.brokerageFee;
      this.contractBrokerFeePercentage = this.project.brokerFeePercentage;
      this.contractMinimumFee = this.project.minimumFee;
      this.contractDealValue = this.project.amount;
      this.contractTransactionDate = this.project.transactionDate;


      if (this.project.client) {
        let projectClientInfo = this.project.client;
        this.contractClient.private._id = projectClientInfo._id;
        this.contractClient.private.contactId = projectClientInfo.contactId;
        this.contractClient.private.name = projectClientInfo.contactName;

        this.contractClient.company.name = projectClientInfo.company_name;
        this.contractClient.company.registryCode = projectClientInfo.reg_code ? projectClientInfo.reg_code : projectClientInfo.regCode;
        this.contractClientSearch = this.contractClient.private;
        this.contractClientSearch.contactName = this.contractClient.private.name; 
      }
    }
    this.setupColleagues();
  },
  computed: {
    ...mapGetters(["customers", "user", "users"]),
  },
  watch: {
    contractPartyType() {
      this.ClientError = {
        showError: false,
        errorMessage: ""
      }
    },
    "contractClient.private.name": function () {
      this.checkForErrors();
    },
    "contractClient.company.name": function () {
      this.checkForErrors();
    },
    contractNumber: function () {
      if (this.ContractNumber.showError) this.checkForErrors(false);
    },
    contractType: function () {
      if (this.ContractType.showError) this.checkForErrors(false);
    },
    contractClientSearch(value) {
      if (!value) {
        if (this.contractClient.private._id && this.contractClient.private.name) {
          this.contractClientSearch = this.contractClient.private;
        }

      }
      this.clientSearchUpdater++;
    }
  },
  methods: {
    ...mapActions(["getCustomers"]),
    async setupColleagues() {
      const loggedUserGroupIds = [];
      this.user.groups.forEach((group) => {
        loggedUserGroupIds.push(group._id);
      })
      this.users.forEach((user) => {
        if (!user.groups.find((group) => loggedUserGroupIds.includes(group._id))) {
          return;
        }
        const formattedUser = {
          value: user._id,
          name: user.name,
        };
        this.colleagues.push(formattedUser);
      });
      this.colleagues.sort((a, b) => a.name.localeCompare(b.name));

      if (!this.contractBrokerId) {
        if (this.contract) {
          if (this.contract.broker) {
            this.contractBrokerId = this.contract.broker.userId;
          } else {
            this.contractBrokerId = this.contract.creator.userId;
          }
        } else {
          this.contractBrokerId = this.user._id;
        }
      }

    },
    formatContractType(type) {
     if (type == "oral_exclusive_representation") {
        return this.$t("OralContractExclusiveRepresentation");
      } else if (type == "written_exclusive_representation") {
        return this.$t("WrittenContractExclusiveRepresentation");
      } else if (
          type === "oral_contract_representation"
      ) {
        return this.$t("OralContractRepresentation");
      } else if (
          type === "written_contract_representation"
      ) {
        return this.$t("WrittenContractRepresentation");
      } else if (type == "rental_representation_contract") {
        return this.$t("RentalRepresentationContract");
      }
      else if (type == "purchasing_representation_contract") {
        return this.$t("PurchasingRepresentationContract");
      }
      else if (type == "development_project_contract") {
        return this.$t("DevelopmentProjectContract");
      }
    },
    closeModal(event = null) {
      if (this.contractDeleteModalOpen || (event && event.target?.id === 'cancel-button')) {
        return;
      }

      if (this.clientAddSidepanelOpen) {
        return;
      }

      if (this.editContract) {
        this.editContract = false;
        return;
      }

      if (!event) {
        this.$emit("closePanel");
      }

      if (event.target.id !== "contract-overlay") return;
      this.$emit("closePanel");
    },
    checkForErrors(checkClick = true) {
      if (checkClick && !this.saveClicked) return;
      if (!this.contractNumber) {
        this.ContractNumber.errorMessage = this.$t("FormErrors.FieldRequired");
        this.ContractNumber.showError = true;
      } else {
        this.ContractNumber.errorMessage = "";
        this.ContractNumber.showError = false;
      }
      if (!this.contractType) {
        this.ContractType.errorMessage = this.$t("FormErrors.FieldRequired");
        this.ContractType.showError = true;
      } else {
        this.ContractType.errorMessage = "";
        this.ContractType.showError = false;
      }
      if (this.contractPartyType === 'private') {
        if (!this.contractClient.private._id && !this.contractClient.private.name) {
          this.ClientError.errorMessage = this.$t("FormErrors.FieldRequired");
          this.ClientError.showError = true;
        } else {
          this.ClientError.errorMessage = "";
          this.ClientError.showError = false;
        }
      } else if (this.contractPartyType === 'company') {
        if (!this.contractClient.company.name) {
          this.ClientError.errorMessage = this.$t("FormErrors.FieldRequired");
          this.ClientError.showError = true;
        } else {
          this.ClientError.errorMessage = "";
          this.ClientError.showError = false;
        }
      }
      return !!(this.ContractNumber.showError || this.ClientError.showError || this.ContractType.showError);
    },
    formatDate(date) {
      return date ? moment(date).format("DD.MM.YYYY") : "-";
    },
    async newClientAdded(clientId) {
      if (!clientId) return;
      await this.getCustomers();
      const client = this.customers.find(customer => customer.customerId === clientId);
      this.contractClient.private._id = client._id;
      this.contractClient.private.contactId = client.customerId;
      this.contractClient.private.name = client.firstName + " " + client.lastName;
      this.contractClient.private.personalCode = client.personalCode;
      this.contractClient.private.address = client.address;

      this.contractClientSearch = this.contractClient.private;
      this.contractClientSearch.contactName = this.contractClient.private.name;
    },
    selectClient(client) {
      if (!client) return;
      this.contractClient.private._id = client._id;
      this.contractClient.private.contactId = client.customerId;
      this.contractClient.private.name = client.firstName + " " + client.lastName;
      this.contractClient.private.personalCode = client.personalCode;
      this.contractClient.private.address = client.address;
    },
    openClientAddSidepanel() {
      this.OverlayStyle.background = "#00000000";
      this.sidewaysHeaderStyle.right = "640px";
      this.sidewaysHeaderStyle.zIndex = "12";
      this.sidewaysHeader = true;
      this.clientAddSidepanelOpen = true;
    },
    closeClientAddSidepanel() {
      this.OverlayStyle.background = "#0000001a";
      this.sidewaysHeader = false;
      this.clientAddSidepanelOpen = false;
    },
    closeSidepanels() {
      this.OverlayStyle.background = "#0000001a";
      this.clientAddSidepanelOpen = false;
      this.sidewaysHeader = false;
    },
    saveButtonClicked() {
      this.saveClicked = true;
      if (!this.editContract) {
        this.createNewContract();
      } else {
        this.editCurrentContract();
      }
      this.saveClicked = false;
    },
    async createNewContract() {
      const selectedBroker = this.users.find((user) => user._id === this.contractBrokerId);
      const brokerObject = {
        name: selectedBroker.name,
        userId: selectedBroker._id
      }
      let endStateItem = null;
      if (!this.developmentView) {
        if (this.project.endState && this.project.endState.length === 1) {
        endStateItem = this.project.endState[0]
        }
        else if (this.project.endState && this.project.endState.length > 1) {
          endStateItem = this.project.endState[this.project.endState.length - 1]
        } else {
          endStateItem = null;
        }
      }
    
      const newContractObject = {
        _id: nanoid(16),
        creator: {
          name: this.user.name,
          userId: this.user._id,
          companyId: this.user.companyId
        },
        projectId: this.project._id,
        endState: endStateItem,
        client: this.contractClient[this.contractPartyType],
        broker: brokerObject,
        type: this.contractType,
        number: this.contractNumber,
        startDate: this.contractStartDate,
        endDate: this.contractEndDate,
        representationBasis: this.contractRepresentationBasis,
        createdAt: this.contractCreatedAt,
        brokerageFeeType: this.contractBrokerageFeeType,
        brokerageFee: this.contractBrokerageFee,
        brokerFeePercentage: this.contractBrokerFeePercentage,
        minimumFee: this.contractMinimumFee,
        comment: this.contractComment,
        transactionDate: this.contractTransactionDate,
        dealValue: this.contractDealValue
      }

      if (this.checkForErrors()) {
        return;
      }
      let projectId = this.project._id;
      let newContract = newContractObject;
      let response
      if (!this.developmentView) {
        response = await axios.post("/api/project/contract/add", {newContract, projectId});
      } else {
        response = await axios.post("/api/development/contract/add", {newContract, projectId});
      }
      if (response && response.status === 200) {
        this.$emit('newContractAdded')
        this.toastSuccess(this.$t("Contract") + " " + this.$t("Created").toLowerCase())
        this.$emit('closePanel');
      } else {
        this.toastError(this.$t("ErrorCreating"))
      }
    },
    
   async editCurrentContract() {
      const selectedBroker = this.users.find((user) => user._id === this.contractBrokerId);
      const brokerObject = {
        name: selectedBroker.name,
        userId: selectedBroker._id
      }
      let endStateItem = null;
      if (!this.developmentView) {
        if (this.project.endState && this.project.endState.length === 1) {
          endStateItem = this.project.endState[0]
        }
        else if (this.project.endState && this.project.endState.length > 1) {
          endStateItem = this.project.endState[this.project.endState.length - 1]
        } else {
          endStateItem = null;
        }
      }
      const updatedContractObject = {
        _id: this.contract._id,
        projectId: this.project._id,
        endState: endStateItem,
        client: this.contractClient[this.contractPartyType],
        broker: brokerObject,
        creator: this.contract.creator,
        type: this.contractType,
        number: this.contractNumber,
        startDate: this.contractStartDate,
        endDate: this.contractEndDate,
        representationBasis: this.contractRepresentationBasis,
        createdAt: this.contractCreatedAt,
        brokerageFeeType: this.contractBrokerageFeeType,
        brokerageFee: this.contractBrokerageFee,
        brokerFeePercentage: this.contractBrokerFeePercentage,
        minimumFee: this.contractMinimumFee,
        comment: this.contractComment,
        transactionDate: this.contractTransactionDate,
        dealValue: this.contractDealValue
      }

      if (this.checkForErrors()) {
        return;
      }
      let body = {
        contractId: this.contract._id,
        projectId: this.project._id,
        updatedContractObject: updatedContractObject,
        previousStartDate: this.initialStartDate
       }
      this.initialStartDate = this.contract.startDate
      let response
      if (!this.developmentView) {
        response = await axios.post("/api/project/contract/edit", body);
      } else {
        response = await axios.post("/api/development/contract/edit", body);
      }
      if (response && response.status === 200) {
        this.$emit("contractUpdated", response.data)
        this.editContract = false;
        this.toastSuccess(this.$t("Contract") + " " + this.$t("Updated").toLowerCase())
      } else {
        this.toastError(this.$t("ErrorUpdating"))
      }
    },
    async setupEditFields() {
      this.editContract = true;
      const contract = JSON.parse(JSON.stringify(this.contract))
      this.contractType = contract.type;
      this.contractNumber = contract.number;
      this.contractStartDate = contract.startDate;
      this.contractEndDate = contract.endDate;
      this.contractRepresentationBasis = contract.representationBasis;
      this.contractBrokerageFeeType = contract.brokerageFeeType;
      this.contractMinimumFee = contract.minimumFee;
      this.contractComment = contract.comment;
      this.contractTransactionDate = contract.transactionDate;
      this.contractDealValue = contract.dealValue;
      this.contractBrokerFeePercentage = contract.brokerFeePercentage;
      this.contractBrokerageFee = contract.brokerageFee;
      if (contract.client._id) {
        this.contractClient.private = contract.client;
        this.contractClientSearch = contract.client;
      } else {
        this.contractClient.company = contract.client;
      }
    },
    deleteContract() {
      this.contractDeleteModalOpen = true;
    },
    async deleteContractConfirmed() {
      this.contractDeleteModalOpen = false;
      let body = {
        contractId: this.contract._id,
        projectId: this.project._id,
        contractType: this.contract.type,
        contractStartDate: this.contract.startDate
      };
      let response
      if (!this.developmentView) {
        response = await axios.post("/api/project/contract/remove", body)
      } else {
        response = await axios.post("/api/development/contract/remove", body)
      }
       if (response && response.status === 200) {
          this.toastSuccess(this.$t("Contract") + " " + this.$t("Deleted").toLowerCase())
          this.$emit('closePanel')
          this.$emit('contractDeleted');
        } else {
          this.toastError(this.$t("ErrorDeleting"))
        }
    },
  }
}
</script>

<style lang="scss" scoped>
@import "src/global_variables";

#contract-overlay {
  position: fixed;
  display: flex;
  justify-content: flex-end;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 7;
  width: unset;
  left: 0;
}

.contract-panel {
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  bottom: 0;
  background-color: #ffffff;
  z-index: 8;
  width: 640px;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.contract-panel-header {
  height: 96px;
  padding: 40px 32px 32px 32px;
  background-color: white;
  display: flex;
  gap: 8px;
  border-bottom: 1px solid #E6E8EC;
}

.contract-panel-content {
  padding: 32px 72px 0 32px;
}

.contract-panel-content-info {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.contract-panel-content-info-details {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.contract-panel-content-info-row {
  display: flex;
  flex-wrap: nowrap;
}

.contract-panel-content-payments {
  padding-top: 24px;
  padding-bottom: 72px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.contract-panel-header-add {
  position: fixed;
  width: 640px;
  z-index: 100;
  height: 96px;
  padding: 40px 32px 32px 32px;
  background-color: white;
  border-bottom: 1px solid #E6E8EC;
}

.contract-panel-content-add-project {
  display: flex;
  flex-direction: column;
  gap: 24px;
  background-color: #FAFAFA;
  padding: 32px 72px 32px 32px;
}

.contract-panel-content-add-client {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 32px 72px 10px 32px;
}

.contract-panel-content-add {
  height: 100%;
  padding-top: 97px;
  padding-bottom: 113px;
  overflow-y: scroll;
}

.contract-panel-add-footer {
  display: flex;
  position: fixed;
  width: 640px;
  bottom: 0;
  background-color: white;
  gap: 16px;
  padding: 32px 32px 40px 32px;
  height: 112px;
  border-top: 1px solid #E6E8EC;
}

.contract-panel-header-sideways {
  width: 98px;
  height: 100%;
  background-color: white;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 48px 24px 24px;
  cursor: pointer;
  border-right: 1px solid $grey500;

  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.contract-panel-header-sideways h4 {
  transform: rotate(180deg);
  white-space: nowrap;
  overflow: hidden;
  align-self: center;
  justify-self: center;
  writing-mode: vertical-lr;
  top: 0;
  left: 0;
}

.edit-start-date-row,
.edit-contract-row {
  gap: 12px;
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
}
.comment-text-wrapper{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 536px;
  border-radius: 8px;
  margin-bottom: 24px;
}
</style>