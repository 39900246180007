<template>
  <div style="height: 100%; width: 100%">
    <div
      @click="clickInput"
      :style="customStyle"
      :id="id"
      class="input-container"
      :class="{
        'input-container-empty': !inputValid(),
        'input-container-value': inputValid(),
        required: required,
        'input-container-error': showError,
      }"
    >
      <input
        v-if="type !== 'map' && !disabled"
        @focusin="setPlaceholderLineWidth"
        @focusout="inputValid"
        :type="type"
        class="input-field"
        :id="id + '-input'"
        name="name"
        v-model="value"
      />
      <GmapAutocomplete
        @input="mapInput($event)"
        @focus="setPlaceholderLineWidth"
        @focusout="inputValid"
        v-if="type === 'map' || view === 'organization-add'"
        :class="{ hidden: disabled }"
        @place_changed="setPlace"
        placeholder=""
        :id="id + '-input'"
        class="input-field"
        v-model="value"
      />
      <input
        v-if="disabled"
        :class="{
          'large-text': this.size === 'large',
          'small-text': this.size === 'small',
        }"
        disabled
        class="input-field"
        v-model="value"
      />

      <label
        :id="id + '-label'"
        v-if="placeholder"
        :class="{
          'large-placeholder': this.size.toLowerCase() === 'large',
          'small-placeholder': this.size.toLowerCase() === 'small',
        }"
        style="overflow: hidden"
        >{{ placeholder }}</label
      >
      <span
        :id="id + '-label-background'"
        class="input-container-label-background"
      ></span>
      <img
        v-if="suffixIcon"
        :src="require(`@/assets/images/` + suffixIcon)"
        alt=""
      />
    </div>
    <div
      style="color: #ff1e24; margin-top: 4px"
      v-if="showError && errorMessage"
    >
      {{ $t("FormErrors." + errorMessage) }}
    </div>
    <div
      :id="id + '-visibility'"
      style="visibility: hidden; position: absolute; display: inline-flex"
    ></div>
  </div>
</template>

<script>
export default {
  name: "Input",
  props: [
    "id",
    "styles",
    "size",
    "placeholder",
    "suffixIcon",
    "type",
    "prefilledValue",
    "required",
    "showError",
    "errorMessage",
    "disabled",
    "view",
  ],
  created() {},
  mounted() {
    this.getPlaceholderSize();
    this.initializeInput();
    if (this.prefilledValue) {
      const inputContainer = document.getElementById(this.id);
      inputContainer.style.border = "1px solid #E6E8EC";
    }
  },
  data() {
    return {
      value: "",
      customStyle: "",
      sizes: {
        small: {
          display: "flex",
          height: "32px",
          padding: "7px 11px",
          width: "99px",
        },
        large: {
          display: "flex",
          height: "56px",
          padding: "7px 11px",
          width: "100%",
        },
      },
      placeholderLineWidthSet: false,
      placeholderLineWidth: "",
      mapAutoCompleteOptions: null,
      mapPlaceSelectedLength: undefined,
    };
  },
  watch: {
    value() {
      if (
        this.type === "map" &&
        this.mapPlaceSelectedLength !== this.value.length &&
        !this.disabled
      ) {
        this.mapPlaceSelectedLength = undefined;
        this.$emit("removeObject");
      }
      this.inputValid();
    },
    showError() {
      const whiteLine = document.getElementById(this.id + "-label-background");
      const inputContainer = document.getElementById(this.id);
      this.placeholderLineWidthSet = true;
      if (this.showError) {
        whiteLine.style.height = "2px";
        whiteLine.style.top = "-2px";
        inputContainer.style.border = "2px solid #ff1e24";
      } else {
        whiteLine.style.height = "2px";
        whiteLine.style.top = "-2px";
        inputContainer.style.border = "1px solid #E6E8EC";
      }
    },
  },
  methods: {
    initializeInput() {
      if (this.styles) {
        this.customStyle = this.styles;
      }
      if (this.size.toLowerCase() === "small") {
        this.customStyle = this.sizes.small;
      }
      if (this.size.toLowerCase() === "large") {
        this.customStyle = this.sizes.large;
      }
      if (this.prefilledValue) {
        this.value = this.prefilledValue;
        this.setPlaceholderLineWidth();
      }
      if (this.type === "map") {
        this.mapAutoCompleteOptions = {
          componentRestrictions: {
            country: ["fi"],
          },
        };
      }
    },
    inputValid(ignoreFocus) {
      const inputContainer = document.getElementById(this.id);
      if (ignoreFocus) {
        if (inputContainer) {
          if (this.showError) {
            inputContainer.style.border = "2px solid #ff1e24";
          } else {
            inputContainer.style.border = "1px solid #E6E8EC";
          }
        }
      }
      if (this.type !== "map") {
        this.$emit("value", this.value);
      }
      if (this.value === "" || /^\s*$/.test(this.value)) {
        if (this.placeholder) {
          if (inputContainer) {
            if (!inputContainer.classList.contains("input-container-value")) {
              const whiteLine = document.getElementById(
                this.id + "-label-background"
              );
              if (whiteLine) {
                whiteLine.style.width = "0px";
                this.placeholderLineWidthSet = false;
              }
            }
          }
        }
        return false;
      }
      return true;
    },
    setPlaceholderLineWidth() {
      const container = document.getElementById(this.id);
      if (this.showError) {
        container.style.border = "2px solid #ff1e24";
      } else {
        container.style.border = "2px solid #E6E8EC";
      }
      if (this.placeholder && !this.placeholderLineWidthSet) {
        const labelWidth = this.placeholderLineWidth;
        const whiteLine = document.getElementById(
          this.id + "-label-background"
        );
        whiteLine.style.width = labelWidth + "px";
        this.placeholderLineWidthSet = true;
        whiteLine.style.height = "2px";
        whiteLine.style.top = "-2px";
      }
    },
    clickInput() {
      if (this.disabled) {
        return;
      }
      const inputElement = document.getElementById(this.id + "-input");
      inputElement.focus();
      this.setPlaceholderLineWidth();
    },
    setPlace(place) {
      const formatedPlaceObject = this.formatPlace(place);
      this.mapPlaceSelectedLength = place.formatted_address.length;
      this.value = place.formatted_address;
      this.$emit("value", formatedPlaceObject);
    },
    formatPlace(place) {
      const newPlace = {};
      place.address_components.forEach((component) => {
        if (component.types.includes("locality")) {
          newPlace["city"] = component.long_name;
        }
        if (component.types.includes("route")) {
          newPlace["street"] = component.long_name;
        }
        if (component.types.includes("postal_code")) {
          newPlace["postalCode"] = component.long_name;
        }
        if (component.types.includes("street_number")) {
          newPlace["houseNumber"] = component.long_name;
        }
      });
      newPlace["coordinates"] = {
        lng: place.geometry.location.lng(),
        lat: place.geometry.location.lat(),
      };
      newPlace["address"] = place.formatted_address;
      return newPlace;
    },
    mapInput(e) {
      this.value = e.target.value;
    },
    getPlaceholderSize() {
      const placeholder = document.getElementById(this.id + "-visibility");
      placeholder.textContent = this.placeholder;
      placeholder.style.fontSize = "12px";
      if (!this.required) {
        this.placeholderLineWidth = placeholder.clientWidth + 4;
      } else {
        this.placeholderLineWidth = placeholder.clientWidth + 16;
      }
      placeholder.style.fontSize = "";
      placeholder.style.display = "none";
    },
  },
};
</script>

<style scoped>
.input-container {
  border: 1px solid #e6e8ec;
  border-radius: 8px;
  position: relative;
  cursor: text;
}

.input-container-error {
  border: 2px solid #ff1e24;
}

.input-container label {
  top: 25%;
  width: fit-content;
  position: relative;
  overflow: hidden;
  cursor: text;
  z-index: 2;
}

.input-container .large-placeholder {
  font-size: 16px;
}

.input-container .small-placeholder {
  font-size: 12px;
}

.input-container-empty .large-placeholder {
  font-size: 16px;
  color: #75787a;
}

.input-container-empty .small-placeholder {
  font-size: 10px;
}

.input-container-empty input:focus + .large-placeholder {
  font-size: 12px;
  color: #75787a;
}

.input-container-empty input:focus + .small-placeholder {
  font-size: 10px;
}

.input-container-value input + .large-placeholder {
  font-size: 12px;
  color: #75787a;
}

.input-container-value input + .small-placeholder {
  font-size: 10px;
}

.input-container-empty input + label {
  position: absolute;
  -webkit-transition: font-size 0.3s ease;
  -moz-transition: font-size 0.3s ease;
  -o-transition: font-size 0.3s ease;
  transition: font-size 0.3s ease, transform 0.4s;
}

.input-container-empty input:focus + label {
  position: absolute;
  transform: translate(0%, -115%);
  -webkit-transition: font-size 0.3s ease;
  -moz-transition: font-size 0.3s ease;
  -o-transition: font-size 0.3s ease;
  transition: font-size 0.3s ease, transform 0.4s;
}

.input-container-value input + label {
  position: absolute;
  transform: translate(0%, -115%);
}

.input-container-label-background {
  position: absolute;
  z-index: 0;
  top: -1px;
  margin-left: -2px;
  background-color: white;
  width: 0;
  height: 1px;
  -webkit-transition: width 0.1s ease-in-out;
  -moz-transition: width 0.1s ease-in-out;
  -o-transition: width 0.1s ease-in-out;
  transition: width 0.1s ease-in-out;
}

.input-container-empty input:focus + label + .input-container-label-background {
  width: 0; /* placeholder width */
}

.input-container-value .input-container-label-background {
  width: 0; /* placeholder width */
}

.input-field {
  width: 100%;
  outline: none;
}

.large-text {
  font-size: 16px;
}

.small-text {
  font-size: 12px;
}

.hidden {
  display: none;
}
</style>
