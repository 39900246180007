var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.loaded,
      expression: "loaded"
    }],
    staticStyle: {
      "width": "720px",
      "display": "flex",
      "flex-direction": "column",
      "gap": "16px"
    }
  }, _vm._l(_vm.interests, function (interest, index) {
    return _c('div', {
      key: interest._id,
      staticClass: "interest"
    }, [_c('div', {
      staticClass: "interest-title-wrapper bottom-radius",
      attrs: {
        "id": 'title' + index
      }
    }, [_c('div', {
      staticClass: "interest-title-row"
    }, [_c('div', {
      staticClass: "interest-heading"
    }, [_c('p', {
      staticClass: "interest-title-text",
      class: {
        'interest-title-text': interest.statusCode === 1,
        'interest-title-text inactive-text': interest.statusCode !== 1
      }
    }, [_vm._v(" " + _vm._s(_vm.formatTransactionType(interest.transactionType)) + " ")]), _c('img', {
      class: {
        'point-separator': interest.statusCode === 1,
        'point-separator inactive-svg': interest.statusCode !== 1
      },
      attrs: {
        "src": require("@/assets/images/•.svg"),
        "alt": ""
      }
    }), interest.estateType ? _c('p', {
      class: {
        'interest-title-text': interest.statusCode === 1,
        'interest-title-text inactive-text': interest.statusCode !== 1
      }
    }, [_vm._v(" " + _vm._s(_vm.formatEstateType(interest.estateType)) + " ")]) : _vm._e(), interest.address.county ? _c('img', {
      class: {
        'point-separator': interest.statusCode === 1,
        'point-separator inactive-svg': interest.statusCode !== 1
      },
      attrs: {
        "src": require("@/assets/images/•.svg"),
        "alt": ""
      }
    }) : _vm._e(), interest.address.county ? _c('p', {
      class: {
        'interest-address-text': interest.statusCode === 1,
        'interest-address-text inactive-text': interest.statusCode !== 1
      }
    }, [_vm._v(" " + _vm._s(_vm.interestTitle(interest)) + " ")]) : _vm._e()]), _c('IconDropdown', {
      staticStyle: {
        "border-radius": "8px"
      },
      attrs: {
        "items": _vm.interestActions,
        "display-key": 'name',
        "value-key": 'value',
        "icon": 'dot_menu.svg'
      },
      on: {
        "itemSelected": function ($event) {
          return _vm.selectInterestAction($event, interest);
        }
      }
    })], 1), _c('div', {
      staticClass: "interest-buttons-row"
    }, [_c('div', {
      key: _vm.countUpdater,
      class: {
        'interest-button': !_vm.openedExtras.listings.includes(interest._id),
        'interest-button button-active': _vm.openedExtras.listings.includes(interest._id)
      },
      on: {
        "click": function ($event) {
          return _vm.openExtras(interest._id, 'listings');
        }
      }
    }, [_c('img', {
      attrs: {
        "src": require("@/assets/images/objects.svg"),
        "alt": ""
      }
    }), _c('p', {
      staticClass: "button-small"
    }, [_vm._v(" " + _vm._s(_vm.listingsCount(interest)) + " " + _vm._s(_vm.$t("Listingst")) + " ")])]), _c('div', {
      class: {
        'interest-button': !_vm.openedExtras.details.includes(interest._id),
        'interest-button button-active': _vm.openedExtras.details.includes(interest._id)
      },
      on: {
        "click": function ($event) {
          return _vm.openExtras(interest._id, 'details');
        }
      }
    }, [_c('img', {
      attrs: {
        "src": require("@/assets/images/info.svg"),
        "alt": ""
      }
    }), _c('p', {
      staticClass: "button-small"
    }, [_vm._v(_vm._s(_vm.$t("Details")))])]), _c('div', {
      class: {
        'interest-button': !_vm.openedExtras.comments.includes(interest._id),
        'interest-button button-active': _vm.openedExtras.comments.includes(interest._id)
      },
      on: {
        "click": function ($event) {
          return _vm.openExtras(interest._id, 'comments');
        }
      }
    }, [interest.comment ? _c('img', {
      attrs: {
        "src": require("@/assets/images/chat-bubble.svg"),
        "alt": ""
      }
    }) : _c('img', {
      attrs: {
        "src": require("@/assets/images/chat-no-bubble.svg"),
        "alt": ""
      }
    }), _c('p', {
      staticClass: "button-small"
    }, [_vm._v(_vm._s(_vm.$t("Comment")))])]), _c('div', {
      class: {
        'interest-button': !_vm.openedExtras.settings.includes(interest._id),
        'interest-button button-active': _vm.openedExtras.settings.includes(interest._id)
      },
      on: {
        "click": function ($event) {
          return _vm.openExtras(interest._id, 'settings');
        }
      }
    }, [_c('img', {
      attrs: {
        "src": require("@/assets/images/admin.svg"),
        "alt": ""
      }
    }), _c('p', {
      staticClass: "button-small"
    }, [_vm._v(_vm._s(_vm.$t("Settings")))])])])]), _c('InterestListings', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.openedExtras.listings.includes(interest._id),
        expression: "openedExtras.listings.includes(interest._id)"
      }],
      key: _vm.listingsUpdater,
      attrs: {
        "project-id": _vm.projectId,
        "project-name": _vm.project.name,
        "interest": interest,
        "chosen-listing-preview-id": _vm.chosenListingPreviewId,
        "chosen-interest-id": _vm.chosenInterestId
      },
      on: {
        "openListingPreview": _vm.openListingPanel,
        "closeListingPreview": _vm.closeListingPanel,
        "funnelRemoved": function ($event) {
          return _vm.$emit('funnelRemoved');
        },
        "refreshLeads": _vm.refresh,
        "autoSuggestionsCount": _vm.setAutoSuggestionsCount
      }
    }), _c('InterestComment', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.openedExtras.comments.includes(interest._id),
        expression: "openedExtras.comments.includes(interest._id)"
      }],
      attrs: {
        "comment": interest.comment,
        "project-id": _vm.projectId,
        "interest": interest
      },
      on: {
        "commentUpdated": _vm.refresh
      }
    }), _c('InterestDetails', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.openedExtras.details.includes(interest._id),
        expression: "openedExtras.details.includes(interest._id)"
      }],
      attrs: {
        "interest": interest
      },
      on: {
        "editInterest": function ($event) {
          return _vm.editFromDetails($event);
        }
      }
    }), _c('InterestSettings', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.openedExtras.settings.includes(interest._id),
        expression: "openedExtras.settings.includes(interest._id)"
      }],
      attrs: {
        "project-id": _vm.projectId,
        "interest": interest
      },
      on: {
        "changeInterestStatus": _vm.changeInterestStatus,
        "changeInterestWideSearch": _vm.changeInterestWideSearch,
        "toggled": function ($event) {
          return _vm.toggleOutputs($event);
        }
      }
    })], 1);
  }), 0);

}
var staticRenderFns = []

export { render, staticRenderFns }