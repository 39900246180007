<template>
  <div id="details-dropdown" class="details-dropdown" v-click-outside="closeWidget">
    <div v-for="(item, index) in data" class="details-dropdown-content-row" :key="'details-dropdown-item-' + index">
      <div class="details-dropdown-icon">
        <img v-if="item.icon" :src="require(`@/assets/images/${item.icon}`)" alt=""/>
      </div>
      <div v-if="item.editType === 'text'" class="details-dropdown-content">
        <div class="label12" style="color: #75787A">
          {{ item.title }}
        </div>
        <div v-if="!inlineEditOpen || inlineEditIndex !== index" @click="inlineEdit(item, index)"
             style="cursor: pointer; word-break:break-all" :class="{ 'content': item.font === 'normal',
                 'content-small': item.font === 'small' || !item.font}">
          <span>
            {{ item.value }}
          </span>
          <span v-if="item.value && item.value !== '-'">
            {{ item.valueSuffix }}
          </span>
        </div>
        <v-text-field type="number" @focusout="saveEditedItem(item, index)" ref="inlineEdit"
                      @keydown.enter="$event.target.blur()" style="height: 24px;" dense
                      v-if="inlineEditOpen && inlineEditIndex === index" v-model="item.value"></v-text-field>
      </div>


      <div v-if="item.editType === 'options'" class="details-dropdown-content">
        <div class="label12" style="color: #75787A">{{ item.title }}</div>
        <div @click="openOptions" style="word-break: break-word; cursor: pointer;" :class="{ 'content': item.font === 'normal',
                 'content-small': item.font === 'small' || !item.font}">
          <span style="word-break: keep-all; white-space: nowrap">{{ formatOptionValue(item) }}</span>
          <span v-if="item.value && item.value !== '-'">{{ item.valueSuffix }}</span>
        </div>
        <div ref="dropdownOptions" @focusout="optionsOpen = false" tabindex="0" v-if="optionsOpen"
             style="position: absolute; outline: none; display: flex; flex-direction: column; gap: 16px; z-index: 9; padding: 12px; border: 1px solid #E6E8EC; border-radius: 8px; background-color: white;">
          <div v-for="option in item.options" :key="option.value">
            <span v-if="option.value === item.value" style="font-weight: 600; cursor: pointer;"
                  class="content-small">{{ option.name }}</span>
            <span @click="chooseOption(option, item)" v-else class="content-small"
                  style="cursor: pointer;">{{ option.name }}</span>
          </div>
        </div>
      </div>


      <div v-if="item.editType === 'calendar'" class="details-dropdown-content">
        <div class="label12" style="color: #75787A">{{ item.title }}</div>
        <date-pick style="width: 100%;" @input="(date) => {chooseDate(date, item)}">
          <template style="width: 100%;" v-slot:default="{ toggle }">
            <div @click="toggle" style="word-break: break-all; cursor: pointer; width: 100%;" :class="{ 'content': item.font === 'normal',
                     'content-small': item.font === 'small' || !item.font}">
              <span style="width: 100%; white-space: nowrap">{{ formatDate(item.value) }}</span>
            </div>
          </template>
        </date-pick>
      </div>


      <div v-if="!item.editType" class="details-dropdown-content">
        <div class="label12" style="color: #75787A">{{ item.title }}</div>
        <div style="word-break: break-all" :class="{ 'content': item.font === 'normal',
                 'content-small': item.font === 'small' || !item.font}">
          <span>{{ item.value }}</span>
          <span v-if="item.value && item.value !== '-'">{{ item.valueSuffix }}</span>
        </div>
      </div>
      <div v-if="item.clearable && item.value !== '-'" class="details-dropdown-row-clear-button">
        <img @click="clearField(item, index)" style="cursor: pointer;" :src="require(`@/assets/images/close_16_16.svg`)"
             alt=""/>
      </div>
    </div>
    <div @click="isDeleteModalOpen = true" style="height: 20px; cursor: pointer;" v-if="showDelete"
         class="details-dropdown-content-row">
      <div class="details-dropdown-icon">
        <img :src="require(`@/assets/images/red_trash_20_20.svg`)" alt=""/>
      </div>
      <div style="display: flex; align-items: center; max-height: 20px;" class="details-dropdown-content">
        <div style="color: #FF1E24" class="content-small">{{$t("Delete")}}</div>
      </div>
    </div>
    <DeleteModal
        @canceled="isDeleteModalOpen = false"
        @approved="$emit('delete', item);isDeleteModalOpen = false;"
        v-if="isDeleteModalOpen"
    />
  </div>
</template>

<script>
import DeleteModal from "@/components/common/DeleteModal";
import DatePick from "vue-date-pick";
import moment from "moment";

export default {
  components: {
    DeleteModal,
    DatePick,
  },
  name: "DetailsDropdown",
  props: ["data", "position", "showDelete", "item", "key"],
  data() {
    return {
      isDeleteModalOpen: false,
      inlineEditOpen: false,
      inlineEditIndex: null,
      optionsOpen: false,
    }
  },
  mounted() {
    if (this.position) {
      this.initializePosition();
    }
  },
  methods: {
    initializePosition() {
      const position = {};
      position.top = this.position.top + window.scrollY - 60;
      position.left = this.position.left + window.scrollX;
      let element = document.getElementById("details-dropdown");
      element.style.setProperty("position", "absolute");
      element.style.setProperty("left", position.left + "px");
      element.style.setProperty("top", position.top + "px");
    },
    closeWidget() {
      this.$emit("closeWidget");
    },
    inlineEdit(item, index) {
      console.log("editing", item);
      this.inlineEditOpen = true;
      this.inlineEditIndex = index;
      this.$nextTick(() => this.$refs.inlineEdit[0].focus());
    },
    clearField(item) {
      item.value = "-";
      this.$emit("action", item);
    },
    saveEditedItem(item, index) {
      this.inlineEditOpen = false;
      this.inlineEditIndex = null;
      if (item.value === "" || /^\s*$/.test(item.value)) {
        item.value = "-";
      }
      // endpoint? to save edited task item = edited item, this.item = parent item
      console.log(item.value);
      console.log(index);
      this.$emit("action", item);
    },
    formatDate(date) {
      const timestamp = Date.parse(date);
      if (isNaN(timestamp) === false) {
        return moment(date).format("DD.MM.YYYY");
      }
      return "-";
    },
    openOptions() {
      this.optionsOpen = true;
      this.$nextTick(() => this.$refs.dropdownOptions[0].focus());
    },
    chooseOption(option, item) {
      item.value = option.value;
      this.$emit("action", item);
    },
    chooseDate(date, item) {
      item.value = date;
      this.$emit("action", item);
    },
    formatOptionValue(item) {
      console.log(item);
      console.log("ABOVE MEE");
      if (item.options.filter((option) => option.value === item.value).length > 0) {
        return item.options.filter((option) => option.value === item.value)[0].name;
      } else {
        return item.value;
      }
    }
  }
}
</script>

<style scoped>
.details-dropdown {
  display: flex;
  flex-direction: column;
  padding: 11px;
  width: 148px;
  background: white;
  border: 1px solid #E6E8EC;
  border-radius: 8px;
  z-index: 5;
  gap: 16px;
}

.details-dropdown-content-row {
  display: flex;

}

.details-dropdown-icon {
  width: 20px;
}

.details-dropdown-content {
  width: 72px;
  margin: 0 8px;
  white-space: nowrap;
}

.details-dropdown-row-clear-button {
  width: 16px;
}
</style>