<template>
  <div @click="closePopover($event)" id="overlay" class="overlay">
    <div id="popover" class="card-details">
      <div class="address-div" id="InAadressRow" style="width: 100%"></div>
      <div class="object-rows" v-if="deal.objects.length">
        <div
          v-for="(object, index) in deal.objects"
          :key="object._id"
          class="object-row"
        >
          <img
            @click="deleteObject(index)"
            src="../../assets/images/action-delete.svg"
            alt=""
          />
          <p>{{ object.address }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import nanoid from "nanoid";

export default {
  name: "AddObjectPopover",
  props: ["deal", "position"],
  data: () => ({
    aadressSearch: null,
    apiSearchOpen: false,
    cities: [],
    districts: [],
    cityParts: [],
    parentName: null,
    isCustomerTask: false,
    isDealTask: false,
  }),
  created() {},
  mounted() {
    let topPosition = this.position.top;
    let rightPosition = this.position.right;
    document
      .getElementById("popover")
      .style.setProperty("top", topPosition + "px");
    document
      .getElementById("popover")
      .style.setProperty("right", rightPosition + "px");
    this.initiateApi();
  },
  beforeDestroy() {
    this.aadressSearch = null;
  },
  computed: {},

  methods: {
    containsWord(str, word) {
      return str.match(new RegExp("\\b" + word + "\\b")) != null;
    },
    initiateApi() {
      // eslint-disable-next-line no-undef
      this.aadressSearch = new InAadress({
        container: "InAadressRow",
        mode: "3",
        nocss: true,
        appartment: 0,
        ihist: 0,
        lang: "et",
        namespace: "projectGeneral",
      });
      let searchInput = null;
      const search = setInterval(() => {
        if (document.getElementsByClassName("ui-input-search")[0]) {
          searchInput =
            document.getElementsByClassName("ui-input-search")[0].childNodes[0];
          searchInput.placeholder = this.$t("FindAddress");
          document.getElementsByClassName("ui-popup")[0].style.display = "none";
          clearInterval(search);
        }
      }, 10);

      document.addEventListener("addressSelected.projectGeneral", async (a) => {
        let object = { _id: nanoid(15) };
        console.log(a);
        console.log(a.detail);
        a.detail.forEach((param) => {
          if (param.liik == "CU") {
            object.cadastralNumber = param?.orig_tunnus;
          } else if (param.liik != "CU") {
            object.type = param?.liik;
          }
        });
        object.street = a.detail[0]?.liikluspind;
        object.houseNumber = a.detail[0]?.aadress_nr;
        object.county = a.detail[0]?.maakond;
        let asum = a.detail[0].asum;
        let asustusyksus = a.detail[0].asustusyksus;
        let kohanimi = a.detail[0].nimi;
        if (kohanimi.length > 1) {
          object.street = kohanimi;
        }
        if (
          !this.containsWord(asustusyksus, "linn") &&
          !this.containsWord(asum, "linn")
        ) {
          if (asum.length > 1 && asum === "Vanalinna asum") {
            this.cityParts.push(asum);
            object.district = asum;
          } else {
            object.cityPart = null;
            object.district = asustusyksus;
          }
          this.districts.push(asustusyksus);
        }
        if (this.containsWord(asustusyksus, "linn")) {
          this.cities.push(asustusyksus.split(" linn")[0]);
          object.city = asustusyksus.split(" linn")[0];
          object.district = null;
          object.cityPart = null;
        } else {
          if (this.containsWord(a.detail[0].omavalitsus, " linn")) {
            object.city = a.detail[0].omavalitsus.split(" linn")[0];
          } else {
            object.city = a.detail[0].omavalitsus;
          }
        }
        const objectAddress = this.createObjectAddress(object);
        object.address = objectAddress;
        this.aadressSearch.setAddress("");
        object.units = [];
        object.listings = [];
        this.deal.objects.push(object);
        this.aadressSearch.hideResult();
        await axios.patch("/api/project", this.deal);
        this.$emit("refreshDeal");
      });
    },
    createObjectAddress(object) {
      let listingTitleObject = {
        street: object.street,
        houseNumber: object.houseNumber,
        district: object.district,
        cityPart: object.cityPart,
        city: object.city,
        county: object.county,
      };

      listingTitleObject = Object.fromEntries(
        Object.entries(listingTitleObject).filter(
          //eslint-disable-next-line no-unused-vars
          ([_, v]) => v != null && v != "" && v != "undefined"
        )
      );
      let listingTitle = "";
      for (const [key, value] of Object.entries(listingTitleObject)) {
        if (key && value && value !== "undefined") {
          if (key === "street" && listingTitleObject.houseNumber) {
            listingTitle = listingTitle + value + " ";
          } else {
            listingTitle = listingTitle + value + ", ";
          }
        }
      }

      let titleEnd = listingTitle.slice(listingTitle.length - 2);
      if (titleEnd === ", ") {
        listingTitle = listingTitle.slice(0, -2);
      }
      return listingTitle;
    },
    async deleteObject(id) {
      let findObject = this.deal.objects[id];
      let objectId = findObject._id;
      let deletableObject = { objectId };
      this.deal.contactPersonName = this.deal.contactPerson.contactName;
      this.deal.objects.splice(id, 1);
      await axios.post(
        `/api/project/object/delete/${this.deal._id}`,
        deletableObject
      );
      this.$emit("refreshDeal");
    },
    closePopover(e) {
      let popover = document.getElementById("overlay");
      if (e.target === popover) {
        this.$emit("closed");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
#popover {
  position: absolute;
}
::v-deep .inaadressSearch,
::v-deep .ui-input-search {
  ul {
    //border: 1px solid #e6e8ec !important;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    background-color: white !important;
  }
  input {
    width: 80% !important;
    padding: 8px 12px;
    height: 52px;

    &:focus-visible {
      outline: none;
      border: none;
      //background-color: white !important;
    }

    &::after {
      content: url("../../assets/images/Dollar.svg");
    }
  }
}
::v-deep .ui-input-clear,
::v-deep .inAdsCopyright {
  display: none;
}
::v-deep #InAadressRow {
  z-index: 5;
}
::v-deep .ui-filterable {
  border: 1px solid #e6e8ec !important;
  border-radius: 8px;
  background: white;
  &:focus-visible {
    border: none;
    outline: none;
  }
}
.address-selected {
  padding-top: 4px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  /* or 143% */

  display: flex;
  align-items: center;

  /* black */

  color: #000000;
}
::v-deep .ui-input-search {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  &:after {
    margin-right: 12px;
    margin-top: 6px;
    content: url("../../assets/images/search.svg");
  }
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;

  width: 560px;

  /* white */

  background: #ffffff;
  /* shadow1 */

  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 1px 2px rgba(0, 0, 0, 0.03),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
}
.object-rows {
  margin-top: 16px;
}
.object-row {
  display: flex;
  gap: 12px;
  flex-direction: row;
  align-items: center;
  padding: 12px;
  width: 512px;
  background: #f4f5f7;
  border-radius: 8px;
  margin: 4px 0px;
  img {
    cursor: pointer;
  }
}
</style>
