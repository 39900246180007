<template>
  <div
    @keyup.esc="closeModalPanel"
    class="overlay"
    id="overlay-customer-related"
    @click="closeModalPanel($event)"
  >
    <div id="modal" class="panel-wrapper">
      <v-row class="top-row"
        ><h4 class="main-subtitle">Lisa seotud isik</h4>
        <button @click="closeModalPanel()" class="close-button">
          <img src="../../../assets/images/cross.svg" alt="" />
        </button>
      </v-row>
      <v-banner single-line></v-banner>
      <div class="relationModal-0-2-185">
        <form novalidate="">
          <div class="modalContent-0-2-188">
            <h5 class="title-0-2-189">Lisa isik</h5>
            <div class="relationSearch-0-2-190">
              <div class="search-0-2-193">
                <div class="find-person">
                  <v-autocomplete
                    return-object
                    value=""
                    :items="customersCopy"
                    v-model="selectedRelation"
                    autocomplete="nope"
                    prepend-inner-icon="mdi-magnify"
                    outlined
                    item-text="customerName"
                    :placeholder="$t('CustomerRelation.FieldPlaceholder.COMP')"
                  />
                </div>
              </div>
              <div>
                <button
                  type="button"
                  class="addPersonButton-0-2-204"
                  @click="
                    showDiv();
                    name = null;
                    phone = null;
                    email = null;
                  "
                >
                  <span class="buttonLabel-0-2-206">Lisa uus seotud isik</span
                  ><svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="plusSign-0-2-205"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M4.46447 11.5353C4.85499 11.9258 5.48816 11.9258 5.87868 11.5353L8 9.41399L10.1213 11.5353C10.5118 11.9258 11.145 11.9258 11.5355 11.5353C11.9261 11.1448 11.9261 10.5116 11.5355 10.1211L9.41421 7.99978L11.5355 5.87846C11.9261 5.48793 11.9261 4.85477 11.5355 4.46424C11.145 4.07372 10.5118 4.07372 10.1213 4.46424L8 6.58557L5.87868 4.46424C5.48816 4.07372 4.85499 4.07372 4.46447 4.46424C4.07394 4.85477 4.07394 5.48793 4.46447 5.87846L6.58579 7.99978L4.46447 10.1211C4.07394 10.5116 4.07394 11.1448 4.46447 11.5353Z"
                      fill="black"
                    ></path>
                  </svg>
                </button>
              </div>
            </div>
            <div
              class="relationRow-0-2-232 addedRelation-0-2-207"
              id="added-person"
              style="display: none"
            >
              <div
                class="
                  relationIcon-0-2-234
                  alignedRow-0-2-233
                  relationIcon-d4-0-2-247
                "
              >
                <span class="initialsText-0-2-241">+</span>
              </div>
              <div class="relationInfo-0-2-235 alignedRow-0-2-233">
                <div class="roleInfo-0-2-239 relationBlock-0-2-236">
                  <span>{{ name }}</span
                  ><span class="roleLabel-0-2-238 smallText-0-2-237"></span>
                </div>
                <div
                  class="
                    buttonContainer-0-2-240
                    relationBlock-0-2-236
                    smallText-0-2-237
                  "
                >
                  <button
                    @click="
                      email = null;
                      phone = null;
                      name = null;
                      showPerson();
                    "
                    type="button"
                    class="removeRelation-0-2-242"
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.3334 4.00004H14.6667V5.33337H13.3334V14C13.3334 14.1769 13.2631 14.3464 13.1381 14.4714C13.0131 14.5965 12.8435 14.6667 12.6667 14.6667H3.33337C3.15656 14.6667 2.98699 14.5965 2.86197 14.4714C2.73695 14.3464 2.66671 14.1769 2.66671 14V5.33337H1.33337V4.00004H4.66671V2.00004C4.66671 1.82323 4.73695 1.65366 4.86197 1.52864C4.98699 1.40361 5.15656 1.33337 5.33337 1.33337H10.6667C10.8435 1.33337 11.0131 1.40361 11.1381 1.52864C11.2631 1.65366 11.3334 1.82323 11.3334 2.00004V4.00004ZM12 5.33337H4.00004V13.3334H12V5.33337ZM6.00004 7.33337H7.33338V11.3334H6.00004V7.33337ZM8.66671 7.33337H10V11.3334H8.66671V7.33337ZM6.00004 2.66671V4.00004H10V2.66671H6.00004Z"
                        fill="#08051B"
                      ></path>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div class="rows-0-2-191 rows-d2-0-2-222"></div>
          </div>
          <div class="modalFooter-0-2-214">
            <div id="new-person" class="add-new-person" style="display: none">
              <h5 class="title-0-2-213">Uus isik</h5>
              <div class="newRelationInputs-0-2-210">
                <div class="newRelationField-0-2-211">
                  <div
                    class="
                      inputFieldWrapper-0-2-199 inputFieldWrapper-d55-0-2-387
                      textInputField-0-2-219 textInputField-d108-0-2-385
                    "
                  >
                    <input
                      v-model="name"
                      type="text"
                      name="customerName"
                      class="inputField-0-2-220"
                      required=""
                      placeholder="Kliendi nimi (kohustuslik)"
                    />
                    <label
                      for="customerName"
                      class="label-0-2-222 label-d109-0-2-386"
                    ></label>
                  </div>
                </div>
                <div class="newRelationField-0-2-211">
                  <div
                    class="
                      inputFieldWrapper-0-2-199 inputFieldWrapper-d56-0-2-390
                      textInputField-0-2-219 textInputField-d110-0-2-388
                    "
                  >
                    <span class="prefix-0-2-202 icon-0-2-200"
                      ><svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M13.3333 3.33333H2.66663C2.29844 3.33333 1.99996 3.63181 1.99996 4V12C1.99996 12.3682 2.29844 12.6667 2.66663 12.6667H13.3333C13.7015 12.6667 14 12.3682 14 12V4C14 3.63181 13.7015 3.33333 13.3333 3.33333ZM2.66663 2C1.56206 2 0.666626 2.89543 0.666626 4V12C0.666626 13.1046 1.56206 14 2.66663 14H13.3333C14.4379 14 15.3333 13.1046 15.3333 12V4C15.3333 2.89543 14.4379 2 13.3333 2H2.66663Z"
                          fill="black"
                        ></path>
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M3.48779 4.9064C3.7235 4.62355 4.14387 4.58533 4.42672 4.82104L7.57315 7.44306C7.82038 7.64908 8.17949 7.64908 8.42672 7.44306L11.5731 4.82104C11.856 4.58533 12.2764 4.62355 12.5121 4.9064C12.7478 5.18925 12.7096 5.60963 12.4267 5.84533L9.2803 8.46735C8.53861 9.08543 7.46126 9.08543 6.71957 8.46735L3.57315 5.84533C3.29029 5.60963 3.25208 5.18925 3.48779 4.9064Z"
                          fill="black"
                        ></path></svg></span
                    ><input
                      v-model="email"
                      type="text"
                      name="email"
                      class="inputField-0-2-220"
                      placeholder="E-post"
                    /><label
                      for="email"
                      class="label-0-2-222 label-d111-0-2-389"
                    ></label>
                  </div>
                </div>
                <div class="newRelationField-0-2-211">
                  <div
                    class="
                      inputFieldWrapper-0-2-199 inputFieldWrapper-d57-0-2-393
                      textInputField-0-2-219 textInputField-d112-0-2-391
                    "
                  >
                    <span class="prefix-0-2-202 icon-0-2-200"
                      ><svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0)">
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M3.09838 1.56812C3.959 0.707503 5.38234 0.808659 6.1126 1.78234L7.32478 3.39858C7.92196 4.19482 7.84277 5.30901 7.13899 6.01279L6.24528 6.9065C6.33189 7.13194 6.61701 7.62666 7.48528 8.49493C8.35355 9.3632 8.84827 9.64832 9.07371 9.73493L9.96742 8.84122C10.6712 8.13744 11.7854 8.05825 12.5816 8.65543L14.1979 9.86762C15.1716 10.5979 15.2727 12.0212 14.4121 12.8818C14.1323 13.1616 14.0849 13.209 13.618 13.6759C13.1421 14.1518 12.1317 14.5966 11.1087 14.641C9.50782 14.7107 7.3333 13.9998 4.65685 11.3234C1.9804 8.64691 1.26956 6.47239 1.33917 4.87151C1.37789 3.98098 1.66101 3.00289 2.30804 2.36592C2.77124 1.89527 2.83147 1.83503 3.09838 1.56812ZM2.67124 4.92943C2.62299 6.03913 3.09066 7.87155 5.59966 10.3805C8.10866 12.8895 9.94109 13.3572 11.0508 13.309C12.0867 13.2639 12.6424 12.7634 12.6752 12.7331L13.4693 11.939C13.7562 11.6521 13.7224 11.1777 13.3979 10.9343L11.7816 9.7221C11.5162 9.52304 11.1448 9.54943 10.9102 9.78403C10.5568 10.1375 10.3155 10.3823 9.8432 10.8528C8.86211 11.8302 7.18273 10.078 6.54247 9.43774C5.95408 8.84935 4.16098 7.11665 5.12641 6.13976C5.12832 6.13785 5.38835 5.87781 6.19618 5.06998C6.43078 4.83539 6.45717 4.46399 6.25811 4.19858L5.04593 2.58234C4.80251 2.25778 4.32806 2.22406 4.04119 2.51093C3.77716 2.77497 3.47834 3.07378 3.24792 3.3058C2.77367 3.78332 2.69662 4.3457 2.67124 4.92943Z"
                            fill="black"
                          ></path>
                        </g>
                        <defs>
                          <clipPath id="clip0">
                            <rect width="16" height="16" fill="white"></rect>
                          </clipPath>
                        </defs></svg></span
                    ><input
                      v-model="phone"
                      type="tel"
                      name="phoneNumber"
                      class="inputField-0-2-220"
                      placeholder="Telefoni number"
                    /><label
                      for="phoneNumber"
                      class="label-0-2-222 label-d113-0-2-392"
                    ></label>
                  </div>
                </div>
                <div class="newRelationField-0-2-211">
                  <button
                    class="addRelationButton-0-2-212"
                    type="button"
                    @click="
                      showPerson();
                      showDiv();
                    "
                  >
                    Lisa seotud isik
                  </button>
                </div>
              </div>
              <v-banner single-line></v-banner>
            </div>
          </div>
        </form>
        <div class="buttonContainer-0-2-216 button-0-2-215">
          <div>
            <button
              type="button"
              class="cancelButton-0-2-218 button-0-2-215"
              @click="closeModalPanel()"
            >
              Katkesta
            </button>
          </div>
          <div>
            <button @click="createRelation()" class="submitButton-0-2-217">
              Lisa seotud isik
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
export default {
  name: "Modal",
  props: ["customerId", "fromProjectGen"],
  data() {
    return {
      showAddPerson: false,
      email: null,
      phone: null,
      name: null,
      selectedRelation: null,
      customersCopy: null,
    };
  },
  created() {
    this.customersCopy = this.customers.filter(
      (customer) => customer.customerId !== this.customerId
    );
  },
  mounted() {
    let modal = document.getElementById("overlay-customer-related");
    window.onclick = (e) => {
      if (e.target === modal) {
        /*With 0 state add-person window did not appear when click outside overlay */
        this.changeModalStateDeals(0);
      }
      //this.closeModalPanel(e);
    };
  },

  computed: {
    ...mapGetters(["customers"]),
  },
  methods: {
    ...mapActions(["changeModalStateDeals"]),
    closeModalPanel(e) {
      if (!e) {
        this.changeModalStateDeals(0);
        this.$emit("modalClosed");
        return;
      } else if (e.target.id === "overlay-customer-related") {
        this.changeModalStateDeals(0);
        this.$emit("modalClosed");
        return;
      }
      if (e.target !== e.currentTarget) return;
    },

    async createRelation() {
      if (!this.fromProjectGen) {
        if (this.selectedRelation === null && this.name !== null) {
          console.log("implementation missing");
          /*await axios.post(`/api/contact/relation/add/${this.customerId}`, {
            email: this.email,
            phone: this.phone,
            name: this.name,
            relatedTo: this.customerId,
          });
          this.$router.go();*/
        } else if (this.selectedRelation !== null) {
          let newRelation = { relationId: this.selectedRelation.customerId };
          await axios.post(
            `/api/contact/relation/add/${this.customerId}`,
            newRelation
          );
          this.$router.go();
        }
      } else {
        let newRelation = { relationId: this.selectedRelation.customerId };
        this.$emit("addRelatedPerson", newRelation);
        this.closeModalPanel();
      }
    },
    showDiv() {
      this.showAddPerson = !this.showAddPerson;
      if (this.showAddPerson) {
        var x = document.getElementById("new-person");
        x.style.display = "block";
      } else {
        var y = document.getElementById("new-person");
        y.style.display = "none";
      }
    },
    showPerson() {
      var x = document.getElementById("added-person");
      if (x.style.display === "none") {
        x.style.display = "block";
      } else {
        x.style.display = "none";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.panel-wrapper {
  position: absolute;
  background-color: #ffffff;
  padding: 16px;
  z-index: 104;
  min-width: 350px;
  border: 1px solid #e6e8ec;
  top: 10%;
  width: 675px;
  margin: auto;
  overflow-y: auto;
  border-radius: 8px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 103;
  display: flex;
  justify-content: center;
  align-items: center;
}

.top-row {
  width: 100%;
  justify-content: space-between;
}
.find-person {
  width: 500px;
}

.title-0-2-213 {
  margin-top: 16px;
  margin-bottom: 24px;
}
.newRelationInputs-0-2-210 {
  display: flex;
  flex-flow: row wrap;
}
.newRelationField-0-2-211 {
  flex: 1 0 calc(50% - 24px);
  display: flex;
  margin-bottom: 12px;
}
.textInputField-d108-0-2-385 {
  height: 32px;
}
/*
.add-new-person {
}
*/
::v-deep .v-text-field--outlined fieldset {
  border: 1px solid #e6e8ec !important;
}
.relationRow-0-2-232 {
  flex: 1 1 0%;
  display: flex;
  padding: 12px;
}
.addedRelation-0-2-207 {
  padding: 12px;
  margin-top: 12px;
  border-radius: 8px;
  background-color: #f4f5f7;
}
.relationIcon-d4-0-2-247 {
  background-color: rgb(11, 159, 1);
}
.relationIcon-0-2-234 {
  color: rgb(0, 0, 0);
  width: 32px;
  height: 32px;
  font-family: Geomanist;
  font-weight: bold;
  user-select: none;
  border-radius: 50%;
}
.alignedRow-0-2-233 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -4px;
  margin-bottom: -8px;
}
.roleInfo-0-2-239 {
  flex: 1 1 0%;
}
.relationBlock-0-2-236 {
  display: flex;
  flex-direction: column;
}
.roleLabel-0-2-238 {
  color: rgb(117, 120, 122);
  padding-top: 4px;
}

.smallText-0-2-237 {
  font-size: 12px;
  font-weight: 400;
}
.buttonContainer-0-2-240 {
  color: rgb(0, 0, 0);
  align-self: center;
  text-align: right;
}
.smallText-0-2-237 {
  font-size: 12px;
  font-weight: 400;
}
.removeRelation-0-2-242 {
  outline: none;
  padding: 8px 12px;
  background: none;
  appearance: none;
}
.relationBlock-0-2-236 {
  display: flex;
  flex-direction: column;
}
.initialsText-0-2-241 {
  overflow: hidden;
  max-width: 32px;
  text-align: center;
  text-overflow: ellipsis;
}
.relationInfo-0-2-235 {
  flex: 1 1 0%;
  padding-left: 16px;
  margin-left: 30px;
  margin-top: -32px;
}

.inputFieldWrapper-d55-0-2-387 {
  background-color: rgb(255, 255, 255);
  border-color: rgb(230, 232, 236);
  border-color: #e6e8ec !important;
}
.inputFieldWrapper-0-2-199 {
  width: 300px !important;
  border: 1px solid;
  height: 50px !important;
  display: flex;
  padding: 8px 12px;
  position: relative;
  transition: all 0.2s ease-out 0s;
  align-items: center;
  border-radius: 8px;
  justify-content: center;
  border-color: #e6e8ec !important;
}
.prefix-0-2-202 {
  padding-right: 12px;
}
.inputField-0-2-220 {
  width: 100%;
  height: 100%;
  margin-left: 3px;
}
.label-d109-0-2-386 {
  left: 12px !important;
}
.label-d111-0-2-389 {
  transform: translate(0px, 16px);
  font-size: 14px !important;
}
.textInputField-d112-0-2-391 {
  height: 32px;
}

.addRelationButton-0-2-212 {
  color: #ffffff;
  outline: none;
  background-color: #939597;
}
.label-0-2-222 {
  top: 14px;
  right: 160px;
  color: rgb(117, 120, 122);
  position: absolute;
  /*margin-right: 120px;*/
  pointer-events: none;
  font-size: 14px;
}
.icon-0-2-200 {
  width: 16px;
  height: 16px;
}
.relationSearch-0-2-190 {
  display: flex;
}
.search-0-2-193 {
  width: calc(50% - 12px);
  display: flex;
  margin-right: 24px;
}
.autocompleteFieldWrapper-0-2-195 {
  position: relative;
}
.inputFieldWrapper-d2-0-2-224 {
  background-color: rgb(255, 255, 255);
  border-color: rgb(230, 232, 236) !important;
}
.inputFieldWrapper-0-2-199 {
  width: 100%;
  border: 1px solid;
  display: flex;
  padding: 8px 12px;
  position: relative;
  transition: all 0.2s ease-out 0s;
  align-items: center;
  border-radius: 8px;
  justify-content: center;
}
.suffix-0-2-201 {
  right: 16px;
  position: absolute;
}

.icon-0-2-200 {
  width: 16px;
  height: 18px;
}
.inputField-0-2-194 {
  width: 100%;
  height: 100%;
}
input {
  font-size: 14px;
  font-style: normal;
  font-family: Inter;
}
.addPersonButton-0-2-204 {
  border: 1px solid #e6e8ec;
  height: 56px;
  display: flex;
  outline: none;
  padding: 12px 16px;
  font-size: 14px;
  align-items: center;
  font-weight: 400;
}
.buttonLabel-0-2-206 {
  padding-right: 16px;
}
.plusSign-0-2-205 {
  transform: rotate(45deg);
}
.close-button {
  background: #f4f5f7;
  border-radius: 8px;
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}
.title-0-2-189 {
  margin-top: 16px;
  margin-bottom: 24px;
}
.main-subtitle {
  display: flex;
  margin-top: 8px;
}
.modalContent-0-2-188 {
  padding: 0 24px;
  margin-left: -22px;
}
.modalFooter-0-2-214 {
  margin-top: 24px;
  border-top: 1px solid #e6e8ec;
}
.buttonContainer-0-2-216 {
  display: flex;
  padding: 16px 24px;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: -16px;
  margin-right: -14px;
}
.button-0-2-215 {
  outline: none;
}
.cancelButton-0-2-218 {
  border: 1px solid #e6e8ec;
  margin-right: 8px;
  height: 44px;
}
.button-0-2-215 {
  outline: none;
}
button {
  border: none;
  cursor: pointer;
  padding: 12px 16px;
  font-size: 14px;
  background: #ffffff;
  font-family: Inter;
  border-radius: 8px;
}
.submitButton-0-2-217 {
  color: #ffffff;
  background: #000000;
  height: 41px;
  padding-top: 10px;
}

.inputFieldWrapper-0-2-207 > input,
.inputFieldWrapper-0-2-207 > select,
.inputFieldWrapper-0-2-207 > textarea {
  border: none;
  outline: none;
  padding: 0px;
  background-color: transparent;
}
.inputField-0-2-202 {
  width: 100%;
  height: 100%;
}
.textInputField-d24-0-2-338 {
  height: 32px;
}
.inputFieldWrapper-d14-0-2-340 {
  background-color: rgb(244, 245, 247);
  border-color: rgb(244, 245, 247);
}
.inputFieldWrapper-0-2-207 {
  width: 100%;
  border: 1px solid;
  height: 32px;
  display: flex;
  padding: 8px 12px;
  position: relative;
  transition: all 0.2s ease-out 0s;
  align-items: center;
  border-radius: 8px;
  justify-content: center;
}
.label-d25-0-2-339 {
  left: 12px;
  transform: translate(0px, -13px);
}
.label-0-2-204 {
  top: 0px;
  color: rgb(117, 120, 122);
  position: absolute;
  transition: all 0.2s ease-out 0s;
  pointer-events: none;
  transform-origin: left top;
}

.labelActive-0-2-203 {
  left: 12px;
  padding: 4px 8px;
  font-size: 14px;
  font-family: Geomanist;
  border-radius: 8px;
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0) !important;
}
.inputFieldWrapper-d15-0-2-343 {
  background-color: rgb(255, 255, 255);
  border-color: rgb(230, 232, 236);
}
</style>
